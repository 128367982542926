import Routes from './ROUTES'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redite, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux'

// COMPONENTS
import SideBar from './COMPONENTS/SideMenu'
import AuthenticationLinks from './LINK/Authentication'
import { setProfleDetails } from "./SCREENS/Authentications/Login/slice.reducer";
import { ToastContainer } from 'react-toastify';
import Loader from "./COMPONENTS/Loader";
import ProvideTokenContext from "./STORE/App-wide-state/Token/provideTokenContext";

function App() { 

  const loader = useSelector(state => state.loader)
  const LoaderForIndividualPage = useSelector(state => state.LoaderForIndividualPage)
 
  return (
    <Router>
      <div className="App">
        {/* <ToastContainer /> */}
        { ReactDOM.createPortal(
            <ToastContainer />,
            document.getElementById('toast-portal')
        )}
        {loader.open && <Loader />} 
        {LoaderForIndividualPage.open && <Loader />} 
        <ProvideTokenContext>
          <Routes />
        </ProvideTokenContext>
      </div>
    </Router>
  );
}

export default App;