import React from "react";
import { Button } from 'reactstrap';
import Breadcrum from "../../COMPONENTS/Breadcrum";
import CloseIcon from './../../ASSETS/Images/icons/CloseSmall.svg';

const BreadCrumWithShareAndDelete= (props)=>{  

    console.log('this is BreadCrumWithShareAndDelete component 1', props.assestsCounts, props.isAnyOneIsSelected);

    return <div className={props.isAnyOneIsSelected != null && props.isAnyOneIsSelected != undefined ? 'col-sm-6' : 'col-12'}>   
            <Breadcrum   
                breadCrumbItems = {props.breadCrumbItems}
            />
            {props.isAnyOneIsSelected != null && props.isAnyOneIsSelected != undefined &&
                <Button className="mb-0 btn btn-link p-0" color="link" onClick={props.onUnselectAssest}>
                    <img src={CloseIcon} alt="Images" className="img-fluid me-2" /><span>{props.assestsCounts.length} Selected</span>
                </Button>
            }
        </div>
}

export default React.memo(BreadCrumWithShareAndDelete);