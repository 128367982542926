import React, {useState} from "react";
import {Modal, ModalBody} from 'reactstrap';
import EyeIcon from './../../ASSETS/Images/eye.png';
import OffEyeIcon from './../../ASSETS/Images/eye-off.png';
import { useDispatch, useSelector } from 'react-redux'
import {updatePinDispatch} from './../VR-experience/Slices/VRexperienceUser.slice';
import { ExpiredModal } from "../../COMPONENTS/Modal/Expired";
import { setDoNotShowExpiredModal } from "../Profile/slice.reducer";
import { toast } from 'react-toastify';

export default (props)=>{
   
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [pinShow, setPinShow] = useState(false);
    const userProfileDetails = useSelector(state => state.userProfileDetails)

    const toggle = () => {
        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        setModal(!modal);
    }

    const togglePin = () => {
        console.log(pinShow);
        setPinShow(!pinShow);
    }
 
    const getContent =(pin)=>  {
        return <><div className="mb-3">
        <small>Relationship</small>
        <h6>{pin.name}</h6>
    </div>
    <div className="mb-3">
        <small>Email</small>
        <h6 className="truncateEmail">{pin.email} </h6>
    </div>
    <div className="mb-3">
        <small>Pin</small>
        <h6 className="d-flex align-items-center">
            <span className={!pinShow ? 'pin hide' : 'pin'}>{!pinShow ? '......' : pin.pin}</span> 
            <img src={!pinShow ? EyeIcon : OffEyeIcon} className="viewPin" onClick={togglePin} alt="Eye" />
        </h6>
    </div>
    <div className="text-center pt-2">
        <button className="btn btn-outline-primary btn-sm" onClick={toggle}>Regenerate Pin</button>
    </div></>
    }

    const updatePin = async() => {

        let updatePINdata = {
            vruseraccessid: props.pin.id
        }
      
        dispatch(updatePinDispatch(updatePINdata));
        toggle()
    }

    return ( 
        <React.Fragment>
            <ExpiredModal />
            {getContent(props.pin)}
            {/* YOUR MODEL WILL GO HERE */}
            <Modal isOpen={modal} centered className="modal-pinGeneration" toggle={toggle}>
                <ModalBody>
                    {/* <h4 className="text-center mb-4">Share</h4> */}
                    <div className="p-2">
                        <div className="row">
                            <div className="col-12 text-center pt-3 pb-3">
                                <h5>Do you want to proceed with the PIN regeneration?</h5>
                            </div>
                        </div>
                        
                        <div className="row pb-3">
                            <div className="col-12 text-center mt-4">
                                <button onClick={toggle} className=" btn btn-outline-secondary me-2 ms-2 btn-long">Cancel</button>
                                <button onClick={updatePin} className="btn btn-primary  btn-long me-2 ms-2">Yes</button>
                            </div>
                        </div>
                    </div> 
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}