import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import SideBarMenu from "../../COMPONENTS/SideBarMenu/SideBarMenu";
import HeaderCOM from "../../COMPONENTS/Header/HeaderCOM";
import { useSelector, useDispatch } from 'react-redux'
import { logoutDispatch } from "../Authentications/Login/slice.reducer";
import { logOut } from "./../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import { fetchUserDetailsDispatch } from './../Profile/slice.reducer.tsx'
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";

export default (props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const localSessionDetails = useSelector(state => state.localSessionDetails)
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const location = useLocation();
    const tokenContext = useContext(CreateTokenContextProvider);

    function getRemainingState(location){
        if(location.state!==undefined) 
            return location.state; 
            return {}; 
    }

    useEffect(()=>{
        if( localSessionDetails.payload === ""){
            
            return navigate('/', {
                 state: {
                    "currentLocation": location.pathname, 
                    "state": getRemainingState(location)
                }});
            // return navigate('/') 
        }
    }, [localSessionDetails.payload])

    useEffect(()=>{
        window.scrollTo(0, 0);
        console.log('oj mey profile iof ths 1', location.pathname) 
        // dispatch(fetchUserDetailsDispatch());
    }, []);
   
    // useEffect(()=>{
    //     console.log("this is a usesrprofile useEffect userProfileDetails.isUserFetchFaild", userProfileDetails.isUserFetchFaild)
    //     if(userProfileDetails.isUserFetchFaild!== null){
    //         toast.error(userProfileDetails.isUserFetchFaild, { });
    //     }
    // }, [userProfileDetails.isUserFetchFaild]);
  
    const HeaderComponent = () =>{
        return <HeaderCOM />
    }

    const logoutFun =async ()=>{
        try{
            let data = {
               email :userProfileDetails.email
            }
            await logOut(data, dispatch, localSessionDetails.payload.accessToken);
            tokenContext.reset();
          }catch (err){
        }
    }
    
    const SidebarComponent = () =>{
        return <SideBarMenu onLogout={()=>{
            dispatch(logoutDispatch(navigate));
            logoutFun();
        } }/>
    } 

    return (
        <React.Fragment>
            {HeaderComponent()}
            {SidebarComponent()}
            <div className="main-content">
                {props.children}
            </div>
        </React.Fragment>
    )
}