import React, {useState} from "react";
import CustomeCardBody from "./../../COMPONENTS/HOME/CustomeCard/CardBody";
import CustomeCardFooter from "./../../COMPONENTS/HOME/CustomeCard/CardFooter";
import {  Col, Card, Row } from 'reactstrap';
import Image2 from './../../ASSETS/Images/1.jpg';
import Video2 from './../../ASSETS/Images/2.jpg';
import Audio2 from './.././../ASSETS/Images/3.jpg';
import UserInfoCard from "../../COMPONENTS/HOME/CustomeCard/UserInfoCard";
// import ReactSimplyCarousel from 'react-simply-carousel';
import SimpleCustomCarousel from "../../COMPONENTS/SimpleCustomCarousel/index";
import { random } from "../../UTILITYS/index.tsx";

export default (props)=>{ 
    
    // const [tooltipOpen, setTooltipOpen] = useState(false);
    
    // SELF LIBRARY ON HOME PAGE 
    const getAssestList = ()=>{
        
        let audio =null;
        let video =null;
        let img =null;
      
        audio = props.selfAUDIOAssestsOnHome !=undefined && props.selfAUDIOAssestsOnHome.length >0 && props.selfAUDIOAssestsOnHome.map((assest, index)=>{
            return <UserInfoCard 
                tolltipID ={random(14)}
                index ={index}
                {...assest}
                onNavigate = {()=>onNavigate("selfLibraryOnHomeAudio")}
                imgSrc={Audio2}
                selfLibraryOnHome= {true}
                hideTooltip= {true}
            />
        })
        
        video =props.selfVEDIOAssestsOnHome !=undefined &&  props.selfVEDIOAssestsOnHome.length >0 && props.selfVEDIOAssestsOnHome.map((assest, index)=>{
            return <UserInfoCard 
                tolltipID ={random(14)}
                index ={index}
                {...assest}
                onNavigate = {()=>onNavigate("selfLibraryOnHomeVideo")}
                imgSrc={Video2}
                selfLibraryOnHome= {true}
                hideTooltip= {true}
            />
        })
        
        img =props.selfIMGAssestsOnHome !=undefined &&  props.selfIMGAssestsOnHome.length >0 && props.selfIMGAssestsOnHome.map((assest, index)=>{
            return <UserInfoCard 
                tolltipID ={random(14)}
                index ={index}
                {...assest}
                onNavigate = {()=>onNavigate("selfLibraryOnHomeImg")}
                imgSrc={Image2}
                selfLibraryOnHome= {true}
                hideTooltip= {true}
            />
        });

        let assests = [];

        if(audio !== null){
            assests = [audio[0], ...assests] 
        }
        if(video !== null){
            assests = [video[0], ...assests] 
        }
        if(img !== null){
            assests = [img[0], ...assests] 
        }

        return assests; 
    }

    // SHARE WITH ME
    const shareByMe = ()=>{
        let shareWitoTher =null;
        shareWitoTher =props.shareWithOtherUserInfo !=undefined &&  props.shareWithOtherUserInfo.length >0 && props.shareWithOtherUserInfo.map((assest, index)=>{
            return <div ><UserInfoCard 
                // tolltipID ={random(14)}
                tolltipID ={"shareByMe"+index}
                key ={index}
                index ={index}
                {...assest} 
                onNavigate = {()=>onNavigate("sharedByMe", assest)}
                shareWithOtherUserInfo= {true}
                isFF={true}
            />
        
        </div>
        })
        // return shareWitoTher;

        return <SimpleCustomCarousel items= {shareWitoTher} />
    }
   
    // SHARE WITH OTHERS
    const sharedByUser = ()=>{
        let shareWitMe =null;
        shareWitMe = props.sharedWithMeUserInfo !=undefined && props.sharedWithMeUserInfo.length >0 && props.sharedWithMeUserInfo.map((assest, index)=>{
            return <UserInfoCard 
            index ={index}
            // tolltipID ={random(14)}
            tolltipID ={"sharedByUser"+index}
            {...assest}
            onNavigate = {()=>{
                console.log('tis i s shareWitMe', assest)
                onNavigate("sharedByUser", assest)
            }}
            sharedWithMeUserInfo= {true}
            isFF={true}
            />
        })
        return <SimpleCustomCarousel items= {shareWitMe} />
        // return shareWitMe;
    }
   
    // NAVIGATORE
    const onNavigate = (navigateTo, assest)=>{
        props.onNavigate(navigateTo, assest)
    }

    return (
        <React.Fragment>
            <Row>
                {props.selfLibraryOnHome &&  getAssestList()}
                {props.shareByMe && shareByMe()}
                {props.sharedByUser && sharedByUser()}
            </Row>
        </React.Fragment> 
    )
}