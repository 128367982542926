export const RECUERO_INFO_TEXT = `"Love your parents. We are so busy growing up; we often forget that they are also growing old." - Unknown`;
export const COPY_RIGHT_TEXT = "Copyright 2024 © Recuerdo All Rights Reserved";
export const EMPTY_ASSEST_PRIMARY_TEXT = "No Asset Added";
export const EMPTY_ASSEST_DESCRIPTION_TEXT = "Once you add an asset, it will appear here!";
export const EMPTY_ASSET_WITH_ME_USER_PRIMARY_TEXT = "No Asset Shared with me";
export const EMPTY_ASSET_WITH_ME_DESCRIPTION_TEXT = "Once an asset is shared with you, it will appear here!";
export const EMPTY_ASSET_WITH_OTHERS_USER_PRIMARY_TEXT = "No Asset Shared with others";
export const EMPTY_ASSET_WITH_OTHERS_DESCRIPTION_TEXT = "Once you share an asset, it will appear here!";
export const NO_VR_USER_PRIMARY_TEXT = "No VR Experience Added";
export const NO_VR_USER_DESCRIPTION_TEXT = "Once you add a VR Experience, it will appear here";
export const EMPTY_AR_ASSEST_DES = "Once you select asset for this room, it will appear here!";

export const EMPTY_360_TOUR_PRIMARY_TEXT = "No 360° Virtual Tour Added";
export const EMPTY_360_TOUR_DESCRIPTION_TEXT = "Once you add 360° Virtual Tour, it will appear here!";
export const EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT = "No 360° Images Added for this Virtual Tour";
export const EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT = "Once you upload 360° images, it will appear here!";


export const UPLOADED_FILES_EXT = {
    images: ["png", "jpeg", "jpg", "heic", "Heif"],
    audios: ["mp3", "aac", "wav", "m4a",],
    videos: ["mp4", "3gp", "mov", "m4v", "Hevc"] 
}
