import React, {useEffect, useState} from "react";
import { Modal, ModalBody, Button, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Close from './../../ASSETS/Images/icons/cancel.png'
import { toast } from 'react-toastify';
import validator from 'validator';
import classNames from 'classnames';
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import UpgradModal from "../Subscription/UpgradModal";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";
import { ExpiredModal } from "../Modal/Expired";

export default (props)=>{
    
    const dispatch = useDispatch();   
    const [shareAssetsModal, setShareAssetsModal] = useState(false);
    const [email, setEmail] = useState('');
    const [emailErr, setEmailErr] = useState(false)
    const tokenContext = useContext(CreateTokenContextProvider);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [openUpgrade, setOpenUpgrade] = useState(false);
    
    const shareModal = () =>{
        setShareAssetsModal(!shareAssetsModal);
        props.setShareAssetsModal(!shareAssetsModal)
    } 

    useEffect(()=>{

        if(props.isOpen){
            if(userProfileDetails.isExpired){
                toast.dismiss();
                toast("Your subscription has been Expired...");
                dispatch(setDoNotShowExpiredModal(false));
                props.setShareAssetsModal()
                return;
            }
        }

        setShareAssetsModal(props.isOpen)
    }, [props.isOpen])

    const getSharingIDs =()=>{
        return props.getSharingIDs()
    }

    const applySharingAssest =async ()=>{

        toast.dismiss();

        if(!validator.isEmail(email.trim())){
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailErr(true)
            return
        }

        if(userProfileDetails.email.toLowerCase() === email.toLowerCase()){
            toast.error("You can't share asset to yourself", { });
            return
        }

        if( userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
         
        let sharingData = {
            "assetIDs": getSharingIDs(),
            "emails": email,
            "accessType": props.accessType
        }

        try{
            await props.onSharing( sharingData, dispatch, tokenContext.accessToken);
            toast("Shared Successfully!");
            shareModal()
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
        setEmail("")
    }

    const onInputChange = (e)=>{
    
        switch(e.target.name){
          case "email":
            setEmail(e.target.value);
            setEmailErr(false)
            break;
        }
    }

    if(userProfileDetails.isExpired){
        return <ExpiredModal />;
    }
    
    return <React.Fragment>
          {/* <UpgradModal 
                isOpen= {openUpgrade} 
                setOpenUpgrade ={setOpenUpgrade}
            /> */}
        <Modal isOpen={shareAssetsModal} centered className="drop-modal" toggle={()=>{
        setEmail('')
        shareModal()
        }} {...props}>
        <Button className="modalClose" onClick={()=>{
            setEmail('')
            shareModal()
        }}>
            <img src={Close} alt="Images" className="img-fluid" />
        </Button>
        <ModalBody>
            <h4 className="text-center mb-4">Share</h4>
            <div className="share_people p-2">
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <input value={email} type="text" placeholder="Add People"
                            className={classNames("form-control", { "form-error": emailErr })} name={'email'} onChange={onInputChange}
                            />
                        </FormGroup>
                    </div>
                    {/* <div className="col-4">
                        <FormGroup>
                            <Input
                            id="shareOption"
                            name="shareOption"
                            type="select"
                            >
                            <option>
                                Viewer
                            </option>
                            <option>
                                Viewer
                            </option>
                            </Input>
                        </FormGroup>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-12 text-center mt-4">
                        <button 
                            className="btn btn-primary"
                            onClick={applySharingAssest}
                        >Send</button>
                    </div>
                </div>
            </div> 
        </ModalBody>
    </Modal>
    </React.Fragment>
}

