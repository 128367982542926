import React from "react";
import { COPY_RIGHT_TEXT } from "../../UTILITYS/Constant";

export default (props) => {

    // COMMONE CLASS WILL GO HERE
    let copyRightClassess = " ";

    if(props.copyRightClassess !== undefined){
        copyRightClassess = copyRightClassess + props.copyRightClassess.join(" ")
    }
    
    return <div className={copyRightClassess}>
    <small>{COPY_RIGHT_TEXT}</small>
    </div>
} 