import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  module: "parentDashboard",
  toggleModul: false
} 

export const activeParentModule = createSlice({
  name: 'activeParentModule',
  initialState: { ...initialState },
  reducers: {
    setParentModuleActive: (state, action) => {
      console.log('this is our setParentModuleActive', action)
      return {
        ...state,
        module: action.payload
      }
    },

    setToggleForExpandAndColleps: (state, action) => {
      console.log('novwwwsss 6 >>', action)
      return {
        ...state,
        toggleModul: action.payload}
    },
    resetParentModuleActive: (state, action) => {
    //   console.log('this is our activeParentModule submit data >>', action)
        return { ...initialState }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setParentModuleActive, resetParentModuleActive, setToggleForExpandAndColleps } = activeParentModule.actions

export const setParentModuleActiveDispatch = (data) => {

  return async(dispatch, getState) => {
    dispatch(setParentModuleActive(data));
  }
}

export const resetParentModuleActiveDispatch = () => {
  return async(dispatch) => {
    dispatch(resetParentModuleActive());
  }
}


export default activeParentModule.reducer