import React, { useState, useEffect } from "react";
import HeaderSideBar from "..";
import { NavLink, useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
// import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import { resetShareWithMeAndOthers, fetchShareWithOthersUserInfoDispatch } from "../../Home/slices/ShareWithMe";
import UserInfoCard from "../../../COMPONENTS/HOME/CustomeCard/UserInfoCard";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { hamburgurDispatch } from "../../../STORE/CommonSlices/hameBurgerOnOff";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import EmptyAssest from "../../Home/EmptyAssest";
import { EMPTY_ASSET_WITH_OTHERS_USER_PRIMARY_TEXT,
    EMPTY_ASSET_WITH_OTHERS_DESCRIPTION_TEXT } from "../../../UTILITYS/Constant";
import { random } from "../../../UTILITYS/index.tsx";
import NoContents from "../../../COMPONENTS/NoContentPage/NoContents";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import Breadcrum from "../../../COMPONENTS/Breadcrum";
import {shareWithMeAndOthersUsersInfoInterface, userInfo} from "./../../../UTILITYS/Modals/slicese/shareWithMeAndOthersUsersInfo.modal.ts";

export default ()=>{

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const shareWithMeAndOthers: shareWithMeAndOthersUsersInfoInterface = useAppSelector(state => state.shareWithMeAndOthers) as shareWithMeAndOthersUsersInfoInterface;
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isUserEmpty, setIsUserEmpty] = useState<boolean>(true);

    useEffect(()=>{
        dispatch(resetShareWithMeAndOthers({}))
        // SET SIDE MENU HIGHLIGHTING
        dispatch(setModuleActiveDispatch("shared_with_others"))
        dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
    }, [dispatch])

    useEffect(()=>{ 
        dispatch(fetchShareWithOthersUserInfoDispatch(loadEventIndex, 25))
    },[loadEventIndex])  

    useEffect(()=>{
        if( shareWithMeAndOthers?.shareWithOtherUserInfo?.length === 0){
            setIsUserEmpty(true)
        }else{
            setIsUserEmpty(false)
        }
    }, [shareWithMeAndOthers?.shareWithOtherUserInfo?.length])

    const fetchMoreData: () => void = () => {
        seLoadEventIndex(loadEventIndex+1) 
    };

    const onNavigate: (navigateTo: string, assest: userInfo) => void = (navigateTo: string, assest: userInfo) => { 
        
        switch(navigateTo){
            case "sharedByMe":
                dispatch(setModuleActiveDispatch("shared_with_others"))
                navigate('/SharedByMe', {state: assest})
                break;
        }
    }

    const getImagesAssestsList: () => JSX.Element = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={shareWithMeAndOthers?.shareWithOtherUserInfo?.length as number}
                    next={fetchMoreData}
                    hasMore={shareWithMeAndOthers.hasMoreShareWithOtherUserInfo as boolean}
                    loader={shareWithMeAndOthers?.shareWithOtherUserInfo && shareWithMeAndOthers?.shareWithOtherUserInfo?.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                        </p>
                    }
                >
                {getImagesAssest()}
            </InfiniteScroll> as JSX.Element
       
     }

     const getImagesAssest = ()=>{

        return shareWithMeAndOthers.shareWithOtherUserInfo !=undefined && shareWithMeAndOthers.shareWithOtherUserInfo.length >0 && shareWithMeAndOthers.shareWithOtherUserInfo.map((assest, index)=>{
            return <UserInfoCard 
                        // tolltipID ={random(14)}
                        tolltipID ={"SWO"+index}
                        key ={index}
                        {...assest} 
                        onNavigate = {()=>onNavigate("sharedByMe", assest)}
                        shareWithOtherUserInfo= {true}
                        isFF={true}
                    />
        }) as JSX.Element[]

    }

    if(isUserEmpty){
        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        breadCrumbItems= {["Asset Library", "Shared with Others"]}
                        primaryText= {EMPTY_ASSET_WITH_OTHERS_USER_PRIMARY_TEXT}
                        description= {EMPTY_ASSET_WITH_OTHERS_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar> as JSX.Element
    }

    return (
        <React.Fragment>
            <HeaderSideBar>
                <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                        <Breadcrum 
                            breadCrumbItems= {["Asset Library", "Shared with Others"]}
                        />
                    </div>
                </div>
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="shared_item dashboard_card pt-3">
                        {/* <div className="mb-1">
                            <span className="lead">Shared with others</span>
                        </div>  */}
                       {getImagesAssestsList()}
                    </div>
                   
                </div>
            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}