import React from "react"; 
import ProgressStatus from "./ProgressStatus";
import CountCard from "./CountCard.tsx";
import Image from "./../../ASSETS/Images/icons/Image_1.png";
import Video from './../../ASSETS/Images/icons/Video.png';
import Audio from './../../ASSETS/Images/icons/Audio.png';

type allProps = {
    imageFileCount: number;
    videoFileCount: number;
    audioFileCount: number;
    totalUsedStorage: number;
}; 

type componentType= (props: allProps)=> JSX.Element;

const StorageStatus: componentType = (props: allProps): JSX.Element=>{

    console.log('this is StorageStatus component 1', props);
    
    return (
        <React.Fragment>
            <div className="bg-white Dashboard_Counter mb-3 mt-2">
                <CountCard   
                    count= {props.imageFileCount}
                    whichAssest= {"Images"}
                    src={Image}
                />
                <CountCard 
                    count= {props.videoFileCount}
                    whichAssest= {"Videos"}
                    src={Video}
                />
                <CountCard 
                    count= {props.audioFileCount}
                    whichAssest= {"Audios"}
                    src={Audio}
                />

                {props.totalUsedStorage!= undefined &&<ProgressStatus 
                    totalUsedStorage ={props.totalUsedStorage}
                />}
            </div>
        </React.Fragment>
    )
}    

export default React.memo(StorageStatus);
             