
import React, { memo, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressProvider from "./AnimatedProgressProvider";

function AnimatedProgressBar (props){

   return <AnimatedProgressProvider
            valueStart={props.valueStart}
            valueEnd={props.valueEnd}
            duration={props.duration}
            easingFunction={props.easingFunction}
        >
            {value => {
            const roundedValue = Math.round(value);
            return (
            <CircularProgressbar
                value={value}
                text={`${roundedValue}%`}
                /* This is important to include, because if you're fully managing the
                animation yourself, you'll want to disable the CSS animation. */
                styles={{
                    ...buildStyles({ 
                        textSize: '28px',
                        pathColor: `rgba(255, 75, 0, 1)`,
                        textColor: '#000',
                        trailColor: '#d6d6d6',
                        backgroundColor: '#fc4a0b',
                    })
                }}
            />
            );
        }}
    </AnimatedProgressProvider>
}

function arePropsEqual(prevProps, nextProps) {
    return prevProps.valueEnd !== nextProps.valueEnd; 
}


// export default memo(AnimatedProgressBar, arePropsEqual)
export default AnimatedProgressBar