import React from "react";


export default (props) => {

    console.log('ti is our button', props)

    // COMMONE CLASS WILL GO HERE
    let buttonClassess = " ";

    if(props.buttonClassess!= undefined){
        buttonClassess = buttonClassess + props.buttonClassess.join(" ")
    }

    return <React.Fragment>
       
        <button 
            className={buttonClassess} 
            type= "submit"
        >{props.lable}</button>
                
    </React.Fragment>
}

{/* <div className="mb-4 mt-4">
<button className="btn btn-primary w-100" type="submit" >Login</button>
</div> */}