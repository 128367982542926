import React from "react";

export default (props)=>{ 

    return (
        <React.Fragment>
            {/* Dashboard no content  */}
            <div className="no-content text-center">
                <img src={props.icon} alt="profile" className="img-fluid mb-3" />
                <div>
                    <h3 className="text-primary">{props.primaryText}</h3>
                    <p>{props.description}</p>
                </div>
            </div>
        </React.Fragment>
    )
}
