import React, { useEffect, useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Demo.css";

export default (props) => {

  const { imageToCrop, onImageCropped } = props;

  const [image, setImage] = useState(imageToCrop);
  const cropperRef = useRef(null);

  const onCropEnd = ()=>{
     cropperRef.current.cropper.getCroppedCanvas({
      minWidth: 400,
      minHeight: 400,
      maxWidth: 400,
      maxHeight: 400
    }).toBlob((blob) => {
      onImageCropped(blob, cropperRef.current.cropper.getCroppedCanvas(
          {
            minWidth: 800,
            minHeight: 800,
            maxWidth: 800,
            maxHeight: 800,
          }
          ).toDataURL(), );
        })
  }
  
  const onCrop =async ()=>{
    await cropperRef.current.cropper.getCroppedCanvas().toBlob((blob) => {
      onImageCropped(blob, cropperRef.current.cropper.getCroppedCanvas().toDataURL());})
  }

  useEffect(()=>{
    setTimeout(() => {
        onCropEnd()
    }, 800);
  }, [])
  
  return (
    <React.Fragment>
        <Cropper
            style={{ height: 400, width: "100%" }}
            zoomTo={0.5}
            initialAspectRatio={1}
            preview=".img-preview"
            src={image}
            viewMode={1}
            background={false}
            responsive={true}
            autoCropArea={0.7}
            checkOrientation={false}
            guides={true}
            cropBoxMovable= {false}
            cropBoxResizable= {false}
            dragMode= 'move'
            cropend= {(e)=>{
              onCropEnd()
            }}
            zoom= {(e)=>{
              // onCropEnd()
            }}
            ref= {cropperRef}
            zoomOnWheel = {true}
            // crop={onCrop}
            />
      </React.Fragment>
  );
};