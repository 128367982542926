import React, { useState, useEffect, useRef } from "react";
import { Modal, ModalBody, TabContent, TabPane, Nav, NavItem, NavLink,  FormGroup, Input, Button, ModalFooter  } from 'reactstrap';
import Image from "./../../ASSETS/Images/am (14).jpg";
import ReactPlayer from 'react-player'
import Video from './../../ASSETS/Images/icons/play.svg';
import Audio from './../../ASSETS/Images/icons/AudioAssest.jpg';
import Close from './../../ASSETS/Images/icons/cancel.png';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAudioSharedByThisUserDispatch, fetchImagesSharedByThisUserDispatch, fetchVideoSharedByThisUserDispatch, resetSharedAssestWithMeOrBy, resetSharedAssestWithMeOrByButPreservState, setToggleSelectForAudioSharedByThisUser, setToggleSelectForIMGSharedByThisUser, setToggleSelectForVideoSharedByThisUser } from "../../SCREENS/FamilyAndFriends/slice.reducer";
import { fetchSelFAUDIOassestDispatch, fetchSelFIMGassestDispatch, fetchSelFVEDIOassestDispatch, resetSelfAssestsOnHome, resetToggleSelectForAudioDispatch, resetToggleSelectForIMGDispatch, resetToggleSelectForVidioDispatch, setToggleSelectForAudioDispatch, setToggleSelectForIMGDispatch, setToggleSelectForVideoDispatch } from "../../SCREENS/Home/slice.reducer";
import { fetchShareWithMeUserInfoDispatch, resetShareWithMeAndOthers } from "../../SCREENS/Home/slices/ShareWithMe";
import InfiniteScroll from "react-infinite-scroll-component";
import ImagesCardList from "../AssestsAndList/Images/ImagesCardList";
import AudioCardList from "../AssestsAndList/Audios/AudioCardList";
import VideosCardList from "../AssestsAndList/Videos/VideosCardList";
import { addAssestInTheRoom } from "../../UTILITYS/endPoints";
import { VRresetAssests } from "../../SCREENS/VR-experience/Slices/VRassestList.slice";
import convert from 'convert';
import { LoaderForIndividualPageDispatch } from "../Loader/LoaderForIndividualPage.slice.reducer";
import HeaderFooter from "./HeaderFooter";
import Asset from './../../ASSETS/Images/icons/Asset_icon.png';
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../UTILITYS/Constant";
import NoContentWithOutUploadPage from "./NoContentPage/NoContentWithOutUploadPage";
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import { useMediaQuery } from 'react-responsive';
import { useLongPress } from 'use-long-press';
import UpgradModal from './../../COMPONENTS/Subscription/UpgradModal';
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";

export default (props)=>{
    let vidRef =useRef()    

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [activeTab, setTab] = useState('photo');
    const [pageIndexForSharedUser, setPageIndexForSharedUser] = useState(1);
    const [selectedSharedUser, setSelectedSharedUser] = useState(null);
    const [pageIndexForSharedByUserIMG, setPageIndexForSharedByUserIMG] = useState(1);
    const [pageIndexForSharedByUserAudio, seTpageIndexForSharedByUserAudio] = useState(1);
    const [pageIndexForSharedByUserVideo, setPageIndexForSharedByUserVideo] = useState(1);
    const [pageIndexForSelfIMG, setPageIndexForSelfIMG] = useState(1);
    const [pageIndexForSelfAudio, setPageIndexForSelfAudio] = useState(1);
    const [pageIndexForSelfVideo, setPageIndexForSelfVideo] = useState(1);
    const [isSharedUserListEmply, setIsSharedUserListEmply] = useState(true);
    const [isSharedByUserImageEmply, setIsSharedByUserImageEmply] = useState(true);
    const [isSharedByUserAudioEmply, setIsSharedByUserAudioEmply] = useState(true);
    const [isSharedByUserVideoEmply, setIsSharedByUserVideoEmply] = useState(true);
    const [isSelfUploadedIMGEmpty, setIsSelfUploadedIMGEmpty] = useState(true);
    const [isSelfUploadedAudiompty, setIsSelfUploadedAudiompty] = useState(true);
    const [isSelfUploadedVideoempty, setIsSelfUploadedVideoempty] = useState(true); 
    const [duplicateAssestModal, setDuplicateAssestModal] = useState(false);
    const shareWithMeAndOthers = useSelector(state => state.shareWithMeAndOthers);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const assestsData = useSelector(state => state.assestsData);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const tokenContext = useContext(CreateTokenContextProvider);
    const isMobileView = useMediaQuery({ maxWidth: 767 });
    const [isLongPressEnable, setIsLongPressEnable] = useState(false);
    const [openUpgrade, setOpenUpgrade] = useState(false); 
    const VRassestList = useSelector(state => state.VRassestList);

    const ClickTab = (val) => {
        setTab(val);
        console.log('tab: ', activeTab);
    }

    const toggle = () => {

        if( userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        
        resetModal()
        setModal(!modal);
        setIsLongPressEnable(false);
    }

    useEffect(()=>{
        if(modal){
            dispatch(LoaderForIndividualPageDispatch(true))
            setSelectedSharedUser(null);
            dispatch(resetSelfAssestsOnHome());
            dispatch(fetchSelFIMGassestDispatch(1, 25, "LOAD_MORE"));
            dispatch(fetchSelFAUDIOassestDispatch(1, 25, "LOAD_MORE"));
            dispatch(fetchSelFVEDIOassestDispatch(1, 25, "LOAD_MORE"));
            setPageIndexForSelfIMG(1)
            setPageIndexForSelfAudio(1)
            setPageIndexForSelfVideo(1)
        }
    }, [modal]);

    const resetModal =()=>{
        dispatch(resetSharedAssestWithMeOrBy());
        dispatch(resetToggleSelectForAudioDispatch()) 
        dispatch(resetToggleSelectForIMGDispatch()) 
        dispatch(resetToggleSelectForVidioDispatch()) 
    }

    useEffect(()=>{
        return ()=>{
            dispatch(VRresetAssests())
            console.log('`this is deata commmie  f af 4`',)
        }
    },[])
  
    useEffect(()=>{
        dispatch(resetSharedAssestWithMeOrBy());
        dispatch(resetShareWithMeAndOthers());
        dispatch(resetSelfAssestsOnHome());
    }, [dispatch]);


    const bind = useLongPress((e, params) => {
        if(!isLongPressEnable && isMobileView !== undefined && isMobileView){ 
            setIsLongPressEnable(true);
            switch(params.context.type){
                case 1:
                    if(params.context.isSelf){
                        toggleSelectBoxForAudio(params.context.id);
                    }else{
                        toggleSelectBoxForSharedUserAudio(params.context.id);
                    }
                break
                case 2:
                    if(params.context.isSelf){
                        toggleSelectBoxForVideo(params.context.id);
                    }else{
                        toggleSelectBoxForSharedUserVideo(params.context.id);
                    }
                break
                case 3:
                    if(params.context.isSelf){
                        toggleSelectBoxForImg(params.context.id);
                    }else{
                        toggleSelectBoxForSharedUserImg(params.context.id);
                    }
                break
            }
        }
    });

    useEffect(()=>{
        if(
            assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected ).length === 0 &&
            assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected ).length === 0 &&
            assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected ).length === 0 &&
            sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length === 0 &&
            sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length === 0 &&
            sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length === 0 
        ){
            setIsLongPressEnable(false);
        }
    }, [
        assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected ).length,
        assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected ).length,
        assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected ).length,
        sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length,
        sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length,
        sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length, 
    ])

    
    
    // THIS IS FOR SHARED WITH ME USERS LIST(START)
    useEffect(()=>{
        dispatch(fetchShareWithMeUserInfoDispatch(pageIndexForSharedUser, 25, "LOAD_MORE"))
    },[pageIndexForSharedUser])  

    const fetchMoreDataForSharedUserList = () => {
        setPageIndexForSharedUser(pageIndexForSharedUser+1) 
    };

    useEffect(()=>{
        if( shareWithMeAndOthers.sharedWithMeUserInfo.length === 0){
            setIsSharedUserListEmply(true)
        }else{
            setIsSharedUserListEmply(false)
        }
    }, [shareWithMeAndOthers.sharedWithMeUserInfo.length]);

    const getShareWithMeUsersList = ()=>{
        
        return <InfiniteScroll
                    // className="row"
                    dataLength={shareWithMeAndOthers.sharedWithMeUserInfo}
                    next={fetchMoreDataForSharedUserList}
                    hasMore={shareWithMeAndOthers.hasMoreSharedWithMeUserInfo}
                    loader={shareWithMeAndOthers.sharedWithMeUserInfo.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    }
                >
                {getShareWithMeList()}
            </InfiniteScroll>
       
    }

    const getShareWithMeList = ()=>{ 
        return shareWithMeAndOthers.sharedWithMeUserInfo !=undefined && shareWithMeAndOthers.sharedWithMeUserInfo.length >0 && shareWithMeAndOthers.sharedWithMeUserInfo.map((assest, index)=>{
            return <option onChange={()=>loadThisUserAssest(assest)}>{assest.name}</option>
            // return <div>shred-user-list</div>
        })
    }

    const getShareWithMeUsersListOptions = ()=>{

        return shareWithMeAndOthers.sharedWithMeUserInfo !=undefined && shareWithMeAndOthers.sharedWithMeUserInfo.length >0 && shareWithMeAndOthers.sharedWithMeUserInfo.map((assest, index)=>{
            return <option value={JSON.stringify(assest)}>{`Shared By ${assest.name}`}</option>
        })
    }

    useEffect(()=>{
        if(assestsData.isImgResGet && assestsData.isAudioResGet && assestsData.isVideoResGet ){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [ assestsData.isImgResGet, assestsData.isAudioResGet, assestsData.isVideoResGet ])

    useEffect(()=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.isImgResGet && sharedAssestWithMeOrBy.sharedByThisUser.isAudioResGet && sharedAssestWithMeOrBy.sharedByThisUser.isVideoResGet ){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [ sharedAssestWithMeOrBy.sharedByThisUser.isImgResGet, sharedAssestWithMeOrBy.sharedByThisUser.isAudioResGet, sharedAssestWithMeOrBy.sharedByThisUser.isVideoResGet ])

    const loadThisUserAssest = (e)=>{
        if(e.target.value !== ""){

            dispatch(LoaderForIndividualPageDispatch(true))
            if(document.getElementById('getSelfImagesListScroll')){
                document.getElementById('getSelfImagesListScroll').scrollTop = "0px"
                document.getElementById('getSelfVideosListScroll').scrollTop = "0px"
                document.getElementById('getSelfAudiosListScroll').scrollTop = "0px"
            }
            setTimeout(() => {
                dispatch(resetSharedAssestWithMeOrByButPreservState());
                setSelectedSharedUser(JSON.parse(e.target.value));
                dispatch(fetchImagesSharedByThisUserDispatch(1, 25, JSON.parse(e.target.value).userID))
                dispatch(fetchAudioSharedByThisUserDispatch(1, 25, JSON.parse(e.target.value).userID))
                dispatch(fetchVideoSharedByThisUserDispatch(1, 25, JSON.parse(e.target.value).userID))
                setPageIndexForSharedByUserIMG(1)
                seTpageIndexForSharedByUserAudio(1)
                setPageIndexForSharedByUserVideo(1)
            }, 400);

        }else{
            setSelectedSharedUser(null);
        }
    }

    // // // THIS IS FOR SHARED WITH ME USERS LIST(END)





   
   
    // SHARED WITH ASSESTS LIST img(END)
    useEffect(()=>{
        if(selectedSharedUser !== null && pageIndexForSharedByUserIMG !== 1){
            dispatch(fetchImagesSharedByThisUserDispatch(pageIndexForSharedByUserIMG, 25, selectedSharedUser.userID))
        }
    },[pageIndexForSharedByUserIMG]) 

    const fetchMoreDataForSharedByUserIMG = () => {
        setPageIndexForSharedByUserIMG(pageIndexForSharedByUserIMG+1)
    };

    useEffect(()=>{
        if( sharedAssestWithMeOrBy.sharedByThisUser.images.length === 0){
            setIsSharedByUserImageEmply(true)
        }else{
            setIsSharedByUserImageEmply(false)
        }
    }, [sharedAssestWithMeOrBy.sharedByThisUser.images]);

    const getSharedByUserImagesList = ()=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.images.length ===0)
        return <NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Images" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
       return  <InfiniteScroll
                    className="row"
                    dataLength={sharedAssestWithMeOrBy.sharedByThisUser.images}
                    next={fetchMoreDataForSharedByUserIMG}
                    hasMore={sharedAssestWithMeOrBy.sharedByThisUser.hasMoreImageAssests}
                    loader={sharedAssestWithMeOrBy.sharedByThisUser.images.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                    }

                    useWindow= {false}
                    threshold ={200}
                   //  scrollableTarget="scrollableDiv"
                   //  scrollableTarget= {activeTab}
                    scrollableTarget= "getSelfImagesListScroll"
               >
               {getSharedByUserImages()}
           </InfiniteScroll>
      
    }

    const getSharedByUserImages = ()=>{
        return sharedAssestWithMeOrBy.sharedByThisUser.images.map((img, index)=>{
            return <ImagesCardList 
            selectShredWithMeAssest= {true} 
            img={img} 
            index ={index} 
            key ={index} 
            assests= {sharedAssestWithMeOrBy.sharedByThisUser.images}
            getCheckBoxClasses ={()=>getCheckBoxClassesForSharedUserImg(img.id)}
            toggleSelectBox ={()=>toggleSelectBoxForSharedUserImg(img.id)}
            
            isMobileView= {isMobileView}
            bind= {()=>bind({id: img.id, type: img.fileType, isSelf: false})}
            isLongPressEnable= {isLongPressEnable}
            /> 
        }) 
    }

    const getCheckBoxClassesForSharedUserImg = (id)=>{
        let currentImg= null;
        if(sharedAssestWithMeOrBy.sharedByThisUser.images.length>0){
            currentImg =sharedAssestWithMeOrBy.sharedByThisUser.images.find(img=> img.id === id && img.isImgSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1"; 
    }

    const toggleSelectBoxForSharedUserImg = (id)=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.images.find(img=> (img.id === id && img.isImgSelected))){
            dispatch(setToggleSelectForIMGSharedByThisUser(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
        // else if(
        //     userProfileDetails.premiumUser !== 1 && (assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length)===3){
        //     // toast.dismiss();
        //     // toast.error("You can only select maximum 3 image", { });
        //     // setOpenUpgrade(true);
        //     return
        // }
        else{
            dispatch(setToggleSelectForIMGSharedByThisUser(id)) 
        }
    }
    // SHARED WITH LIST img(END)
    
    // SHARED WITH LIST aud(START)
    useEffect(()=>{
        if(selectedSharedUser !== null && pageIndexForSharedByUserAudio !== 1)
        dispatch(fetchAudioSharedByThisUserDispatch(pageIndexForSharedByUserAudio, 25, selectedSharedUser.userID)) 
    },[pageIndexForSharedByUserAudio])

    useEffect(()=>{
        if( sharedAssestWithMeOrBy.sharedByThisUser.audios.length === 0){
            setIsSharedByUserAudioEmply(true)
        }else{
            setIsSharedByUserAudioEmply(false)
        }
    }, [sharedAssestWithMeOrBy.sharedByThisUser.audios])

    const fetchMoreDataForSharedByUserAudio = () => {
        seTpageIndexForSharedByUserAudio(pageIndexForSharedByUserAudio+1)
    };

    const getSharedByUserAudosList = ()=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.audios.length ===0)
        return<NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Audios" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
       return  <InfiniteScroll
                    className="row"
                    dataLength={sharedAssestWithMeOrBy.sharedByThisUser.audios}
                    next={fetchMoreDataForSharedByUserAudio}
                    hasMore={sharedAssestWithMeOrBy.sharedByThisUser.hasMoreAudioAssests}
                    loader={sharedAssestWithMeOrBy.sharedByThisUser.audios.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                    }
                    threshold ={200}
                    //  scrollableTarget="scrollableDiv"
                    //  scrollableTarget= {activeTab}
                    scrollableTarget= "getSelfAudiosListScroll"
               >
               {getSharedByUserAudio()}
           </InfiniteScroll>
    
    }

    const getSharedByUserAudio = ()=>{
        return sharedAssestWithMeOrBy.sharedByThisUser.audios.map((audio, index)=>{
            return <AudioCardList 
                selectShredWithMeAssest= {true} 
                audio={audio} 
                key={index}
                getCheckBoxClasses ={()=>getCheckBoxClassesForSharedUserAudio(audio.id)}
                toggleSelectBox ={()=>toggleSelectBoxForSharedUserAudio(audio.id)}    
                
                isMobileView= {isMobileView}
                bind= {()=>bind({id: audio.id, type: audio.fileType, isSelf: false})}
                isLongPressEnable= {isLongPressEnable}
            /> 
        })
        
    }

    const getCheckBoxClassesForSharedUserAudio = (id)=>{
        let currentImg= null;
        if(sharedAssestWithMeOrBy.sharedByThisUser.audios.length>0){
            currentImg =sharedAssestWithMeOrBy.sharedByThisUser.audios.find(aud=> aud.id === id && aud.isAudioSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    }

    const toggleSelectBoxForSharedUserAudio = (id)=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.audios.find(aud=> (aud.id === id && aud.isAudioSelected))){
            dispatch(setToggleSelectForAudioSharedByThisUser(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
//         else if(
// userProfileDetails.premiumUser !== 1 && (assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length)===1){
//             // toast.dismiss();
//             // toast.error("You can only select maximum 1 audio", { });
//             // setOpenUpgrade(true);
//             return
//         }
        else{
            dispatch(setToggleSelectForAudioSharedByThisUser(id))
        }
    }
    // SHARED WITH LIST aud(END)
    
    // SHARED WITH LIST vid(END)
    useEffect(()=>{
        if(selectedSharedUser !== null && pageIndexForSharedByUserVideo !== 1)
        dispatch(fetchVideoSharedByThisUserDispatch(pageIndexForSharedByUserVideo, 25, selectedSharedUser.userID))
    },[pageIndexForSharedByUserVideo])

    useEffect(()=>{
        if( sharedAssestWithMeOrBy.sharedByThisUser.videos.length === 0){
            setIsSharedByUserVideoEmply(true)
        }else{
            setIsSharedByUserVideoEmply(false)
        }
    }, [sharedAssestWithMeOrBy.sharedByThisUser.videos])

    const fetchMoreDataForSharedByUserVideo = () => {
        setPageIndexForSharedByUserVideo(pageIndexForSharedByUserVideo+1)
    };

    const getSharedByUserVideoList = ()=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.videos.length ===0)
        return <NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Videos" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
       return  <InfiniteScroll
                    className="row"
                    dataLength={sharedAssestWithMeOrBy.sharedByThisUser.videos}
                    next={fetchMoreDataForSharedByUserVideo}
                    hasMore={sharedAssestWithMeOrBy.sharedByThisUser.hasMoreVediosAssests}
                    loader={sharedAssestWithMeOrBy.sharedByThisUser.videos.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                    }
                    useWindow= {false}
                    threshold ={200}
                    //  scrollableTarget="scrollableDiv"
                    //  scrollableTarget= {activeTab}
                    scrollableTarget= "getSelfVideosListScroll"
               >
               {getSharedByUserVideo()}
           </InfiniteScroll>
    
      
    }

    const getSharedByUserVideo = ()=>{
        return sharedAssestWithMeOrBy.sharedByThisUser.videos.map((video, index)=>{
            return <VideosCardList 
                selectShredWithMeAssest= {true} 
                video={video} 
                key ={index} 
                getCheckBoxClasses ={()=>getCheckBoxClassesForSharedUserVideo(video.id)}
                toggleSelectBox ={()=>toggleSelectBoxForSharedUserVideo(video.id)}
                
                isMobileView= {isMobileView}
                bind= {()=>bind({id: video.id, type: video.fileType, isSelf: false})}
                isLongPressEnable= {isLongPressEnable}
                /> 
        })
    } 

    const getCheckBoxClassesForSharedUserVideo = (id)=>{
        let currentImg= null;
        if(sharedAssestWithMeOrBy.sharedByThisUser.videos.length>0){
            currentImg =sharedAssestWithMeOrBy.sharedByThisUser.videos.find(vid=> vid.id === id && vid.isVideoSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    }
    const toggleSelectBoxForSharedUserVideo = (id)=>{
        if(sharedAssestWithMeOrBy.sharedByThisUser.videos.find(vid=> (vid.id === id && vid.isVideoSelected))){
            dispatch(setToggleSelectForVideoSharedByThisUser(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
//         else if(
// userProfileDetails.premiumUser !== 1 && (assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length)===1){
//             // toast.dismiss();
//             // toast.error("You can only select maximum 1 videos", { });
//             // setOpenUpgrade(true);
//             return
//         }
        else{
            dispatch(setToggleSelectForVideoSharedByThisUser(id))
        }
    }
    // SHARED WITH LIST vid(END)

 

 
    

    // COMMAN
    // useEffect(()=>{
    //     dispatch(fetchSelFIMGassestDispatch(pageIndexForSelfIMG, 25, "LOAD_MORE"));
    //     dispatch(fetchSelFAUDIOassestDispatch(pageIndexForSelfAudio, 25, "LOAD_MORE"));
    //     dispatch(fetchSelFVEDIOassestDispatch(pageIndexForSelfVideo, 25, "LOAD_MORE")) ;
    // },[]) 
    
    // MY ASSESTS LIST img(END)
    useEffect(()=>{
        if(pageIndexForSelfIMG !== 1)
        dispatch(fetchSelFIMGassestDispatch(pageIndexForSelfIMG, 25, "LOAD_MORE"))
    },[pageIndexForSelfIMG]) 
    
    
    const fetchMoreDataForSelfIMG = () => {
        setPageIndexForSelfIMG(pageIndexForSelfIMG+1)
    }; 

    useEffect(()=>{
        if( assestsData.selfIMGAssestsOnHome.length === 0){
            setIsSelfUploadedIMGEmpty(true)
        }else{
            setIsSelfUploadedIMGEmpty(false)
        }
    }, [assestsData.selfIMGAssestsOnHome.length])

    const getSelfImagesList = ()=>{

        if(assestsData.selfIMGAssestsOnHome.length ===0)
        return  <NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Images" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                
        return <InfiniteScroll
                     className="row"
                     dataLength={assestsData.selfIMGAssestsOnHome}
                     next={fetchMoreDataForSelfIMG}
                     hasMore={assestsData.hasMoreImageAssests}
                     loader={assestsData.selfIMGAssestsOnHome.length > 25 ?<h4>Loading...</h4>: ""}
                     endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                     }
                     useWindow= {false}
                     threshold ={200}
                     // scrollableTarget="scrollableDiv"
                     // scrollableTarget= {activeTab}
                     scrollableTarget= "getSelfImagesListScroll"
                >
                {getSelfImages()}   
            </InfiniteScroll>
       
    }
 
     const getSelfImages = ()=>{
         return assestsData.selfIMGAssestsOnHome.map((img, index)=>{
             return <ImagesCardList 
                img={img} 
                index ={index} 
                key ={index} 
                assests= {assestsData.selfIMGAssestsOnHome}
                getCheckBoxClasses ={()=>getCheckBoxClassesForImg(img.id)}
                toggleSelectBox ={()=>toggleSelectBoxForImg(img.id)}
                
                isMobileView= {isMobileView}
                bind= {()=>bind({id: img.id, type: img.fileType, isSelf: true})}
                isLongPressEnable= {isLongPressEnable}
            />
         })
     } 

    const getCheckBoxClassesForImg = (id)=>{
        let currentImg= null;
        if(assestsData.selfIMGAssestsOnHome.length>0){
            currentImg =assestsData.selfIMGAssestsOnHome.find(img=> img.id === id && img.isImgSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    }

    const toggleSelectBoxForImg = (id)=>{
        if(assestsData.selfIMGAssestsOnHome.find(img=> (img.id === id && img.isImgSelected))){
            dispatch(setToggleSelectForIMGDispatch(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
//         else if(
// userProfileDetails.premiumUser !== 1 && (assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length)===3){
//             // toast.dismiss();
//             // toast.error("You can only select maximum 3 image", { });
//             // setOpenUpgrade(true);
//             return
//         }
        else{
            dispatch(setToggleSelectForIMGDispatch(id))
        }
    }
    // MY ASSESTS LIST img(END)
    
    // MY ASSESTS LIST aud(END)
    useEffect(()=>{
        if(pageIndexForSelfAudio !== 1)
        dispatch(fetchSelFAUDIOassestDispatch(pageIndexForSelfAudio, 25, "LOAD_MORE"))
    },[pageIndexForSelfAudio])

    useEffect(()=>{
        if( assestsData.selfAUDIOAssestsOnHome.length === 0){
            setIsSelfUploadedAudiompty(true)
        }else{
            setIsSelfUploadedAudiompty(false)
        }
    }, [assestsData.selfAUDIOAssestsOnHome])

    const fetchMoreDataForSelfAudio = () => {
        setPageIndexForSelfAudio(pageIndexForSelfAudio+1)
    };

    const getSelfAudiosList = ()=>{
        if(assestsData.selfAUDIOAssestsOnHome.length ===0)
        return <NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Audios" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
       return  <InfiniteScroll
                    className="row"
                    dataLength={assestsData.selfAUDIOAssestsOnHome}
                    next={fetchMoreDataForSelfAudio}
                    hasMore={assestsData.hasMoreAudioAssests}
                    loader={assestsData.selfAUDIOAssestsOnHome.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                    }
                    useWindow= {false}
                    threshold ={200}
                    //  scrollableTarget="scrollableDiv"
                    //  scrollableTarget= {activeTab}
                    scrollableTarget= "getSelfAudiosListScroll"
               >
               {getSelfAudios()}
           </InfiniteScroll>
    
      
    }

    const getSelfAudios = ()=>{
        return assestsData.selfAUDIOAssestsOnHome.map((audio, index)=>{
            return <AudioCardList 
                    audio={audio} 
                    key={index}
                    getCheckBoxClasses ={()=>getCheckBoxClassesForAudio(audio.id)}
                    toggleSelectBox ={()=>toggleSelectBoxForAudio(audio.id)}

                    isMobileView= {isMobileView}
                    bind= {()=>bind({id: audio.id, type: audio.fileType, isSelf: true})}
                    isLongPressEnable= {isLongPressEnable}
                /> 
        })
         
    }

    const getCheckBoxClassesForAudio = (id)=>{
        let currentImg= null;
            if(assestsData.selfAUDIOAssestsOnHome.length>0){
                currentImg =assestsData.selfAUDIOAssestsOnHome.find(aud=> aud.id === id && aud.isAudioSelected )
                console.log('dmfdkfjkdjfkjsdkfj 1', currentImg)
            }
            if(currentImg != null && currentImg != undefined)
            return "my-1 selected";
            return "my-1";
    }

    const toggleSelectBoxForAudio = (id)=>{
        if(assestsData.selfAUDIOAssestsOnHome.find(aud=> (aud.id === id && aud.isAudioSelected))){
            dispatch(setToggleSelectForAudioDispatch(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
//         else if(
// userProfileDetails.premiumUser !== 1 && (assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length)===1){
//             // toast.dismiss();
//             // toast.error("You can only select maximum 1 audio", { });
//             // setOpenUpgrade(true);
//             return
//         }
        else{
            dispatch(setToggleSelectForAudioDispatch(id))
        }
    }
    // MY ASSESTS LIST aud(END)

    // MY ASSESTS LIST vid(END) 
    useEffect(()=>{
        if(pageIndexForSelfVideo !== 1)
        dispatch(fetchSelFVEDIOassestDispatch(pageIndexForSelfVideo, 25, "LOAD_MORE")) 
    },[pageIndexForSelfVideo])

    useEffect(()=>{
        if( assestsData.selfVEDIOAssestsOnHome.length === 0){
            setIsSelfUploadedVideoempty(true)
        }else{
            setIsSelfUploadedVideoempty(false)
        }
    }, [assestsData.selfVEDIOAssestsOnHome])

    const fetchMoreDataForSelfVideo = () => {
        setPageIndexForSelfVideo(pageIndexForSelfVideo+1)
    };

    const getSelfVideosList = ()=>{
        if(assestsData.selfVEDIOAssestsOnHome.length ===0)
        return <NoContentWithOutUploadPage
                        breadCrumbItems= {["VR Experience", "Videos" ]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
       return  <InfiniteScroll
                    className="row"
                    dataLength={assestsData.selfVEDIOAssestsOnHome}
                    next={fetchMoreDataForSelfVideo}
                    hasMore={assestsData.hasMoreVediosAssests}
                    loader={assestsData.selfVEDIOAssestsOnHome.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                        <p style={{ textAlign: "center" }}>
                            {/* <b>Yay! You have seen it all</b> */}
                        </p>
                    }
                    useWindow= {false}
                    threshold ={200}
                    //  scrollableTarget="scrollableDiv"
                    //  scrollableTarget= {activeTab}
                    scrollableTarget= "getSelfVideosListScroll"
               >
               {getSelfVideo()}
           </InfiniteScroll>
    
      
    }

    const getSelfVideo = ()=>{
        return assestsData.selfVEDIOAssestsOnHome.map((video, index)=>{
            return <VideosCardList 
                video={video} 
                key ={index}
                getCheckBoxClasses ={()=>getCheckBoxClassesForVideo(video.id)}
                toggleSelectBox ={()=>toggleSelectBoxForVideo(video.id)}
                
                isMobileView= {isMobileView}
                bind= {()=>bind({id: video.id, type: video.fileType, isSelf: true})}
                isLongPressEnable= {isLongPressEnable}
                />
        })
    }

    const getCheckBoxClassesForVideo = (id)=>{
        let currentImg= null;
        if(assestsData.selfVEDIOAssestsOnHome.length>0){
            currentImg =assestsData.selfVEDIOAssestsOnHome.find(vid=> vid.id === id && vid.isVideoSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    }
    console.log("mmmmm>>>", 
        sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds,
        sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds,
        sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds,
    )
    const toggleSelectBoxForVideo = (id)=>{
        if(assestsData.selfVEDIOAssestsOnHome.find(vid=> (vid.id === id && vid.isVideoSelected))){
            dispatch(setToggleSelectForVideoDispatch(id))
        }else if(isSelectedAssestsSizeisGraterThan_250()){
            // toast.dismiss();
            // toast.error("Total selected assests size should be less than 250", { });
            return
        }
//         else if(
// userProfileDetails.premiumUser !== 1 && (assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected).length + sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length)===1){
//             // toast.dismiss();
//             // toast.error("You can only select maximum 1 videos", { });
//             // setOpenUpgrade(true);
//             return
//         }
        else{
            dispatch(setToggleSelectForVideoDispatch(id))
        }
    }
    // MY ASSESTS LIST vid(END)


    function isSelectedAssestsSizeisGraterThan_250 (){

        let totalSizeInArray =[];
        let totalSize =[];

        if(sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length >0){
            totalSizeInArray =[...totalSizeInArray ,sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.map(img=> img.size)]
        }
        if(sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length >0){
            totalSizeInArray =[...totalSizeInArray ,sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.map(aud=> aud.size)]
        }
        if(sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length >0){
            totalSizeInArray =[...totalSizeInArray ,sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.map(vid=> vid.size)]
        }
        if(assestsData.selfIMGAssestsOnHome.find(img=> img.isImgSelected )){
            totalSizeInArray =[...totalSizeInArray ,assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected ).map(img=> img.fileSizeInKB)]
        }
        if(assestsData.selfAUDIOAssestsOnHome.find(aud=> aud.isAudioSelected )){
            totalSizeInArray =[...totalSizeInArray ,assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected ).map(aud=> aud.fileSizeInKB)]
        }
        if(assestsData.selfVEDIOAssestsOnHome.find(vid=> vid.isVideoSelected )){
            totalSizeInArray =[...totalSizeInArray ,assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected ).map(vid=> vid.fileSizeInKB)]
        }

        totalSizeInArray.forEach(arraySize =>{
            arraySize.forEach(size =>{
                totalSize =[...totalSize, size]
            })
        });

        
        const initialValue = 0;
        let totalSizeInMB = 0;

        const sumWithInitial = totalSize.reduce((accumulator, currentValue) => accumulator + 
        currentValue, initialValue );
    
        totalSizeInMB = convert(sumWithInitial, 'kB').to('MB');
        
        console.log('this is my totla sise of tha', totalSize, totalSizeInMB);


        // IF USER IS PRIMIUM, ALLOW THEM FOR UNLIMITED 
        if(userProfileDetails.premiumUser === 1 && props.room.accessable === 1){
            return false;
        }
        // else if(userProfileDetails.premiumUser === 0 && props.room.accessable === 1 && totalSizeInMB >250){ // IF USER IS FREE, APPLY THESE CONDITION 
        //     setOpenUpgrade(true);
        //     return true;
        // }
        else{
            return false;
        }
    
    }

    // ADDING IN TO THE ROOM
    const ApplyAddAssestsIntheRoom =async ()=>{
        
        toast.dismiss();
        // let selfImgTobeUploaded =assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected ).map(img=> img.id);
        // let selfAudTobeUploaded =assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected ).map(aud=> aud.id);
        // let selfVidTobeUploaded =assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected ).map(vid=> vid.id);

        // let sharedImgTobeUploded =sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.map(img=> img.id);
        // let sharedAubTobeUploded =sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.map(aud=> aud.id);
        // let sharedVidTobeUploded =sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.map(vid=> vid.id);

        // let shouldUploaded= false;

        // if((VRassestList.images.length <= 2) && (selfImgTobeUploaded.length >0 || sharedImgTobeUploded.length >0)){
        //     shouldUploaded= true;
        // }else if((VRassestList.audios.length <= 2) && (selfAudTobeUploaded.length >0 || sharedAubTobeUploded.length >0)){
        //     shouldUploaded= true;
        // }else if((VRassestList.videos.length <= 2) && (selfVidTobeUploaded.length >0 || sharedVidTobeUploded.length >0)){
        //     shouldUploaded= true;
        // }

        if( userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        // if(
        //     // !userProfileDetails.isPremium 
        //     userProfileDetails.isExpired && !shouldUploaded){
            
        //     if((selfImgTobeUploaded.length >0 || sharedImgTobeUploded.length >0) // IMAGE
        //         && (VRassestList.images.length + selfImgTobeUploaded.length +sharedImgTobeUploded.length) > 3
        //     ){
        //         setOpenUpgrade(true);
        //         toast.error("Please purchase a premium plan to access this feature(Free users are allowed to add only 3 Photos, 1 Video and 1 Audio)", { });
        //         return;
        //     }else if((selfAudTobeUploaded.length >0 || sharedAubTobeUploded.length >0) // AUDIO
        //     && (VRassestList.audios.length + selfAudTobeUploaded.length +sharedAubTobeUploded.length) > 1){
        //         setOpenUpgrade(true);
        //         toast.error("Please purchase a premium plan to access this feature(Free users are allowed to add only 3 Photos, 1 Video and 1 Audio)", { });
        //         return;
        //     }else if((selfVidTobeUploaded.length >0 || sharedVidTobeUploded.length >0) // VIDEO
        //     && (VRassestList.videos.length + selfVidTobeUploaded.length +sharedVidTobeUploded.length) > 1){
        //         setOpenUpgrade(true);
        //         toast.error("Please purchase a premium plan to access this feature(Free users are allowed to add only 3 Photos, 1 Video and 1 Audio)", { });
        //         return;
        //     }

        // }

        let isAnyOneImgIsSelected =assestsData.selfIMGAssestsOnHome.find(img=> img.isImgSelected );
        let isAnyOneAudioIsSelected =assestsData.selfAUDIOAssestsOnHome.find(aud=> aud.isAudioSelected );
        let isAnyOneVedioIsSelected =assestsData.selfVEDIOAssestsOnHome.find(vid=> vid.isVideoSelected );

        if(
            (isAnyOneImgIsSelected || isAnyOneAudioIsSelected || isAnyOneVedioIsSelected) ||
            (sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.length >0 ||sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.length >0 ||sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.length >0 )
        ){
        let totalRecord= [
            assestsData.selfIMGAssestsOnHome.filter(img=> img.isImgSelected ).map(img=> img.id),
            assestsData.selfAUDIOAssestsOnHome.filter(aud=> aud.isAudioSelected ).map(aud=> aud.id),
            assestsData.selfVEDIOAssestsOnHome.filter(vid=> vid.isVideoSelected ).map(vid=> vid.id),
            sharedAssestWithMeOrBy.sharedByThisUser.selectedImgIds.map(img=> img.id),
            sharedAssestWithMeOrBy.sharedByThisUser.selectedAudIds.map(aud=> aud.id),
            sharedAssestWithMeOrBy.sharedByThisUser.selectedVidIds.map(vid=> vid.id)
        ].join();

        let assestToBeAddedIntheRoom = {
            assetsids: totalRecord,
            vrexperiencemappintid: props.room.vrRoomMappingID.toString()
        }

        try{
            console.log('this is try registration', "try");
            let res = await addAssestInTheRoom(assestToBeAddedIntheRoom, dispatch, tokenContext.accessToken);   
            console.log("addAssestInTheRoomaddAssestInTheRoom >>", res);
            if(res.result !== undefined && res.result.payload !== undefined && res.result.payload.isDuplicate !== undefined && res.result.payload.isDuplicate === 1 ){
                setDuplicateAssestModal(!duplicateAssestModal)
            }else{
                toast("Assets shared successfully");
                props.fetchAssests();
                dispatch(resetSharedAssestWithMeOrBy());
                toggle();
            }
            setIsLongPressEnable(false);
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
                console.log('this is catch registration', err)
            }

        }

    }
 
    return (
        <React.Fragment>
            <ExpiredModal />
           {/* add assest button (5th VRpic-Sprint-6   bottom-right) */}
            <div className="addAssets">
                {
                // userProfileDetails.premiumUser !==0 
                !userProfileDetails.isExpired && <button className="btn btn-primary invite-contributors-add-assets" onClick={props.toggleInviteContributors}>Invite Contributors</button>}
                <button className="btn btn-primary invite-contributors-add-assets" onClick={toggle}>Add Assets</button>
            </div>

           {/* add `select assest` modal (6th VRpic-Sprint-6   bottom-right) */}
           <Modal isOpen={modal} centered className="modal-xl" toggle=  {()=>{
                toggle()
                }} {...props} >
               
                <ModalBody >
                    <h4 className="mb-1">Select Assets</h4>
                    
                    <div className="cs_tabs" >
                        
                        <FormGroup className="asset_filter ">
                            <Input className="select_secondary" id="custodian" name="custodian" type="select"
                            onChange={loadThisUserAssest}
                            >
                                <option value={""}>My Asset</option>
                                {getShareWithMeUsersListOptions()}
                            </Input>
                        </FormGroup> 
                        
                        <Nav tabs>
                            <NavItem>
                                <NavLink
                                className={activeTab === 'photo' ? 'active' : ''}
                                onClick={()=>{ ClickTab('photo')}}
                                >
                                Photo
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={activeTab === 'video' ? 'active' : ''}
                                onClick={()=>{ ClickTab('video')}}
                                >
                                Video
                                </NavLink> 
                            </NavItem>
                            <NavItem>
                                <NavLink
                                className={activeTab === 'audio' ? 'active' : ''}
                                onClick={()=>{ ClickTab('audio')}}
                                >
                                Audio
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent className="assets_item" 
                            activeTab={activeTab}
                            // id= {activeTab}
                        >
                            <TabPane 
                                tabId="photo" 
                                
                                id= {"getSelfImagesListScroll"}
                            >
                               
                                {selectedSharedUser?getSharedByUserImagesList(): getSelfImagesList()}
                               
                            </TabPane>
                            <TabPane 
                                tabId="video"
                                 
                                id= {"getSelfVideosListScroll"}    
                                ref ={vidRef}
                            >

                                {selectedSharedUser?getSharedByUserVideoList() : getSelfVideosList()}
                                
                            </TabPane>
                            <TabPane 
                                tabId="audio"
                                id= {"getSelfAudiosListScroll"}    
                            >
                                {selectedSharedUser?getSharedByUserAudosList() : getSelfAudiosList()}
                                
                            </TabPane>
                            
                        </TabContent>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 mt-4">
                            <button 
                                className="btn btn-primary me-3 btn-long"
                                onClick={ApplyAddAssestsIntheRoom}
                            >Add</button>
                            <button 
                                className="btn btn-white btn-long"
                                onClick={()=>{ toggle()}}
                            >Cancel</button>
                        </div>
                    </div> 
                </ModalBody>
            </Modal>

            {/* Modal if share dublicate assests  */}
            <Modal isOpen={duplicateAssestModal} centered className="modal-md drop-modal" toggle={()=>{
                        setDuplicateAssestModal(!duplicateAssestModal);
                        toast("Assets shared successfully");
                        props.fetchAssests();
                        dispatch(resetSharedAssestWithMeOrBy());
                        toggle();
            }} {...props}>
                <Button className="modalClose" onClick={()=>{
                        setDuplicateAssestModal(!duplicateAssestModal);
                        toast("Assets shared successfully");
                        props.fetchAssests();
                        dispatch(resetSharedAssestWithMeOrBy());
                        toggle();
                    }}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <modalHeader className="text-center"><h4 className="mb-0 mt-4">Information</h4></modalHeader>
                <modalBody className="text-center pt-4 p-3">
                    Duplicate assets will not be added to the room.
                </modalBody>
                <ModalFooter className="justify-content-center">
                    <Button className="ms-2 ms-md-3" color="primary" onClick={()=>{
                        setDuplicateAssestModal(!duplicateAssestModal);
                        toast("Assets shared successfully");
                        props.fetchAssests();
                        dispatch(resetSharedAssestWithMeOrBy());
                        toggle();
                    }}>
                        Dismiss
                    </Button>
                </ModalFooter>
            </Modal>

            {/* Modal to be upgraded  */}
            {/* <UpgradModal 
                isOpen= {openUpgrade} 
                setOpenUpgrade ={setOpenUpgrade}
            /> */}
        </React.Fragment> 
    )
} 