import React from "react";
import { Row, Col, Button, Modal, ModalBody, FormGroup, Input } from 'reactstrap';
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css'; 
import NoContents from "./NoContents";
import Breadcrum from "../Breadcrum";
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';
import classNames from 'classnames';
import AddVRexperieceModal from "../AddVRexperieceModal";

export default (props)=>{

    return (
        <React.Fragment>
            <div className="ps-0 pe-0 ps-md-3 pe-md-3">

                {/* Dashboard no content  */}
                <NoContents 
                    primaryText ={props.primaryText} 
                    description ={props.description}
                    icon ={props.noImgIcon}
                />
                
                {/* { `Add VR Experience` will goes here (1st VRpic-Sprint-6 )  */}
                <AddVRexperieceModal />
            </div>
        </React.Fragment>
    )
}
