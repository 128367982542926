import React from "react";
import Logo from './../../ASSETS/Images/Recuerdo_1x.png';
export default (props) => {

    // COMMON CLASS WILL GO HERE
    let logoClass = "logo";

    if( props.logoClass!= undefined){
        logoClass = logoClass+ ' ' + props.logoClass;
    }

    return  <React.Fragment>
            <img src={Logo} alt="Logo" className={logoClass} />
        </React.Fragment>
}