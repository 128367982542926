import React from "react";

export default (props)=>{ 
 
    return (
        <React.Fragment>
            <div className="pageTitle mt-3">
                <div className="imageHeader">
                    <img src={props.BG} alt="banner" className="d-none d-md-block w-100" />
                    <img src={props.MobileBG} alt="banner" className="d-block d-md-none w-100" />
                    {props.render !==undefined && props.render()}
                </div>
            </div>
        </React.Fragment>
    )
}
