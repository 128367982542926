import { createSlice } from '@reduxjs/toolkit'
import { getData } from './../../../UTILITYS/index.tsx';
 
import * as API from './../../../APIs'

const initialState = {
    sharedWithMeUserInfo: [], 
    shareWithOtherUserInfo: [],
    hasMoreSharedWithMeUserInfo: true,
    hasMoreShareWithOtherUserInfo: true
}

export const shareWithMeAndOthers = createSlice({ 
  name: 'shareWithMeAndOthers',
  initialState: { ...initialState },
  reducers: {
    setShareWithMe: (state, action) => {
      console.log('this is our setShareWithMe submit data >>', action)
      return {
        ...state,
        sharedWithMeUserInfo: [...state.sharedWithMeUserInfo, ...action.payload.pagedList]
      }
    },
    setShareWithOthers: (state, action) => {
      console.log('this is our shareWithMeAndOthers submit data >>', action)
      return {
        ...state,
        shareWithOtherUserInfo: [...state.shareWithOtherUserInfo, ...action.payload.pagedList]
      }
    },
    setHasMoreSharedWithMeUserInfo: (state, action) => {
      return {
        ...state,
        hasMoreSharedWithMeUserInfo: action.payload
      }
    },
    setHasMoreShareWithOtherUserInfo: (state, action) => {
      return {
        ...state,
        hasMoreShareWithOtherUserInfo: action.payload
      }
    },
    resetShareWithMeAndOthers: (state, action)=>{
      return {...initialState}
    }
  } 
})

// Action creators are generated for each case reducer function
export const { setShareWithMe, setShareWithOthers, resetShareWithMeAndOthers, setHasMoreSharedWithMeUserInfo, setHasMoreShareWithOtherUserInfo } = shareWithMeAndOthers.actions

const setShareWithMeUserInfo =async (token, dispatch, pageIndex, pageSize) =>{
    
  console.log("this is a selfAssestFetch try", token)

    try{
      let url= `${API.BASE_URL+API.SHARE_WITH_ME_AND_OTHERS_USER_INFO}?pageindex=${pageIndex}&pagesize=${pageSize}&issharedwithme=1`
      
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchShareWithMeUserInfo =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchShareWithMeUserInfo try", fetchShareWithMeUserInfo)
    
    return fetchShareWithMeUserInfo;
    
  }catch (err){

    console.log("this is a fetchShareWithMeUserInfo catch")
    throw (err)
  }

}

export const fetchShareWithMeUserInfoDispatch = (pageIndex, pageSize) => {

  return async(dispatch, getState) => {
    console.log('this is our state data', getState().localSessionDetails.payload) 
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resShareWithMe =await setShareWithMeUserInfo(token, dispatch, pageIndex, pageSize) 

        if(resShareWithMe.result.payload.pagedList.length === 0){ 
          dispatch(setHasMoreSharedWithMeUserInfo(false))
        }
        
        dispatch(setShareWithMe(resShareWithMe.result.payload))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

const setShareWithOthersUserInfo =async (token, dispatch, pageIndex, pageSize) =>{
    
  console.log("this is a selfAssestFetch try", token)

    try{
      let url= `${API.BASE_URL+API.SHARE_WITH_ME_AND_OTHERS_USER_INFO}?pageindex=${pageIndex}&pagesize=${pageSize}&issharedwithme=0`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchShareWithOthersUserInfo =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchShareWithOthersUserInfo try", fetchShareWithOthersUserInfo)
    
    return fetchShareWithOthersUserInfo;
    
  }catch (err){

    console.log("this is a fetchShareWithOthersUserInfo catch")
    throw (err)
  }

}

export const fetchShareWithOthersUserInfoDispatch = (pageIndex, pageSize) => {

  return async(dispatch, getState) => {
    console.log('this is our state data', getState().localSessionDetails.payload) 
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resShareWithOthers =await setShareWithOthersUserInfo(token, dispatch, pageIndex, pageSize) 

        if(resShareWithOthers.result.payload.pagedList.length === 0){
          dispatch(setHasMoreShareWithOtherUserInfo(false))
        }
        
        dispatch(setShareWithOthers(resShareWithOthers.result.payload))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export const resetDataDispatch = (pageIndex, pageSize) => {
  return async(dispatch, getState) => {
    try{
        dispatch(resetShareWithMeAndOthers())
      }catch (err){ 
    }
  }
}


export default shareWithMeAndOthers.reducer