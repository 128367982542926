import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
  open: false
}

export const LoaderForIndividualPage = createSlice({
  name: 'LoaderForIndividualPage',
  initialState: {
   ...initialState
  },
  reducers: {
    setLoaderForIndividualPage: (state, action)=>{
        // alert()
      state.open = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setLoaderForIndividualPage } = LoaderForIndividualPage.actions


export const LoaderForIndividualPageDispatch = bool => {
    
    return async(dispatch, getState) => {
        dispatch(setLoaderForIndividualPage(bool))
    }
}


export default LoaderForIndividualPage.reducer