import React, {useState, useEffect } from "react";
import HeaderSideBar from "..";
// import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyAssest from "../../Home/EmptyAssest";
import ImagesCardList from "../../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import CustomeBreadcrumb from "../../../COMPONENTS/CustomeBreadcrumb";
import { resetSharedAssestWithMeOrBy, fetchImagesSharedBymeDispatch,fetchImagesSharedByThisUserDispatch } from "../slice.reducer";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../../UTILITYS/Constant";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContents from "../../../COMPONENTS/NoContentPage/NoContents";
import NoContentWithOutUploadPage from "../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import Breadcrum from "../../../COMPONENTS/Breadcrum"; 
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import {sharedAssestWithMeOrByInterface, sharedByThisUserInterface, sharedBymeInterFace} from "./../../../UTILITYS/Modals/slicese/sharedAssestWithMeOrBy.modal.ts";
 
const ImageAssest: () => JSX.Element =(): JSX.Element =>{
    
    const dispatch = useAppDispatch();
    let {state}  = useLocation();
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isImageEmply, setIsImageEmply] = useState<boolean>(true);
    const sharedAssestWithMeOrBy: sharedAssestWithMeOrByInterface = useAppSelector(state => state.sharedAssestWithMeOrBy) as sharedAssestWithMeOrByInterface;
    const [userData, setUserData] = useState(state)

    let assestsInfo: sharedByThisUserInterface| sharedBymeInterFace= null;

    if(userData.isShareByMe){
        assestsInfo= sharedAssestWithMeOrBy.sharedByme;
    }else{
        assestsInfo= sharedAssestWithMeOrBy.sharedByThisUser;
    }

    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        dispatch(resetSharedAssestWithMeOrBy({}))
        if(!userData.isShareByMe){
            dispatch(setModuleActiveDispatch("shared_with_me"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }else{
            dispatch(setModuleActiveDispatch("shared_with_others"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }
        console.log('kjfiuuuu', userData)
    }, [dispatch])

    useEffect(()=>{
        if(userData.isShareByMe){
            dispatch(fetchImagesSharedBymeDispatch(loadEventIndex, 25, userData.userID)) 
        }else{
            dispatch(fetchImagesSharedByThisUserDispatch(loadEventIndex, 25, userData.userID)) 
        }
    },[loadEventIndex]) 

    const fetchMoreData: () => void = () => {
        seLoadEventIndex(loadEventIndex+1)
    };

    useEffect(()=>{
        if( assestsInfo?.images?.length === 0){
            setIsImageEmply(true)
        }else{
            setIsImageEmply(false)
        }
    }, [assestsInfo?.images])  

    const getCheckBoxClasses: () => string = ()=>{
        return "my-1";
    }
    const toggleSelectBox: () => void = ()=>{
        return;
    }

    const getImagesAssestsList: () => JSX.Element = ()=>{
       return  <InfiniteScroll
                    className="row"
                    dataLength={assestsInfo?.images?.length as number}
                    next={fetchMoreData}
                    hasMore={assestsInfo?.hasMoreImageAssests as boolean}
                    loader={assestsInfo?.images && assestsInfo?.images?.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                    }
               >
               {getImagesAssest()}
           </InfiniteScroll> as JSX.Element
      
    }

    const getImagesAssest: () => JSX.Element[] = ()=>{
        return assestsInfo?.images?.map((img, index)=>{
            return <ImagesCardList 
            img={img} 
            index ={index} 
            key ={index} 
            isFF={true} 
            assests= {assestsInfo?.images}
            getCheckBoxClasses ={()=>getCheckBoxClasses()}
            toggleSelectBox ={()=>toggleSelectBox()}
            isHideCheckBox= {true}
            /> 
        }) as JSX.Element[]
    }

    if(isImageEmply){
        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Images"]: ["Asset Library", "Shared with me", "Images"]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar> as JSX.Element
    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                {/* content header - Breadcrumb */}
                <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                        <Breadcrum
                            breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Images"]: ["Asset Library", "Shared with me", "Images"]}
                        />  
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4">            
                    {/* Images  */}
                    <div className="assets_item ps-2 pe-2">
                        {getImagesAssestsList()}
                    </div>
                </div>
            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}

export default ImageAssest