import React, {useEffect, useState} from "react";
import Close from './../../../ASSETS/Images/icons/cancel.png'
import { Modal, ModalBody, Button } from 'reactstrap';
import ReactPlayer from 'react-player'

export default (props)=>{

    const [isOpenVidioPlayer, setIsOpenVidioPlayer] = useState(props.isOpenAudio); 

    useEffect(()=>{
        setIsOpenVidioPlayer(props.isOpenVidioPlayer)
    }, [props.isOpenVidioPlayer]);

    return (
        <Modal isOpen={isOpenVidioPlayer} centered className="drop-modal videoModal" toggle={setIsOpenVidioPlayer} {...props}>
            <Button className="modalClose" onClick={()=>{
                setIsOpenVidioPlayer(false)
                props.closeVideoPlayr()
            }}> 
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <ReactPlayer 
                    url={props.video.fIleUrl} 
                    controls= {true}
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload noplaybackrate',
                                disablePictureInPicture: 'true',
                                autoplay: 'true'
                            }
                        }
                    }}
                />
            </ModalBody>
        </Modal>
    )
}