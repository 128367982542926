import React from "react";
import ImageCard from './ImageCard'
import { useSelector } from 'react-redux';

export default (props)=>{
    const assestsData = useSelector(state => state.assestsData); 

    return <ImageCard img={props.img} index ={props.index} {...props}/>
    // return assestsData.selfIMGAssestsOnHome.map((img, index)=>{
    // return ( <ImageCard img={img} index ={index} key ={index}/>)  
    // })
}