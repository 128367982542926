import React, {useState} from "react";
import { Col, Card, CardBody, CardFooter } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleSelectForAudioDispatch } from "./../../../SCREENS/Home/slice.reducer";
import Audio from './../../../ASSETS/Images/icons/AudioAssest2.jpg';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import { toast } from 'react-toastify'; 
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap'; 
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import CustomAudioPlayer from "../../Galaries/CustomAudioPlayer";
import { setToggleSelectForAudioSharedByThisUser } from "../../../SCREENS/FamilyAndFriends/slice.reducer";

export default (props)=>{

    const dispatch = useDispatch();
    const assestsData = useSelector(state => state.assestsData);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const [isOpenAudio, setIsOpenAudio] = useState(false); 

    const closeAudioPlayer = ()=>{
        setIsOpenAudio(false)
    }

    const getCheckBox = ()=>{
        if(!props.isFF && props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.audio.id)}
            ></span>
        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.audio.id)}
            ></span>
        }else if(!props.isFF && (props.isMobileView=== undefined || !props.isMobileView)){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.audio.id)}
            ></span>
        }
    }
    

    return (
        <React.Fragment >
            <Col sm="6" md="4" lg="3" className="ps-1 pe-1">
                <Card className={props.getCheckBoxClasses(props.audio.id)} >
                {getCheckBox()}
                    <CardBody className="p-0" {...(props.bind !== undefined ? props.bind(props.audio.id) : {})} onClick={()=>{
                        if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
                            props.toggleSelectBox(props.audio.id)
                        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
                            props.toggleSelectBox(props.audio.id);
                        }else{
                            setIsOpenAudio(true)
                        }
                    }}>
                        <img src={Audio} onContextMenu={(event) => {
                            event.preventDefault(); // Prevent the context menu from appearing
                        }} alt="image" className="assets_image prevent-context-view" />
                    </CardBody>
                    <CardFooter><p className="mb-0">{props.audio.name.length >25 ? props.audio.name.substring(0, 25)+"...": props.audio.name}</p></CardFooter>
                </Card>
            </Col>
            
            {/* CAROUSAL MODAL */}
            <CustomAudioPlayer 
                isOpenAudio= {isOpenAudio}
                closeAudioPlayer ={closeAudioPlayer}
                index ={props.index}
                {...props}
            />
        </React.Fragment>
    )
}

