import React from "react";
import loginBackground from './../../ASSETS/Images/Background_image.jpg';

export default (props) =>{
    return (
        <React.Fragment>
            <div 
                className="col-md-6 login-content d-none d-md-flex" 
                style={{backgroundImage: `url(`+loginBackground+`)` }}
                {...props}
            >
               {props.children}
            </div>
        </React.Fragment>
    )
}