import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, FormGroup, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux'
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css'; 
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';
import classNames from 'classnames';
import { fetchCostodianAndRooms, mapvrroomwithrelationship } from "../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import validator from 'validator';
import { fetchVrUsersDispatch, resetVRusers, updateVrUsersDispatch } from "../../SCREENS/VR-experience/Slices/VRexperienceUser.slice";
import { fetchVirtualTourDispatch, resetVirtualTour } from "../../SCREENS/360virtualTour/Slices/VirtualTour.slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { MultiSelect } from "react-multi-select-component";
import { default as ReactSelect, components } from "react-select";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import UpgradModal from "../Subscription/UpgradModal";
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";

export default (props)=>{

    const dispatch = useDispatch();
    const [custodiansAndRooms, setCustodiansAndRooms] = useState({ 
        custodians: [],
        vrRooms: []
    });
    const [modal, setModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [firstName_, setFirstName_] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [firstNameErr, setFirstNameErr] = useState(false);
    const [lastNameErr, setLastNameErr] = useState(false);
    const [emailErr, setEmailErr] = useState(false)
    const [selectedRoom, setSelectedRoom] = useState("")
    const [selectedCustodian, setSelectedCustodian] = useState("")
    const [selectedRoomErr, setSelectedRoomErr] = useState(false)
    const [selectedCustodianErr, setSelectedCustodianErr] = useState(false)
    const tokenContext = useContext(CreateTokenContextProvider);
    // const [loadVirtualTour, setLoadVirtualTour] = useState(1);
    const [selectedTour, setSelectedTour] = useState([]);
    const [selectedTourErr, setSelectedTourErr] = useState(false)
    const [tourOptions, setTourOptions] = useState([]);
    // const [selectedTour, setSelectedTour] = useState([]);
    const virtualTours = useSelector(state => state.virtualTours);
    const vrUsers = useSelector(state => state.vrUsers);
    const [openUpdatedVrUserModal, setOpenUpdatedVrUserModal] = useState(false);
    const [updatedVrUser, setUpdatedVrUser] = useState({});
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [openUpgrade, setOpenUpgrade] = useState(false);

    // IF EDIT THROUGH VR-USER LISTING
    useEffect(()=>{
        if(props.openUpdatedVrUserModal!= undefined && props.openUpdatedVrUserModal){
            setModal(!modal);
            EditableModal(props.updatedVrUser);
            setOpenUpdatedVrUserModal(!openUpdatedVrUserModal);
            setUpdatedVrUser(props.updatedVrUser);
        }
    },
    [props.openUpdatedVrUserModal, props.updatedVrUser]);
    
    // IF EDIT THROUGH ADD-VR EXPERIENCE BUTTON
    const isExistingVRuserSelected= (vrUserID)=>{
        console.log("EditableModalEditableModal",tourOptions);  
        if(vrUsers.users.length >0 && vrUsers.users.find(vrUser =>parseInt(vrUser.relationshipID)=== parseInt(vrUserID))){
            EditableModal(vrUsers.users.find(vrUser =>parseInt(vrUser.relationshipID)=== parseInt(vrUserID)));
            setOpenUpdatedVrUserModal(true);
            setUpdatedVrUser(vrUsers.users.find(vrUser =>parseInt(vrUser.relationshipID)=== parseInt(vrUserID)));
        }else{
            resetState(true);
        }
    }

    const getSeletctedTour = (tour, selectedIDs) => { 
        return selectedIDs.includes(tour.id)
    }
    
    const EditableModal = (updatedVrUser) => {   
        // console.log("EditableModalEditableModal", updatedVrUser);  
        let {id, firstName, lastName, relationshipID, email, virtualTourIDs} = updatedVrUser;

        console.log("VirtualTourIDsVirtualTourIDs", updatedVrUser, email)
        let selectedIDs= virtualTourIDs.split(',').map(string=> parseInt(string))
        let selecteTours =virtualTours.tour.filter(tour=> getSeletctedTour(tour, selectedIDs)).map((tour)=>{
            return {
                label: tour.name, 
                value: tour.id
            }
        })
        // console.log("selecteToursselecteTours", virtualTours.tour.filter(tour=> getSeletctedTour(tour, selectedIDs)))
        setFirstName(firstName)
        setLastName(lastName)
        setSelectedCustodian(relationshipID)
        setEmail(email)
        setSelectedTour(selecteTours)
        // setSelectedRoom("")
        setFirstNameErr(false)
        setLastNameErr(false)
        setEmailErr(false)
        // setSelectedRoomErr(false)
        setSelectedCustodianErr(false)
        setSelectedRoomErr(false)
    }

    useEffect(()=>{
        dispatch(resetVirtualTour())
        dispatch(fetchVirtualTourDispatch(1, 50, "requestForSelectBox")); 

        return ()=>{
            dispatch(resetVirtualTour());
        }
    }, []);

    useEffect(()=>{
      if(virtualTours.tour.length >0){
        setTourOptions(virtualTours.tour.map(tour=>{
            return {
                label: tour.name, 
                value: tour.id
            }
        }));
      }
    }, [virtualTours.tour.length]);

    const Option = (props) => { 
        return (
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        );
    };

    const toggle = () => {

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        resetState()
        setModal(!modal);
        if(openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal){
            props.setOpenUpdatedVrUserModal();
            setOpenUpdatedVrUserModal(false);
            setUpdatedVrUser({});
        }
    }

    const resetState= (isSkipSelectedCustodianToBeReset= false)=>{
        setFirstName("");
        setLastName("");
        setEmail("");
        setFirstNameErr(false);
        setLastNameErr(false);
        setEmailErr(false);
        // setSelectedRoom("")
        !isSkipSelectedCustodianToBeReset && setSelectedCustodian("");
        // setSelectedRoomErr(false)
        setSelectedCustodianErr(false);
        setSelectedRoomErr(false)
        setSelectedTour([]);
        setOpenUpdatedVrUserModal(false);
        setUpdatedVrUser({});
    }

    useEffect(()=>{
        console.log('vr experiecne my ',)
        async function getCostodianAndRooms(){
            let res = await fetchCostodianAndRooms(tokenContext.accessToken, dispatch);
            setCustodiansAndRooms({
                custodians: res.result.payload.vrRelationship
                , 
                vrRooms: res.result.payload.vrRoom});
        }
        getCostodianAndRooms()
    },[]);
    
    const onInputChange = (e)=>{
        switch(e.target.name){
          case "firstName":
            setFirstName(e.target.value.replace(/[^a-zA-Z\s]/g, ''));
            setFirstNameErr(false)
           break;
          case "lastName":
            setLastName(e.target.value.replace(/[^a-zA-Z\s]/g, ''));
            setLastNameErr(false)
           break;
        case "email":
            setEmail(e.target.value);
            setEmailErr(false)
            break;
        }

    }

    const getCutodianOptions = (e)=>{
        let custodians =null;
        custodians= custodiansAndRooms.custodians.map(user=>{
            if(openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal && user.id === updatedVrUser.relationshipID){
                return <option value={user.id} selected> {user.name} </option>
            }
            return <option value={user.id}> {user.name} </option>
        });

        if(openUpdatedVrUserModal){
            return [<option value="">
            Select Relationship
        </option>, custodians];
        }

        return [<option value="">
            Select Relationship
        </option>, custodians];
    }
    
    const selectedValue = (e) =>{

        switch(e.target.name){
            case "custodian":
                setSelectedCustodianErr(false)
                setSelectedCustodian(e.target.value);
                isExistingVRuserSelected(e.target.value);
              break;
          }
    }

    const createdRoom= async()=>{
        
        toast.dismiss();

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        // else if(!userProfileDetails.isPremium && vrUsers.users !=undefined && vrUsers.users.length >0){
        //     setOpenUpgrade(true);
        //     toast.error("Free users are allowed only 1 VR Experience.", { });
        //     return;
        // }

        let virtualTourIDs= selectedTour.map(tour=>{
            return tour.value
        }).toString()
        
        if(validator.isEmpty(selectedCustodian.toString().trim())){
            toast.error("Please Select Relationship", { });
            setSelectedCustodianErr(true)
            return
        }

        if(validator.isEmpty(firstName.trim())){
            toast.error("Please Enter First Name", { });
            setFirstNameErr(true)
            return;
        }else if(!validator.isLength(firstName.trim(), {min: 1, max: 25})){
            toast.error("First Name length should be minimum 3 char and maximum 25 char", { });
            setFirstNameErr(true)
            return;
        }
         
        if(validator.isEmpty(lastName.trim())){
            toast.error("Please Enter Last Name", { });
            setLastNameErr(true)
            return;
        }else if(!validator.isLength(lastName.trim(), {min: 1, max: 25})){
            toast.error("Last Name length should be minimum 3 char and maximum 25 char", { });
            setLastNameErr(true)
            return;
        }

        if(!validator.isEmail(email.trim())){
            if(email === "") toast.error("Please Enter Email", { });
            else toast.error("Please Enter valid Email", { });
            setEmailErr(true)
            return
        }
        
        if(selectedTour.length === 0){
            toast.error("Please Select at least one tour", { });
            setSelectedRoomErr(true)
            return
        }

        let IsEdit= 0;
        if(openUpdatedVrUserModal != undefined && openUpdatedVrUserModal){
            IsEdit= 1;
        }

        let mappingData = {
            relationshipID: selectedCustodian.toString().trim(),
            // vrRoomID: selectedRoom,
            virtualTourIDs, 
            firstName: firstName,
            lastName: lastName,
            email: email, 
        }

        try{
            await mapvrroomwithrelationship({...mappingData, IsEdit}, dispatch, tokenContext.accessToken);
            
            if(openUpdatedVrUserModal != undefined && openUpdatedVrUserModal){
                console.log("openUpdatedVrUserModalopenUpdatedVrUserModal 1", {...mappingData, id: openUpdatedVrUserModal.id});
                dispatch(updateVrUsersDispatch({...mappingData, id: updatedVrUser.id}));
                toast("VR Experience folder updated successfully");
            }else{
                toast("VR Experience folder created successfully");
                dispatch(resetVRusers());
                dispatch(fetchVrUsersDispatch(1, 25));
            }
            toggle();
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          }

    }

    const getSelectBoxErrStyle= ()=>{
        if(selectedTourErr){
            console.log("selectedTourselectedTour", selectedTourErr);
            return{
                border: "1px solid red",
                borderRadius: "5px"
            }
        }
    }

    return (
        <React.Fragment>   
            <ExpiredModal />
            {/* `Add VR Experience` will goes here (1st VRpic-Sprint-6  bottom/right)  */}
            <div className="addAssets">
                <button className="btn btn-primary" onClick={toggle}>Add VR Experience</button>
            </div> 

            {/* YOUR CHECK BOX MODEL WILL GO HERE (2nd VRpic-Sprint-6) */}
            <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
                toggle()
                }} {...props}>
                <Button className="modalClose" onClick={()=>{
                    toggle()
                }}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    {/* <h4 className="text-center mb-4">Share</h4> */}
                    <div className="share_people p-2">
                        <div className="row">
                            <div className="col-12">
                                <div>Who do you want to be a custodian of?</div>
                                <FormGroup>
                                    <Input 
                                        className={classNames({ 
                                            "form-error": selectedCustodianErr,
                                            "cursor-none": props.openUpdatedVrUserModal !== undefined && props.openUpdatedVrUserModal
                                        })}
                                        id="custodian" name="custodian" type="select"
                                        onChange={selectedValue}
                                        value ={selectedCustodian}
                                        disabled= {props.openUpdatedVrUserModal !== undefined && props.openUpdatedVrUserModal}
                                    >
                                        {getCutodianOptions()}
                                    </Input>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <div>First Name</div>
                                <FormGroup>
                                    <input value={firstName} type="text" placeholder="First Name"
                                    className={classNames("form-control", { "form-error": firstNameErr, "cursor-none": openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal })} name={'firstName'} onChange={onInputChange}
                                    disabled= {openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-6">
                                <div>Last Name</div>
                                <FormGroup>
                                    <input value={lastName} type="text" placeholder="Last Name"
                                    className={classNames("form-control", { "form-error": lastNameErr, "cursor-none": openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal })} name={'lastName'} onChange={onInputChange}
                                    disabled= {openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div class="row">
                            <div className="col-12">
                                <div>Email</div>
                                <FormGroup>
                                    <input 
                                        value={email} 
                                        type="text" 
                                        placeholder="Email"
                                        className={
                                            classNames(
                                                "form-control", 
                                                { 
                                                    "form-error": emailErr,
                                                    "cursor-none": openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal
                                                })} name={'email'} onChange={onInputChange}
                                        disabled= {openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal}
                                    />
                                </FormGroup> 
                            </div>
                            <div className="col-12">
                                <div>Please select a virtual tour for your viewer</div>
                                <FormGroup>
                                    <ReactSelect 
                                        options={tourOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        components={{
                                            Option
                                        }}
                                        onChange={(option)=>{
                                            setSelectedTourErr(false);
                                            setSelectedTour(option);
                                        }}
                                        allowSelectAll={true}
                                        value={selectedTour}
                                        // defaultMenuIsOpen
                                        styles={{
                                            valueContainer: (baseStyles, state) => ({
                                              ...baseStyles,
                                              backgroundColor: "#ececec",
                                            }),
                                            indicatorsContainer: (baseStyles, state) => ({
                                              ...baseStyles,
                                              backgroundColor: "#ececec",
                                            }),
                                            container: (baseStyles, state) => ({
                                               ...baseStyles,
                                               ...getSelectBoxErrStyle(),
                                            }),
                                        }}
                                        
                                    />
                                </FormGroup>
                            </div>
                            
                        </div>
                        
                        <div className="row">
                            <div className="col-12 text-center mt-4">
                                <button 
                                    className="btn btn-primary"
                                    onClick={createdRoom}
                                >{openUpdatedVrUserModal !== undefined && openUpdatedVrUserModal ? "Save": "Create"}</button>
                            </div>
                        </div>
                    </div> 
                </ModalBody>
            </Modal>

            {/* Modal to be upgraded  */}
            {/* <UpgradModal 
                isOpen= {openUpgrade} 
                setOpenUpgrade ={setOpenUpgrade}
            /> */}
        </React.Fragment>
    )
}
