import Info from "./info";
import Dashboard from "./../Dashboard";
import Plan from "./plan";

export default (props) =>{
    
    const subscription= props.subscription;

    // if(!subscription.hasPaymentMethod){
    //     return <Dashboard><Plan /></Dashboard>
    // }

    return <Dashboard><Info subscription ={subscription}/> </Dashboard> 

}