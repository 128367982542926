import React, { useEffect, useState, useMemo } from "react";
import HeaderSideBar from ".";
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import Asset from './../../ASSETS/Images/icons/Asset_icon.png';
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import DragAndDropFiles from "../../COMPONENTS/DRAGE_FILE_FOLDER";
import { fetchSelFAUDIOassestDispatch, fetchSelFVEDIOassestDispatch, fetchSelFIMGassestDispatch,isFileUploadingDispatch, resetSelfAssestsOnHome } from "./slice.reducer";
import { fetchUserDetailsDispatch } from "../Profile/slice.reducer.tsx";
import CustomeBreadcrumb from "../../COMPONENTS/CustomeBreadcrumb";
import NoContentWithUploadIcon from "../../COMPONENTS/NoContentPage/NoContentWithUploadIcon";
import { redirectAfterUpload } from "../../UTILITYS/index.tsx";
import { useNavigate } from 'react-router-dom'
import { resetCurrentUploadedAssestsCountDispatch } from "../../COMPONENTS/DRAGE_FILE_FOLDER/dragFileFolder.slice";
import { ExpiredModal } from "../../COMPONENTS/Modal/Expired.js";

export default (props)=>{

    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const currentUploadedAssestsCount = useSelector(state => state.currentUploadedAssestsCount);
    const [isUploaded, setIsUploaded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log('dfsdfsdyyyyuyy', props)

    const onFileUpload = (isUploaded)=>{
        // dispatch(fetchSelFAUDIOassestDispatch(1, 1))
        // dispatch(fetchSelFVEDIOassestDispatch(1, 1))
        // dispatch(fetchSelFIMGassestDispatch(1, 1))
        navigateToAssestManagmentBasedOnCurrentUploadedCount();
        dispatch(isFileUploadingDispatch(false))
        // dispatch(fetchUserDetailsDispatch()); 
        setIsUploaded(isUploaded)
    }

    const navigateToAssestManagmentBasedOnCurrentUploadedCount = ()=>{
        let routToNavigate = "assets-images";
        routToNavigate= redirectAfterUpload(currentUploadedAssestsCount.currentUploadedAssestsCount.image, currentUploadedAssestsCount.currentUploadedAssestsCount.audio ,currentUploadedAssestsCount.currentUploadedAssestsCount.video); 
        dispatch(resetCurrentUploadedAssestsCountDispatch())

        if(routToNavigate !== "assets-videos")
            navigate(`/${routToNavigate}`);
    }
    
    // useEffect(()=>{  
    //     if(isUploaded){
    //         let routToNavigate = "assets-images";
    //         routToNavigate= redirectAfterUpload(userProfileDetails.imageFileCount, userProfileDetails.audioFileCount ,userProfileDetails.videoFileCount);
    //         setIsUploaded(false)
    //         navigate(`/${routToNavigate}`);
    //     }
    // }, [userProfileDetails.imageFileCount, userProfileDetails.audioFileCount, userProfileDetails.videoFileCount]);

    const memoizedValue = useMemo(() =>["Dashboard" ], []);

    return (
        <React.Fragment>
            <HeaderSideBar>
                <ExpiredModal />
                <NoContentWithUploadIcon  
                    onFileUpload={onFileUpload} 
                    breadCrumbItems= {memoizedValue}
                    primaryText= {props.primaryText}
                    description= {props.description}
                    noImgIcon= {Asset}
                />
            </HeaderSideBar>
        </React.Fragment>
    )
}
