
import { logoutDispatch } from '../SCREENS/Authentications/Login/slice.reducer';
import * as API from './../APIs'
import { postData, getData, postFormData, deleteAPI, putData } from './../UTILITYS/index.tsx';
import { toast } from 'react-toastify';
import EXIF from 'exif-js';
import { fetchUserDetailsDispatch } from '../SCREENS/Profile/slice.reducer.tsx';
import { updateSubscriptionDetailsDispatch } from '../SCREENS/Subscription/sbscription.slice.js';

// POST USER REGISTRATION 
export const registrationPost =async (data, dispatch) =>{
    
  try{
    let url= API.BASE_URL+API.REGISTRATION
    let dataObje= {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: data.password,
      phoneNumber: data.phoneNumber,
      confirmPassword: data.confirmPassword,
      roles: [
        "User"
      ]
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const RegistrationRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a registrationPost try",RegistrationRes)
    return RegistrationRes;
    
  }catch (err){
      // console.log("this is a registrationPost catch",err)
      throw (err)
  }
}

// FORGOT PASSWORD 
export const forgetPassword =async (data, dispatch) =>{
  try{
    let url= API.BASE_URL+API.FORGOT_PASSWORD
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const forgetPasswordRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a forgetPassword try",forgetPasswordRes)
    return forgetPasswordRes;
    
  }catch (err){
    console.log("this is a forgetPassword catch",err)
    throw (err)
  }

}

// RESET PASSWORD 
export const resetPassword =async (data, dispatch) =>{
  try{
    let url= API.BASE_URL+API.RESET_PASSWORD
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const resetPasswordRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a resetPasswordRes try",resetPasswordRes)
    return resetPasswordRes;
    
  }catch (err){
      console.log("this is a resetPasswordRes catch",err)
      throw (err)
  }

}

// THANK YOU 
export const postSavenothankyou =async (data, dispatch) =>{
  try{
    let url= API.BASE_URL+API.THANK_YOU 
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const savenothankyouRes =await postData({url, headerConfig, dataObje}, dispatch)
    return savenothankyouRes;
    
  }catch (err){
      throw (err)
  }

}

// VERIFY EMAIL
export const verifyEmail =async (data, dispatch) =>{
  try{
    let url= API.BASE_URL+API.VERIFY_EMAIL 
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const verifyEmaildRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a verifyEmaildRes try",verifyEmaildRes)
    return verifyEmaildRes;
    
  }catch (err){
      console.log("this is a verifyEmaildRes catch",err)
      throw (err)
  }

}

// RESEND EMAIL ON CONFIM USER
export const resendEmailOnConfirmUser =async (data, dispatch) =>{
  try{
    let url= API.BASE_URL+API.RESEND_EMAIN_VERIFY_USER 
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json"
    }
    const resendEmaildRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a resendEmaildRes try",resendEmaildRes)
    return resendEmaildRes;
    
  }catch (err){
      console.log("this is a resendEmaildRes catch",err)
      throw (err)
  }

}

// GET COUNTRY
export const getCountry =async (obj, dispatch) =>{
  try{
    let url= API.BASE_URL+API.GET_COUNTRY 

    let headerConfig = {
      "Content-Type": "application/json"
    }

    const contrydRes =await getData({url, headerConfig}, dispatch)
    console.log("this is a contrydRes try",contrydRes)
    return contrydRes;
    
  }catch (err){
      console.log("this is a contrydRes catch",err)
      throw (err)
  }

}

// GET IMG URL
export const getImgURL =async (data, dispatch, token) =>{

  console.log('ti sis datat file ss', data)
  try{
   
    const formData = new FormData();
    let url= API.BASE_URL+API.GET_IMG_UL;
  
    formData.append(
      "file",
      data.file
    );

    let headerConfig = {
      "Authorization": "Bearer " +token
    }
    const verifyEmaildRes =await postFormData({url, headerConfig, formData}, dispatch)
    console.log("this is a verifyEmaildRes try", verifyEmaildRes)
    return verifyEmaildRes;
    
  }catch (err){
      console.log("this is a verifyEmaildRes catch",err)
      throw (err)
  }

}

// GET IMG URL
export const updatePassword =async (data, dispatch, token) =>{

  try{
    let url= API.BASE_URL+API.UPDATE_PASSWORD  
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const verifyEmaildRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a verifyEmaildRes try",verifyEmaildRes)
    return verifyEmaildRes;
    
  }catch (err){
      console.log("this is a verifyEmaildRes catch",err)
      throw (err)
  }

}

// LOG-OUT
export const logOut =async (data, dispatch, token) =>{

  try{
    let url= API.BASE_URL+API.LOG_OUT  
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const logoutRes =await postData({url, headerConfig, dataObje})
    console.log("this is a logoutRes try",logoutRes)
    return logoutRes;
    
  }catch (err){
      console.log("this is a logoutRes catch",err)
      throw (err)
  }

}

// CLEAR DATA WHEN REFRESH TOKEN EXPIRE
export const resetApp =async (dispatch) =>{
  dispatch(logoutDispatch());
}

// GET ASSESTS THAT YOU UPLOADED
export const getSelfUploadAssests =async (data, dispatch, token) =>{
  
  console.log('ti sis datat file ss', data)
  try{
   
    const formData = new FormData();
    let url= API.BASE_URL+API.GET_SELF_UPLOAD_ASSESTS;
  
    formData.append(
      "file",
      data.file
    );

    let headerConfig = {
      "Authorization": "Bearer " +token
    }
    const uploadAssestsRes =await postFormData({url, headerConfig, formData}, dispatch)
    console.log("this is a uploadAssestsRes try", uploadAssestsRes)
    return uploadAssestsRes;
    
  }catch (err){
      console.log("this is a uploadAssestsRes catch",err)
      throw (err)
  }


}

// SHARING THE ASSESTS
export const sharingAssest =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.SHARE_ASSESTS  
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const sharingRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a sharingRes try",sharingRes)
    return sharingRes;
    
  }catch (err){
      console.log("this is a sharingRes catch",err)
      throw (err)
  }

}

// DELETE THE SELF ASSESTS
export const deleteAssest =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.DELETE_ASSESTS;
    
    let dataObje= data;
    
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const deleteRes =await deleteAPI({url, headerConfig, dataObje}, dispatch)
    console.log("this is a deleteRes try",deleteRes)
    return deleteRes;
    
  }catch (err){
      console.log("this is a deleteRes catch",err)
      throw (err)
  }

}

// DELETE THE SHARED ASSESTS
export const deleteShareAssest =async (data, dispatch, token) =>{
  
  try{
    // let url= API.BASE_URL+API.DELETE_ASSESTS;
    let url= API.BASE_URL+API.DELETE_SHARED_ASSESTS;
    
    let dataObje= data;
    
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const deleteRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a deleteRes try",deleteRes)
    return deleteRes;
    
  }catch (err){
      console.log("this is a deleteRes catch",err)
      throw (err)
  }

}

// fetchCostodianAndRooms
export const fetchCostodianAndRooms =async (token, dispatch) =>{
  try{
    let url= API.BASE_URL+API.GET_VR_ROOM_RELATIONSHIP 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const resCustodians =await getData({url, headerConfig}, dispatch)
    console.log("this is a resCustodians try",resCustodians)
    return resCustodians;
    
  }catch (err){
      console.log("this is a resCustodians catch",err)
      throw (err)
  }

}

// MAP VR WITH RELATIONSHIP
export const mapvrroomwithrelationship =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.MAP_VR_WITH_RELATIONSHIP  
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }
}

// MAP VR WITH RELATIONSHIP
export const addAssestInTheRoom =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.SHARE_ASSEST_IN_VR_ROOM   
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }

}

// INVITE CONTRIBURTOR
export const inviteContributor =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.INVITE_CONTRIBUTOR    
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    toast(res.result.message);
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }

}

// ADD VIRTUAL TOUR
export const addVirtualTour =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.SAVE_VIRTUAL_TOUR   
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }
}

// CHECK IMG IS 360 OR NOT
// function is360Image(imageUrl) {
export const is360Image = (file) =>{
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function(event) {
      const img = new Image();
      img.src = event.target.result;

      img.onload = function() {
        EXIF.getData(img, function() {
          const metadata = EXIF.getAllTags(this);
          const is360 = metadata['ProjectionType'] === 'equirectangular';
          console.log("ProjectionTypeProjectionType", metadata)
          resolve(is360);
        });
      };

      img.onerror = function() {
        console.log("ProjectionTypeProjectionType", "err")
        reject(new Error('Failed to load image.'));
      };
    };

    reader.onerror = function() {
      console.log("ProjectionTypeProjectionType 1", "err")
      reject(new Error('Failed to read file.'));
    };

    reader.readAsDataURL(file);
  });

}

export const is360Image__ = (file) =>{
  
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async function(event) {
      const img = new Image();
      img.src = event.target.result;
      await img.decode();
      const is360 = img.width / img.height >= 1.888888888;

      console.log("is360is360", is360, img.width, img.height, img.width / img.height)
      resolve(is360);
    };

    reader.onerror = function() {
      console.log("ProjectionTypeProjectionType 1", "err")
      reject(new Error('Failed to read file.'));
    };

    reader.readAsDataURL(file);
  });

}

// GET SAS URL TO UPLOAD VIDEOS TO AZURE BLOB
export const getSASurlToUploadVideosFiles =async (token, dispatch) =>{
  
  try{
    let url= API.BASE_URL+API.AZUR_SAS  
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const getSASres =await getData({url, headerConfig}, dispatch)
    console.log("this is a getSASres try",getSASres)
    return getSASres;
    
  }catch (err){
      console.log("this is a getSASres catch",err)
      throw (err)
  }

}

// EXPIRE SAS URL TO UPLOAD VIDEOS TO AZURE BLOB
export const expireSASurlToUploadVideosFiles =async (token, dispatch) =>{
  
  try{
    let url= API.BASE_URL+API.EXPIRE_AZUR_SAS  
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const expireSASres =await postData({url, headerConfig}, dispatch)
    console.log("this is a expireSASres try",expireSASres)
    return expireSASres;
    
  }catch (err){
      console.log("this is a expireSASres catch",err)
      throw (err)
  }

}

// FETCH VIRTUAL-TOUR IMAGES
export const fetchDefaultVTimages =async (token, dispatch) =>{
  
  try{
    let url= API.BASE_URL+API.GET_DEFAULT_VIRTUAL_TOUR_IMAGES  
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const fetchVT_DI =await getData({url, headerConfig}, dispatch)
    console.log("this is a fetchVT_DI try",fetchVT_DI)
    return fetchVT_DI;
    
  }catch (err){
      console.log("this is a fetchVT_DI catch",err)
      throw (err)
  }

}


/**
 TAGGING STARTED
*/

// GET TAG
export const getVTtag =async (data, dispatch, token) =>{
  try{
    let url= API.BASE_URL+API.GET_VT_TAGGING+data.id; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const getVTtagRes =await getData({url, headerConfig}, dispatch)
    console.log("this is a getVTtagRes try",getVTtagRes)
    return getVTtagRes;
    
  }catch (err){
      console.log("this is a getVTtagRes catch",err)
      throw (err)
  }

}

// POST TAG
export const postVTtag =async (data, dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.SAVE_VERTUAL_TOUR_TAGGING   
    let dataObje= {
      ...data
    }
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }
}

// put (no use)
export const putDataTo =async (url, body, headerConfig, dispatch) =>{

  try{
    console.log("createTooltipArgscreateTooltipArgs 2", url, body, headerConfig)
    const resPutreq =await putData({url, headerConfig, body}, dispatch)
    return resPutreq;
    
  }catch (err){
      console.log("this is a resPutreq catch",err)
      throw (err)
  }

}

// DELETE TAG
export const deleteVTtag =async (data, dispatch, token) =>{

  try{
    let url= API.BASE_URL+API.DELETE_VERTUAL_TOUR_TAGGING ;
    
    let dataObje= data;
    
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const deleteRes =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a deleteRes try",deleteRes)
    return deleteRes;
    
  }catch (err){
      console.log("this is a deleteRes catch",err)
      throw (err)
  }

}

// GET ASSETS FOR TAGGING
export const getAssetsForTagging =async (pageNum, pagesize, fileType, token) =>{
  try{
    let url= API.BASE_URL+API.GET_USER_ASSETS_FOR_TAGGING+`pageindex=${pageNum}&pagesize=${pagesize}&fileType=${fileType}`; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const getAssetsForTaggingRes =await getData({url, headerConfig})
    console.log("this is a getAssetsForTaggingRes try",getAssetsForTaggingRes)
    return getAssetsForTaggingRes;
    
  }catch (err){
      console.log("this is a getAssetsForTaggingRes catch",err)
      throw (err)
  }

}

/**
 TAGGING ENDED
*/


/** SUBSCRIPTION START */


// // GET SUBSCRIPTION
// export const getSubscriptionDetails =async (dispatch, token) =>{
  
//   try{
//     let url= API.BASE_URL+API.GET_SUBSCRIPTION_DETAILS; 
    
//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }

//     const resSubscribtionDetail =await getData({url, headerConfig}, dispatch)
//     console.log("this is a resSubscribtionDetail try",resSubscribtionDetail)
//     return resSubscribtionDetail;
    
//   }catch (err){
//     console.log("this is a resSubscribtionDetail catch",err)
//     throw (err)
//   }
  
// }

// // GET SETUP INTENT
// export const getSetupIntent =async (data, dispatch, token) =>{
  
//   try{
//     let url= API.BASE_URL+API.GET_SETUP_INTENT+`email=${data.email}`; 

//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
    
//     const getSetupIntent =await getData({url, headerConfig}, dispatch)
//     console.log("this is a getSetupIntent try",getSetupIntent)
//     return getSetupIntent;
    
//   }catch (err){
//     console.log("this is a getSetupIntent catch",err)
//     throw (err)
//   }
  
// }

// // POST PAYMENT-METHOD
// export const PostPaymentMethod =async (data, dispatch, token) =>{

//   try{
//     let url= API.BASE_URL+API.POST_PAYMENT_METHOD;
    
//     let dataObje= data;
    
//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
//     const resPaymentMethod =await postData({url, headerConfig, dataObje}, dispatch);
//     dispatch(fetchUserDetailsDispatch()); 
//     return resPaymentMethod;
    
//   }catch (err){
//     console.log("this is a resPaymentMethod catch",err)
//     throw (err)
//   }
  
// }

// // PAUSH SUBSCRIPTION
// export const pashSubscription =async (data, dispatch, token) =>{
  
//   try{
//     let url= API.BASE_URL+API.PAUSH_SUBSCRIPTION;
    
//     let dataObje= data;
    
//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
//     const resPaushSubscription =await postData({url, headerConfig, dataObje}, dispatch)
//     console.log("this is a resPaushSubscription try", resPaushSubscription)
//     return resPaushSubscription;
    
//   }catch (err){
//     console.log("this is a resPaushSubscription catch",err)
//     throw (err)
//   }
// }

// // RESUME SUBSCRIPTION
// export const resumeSubscription =async (data, dispatch, token) =>{
  
//   try{
//     let url= API.BASE_URL+API.RESUME_SUBSCRIPTION;
    
//     let dataObje= data;
    
//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
//     const resResumeSubscription =await postData({url, headerConfig, dataObje}, dispatch)
//     console.log("this is a resResumeSubscription try", resResumeSubscription)
//     return resResumeSubscription;
    
//   }catch (err){
//     console.log("this is a resResumeSubscription catch",err)
//     throw (err)
//   }  
// }

// // GET CARDS(PAYMENT-METHODS)
// export const getPaymentMethods =async ( dispatch, token) =>{
  
//   try{
//     let url= API.BASE_URL+API.GET_PAYMENT_METHODS; 

//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
    
//     const getPaymentMethod =await getData({url, headerConfig}, dispatch)
//     console.log("this is a getPaymentMethod try",getPaymentMethod)
//     return getPaymentMethod;
    
//   }catch (err){
//     console.log("this is a getPaymentMethod catch",err)
//     throw (err)
//   }
// }

// // SET DEFAULT PAYMENT-METHOD
// export const setDefautPaymentMethod =async (data, dispatch, token) =>{

//   try{
//     let url= API.BASE_URL+API.SET_PAYMENT_METHOD_DEFAULT;
    
//     let dataObje= data;
    
//     let headerConfig = {
//       "Content-Type": "application/json",
//       "Authorization": "Bearer " +token
//     }
//     const resPaymentMethod =await postData({url, headerConfig, dataObje}, dispatch);
//     // dispatch(fetchUserDetailsDispatch()); 
//     return resPaymentMethod;
    
//   }catch (err){
//     console.log("this is a resPaymentMethod catch",err)
//     throw (err)
//   }
  
// }


/** SUBSCRIPTION END */

// Get subscription details
export const getSubscriptionDetails =async (dispatch, token) =>{
  
  try{
    let url= API.BASE_URL+API.GET_SUBSCRIPTION_DETAILS;  
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }
    const res =await getData({url, headerConfig}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
      console.log("this is a res catch",err)
      throw (err)
  }

  
}

export const restartSubscription =async (data, token, dispatch) =>{
  
  try{

    // let params= `subscriptionId=${data.subscriptionId}`;

    let dataObje= data;

    let url= API.BASE_URL+API.RE_START_SUBSCRIPTION; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje}, dispatch)
    // update subscription in reducer
    dispatch(updateSubscriptionDetailsDispatch(res.result.payload));
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }

  
}

export const cancelSubscription =async (data, token, dispatch) =>{
  
  try{

    // let params= `subscriptionId=${data.subscriptionId}`;

    let dataObje= data;

    let url= API.BASE_URL+API.CANCEL_SUBSCRIPTION; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje}, dispatch)
    // update subscription in reducer
    dispatch(updateSubscriptionDetailsDispatch(res.result.payload));
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }


  
}

export const creatSetupIntent =async (data, token, dispatch) =>{
  
  try{
    
    // let params= `customerId=${data.customerId}`;

    let dataObje= {customerId: data.customerId};

    let url= API.BASE_URL+API.CREATE_SETUP_INTENT; 
    // let url= API.BASE_URL+API.CREATE_SETUP_INTENT_NEW; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje}, dispatch)
    console.log("this is a res try",res)
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }
  
}

export const updateSubscriptionWithPayment =async (data, token) =>{
  
  try{
    // let params= `subscriptionId=${data.subscriptionId}&paymentMethodId=${data.paymentmethodid}&customerId=${data.customerId}`;

    let dataObje= {...data };

    let url= API.BASE_URL+API.UPDATE_PAYMENT_METHOD; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje})
     // update subscription in reducer
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }
  
}

export const startNewSubscription =async (data, token) =>{

  try{
    
    // let params= `customerid=${data.customerid}&paymentMethodId=${data.paymentmethodid}`;

    let dataObje= {...data, paymentMethodId: data.paymentmethodid };

    let url= API.BASE_URL+API.CREATE_NEW_SUBSCRIPTION; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje})
     // update subscription in reducer
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }
  
}

export const getPortalLink =async (data, token, dispatch) =>{

  try{
    
    // let params= `customerid=${data.customerid}&paymentMethodId=${data.paymentmethodid}`;

    let dataObje= {...data, paymentMethodId: data.paymentmethodid };

    let url= API.BASE_URL+API.GET_PORTAL_LINK; 

    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    const res =await postData({url, headerConfig, dataObje}, dispatch)
     // update subscription in reducer
    return res;
    
  }catch (err){
    console.log("this is a res catch",err)
    throw (err)
  }
  
}

/**
 * 
    SUBSCRIPTION END
 */



export function makeSomeDelay(){
  return new Promise((resolve, reject)=>{
    setTimeout(() => {
      resolve(true);
    }, 500);
  })
}