import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from './../../UTILITYS/index.tsx';
 
import * as API from './../../APIs'

const initialState = {
    selfAUDIOAssestsOnHome: [], 
    selfVEDIOAssestsOnHome: [],
    selfIMGAssestsOnHome: [],
    isImgResGet: false,
    isAudioResGet: false,
    isVideoResGet: false,
    isFileUploadingState: false,

    hasMoreImageAssests: true,
    hasMoreAudioAssests: true,
    hasMoreVediosAssests: true,
}

export const assestsData = createSlice({
  name: 'assestsData',
  initialState: { ...initialState }, 
  reducers: {
    setSelfAUDIOAssestsOnHome: (state, action) => {
      console.log('this is our assestsData submit data >>', action)
      return {
        ...state,
        selfAUDIOAssestsOnHome: [...state.selfAUDIOAssestsOnHome ,...action.payload.pagedList.map(aud=>{
          return {
            ...aud,
            isAudioSelected: false
          }
        })]
      }
    },
    setToggleSelectForAudio: (state, action) => {
      return {
        ...state,
        selfAUDIOAssestsOnHome: [...state.selfAUDIOAssestsOnHome].map(img=>{
          if(action.payload === img.id){
            console.log('this is our assestsData submit data >>', action.payload, img.id, img)
            return {
              ...img,
              isAudioSelected: !img.isAudioSelected
            }
          }
          return {
            ...img
          }
        })
      }
    },
    setSelfVEDIOAssestsOnHome: (state, action) => {
      console.log('his is our assestsData submit datta >>', action) 
      return {
        ...state,
        selfVEDIOAssestsOnHome: [...state.selfVEDIOAssestsOnHome ,...action.payload.pagedList.map(vid=>{
          return {
            ...vid,
            isVideoSelected: false
          }
        })]
      }
    },
    setToggleSelectForVideo: (state, action) => {
      return {
        ...state,
        selfVEDIOAssestsOnHome: [...state.selfVEDIOAssestsOnHome].map(img=>{
          if(action.payload === img.id){
            console.log('this is our assestsData submit data >>', action.payload, img.id, img)
            return {
              ...img,
              isVideoSelected: !img.isVideoSelected
            }
          }
          return {
            ...img
          }
        })
      }
    },
    setSelfIMGAssestsOnHome: (state, action) => {
      console.log('this is our assestsData submit data >>', action)
      return {
        ...state,
        selfIMGAssestsOnHome: [...state.selfIMGAssestsOnHome, ...action.payload.pagedList.map(img=>{
          return {
            ...img,
            isImgSelected: false
          }
        })]
      }
    },
    setToggleSelectForIMG: (state, action) => {
      return {
        ...state,
        selfIMGAssestsOnHome: [...state.selfIMGAssestsOnHome].map(img=>{
          if(action.payload === img.id){
              console.log('this is our assestsData submit data >>', action.payload, img.id, img)
              return {
                ...img,
                isImgSelected: !img.isImgSelected
              }
            }
          return {
            ...img
          }
        })
      }
    },
    setResponseForSelfAssestList: (state, action) => {
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    },
    isFileUploading: (state, action) => {
      console.log('this is our assestsData submit data >>', action)
      return {
        ...state,
        isFileUploadingState: action.payload
      }
    },
    setHasMoreImageAssests: (state, action)=>{
      return {
        ...state,
        hasMoreImageAssests: action.payload
      }
    },
    setHasMoreAudioAssests: (state, action)=>{
      return {
        ...state,
        hasMoreAudioAssests: action.payload
      }
    },
    setHasMoreVideosAssests: (state, action)=>{
      return {
        ...state,
        hasMoreVediosAssests: action.payload
      }
    },
    resetSelfAssestsOnHome: ()=>{
      return {...initialState}
    },
    resetToggleSelectForAudio: (state, action) => {
      return {
        ...state,
        selfAUDIOAssestsOnHome: [...state.selfAUDIOAssestsOnHome].map(img=>{
          return {
            ...img,
            isAudioSelected: false
          }
        })
      }
    },
    resetToggleSelectForVideo: (state, action) => {
      return {
        ...state,
        selfVEDIOAssestsOnHome: [...state.selfVEDIOAssestsOnHome].map(img=>{
          return {
            ...img,
            isVideoSelected: false
          }
        })
      }
    },
    resetToggleSelectForIMG: (state, action) => {
      return {
        ...state,
        selfIMGAssestsOnHome: [...state.selfIMGAssestsOnHome].map(img=>{
          return {
            ...img,
            isImgSelected: false
          }
        })
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setSelfAUDIOAssestsOnHome, setSelfVEDIOAssestsOnHome, setSelfIMGAssestsOnHome, resetSelfAssestsOnHome, isFileUploading, setHasMoreImageAssests, setHasMoreAudioAssests, setHasMoreVideosAssests, setToggleSelectForIMG, resetToggleSelectForIMG,
 setToggleSelectForAudio, resetToggleSelectForAudio, setToggleSelectForVideo, resetToggleSelectForVideo, setResponseForSelfAssestList } = assestsData.actions

const selfAUDIOAssestFetch =async (token, dispatch, pageIndex, pageSize) =>{
    
  console.log("this is a selfAssestFetch try", token)

    try{
      let url= `${API.BASE_URL+API.GET_SELF_UPLOAD_ASSESTS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=1`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchSelfAUDIOAssest =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchSelfAUDIOAssest try", fetchSelfAUDIOAssest)
    
    return fetchSelfAUDIOAssest;
    
  }catch (err){

    console.log("this is a fetchSelfAUDIOAssest catch")
    throw (err)
  }

}

const selVEDIOfAssestFetch =async (token, dispatch, pageIndex, pageSize) =>{
    
  console.log("this is a selfAssestFetch try", token)

    try{
      let url= `${API.BASE_URL+API.GET_SELF_UPLOAD_ASSESTS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=2`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchSelfVEDIOAssest =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchSelfVEDIOAssest try", fetchSelfVEDIOAssest)
    
    return fetchSelfVEDIOAssest;
    
  }catch (err){

    console.log("this is a fetchSelfVEDIOAssest catch")
    throw (err)
  }

}

const selfIMGAssestFetch =async (token, dispatch, pageIndex, pageSize, LOAD_MORE) =>{
    
  console.log("this is a selfAssestFetch try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SELF_UPLOAD_ASSESTS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=3`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchSelfIMGAssest =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchSelfIMGAssest try", fetchSelfIMGAssest)
    
    return fetchSelfIMGAssest;
    
  }catch (err){

    console.log("this is a fetchSelfIMGAssest catch")
    throw (err)
  }

}

export const fetchSelFAUDIOassestDispatch = (pageIndex, pageSize, LOAD_MORE) => {

  return async(dispatch, getState) => {
    console.log('this is our state data', getState().localSessionDetails.payload) 
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resSelfAUDIOAssestFetch =await selfAUDIOAssestFetch(token, dispatch, pageIndex, pageSize) 
        console.log('this is loging fetch', resSelfAUDIOAssestFetch)

        // if(LOAD_MORE!= undefined && LOAD_MORE && resSelfAUDIOAssestFetch.result.payload.totalRecords === getState().assestsData.selfAUDIOAssestsOnHome.length+resSelfAUDIOAssestFetch.result.payload.pagedList.length
        //   ){
        //     dispatch(setHasMoreAudioAssests(false))
        // }

        if(resSelfAUDIOAssestFetch.result.payload.pagedList.length === 0){
          dispatch(setHasMoreAudioAssests(false))
        }
        
        dispatch(setSelfAUDIOAssestsOnHome(resSelfAUDIOAssestFetch.result.payload))

        dispatch(setResponseForSelfAssestList({ key: "isAudioResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export const fetchSelFVEDIOassestDispatch = (pageIndex, pageSize, LOAD_MORE) => {

  return async(dispatch, getState) => {
    console.log('this is our state data', getState().localSessionDetails.payload) 
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resSelfVEDIOAssestFetch =await selVEDIOfAssestFetch(token, dispatch, pageIndex, pageSize) 
        
        // if(LOAD_MORE!= undefined && LOAD_MORE && resSelfVEDIOAssestFetch.result.payload.totalRecords === getState().assestsData.selfVEDIOAssestsOnHome.length+resSelfVEDIOAssestFetch.result.payload.pagedList.length
        // ){
        //   dispatch(setHasMoreVideosAssests(false))
        // }

        if(resSelfVEDIOAssestFetch.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVideosAssests(false))
        }
        console.log('this is loging fetch', resSelfVEDIOAssestFetch)
        
        dispatch(setSelfVEDIOAssestsOnHome(resSelfVEDIOAssestFetch.result.payload))

        dispatch(setResponseForSelfAssestList({ key: "isVideoResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export const fetchSelFIMGassestDispatch = (paegIndex, pageSize, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        
        let token = getState().localSessionDetails.payload.accessToken;

        const resSelfIMGAssestFetch =await selfIMGAssestFetch(token, dispatch, paegIndex, pageSize, LOAD_MORE) 
        if(resSelfIMGAssestFetch.result.payload.pagedList.length === 0){
          dispatch(setHasMoreImageAssests(false))
        }
        
        dispatch(setSelfIMGAssestsOnHome(resSelfIMGAssestFetch.result.payload))
        dispatch(setResponseForSelfAssestList({ key: "isImgResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export const isFileUploadingDispatch = (isUploading) => {
  return async(dispatch, getState) => {
    dispatch(isFileUploading(isUploading))
  }
}

export const setToggleSelectForAudioDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForAudio(id))
  }
}
export const setToggleSelectForVideoDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForVideo(id))
  }
}
export const setToggleSelectForIMGDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForIMG(id))
  }
}
export const resetToggleSelectForAudioDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForAudio()) 
  }
}
export const resetToggleSelectForVidioDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForVideo())
  }
}
export const resetToggleSelectForIMGDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForIMG())
  }
}

export default assestsData.reducer 