import React, { useEffect, useState } from "react";
import Home from ".";
import { NavLink } from "react-router-dom";
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
// import CustomeCard from "../../COMPONENTS/HOME/CustomeCard"; 
import CustomeCard from "./Info"; 
import StorageStatus from "../../COMPONENTS/HOME/StorageStatus.tsx";
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import Close from './../../ASSETS/Images/icons/cancel.png';
import DragAndDropFiles from "../../COMPONENTS/DRAGE_FILE_FOLDER";
import { fetchSelFAUDIOassestDispatch, fetchSelFVEDIOassestDispatch, fetchSelFIMGassestDispatch, isFileUploadingDispatch, resetSelfAssestsOnHome } from "./slice.reducer";
import { fetchUserDetailsDispatch } from "../Profile/slice.reducer.tsx";
import NoAssests from "../../COMPONENTS/NoAssests/index.tsx";
import Dashboard from "../Dashboard";
import { fetchShareWithMeUserInfoDispatch, fetchShareWithOthersUserInfoDispatch, resetShareWithMeAndOthers } from "./slices/ShareWithMe";
import { useNavigate } from 'react-router-dom'
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { hamburgurDispatch } from "../../STORE/CommonSlices/hameBurgerOnOff";
import { redirectAfterUpload } from "../../UTILITYS/index.tsx";
import { resetCurrentUploadedAssestsCountDispatch } from "../../COMPONENTS/DRAGE_FILE_FOLDER/dragFileFolder.slice";
import { ExpiredModal } from "../../COMPONENTS/Modal/Expired.js";

export default (args, props)=>{

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isUploaded, setIsUploaded] = useState(false);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const assestsData = useSelector(state => state.assestsData);
    const shareWithMeAndOthers = useSelector(state => state.shareWithMeAndOthers);
    const currentUploadedAssestsCount = useSelector(state => state.currentUploadedAssestsCount);

    const [modal, setModal] = useState(false);
    const toggle = () => { 
        if(modal && assestsData.isFileUploadingState){
            // dispatch(resetSelfAssestsOnHome())
            // dispatch(fetchSelFAUDIOassestDispatch(1, 1)) 
            // dispatch(fetchSelFVEDIOassestDispatch(1, 1))
            // dispatch(fetchSelFIMGassestDispatch(1, 1)) 
            navigateToAssestManagmentBasedOnCurrentUploadedCount()
            dispatch(isFileUploadingDispatch(false));
            dispatch(fetchUserDetailsDispatch());
            setIsUploaded(true)
            
            // FETCH SHARE WITH ME AND OTHER USER INFO
            // dispatch(resetShareWithMeAndOthers());
            // dispatch(fetchShareWithMeUserInfoDispatch(1, 4));
            // dispatch(fetchShareWithOthersUserInfoDispatch(1, 4));
        }
        setModal(!modal);
    }

    const navigateToAssestManagmentBasedOnCurrentUploadedCount = ()=>{
        let routToNavigate = "assets-images";
        routToNavigate= redirectAfterUpload(currentUploadedAssestsCount.currentUploadedAssestsCount.image, currentUploadedAssestsCount.currentUploadedAssestsCount.audio ,currentUploadedAssestsCount.currentUploadedAssestsCount.video); 
        dispatch(resetCurrentUploadedAssestsCountDispatch())
        
        if(routToNavigate !== "assets-videos")
            navigate(`/${routToNavigate}`);
    }
    
    // useEffect(()=>{  
    //     if(isUploaded){
    //         console.log('redirectAfterUploadredirectAfterUpload 2', isUploaded, userProfileDetails.imageFileCount, userProfileDetails.audioFileCount, userProfileDetails.videoFileCount)
    //         let routToNavigate = "assets-images";
    //         routToNavigate= redirectAfterUpload(userProfileDetails.imageFileCount, userProfileDetails.audioFileCount ,userProfileDetails.videoFileCount); 
    //         // setIsUploaded(false)
    //         dispatch(resetSelfAssestsOnHome())
    //         navigate(`/${routToNavigate}`);
    //     }
    // }, [userProfileDetails.imageFileCount, userProfileDetails.audioFileCount, userProfileDetails.videoFileCount]);

    // console.log('th is props in storage in', userProfileDetails)

    const onNavigateHandler = (navigateTo, assest) => { 
        console.log("onNavigateHandler >", navigateTo, assest)
        switch(navigateTo){
            case "selfLibraryOnHomeAudio":
                dispatch(setModuleActiveDispatch("audio"))
                dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                navigate('/assets-audios')
                break;
            case "selfLibraryOnHomeVideo":
                dispatch(setModuleActiveDispatch("video"))
                dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                navigate('/assets-videos')
                break;
            case "selfLibraryOnHomeImg":
                dispatch(setModuleActiveDispatch("image"))
                dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                navigate('/assets-images')
                break;
            case "sharedByMe":
                dispatch(setModuleActiveDispatch("shared_with_others"))
                navigate('/SharedByMe', {state: assest})
                break;
            case "sharedByUser":
                dispatch(setModuleActiveDispatch("shared_with_me"))
                navigate('/SharedByUser', {state: assest})
                break;
        }
    }

    return (
        <React.Fragment>
            {/* <Home> */}
                <Dashboard>
                <ExpiredModal />
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>Dashboard</h5>
                            </Col>
                        </Row>
                    </div>

                    {/* Dashboard Counter  */}
                    <StorageStatus {...userProfileDetails}/> 

                    {/* Your Library  */}
                    <div className="Library dashboard_card mb-3 mt-4">
                        <div className="mb-1">
                            <span className="lead">Your Assets</span>
                        </div>

                        {(assestsData.selfAUDIOAssestsOnHome.length === 0 && assestsData.selfVEDIOAssestsOnHome.length === 0 && assestsData.selfIMGAssestsOnHome.length === 0) ? <NoAssests />: <CustomeCard {...assestsData} selfLibraryOnHome= {true} onNavigate={onNavigateHandler} />} 
                    </div>

                    {/* Share with others  */}
                    <div className="shared_item dashboard_card mb-3 mt-4">
                        <div className="mb-1">
                            <span className="lead">Shared with others</span>
                            <span className="float-end">
                                <NavLink to="/share-with-others">See All</NavLink>
                            </span>
                        </div> 

                        {(shareWithMeAndOthers.shareWithOtherUserInfo.length === 0 ) ? <NoAssests />: <CustomeCard 
                            {...shareWithMeAndOthers} 
                            shareByMe= {true}
                            onNavigate={onNavigateHandler}
                        />} 
                        
                    </div>

                    {/* Share with me  */}
                    <div className="shared_item dashboard_card mb-3 mt-4">
                        <div className="mb-1">
                            <span className="lead">Shared with me</span>
                            <span className="float-end">
                                <NavLink to="/share-with-me">See All</NavLink>
                            </span>
                        </div>

                        {(shareWithMeAndOthers.sharedWithMeUserInfo.length === 0 ) ? <NoAssests />: <CustomeCard 
                            {...shareWithMeAndOthers} 
                            sharedByUser= {true}
                            onNavigate={onNavigateHandler} 
                            />} 

                    </div> 

                    {/* Add Asset button  */}
                    <div className="addAssets">
                        <button className="btn btn-link" onClick={toggle}><img src={AddAssets} alt="profile" className="img-fluid" /></button>
                    </div>

                    <Modal isOpen={modal} centered className="drop-modal">
                        <Button className="modalClose" onClick={toggle}>
                            <img src={Close} alt="Images" className="img-fluid" />
                        </Button>
                        <ModalBody>
                            <div className="Drop_Area">
                                <DragAndDropFiles 
                                    preventToUpload = {()=>setModal(!modal)}
                                    closeVideoInfoModalAndDZ = {toggle}
                                />
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            {/* </Home> */}
            </Dashboard>
        </React.Fragment> 
    )
}