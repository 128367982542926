import React, {useState, useEffect, useContext} from "react"; 
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap'; 
import CancelIcon from './../../ASSETS/Images/icons/close.svg' 
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchUserDetailsDispatch, setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer.tsx";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import UpgradModal from "../Subscription/UpgradModal.js";
import { ExpiredModal } from "../Modal/Expired.js";

export default (props) =>{

    const dispatch = useDispatch(); 
    const [deleteModal, setDeleteModal] = useState(false); 
    const tokenContext = useContext(CreateTokenContextProvider);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [openUpgrade, setOpenUpgrade] = useState(false);

    const deleteAssestToggle = () => {
        setDeleteModal(!deleteModal);
        props.setDeleteModal(!deleteModal)
    }
    
    const getArrayOfOBJ =()=>{   
        return props.getArrayOfOBJ()
    }

    useEffect(()=>{

        if(props.isOpen){
            if(userProfileDetails.isExpired){
                toast.dismiss();
                toast("Your subscription has been Expired...");
                dispatch(setDoNotShowExpiredModal(false));
                props.setDeleteModal()
                return;
            }
        }

        setDeleteModal(props.isOpen) 
    }, [props.isOpen])

    const applyDeleteAssests =async ()=>{
        toast.dismiss();
        let deleteAssestDATA = getArrayOfOBJ();

        if( userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        try{
            let res= await props.onDeletingAssest( deleteAssestDATA, dispatch, tokenContext.accessToken);
            console.log("resresresresres", res)
            deleteAssestToggle();
            dispatch(fetchUserDetailsDispatch());
            if(res && res.result && res.result.payload && res.result.payload.id === -1){
                toast.error(res.result.payload.name, { })
                // toast(res.result.payload.name);
            }else{
                toast.error(res.result.message, { })
                // toast(res.result.message);
            }
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
    } 



    return  <React.Fragment>
                {/* <UpgradModal
                    isOpen= {openUpgrade} 
                    setOpenUpgrade ={setOpenUpgrade}
                /> */}
                <ExpiredModal />
                <Modal isOpen={deleteModal} centered className="modal-medium" toggle= {deleteAssestToggle} {...props}>
                    <ModalBody>
                        <div className="text-center mt-3">
                            <img src={CancelIcon} alt="Images" className="img-fluid mb-4" />
                            <p>Are you sure?</p>
                        </div>  
                    </ModalBody>
                    <ModalFooter className="justify-content-center pt-0">
                        <Button className="me-2 me-md-3" color="outline-secondary" onClick={deleteAssestToggle}>Cancel</Button>
                        <Button className="ms-2 ms-md-3" color="primary" onClick={applyDeleteAssests}>Yes</Button>
                    </ModalFooter>

                    
                </Modal>
        </React.Fragment>

}