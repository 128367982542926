export default (props)=>{
    return (
        <div className="row">
            <label>
                <input
                    type="radio"
                    value={props.value}
                    checked={props.checked}
                    onChange={props.onChange}
                    className={props.radioClassess}
                />
                {props.label}
            </label>
            {props.children}
        </div>
    )
}