import React, { useEffect, useState } from "react";
import HTMLtextEditor from './../../../COMPONENTS/HTMLtextEditor';
import { useDispatch,useSelector } from 'react-redux'
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { hamburgurDispatch } from "../../../STORE/CommonSlices/hameBurgerOnOff";
import validator from 'validator';
import { toast } from 'react-toastify';
import { inviteContributor } from "../../../UTILITYS/endPoints";
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import InputAndEmail from "../../../COMPONENTS/AddSameUnlimitedComponent/InputAndEmail";
import { useContext } from "react";
import CreateTokenContextProvider from "../../../STORE/App-wide-state/Token/createTokenContextProvider";

let defaulTemleDes="<p>Hi &lt;&lt;first name&gt;&gt;,</p><p><br></p><p>&lt;&lt;Custodian name&gt;&gt; is creating an awesome VR experience for &lt;&lt;viewer's name&gt;&gt; and you are invited to be part of it by sharing memorable photos and videos.</p><p><br></p><p>Sincerely,</p><p>The Recuerdo Team</p>" 

export default  (props)=>{

    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const [editorValue, setEditorValue] = useState(`${defaulTemleDes}`);
    const [myRef, setmyRef] = useState(null);
    const [email, setEmail] = useState('');
    const tokenContext = useContext(CreateTokenContextProvider);
    const dispatch = useDispatch();
    const [emails, setEmails] = useState([]);
    const [emailsErr, setEmailsErr] = useState(false);
    const [resetAllInputAndEmails, setResetAllInputAndEmails] = useState(false);

    console.log("resetAllInputAndEmailsresetAllInputAndEmails", props.vrUser,
    props.room)

    useEffect(()=>{
        setEditorValue(`${defaulTemleDes}`);
    }, [userProfileDetails]); 

    const sendEmail = async()=>{

        toast.dismiss();

        let isAllMailValidate= false;
        let isUsingSameMail= false;

        console.log("Please EnterPlease Enter", emails)
        if(emails.length === 0){
            toast.error("Please Add at least one Contributor", { });
            return; 
        }

        emails.forEach((mail) =>{
            let myMail = {...mail.email}
            console.log("emailsemails 1", )
            
            // if(!validator.isEmail(myMail.value, {blacklisted_chars : "+-"})){ 
            if(myMail.value.toLowerCase() === userProfileDetails.email.toLowerCase()){ 

                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.email.id === myMail.id
                        ? { 
                            ...inputBox,
                            email: {
                                ...inputBox.email,
                                Err: true, 
                            }
                        }
                        : inputBox
                    })
                });
                
                isAllMailValidate= true;
                isUsingSameMail= true;
            }
        });
 
        emails.forEach((mail) =>{
            let myMail = {...mail.email}
            console.log("emailsemails 1", )
            
            if(!validator.isEmail(myMail.value, {blacklisted_chars : "+-"})){ 
                console.log("emailsemails 2", )

                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.email.id === myMail.id
                        ? { 
                            ...inputBox,
                            email: {
                                ...inputBox.email,
                                Err: true, 
                            }
                        }
                        : inputBox
                    })
                });
                
                isAllMailValidate= true 
            }
        });

        emails.forEach((mail) =>{
            let myFirstName = {...mail.fistName}

            console.log("emailsemails 3", )
            
            if(validator.isEmpty(myFirstName.value.trim())){ 
                console.log("emailsemails 4", )
                
                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.fistName.id === myFirstName.id
                        ? { 
                            ...inputBox,
                            fistName: {
                                ...inputBox.fistName,
                                Err: true,
                            }
                        }
                        : inputBox
                    })
                });
                isAllMailValidate= true 
            }else if(!validator.isLength(myFirstName.value.trim(), {min: 1, max: 25})){
                console.log("emailsemails 5", )
                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.fistName.id === myFirstName.id
                        ? { 
                            ...inputBox,
                            fistName: {
                                ...inputBox.fistName,
                                Err: true,
                            }
                        }
                        : inputBox
                    })
                });
                isAllMailValidate= true;
            }
        });

        if(isUsingSameMail){
            toast.error("You can't invite your own as a contributor", { });
        }
       
        if(isAllMailValidate){
            setEmailsErr(true)
            return;
        }
        
        if(userProfileDetails.email === email){
            toast.error("You can't invite yourself as a contributor", { });
            return
        }

        let sendMailData = {
            InviteContributor:  emails.map(email=> ({ 
                ContributorName: email.fistName.value,
                ContributorEmail: email.email.value,
                CustodianName: userProfileDetails.firstName+" "+userProfileDetails.lastName,
                CustodianEmail: userProfileDetails.email
            })),
            RelationShipName: `${props.vrUser.name} (${props.room.name})`,
            IsInviteViaVRExperience: 1,
            mappingID: props.room.vrRoomMappingID
        }

        try{
          console.log('this is try sendMailData', "try")
          await inviteContributor(sendMailData, dispatch, tokenContext.accessToken);
          setResetAllInputAndEmails(true);
          props.toggle();
        }catch (err){
          if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          console.log('this is catch sendMailData', err)
        }

    }

    const onGetEmailsHandler = (mails)=>{
        setEmails(mails);
    }

    return (
        <React.Fragment >
            <h6 className="mb-3 mb-md-4">Invite Contributors to share photos, videos, or audio assets to enhance the VR experience of your loved ones via email. Please feel free to edit the template below.
            </h6>
            <div className="mb-3 mb-md-4">
                <p className="mb-1">Contributor email</p>
                <InputAndEmail 
                    getEmails= {onGetEmailsHandler}
                    emails= {emails}
                    emailsErr= {emailsErr}
                    setEmailsErr= {setEmailsErr}
                    resetAllInputAndEmails= {resetAllInputAndEmails}
                    setResetAllInputAndEmails= {setResetAllInputAndEmails}
                />
            </div>
            <div  className="mb-3 mb-md-4">
                <label>Email body</label>
                <HTMLtextEditor 
                    className="form-control"
                    editorValue = {editorValue}
                    getMyQuilRef= {(ref)=>{setmyRef(ref)}}
                    readOnly= {true}
                />
            </div>  
            <button className="btn btn-primary" onClick={sendEmail}>Invite Contributor</button>
        </React.Fragment>
               
    )
}