import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector, useDispatch } from 'react-redux'
import NoContents from "./NoContents";
import Breadcrum from "../Breadcrum";
import VRaddAssestModal from "../VRaddAssestModal";
import BG from './../../ASSETS/Images/BG.jpg';
import MobileBG from './../../ASSETS/Images/Mobile-BG.jpg';
import { resetSelfAssestsOnHome } from "../../SCREENS/Home/slice.reducer";
import Banner from "../Banner";

export default (props)=>{

    return (
        <React.Fragment> 
            <div className="ps-0 pe-0 ps-md-3 pe-md-3">

                {/* BANNER */}
                <Banner 
                    BG= {BG}
                    MobileBG= {MobileBG}
                    render= {()=> <h3>{props.vrUser.name} ({props.room.name})</h3>}
                />

                {/* NO CONTENT */}
                <NoContents 
                    primaryText ={props.primaryText} 
                    description ={props.description}
                    icon ={props.noImgIcon}
                />
                <VRaddAssestModal {...props}  noMeSe= {true}/>
            </div>
        </React.Fragment>
    )
}
