import React, {useState, useEffect } from "react";
import HeaderSideBar from "..";
import { Row } from 'reactstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyAssest from "../../Home/EmptyAssest";
import AudioCardList from "../../../COMPONENTS/AssestsAndList/Audios/AudioCardList";
import CustomeBreadcrumb from "../../../COMPONENTS/CustomeBreadcrumb";
import { resetSharedAssestWithMeOrBy, fetchAudioSharedBymeDispatch,fetchAudioSharedByThisUserDispatch } from "../slice.reducer";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../../UTILITYS/Constant";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import Breadcrum from "../../../COMPONENTS/Breadcrum";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import {sharedAssestWithMeOrByInterface, sharedByThisUserInterface, sharedBymeInterFace} from "./../../../UTILITYS/Modals/slicese/sharedAssestWithMeOrBy.modal.ts";

export default ()=>{
    const dispatch = useAppDispatch();
    let {state}  = useLocation();
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isAudioEmply, setIsAudioEmply] = useState<boolean>(true);
    const sharedAssestWithMeOrBy: sharedAssestWithMeOrByInterface = useAppSelector(state => state.sharedAssestWithMeOrBy) as sharedAssestWithMeOrByInterface;
    const [userData, setUserData] = useState(state)

    let assestsInfo: sharedByThisUserInterface | sharedBymeInterFace = null; 

    if(userData.isShareByMe){
        assestsInfo= sharedAssestWithMeOrBy.sharedByme;
    }else{
        assestsInfo= sharedAssestWithMeOrBy.sharedByThisUser;
    }

    useEffect(()=>{
        dispatch(resetSharedAssestWithMeOrBy({}))
        if(!userData.isShareByMe){
            dispatch(setModuleActiveDispatch("shared_with_me"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }else{
            dispatch(setModuleActiveDispatch("shared_with_others"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }
    }, [])

    useEffect(()=>{
        if(userData.isShareByMe){
            dispatch(fetchAudioSharedBymeDispatch(loadEventIndex, 25, userData.userID)) 
        }else{
            dispatch(fetchAudioSharedByThisUserDispatch(loadEventIndex, 25, userData.userID)) 
        }
    },[loadEventIndex])

    useEffect(()=>{
        if( assestsInfo?.audios?.length === 0){
            setIsAudioEmply(true)
        }else{
            setIsAudioEmply(false)
        }
    }, [assestsInfo?.audios])

    const fetchMoreData: () => void = () => {
        seLoadEventIndex(loadEventIndex+1)
    };

    const getCheckBoxClasses: () => string = ()=>{
        return "my-1";
    }
    const toggleSelectBox: () => void = ()=>{
        return;
    }

    const getAudiosAssestsList: () => JSX.Element = ()=>{
    
       return  <InfiniteScroll
                    className="row"
                    dataLength={assestsInfo?.audios?.length as number}
                    next={fetchMoreData}
                    hasMore={assestsInfo?.hasMoreAudioAssests as boolean}
                    loader={assestsInfo?.audios && assestsInfo?.audios?.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
               >
               {getAudiosAssest()}
           </InfiniteScroll> as JSX.Element
    
    }

    const getAudiosAssest: () => JSX.Element[] = ()=>{
        return assestsInfo?.audios?.map((audio, index)=>{
            return <AudioCardList 
                audio={audio} 
                key={index} 
                isFF={true}
                getCheckBoxClasses ={()=>getCheckBoxClasses()}
                toggleSelectBox ={()=>toggleSelectBox()}
            /> 
        }) as JSX.Element[]
        
    }

    if(isAudioEmply){
        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Audios"]: ["Asset Library", "Shared with me", "Audios"] }
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar> as JSX.Element
    }
 
    return (  
        <React.Fragment> 
            <HeaderSideBar>
                <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                            <Breadcrum 
                                breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Audios"]: ["Asset Library", "Shared with me", "Audios"]}
                            />
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4">
                    {/* Images  */}
                    <div className="assets_item ps-2 pe-2">
                        <Row>
                            {getAudiosAssestsList()}
                        </Row>
                    </div>
                </div>

            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}