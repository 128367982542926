export const SIDE_MENU_ITEMS ={
    fistMenuItems: [
        {
            subModule: "profile"
        },
        {
            subModule: "changePassword"
        },
        {
            subModule: "subscription"
        }
    ],

    secondMenuItems: [
        {
            subModule: "image"
        },
        {
            subModule: "audio"
        },
        {
            subModule: "video"
        },
        {
            subModule: "shared_with_others"
        },
        {
            subModule: "shared_with_me"
        }
    ],

    theirdMenuItems: [
        // {
        //     subModule: "shared_with_others"
        // },
        // {
        //     subModule: "shared_with_me"
        // }
    ],
   
    forthMenuItems: [
        {
            subModule: "virtualTour"
        },
        {
            subModule: "vr_Experience"
        },
        {
            subModule: "pinManagement"
        }
    ]
} 
 
export const PARENT_MODULE_ITEMS ={
    fistMenuItems: [
        {
            module: "parentDashboard"
        }
    ],
    secondMenuItems: [
        {
            module: "parentProfile"
        }
    ],
    thiredMenuItems: [
        {
            module: "parentAssestManagment"
        }
    ],
    forthMenuItems: [
        {
            module: "parentFamilyFreind"
        }
    ],
    fifthMenuItems: [
        {
            module: "parentVRexperience"
        }
    ],
    sixthMenuItems: [
        {
            module: "parentICexperience"
        }
    ],
    sevenMenuItems: [
        {
            module: "parentSubscription"
        }
    ]
} 

