import React, { useState, useContext } from "react";
import { Button, Modal, ModalBody } from 'reactstrap'; 
import { cancelSubscription, getPortalLink, restartSubscription } from "./../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom'
import Close from './../../ASSETS/Images/icons/cancel.png'
import CreateTokenContextProvider from "./../../STORE/App-wide-state/Token/createTokenContextProvider";
import { useDispatch } from "react-redux";
import Plan from "./plan";
import { getSubscriptionStatusBC } from "./../../UTILITYS/Helper";
import ConfirmGoToStripPortalIcon from './../../ASSETS/Images/icons/confirmGoToStripPortalIcon.png'

export default (props)=> {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const subscription= props.subscription;
    const [modal, setModal] = useState(false);
    const [stripePortalModal, setStripePortalModal] = useState(false);
    const tokenContext = useContext(CreateTokenContextProvider);

    const getStatusValue =(subscription)=>{
    
        // TRIAL
            // 1) with card `S_002` `S_004`
            if( subscription.stripeSubscriptionDetails.status === "trialing" && !subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "Trial"  // --> Free
            // with card and cancel(not campletally) `S_003`
            if( subscription.stripeSubscriptionDetails.status === "trialing" && subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "Trial (Canceled)"   // --> Free
        // ACTIVE
            // 1) active `S_006` `S_009`
            if( subscription.stripeSubscriptionDetails.status === "active" && !subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "Active" // --> Active
            // Active and Cancel `S_007` 
            if( subscription.stripeSubscriptionDetails.status === "active" && subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "Active (Canceled)"  // --> Active
        // COMMAN
            if( subscription.stripeSubscriptionDetails.status === "canceled" )  // --> Canceled Completelly
                return "Canceled"   // --> Free
    }

    const getStatusCode =(subscription)=>{

        // TRIAL
            // 1) with card `S_002` `S_004`
            if( subscription.stripeSubscriptionDetails.status === "trialing" && !subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "1"
            // with card and cancel(not campletally) `S_003`
            if( subscription.stripeSubscriptionDetails.status === "trialing" && subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "2"
        
        // ACTIVE
            // 1) active `S_006` `S_009`
            if( subscription.stripeSubscriptionDetails.status === "active" && !subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "3"
            // Active and Cancel `S_007` 
            if( subscription.stripeSubscriptionDetails.status === "active" && subscription.stripeSubscriptionDetails.cancel_at_period_end )
                return "4"
            
        // COMMAN
            if( subscription.stripeSubscriptionDetails.status === "canceled" )
                return "5"
    }

    const togglePaushResumeSubscription = async()=>{

        const status = getStatusCode(subscription);
        
        toast.dismiss();
        if((status === "2" || status === "4")){
            try{
                let data ={
                    "subscriptionId": subscription.stripeSubscriptionDetails.subscriptionId
                }
                let getRes = await restartSubscription(data, tokenContext.accessToken, dispatch );
                toast(getRes.result.payload.subscriptionPlan, { });
                setModal(!modal);
            }catch (err){
                if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
                console.log("resultresult 3", err);
            }
        }else if((status === "1" || status === "3")){
            
            console.log("getStripCustomerresFreeTrialgetClientSecret 4", subscription);
            try{
                let data ={
                    "subscriptionId": subscription.stripeSubscriptionDetails.subscriptionId
                }
                let getRes = await cancelSubscription(data, tokenContext.accessToken, dispatch );
                toast(getRes.result.payload.subscriptionPlan, { });
                setModal(!modal);
            }catch (err){
                if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
                console.log("resultresult 3", err);
            }
        }
    }

    const getConfimViewToResumeAndCancel = ()=>{

        const status = getStatusCode(subscription);
      
        return <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
            setModal(!modal);
            }} >
            <Button className="modalClose" onClick={()=>{
                setModal(!modal);
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <div className="share_people py-4 text-center">
                    <div class="row">
                        <div className="col-12">
                            <h5>Are you sure?</h5>
                           <p>Do you want to { (status === "2" || status === "4") ? "Re-start Subscription": "Cancel Subscription"}.</p>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 mt-4">
                            <button 
                                className="btn btn-primary"
                                onClick={togglePaushResumeSubscription}
                            >Yes</button>
                            <button 
                                className="btn btn-primary btn-cancel ms-4"
                                onClick={()=> setModal(!modal)}
                            >Cancel</button>
                        </div>
                    </div>
                </div> 
            </ModalBody>
        </Modal>
    }


    const redirectedToStripePortal = ()=>{
      
        return <Modal isOpen={stripePortalModal} centered className="drop-modal" toggle={()=>{
            setStripePortalModal(!stripePortalModal);
            }} >
            <Button className="modalClose" onClick={()=>{
                setStripePortalModal(!stripePortalModal);
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <div className="share_people py-4 text-center">
                    <div style={{
                        position: "relative",
                        top: "-20px"
                    }}>
                        <img src={ConfirmGoToStripPortalIcon} alt="Images" className="img-fluid" />
                    </div>
                    <div class="row">
                        <div className="col-12">
                            {/* <h5>Are you sure?</h5> */}
                            <div>You will now be redirected to Stripe Payments’ website,</div>
                            <div>our billing processing partner.</div>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-12 mt-4">
                            <button 
                                className="me-2 me-md-3 btn btn-outline-secondary"
                                onClick={()=> setStripePortalModal(!stripePortalModal)}
                            >Cancel</button>
                            <button 
                                className="btn btn-primary"
                                onClick={async ()=> {

                                    let data ={
                                        CustomerId: subscription.stripeSubscriptionDetails.customerId,
                                        stripestatus: subscription.stripeSubscriptionDetails.status
                                    }

                                    let getRes = await getPortalLink(data, tokenContext.accessToken, dispatch );
                                    console.log("getPortalLinkgetPortalLink", getRes)
                                    
                                    if(getRes && getRes.result && getRes.result.payload){
                                        window.open(getRes.result.payload,'_blank')
                                        setStripePortalModal(!stripePortalModal)
                                    }
                                }}
                            >Yes</button>
                        </div>
                    </div>
                </div> 
            </ModalBody>
        </Modal>
    }

    const getSubsctriptionActionButtonBasedOnStatus = ()=>{

        // const status = getStatusCode(subscription);

        return <Button className="btn btn-primary expired-background" 
            onClick={()=> setStripePortalModal(!stripePortalModal)} 
        >
            Manage Subscription
        </Button>

        // if(status === "1" || status === "3"){
        //     return <Button className="btn btn-primary expired-background" 
        //         onClick={()=> setModal(!modal)} 
        //     >
        //         Cancel Subscription
        //     </Button>
        // }else if(status === "2" || status === "4"){
        //     return <Button className="btn btn-primary" 
        //         onClick={()=> setModal(!modal)} 
        //     >
        //         Re-start Subscription
        //     </Button>
        // }
        // // else if(status === "5"){
        // //     return <Button className="text-primary mb-1" color="link" style={{width: "170px"}} 
        // //         onClick={()=> navigate('/checkout')} 
        // //     >
        // //         Create New Subscription
        // //     </Button>
        // // }
    }

    const statusValue = getStatusValue(subscription);
    const status = getStatusCode(subscription);

    // if(status === "5"){
    //     return <Plan />
    // }

    return <React.Fragment>
    {getConfimViewToResumeAndCancel()}
    {redirectedToStripePortal()}
    <div className="px-2 px-md-0">
        <div className="container-fluid">
            <div className="white-box mt-0 mt-md-2 mh-100-120">
                <h5 className=" mb-4"><strong>Subscription</strong></h5>
                <h6 className="text-medium">Current Plan</h6>
                <div 
                    class="card mb-4" 
                    style={{"background-color": "var(--card-some_how_light-background)"}}
                    // style={subscription.recuerdoStatus === 4 ? getColor("#DB0000", "border-color"): {}}
                >
                    <div class="card-body">
                        <div className="d-block d-lg-flex justify-content-between">
                            <div>
                                {/* <h6 class="card-title"><strong>Recuerdo <span className="text-primary text-capitalize">{subscription.stripeSubscriptionDetails.status}</span></strong></h6> */}
                                
                                <p class="card-text mb-0"><span style={{"font-weight": "500"}}>Subscription Status</span>
                                <span className="subscription-status" 
                                    style={getSubscriptionStatusBC(status)}
                                >
                                    {statusValue}
                                </span></p>
                                
                                <div dangerouslySetInnerHTML={{ __html: subscription.message }} style={{"margin-top": "18px"}}/>
                            </div>
                            <div className="text-lg-end mt-3 mt-lg-0">
                                {getSubsctriptionActionButtonBasedOnStatus()}
                            </div>

                        </div>
                    </div>
                </div>

            {/* {subscription.stripeSubscriptionDetails.status !== "canceled" && <Button 
            className="btn btn-primary " 
            onClick={()=> navigate('/checkout', {isUpdatePaymentMethod: true})}
            >
                Update payment method
            </Button>} */}

            </div>
        </div>
    </div>
   
</React.Fragment>
}