import React, { useState } from 'react';
import CreateTokenContextProvider from './createTokenContextProvider'

export default (props)=>{ 

    const [accessToken, setAccessToken]= useState("")
    const [refreshToken, setRefreshToken]= useState("")

    const addTokens =(accessToken, refreshToken)=>{
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
    }

    const reset =()=>{
        setAccessToken("");
        setRefreshToken("");
    }

    return (
        <CreateTokenContextProvider.Provider value={{
            accessToken, refreshToken, addTokens, reset
        }}>
            {props.children}
        </CreateTokenContextProvider.Provider>
    )
}
