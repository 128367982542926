import React, { useEffect, useRef, useState, useContext } from "react";
import edit from './../../ASSETS/Images/icons/edit.png';
import AudioAsset from './../../ASSETS/Images/icons/AudioAssest2.jpg';
import Play from './../../ASSETS/Images/icons/play.svg';
import Image from './../../ASSETS/Images/icons/Image.svg';
import Audio from './../../ASSETS/Images/icons/audio.svg';
import Video from './../../ASSETS/Images/icons/Video.svg';
import Location from './../../ASSETS/Images/icons/Location.svg';
import Web from './../../ASSETS/Images/icons/web.svg';
import deleteIcon from './../../ASSETS/Images/icons/delete.png';
import AddTag from './../../ASSETS/Images/icons/Cancel2x.png';
import Back from './../../ASSETS/Images/icons/Back.png';
import Collapse from './../../ASSETS/Images/icons/collapse.png';
import Expand from './../../ASSETS/Images/icons/Expand.png';
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import { useDispatch, useSelector } from 'react-redux'
import { getData } from "../../UTILITYS";
import { deleteVTtag, getAssetsForTagging, getSASurlToUploadVideosFiles, getVTtag, postVTtag, putDataTo } from "../../UTILITYS/endPoints.js";
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap'; 
import CancelIcon from './../../ASSETS/Images/icons/close.svg' 
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import { setParentModuleActiveDispatch } from "../SideBarMenu/activeParentModule.slice.js";
import { resetShareWithMeAndOthers } from "../../SCREENS/Home/slices/ShareWithMe.js";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer.tsx";
import { ExpiredModal } from "../Modal/Expired.js";

function PenneliumReactOne(props) {
  const panoramaRef = useRef(null);
  let clickStartPosition = { x: 0, y: 0 };
  let clickEndPosition = { x: 0, y: 0 };
  const [viewer, setviewer] = useState(null);
  const [isParonamaIMGloaded, setIsParonamaIMGloaded] = useState(false);
  const [currentVisibleHotspotID, setCurrentVisibleHotspotID] = useState("");  
  const [previousHSiD, setPreviousHSiD] = useState("");
  const tokenContext = useContext(CreateTokenContextProvider);
  const [isClickOnSavedTag, seIsClickOnSavedTag] = useState(false);
  const [isEnableNewTag, setIsEnableNewTag] = useState(false);
  const [isEnableDrawer, setIsEnableDrawer] = useState(false);
  const [isEnableDrawerItem, setIsEnableDrawerItem] = useState(true);
  const userProfileDetails = useSelector(state => state.userProfileDetails)
  const [fetchedHotSpot, setFetchedHotSpot] = useState([]);
  const [activeTagID, setActiveTagID] = useState("");
  const [HStobeDeleted, setHStobeDeleted] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("propsprops >>", props);

  useEffect(()=>{
    dispatch(setParentModuleActiveDispatch("parentVRexperience"))
    dispatch(resetShareWithMeAndOthers("virtualTour"))
    return ()=>{
      localStorage.removeItem('isEditableTagData');
      localStorage.removeItem('isNewAddedTagData');
    }
  }, []);

  const deleteAssestToggle = () => {
    setDeleteModal(!deleteModal);
  }

  const getSAS = async() => {
    return await getSASurlToUploadVideosFiles(tokenContext.accessToken, dispatch);
  }

  // GET HOT-SPOT AND MAP WITH `pannellum viewer`
  const fetchScenesArray= async()=>{

    let data= {
      id: props.id
    }

    let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)
    let getScenesArray1= getScenesArrayPromiseJson.result;

    console.log("VirtualTourImageIDVirtualTourImageID", getScenesArray1);
    if(getScenesArray1.payload.length >0){
      getScenesArray1.payload.forEach(hotSpot =>{

        let createTooltipArgs= {
          pitch: hotSpot.pitchValue, yaw: hotSpot.pitchYaw, tagName: hotSpot.tagName, ...hotSpot, mainDBid: hotSpot.id, id: `edit-hotspot${hotSpot.uniqueTimestamp}`,
        }

        setFetchedHotSpot((prev)=>{
          return [...prev, createTooltipArgs]
        });
        

        let newHotspot = {
            id: `edit-hotspot${hotSpot.uniqueTimestamp}`,
            pitch: hotSpot.pitchValue,
            yaw: hotSpot.pitchYaw,
            cssClass: getIcon(createTooltipArgs.tagType),
            createTooltipFunc: showHotspot,
            // createTooltipArgs: "New Hotspot",
            createTooltipArgs,
            clickHandlerFunc: (e)=>{
              e.stopPropagation()
              setIsEnableNewTag(false);
              setActiveTagID(hotSpot.id);
              editTag("e", createTooltipArgs);
              viewer.lookAt(parseInt(hotSpot.pitchValue), parseInt(hotSpot.pitchYaw));
            },
            isAddInfoEnable: false,
            draggable: true,
            "dragHandlerFunc": dragHandlerFunc,
            "dragHandlerArgs": {id: 0}
          };
        // Add hotspot
        viewer.addHotSpot(newHotspot);
      });
    }
  }

  useEffect(() => {
    if(viewer!==null){
      if(viewer.isLoaded() === undefined){
        let elem = document.getElementsByClassName('pnlm-load-box');
        let temp = "<div class='loader'><div class='lds-spinner'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>";
        elem[0].innerHTML = temp;
      }
    }
  })

  useEffect(()=>{
    if(isParonamaIMGloaded){
      setTimeout(() => {
        let pnlmRenderContainer = document.querySelector('.pnlm-render-container');
        let addtags = document.querySelectorAll('.addtag');
        console.log("pnlm-render-container", pnlmRenderContainer, addtags);
        addtags.forEach((addtag) => {
          pnlmRenderContainer.appendChild(addtag);
        });
      }, 1000);
    }
  }, [isParonamaIMGloaded]);

   // GET HOT-SPOT AND MAP WITH `pannellum viewer`
   useEffect(() => {
    if(viewer!==null){
      viewer.on('load', ()=>{
        fetchScenesArray();
        setIsParonamaIMGloaded(true);
        let elem = document.getElementsByClassName('pnlm-about-msg');
        if (elem.length > 0) {
          elem[0].remove();
        }
      })
    }
  }, [viewer])

  // POST HOT-SPOT TO BACK-END
  async function addNewHotSpot(createTooltipArgs) {
    // Create an object with the data to be sent in the request body
    // console.log("addNewHotSpotaddNewHotSpot", createTooltipArgs);
    let addTagData= {
      tagName: createTooltipArgs.tagName,
      virtualTourID: createTooltipArgs.virtualTourID,
      virtualTourImageID: props.id,
      pitchValue: createTooltipArgs.pitch,
      pitchYaw: createTooltipArgs.yaw,
      url: createTooltipArgs.urlVal,
      assetId: createTooltipArgs.assetId,
      uniqueTimestamp: createTooltipArgs.id,
      tagType: createTooltipArgs.tagType,
    }

    return await postVTtag(addTagData, dispatch, tokenContext.accessToken);

  }

  // EDIT HOT-SPOT TO BACK-END
  async function editHotSpot(createTooltipArgs) {
    // console.log("addNewHotSpotaddNewHotSpot", createTooltipArgs);
    let addTagData= {
      tagName: createTooltipArgs.tagName,
      virtualTourID: createTooltipArgs.virtualTourID,
      virtualTourImageID: props.id,
      pitchValue: createTooltipArgs.pitch,
      pitchYaw: createTooltipArgs.yaw,
      url: createTooltipArgs.urlVal,
      assetId: createTooltipArgs.assetId,
      uniqueTimestamp: createTooltipArgs.uniqueTimestamp,
      tagType: createTooltipArgs.tagType,
    }
    return await postVTtag(addTagData, dispatch, tokenContext.accessToken);
  }

  // TO ADD `panoramaRef` WITH `pannellum viewer`.
  useEffect(() => {
    setviewer(window.pannellum.viewer(panoramaRef.current, {
      draggable: true,
      "disableKeyboardCtrl": true,
      "type": "equirectangular",
      "autoLoad": true, 
      "panorama": props.fIleUrl,
      "hotSpots": [],
      "showControls": false,
      "compass": true
    }));
    // Additional configurations and customization can be done here
    // return () => {
    //   viewer.destroy();
    // };
  }, []);

  // HANDLER TO ADD TAG 
  function dragHandlerFunc(e, args, hs) {
    if (e.type == "mouseup") {
        // Here I can update the hotSpot in my database by using args.id and hs.yaw, hs.pitch for the new position
        console.log("mouseupmouseup >>", args.id, hs.yaw, hs.pitch);
    }
  }
  
  // HANDLER TO ADD TAG 
  async function addEditTagHandler(e, createTooltipArgs) {
    
    e.preventDefault();
    seIsClickOnSavedTag(false);
    setIsEnableNewTag(false);
    setActiveTagID("");
    console.log("addEditTagHandleraddEditTagHandler 1", createTooltipArgs);
    if(createTooltipArgs.isEdite){
      editTagHandler(createTooltipArgs)
    }else{
      addTagHandler(createTooltipArgs);
    }
  }

  async function addTagHandler(createTooltipArgs) {
    const tagName = createTooltipArgs.tagName;
    console.log("tagName,  createTooltipArgs ",tagName,  createTooltipArgs);
    // REMOVE ADD HOT-SPOT
    viewer.removeHotSpot(createTooltipArgs.currenHSiD);

    // will add hotspot, get new-id  
    let savedTag= await addNewHotSpot(createTooltipArgs);
    // await putVideoOnAzure(createTooltipArgs);
    console.log("savedTagsavedTag", savedTag, createTooltipArgs)
    
    let updatedCreateTooltipArgs= {
      pitch: savedTag.result.payload.pitchValue, yaw: savedTag.result.payload.pitchYaw, tagName: savedTag.result.payload.tagName, ...savedTag.result.payload, mainDBid: savedTag.result.payload.id, id: savedTag.result.payload.uniqueTimestamp,
    }

    // SHOW-HOTSPOT but using new-id
    addHotspotParmanent(savedTag.result.payload);
    // setFetchedHotSpot((prev)=>{
    //   return [ ...prev, createTooltipArgs]
    // });

    setFetchedHotSpot((prev)=>{
      return [ ...prev, updatedCreateTooltipArgs]
    })
  }
  
  async function putVideoOnAzure(createTooltipArgs) {
    if(createTooltipArgs.tagType === 2){
      let res = await getSAS();
      let getActualData = res.result.payload;
  
      let containerUrl = getActualData.containerUrl;
      let blobSASURL = getActualData.saasUrl;
  
      let headerConfig = {
        "Content-Type": "application/octet-stream",
        "x-ms-blob-type": "BlockBlob"
      }

      let url= `${containerUrl}/Tag_@$$_${props.virtualTourID}_@$$_${createTooltipArgs.id}_@$$_${userProfileDetails.userID}_@$$_${Date.now()}_$@$_${createTooltipArgs.fileInput.name}${blobSASURL}`;
      let body= new Blob([createTooltipArgs.fileInput]);
      
      console.log("createTooltipArgscreateTooltipArgs 1", createTooltipArgs, body)
  
      await putDataTo(url, body, headerConfig, dispatch);
    }
  }
  
  // HANDLER TO ADD TAG 
  async function editTagHandler(createTooltipArgs) {
    const tagName = createTooltipArgs.tagName;
    console.log(" ",tagName,  createTooltipArgs);
    // REMOVE TEMPORARY EDIT HOT-SPOT
    viewer.removeHotSpot(createTooltipArgs.id);
    // SHOW-HOTSPOT IN REPLACE OF TEMPORARY EDIT HOT-SPOT

    // will edit hotspot, get new-id  
    let updatedTag= await editHotSpot(createTooltipArgs);

    let updatedCreateTooltipArgs= {
      pitch: updatedTag.result.payload.pitchValue, yaw: updatedTag.result.payload.pitchYaw, tagName: updatedTag.result.payload.tagName, ...updatedTag.result.payload, mainDBid: updatedTag.result.payload.id, id: updatedTag.result.payload.uniqueTimestamp,
    }

    // SHOW-HOTSPOT but using new-id
    addHotspotParmanent(updatedTag.result.payload);
    setFetchedHotSpot((prev)=>{
      return [ ...prev.filter(hs=>hs.uniqueTimestamp != updatedTag.result.payload.uniqueTimestamp), updatedCreateTooltipArgs]
    })
    // await putVideoOnAzure(createTooltipArgs);
  }

  const deleteTag=async (createTooltipArgs)=> {
    viewer.removeHotSpot(createTooltipArgs.uniqueTimestamp);
    viewer.removeHotSpot(createTooltipArgs.id);
    deleteVTtag({uniqueTimestamp: createTooltipArgs.uniqueTimestamp}, dispatch, tokenContext.accessToken);
    setFetchedHotSpot((prev)=>{
      return [ ...prev.filter(hs=>hs.uniqueTimestamp != createTooltipArgs.uniqueTimestamp)]
    })
    setHStobeDeleted({});
    deleteAssestToggle();

    let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
    }
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(end)

    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      console.log("removeremoveremove 1", stringToObjIsEditableTagData);
      // REMOVE TEMPORARY EDIT HOT-SPOT

      if(stringToObjIsEditableTagData.pitchYaw.uniqueTimestamp == createTooltipArgs.uniqueTimestamp){
        viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
  
        localStorage.setItem("isEditableTagData", JSON.stringify({
          previouseEditableTagID: "",
          isEditableTagOpen: false,
          pitchYaw: null
        }));
      }
    }
  }
  
  function addShowHotspot(createTooltipArgs) {
    console.log("getActiveClassgetActiveClass 3", createTooltipArgs);
    var currenHSiD= Date.now();
    let newHotspot = {
      id: createTooltipArgs.uniqueTimestamp,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: showHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, id: createTooltipArgs.uniqueTimestamp,
      },
      clickHandlerFunc: (e)=>{
        e.stopPropagation();
        setIsEnableNewTag(false);
        setActiveTagID(createTooltipArgs.mainDBid);
        editTag("e", { pitch: createTooltipArgs.pitch, yaw: createTooltipArgs.yaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, id: createTooltipArgs.uniqueTimestamp });
        viewer.lookAt(parseInt(createTooltipArgs.pitch), parseInt(createTooltipArgs.yaw));
      },
      isAddInfoEnable: false,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };
    // Add hotspot
    console.log("cancelTagHandler 2", createTooltipArgs, newHotspot);
    viewer.addHotSpot(newHotspot);
  }

  // HANDLER TO CANCEL TAG 
  function cancelTagHandler(e, createTooltipArgs) {

    seIsClickOnSavedTag(false);
    setIsEnableNewTag(false);
    setActiveTagID("");
    if(createTooltipArgs.isEdite){
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(createTooltipArgs.id);
      // SHOW-HOTSPOT IN REPLACE OF TEMPORARY EDIT HOT-SPOT
      setTimeout(() => {
        addShowHotspot(createTooltipArgs);
      },0);

    }else{
      // REMOVE ADD HOT-SPOT
      viewer.removeHotSpot(createTooltipArgs.currenHSiD);
    }

    // RESET ALL LOCAL-STORAGE AFTER CANCELING ADDING OR UPDATING HOT-SPOT(start)
    setPreviousHSiD("");
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: ""
    }));

    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: "",
      isEditableTagOpen: false,
      pitchYaw: null
    }));
    // RESET ALL LOCAL-STORAGE AFTER CANCELING ADDING OR UPDATING HOT-SPOT(end)
  }

  const editTag= async(e, createTooltipArgs)=> {


    // REMOVE EDIT AND ADD HOT-SPOT IF EXISTS(start) 

    // hotSpot should be removed if same `isNewAddedTagData` or `isEditableTagData` added.
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(start)
    let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
      // addShowHotspot(createTooltipArgs);
    }
    // edit-tag(already)(new adding): remove everthing with state, localStorage, tag of the tag(end)

    let isEditableTagData= await localStorage.getItem("isEditableTagData");
    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
      console.log("removeremoveremove 1", stringToObjIsEditableTagData);
      // REMOVE TEMPORARY EDIT HOT-SPOT
      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
      // SHOW HOTSPOT IN REPLACE OF PREVIOUSE TEMPORARY EDIT HOT-SPOT
      addShowHotspot(stringToObjIsEditableTagData.pitchYaw);
    }
    // REMOVE EDIT AND ADD HOT-SPOT IF EXISTS(end)

    console.log("getActiveClassgetActiveClass 2", createTooltipArgs);
    // REMOVE CURRENT SHOW-HOTSPOT AND ADD EDIT-HOTSPOT FOR TEMPORARY TIME(start)
    viewer.removeHotSpot(createTooltipArgs.id);

    // UPDATE `fetchedHotSpot`, THE REASON IS THAT WE ARE CHANGING THE ID WHEN CALL `addShowHotspot`.
    updateFetchedHotSpot(createTooltipArgs.uniqueTimestamp);

    var currenHSiD= Date.now();
    var newHotspot = {
      id: currenHSiD,
      tagName: createTooltipArgs.tagName,
      pitch: createTooltipArgs.pitch,
      yaw: createTooltipArgs.yaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: addEditTagHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
        pitch: createTooltipArgs.pitch, 
        yaw: createTooltipArgs.yaw, 
        tagName: createTooltipArgs.tagName,
        isEdite: true,
        ...createTooltipArgs,
        id: currenHSiD,
      },
      isAddInfoEnable: true,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };

    viewer.addHotSpot(newHotspot);
    // REMOVE CURRENT SHOW-HOTSPOT AND ADD EDIT-HOTSPOT FOR TEMPORARY TIME(end)

    
    // STORE THE EDIT HOTS-POT ID TO BE DELETED IF WE DO EDIT NEXT TIME
    let previouseEditableTagID= currenHSiD;
    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: previouseEditableTagID,
      pitchYaw: createTooltipArgs,
      isEditableTagOpen: true
    }));
  }

  // HANDLER ADD HOTSPOT JUST AFTER ADDING
  function showHotspot(hotSpotDiv, createTooltipArgs) {

    console.log("showHotspotshowHotspot 1", createTooltipArgs);

    // WORKIN WITH SPAN

    console.log("this is sss", hotSpotDiv)

    hotSpotDiv.addEventListener('mouseenter', (e) => {
      console.log("mouseentermouseenter", createTooltipArgs);
      seIsClickOnSavedTag(true);
    })

    hotSpotDiv.addEventListener('mouseleave', () => {
      seIsClickOnSavedTag(false);
    });

    const editRemoveButton= `
      <p>${createTooltipArgs.tagName}</p>
    `;
    
    hotSpotDiv.classList.add('custom-tooltip');
    var span = document.createElement('span');
    span.setAttribute('id', 'show-tag-section'+createTooltipArgs.pitch);
    span.innerHTML = editRemoveButton;
    hotSpotDiv.appendChild(span);

    // document.getElementById(`show-tag-img-section${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
    //   editTag(e, createTooltipArgs);
    // });
  
    // document.getElementById(`show-tag-delete-section${createTooltipArgs.pitch}`).addEventListener('click', () => {
    //   console.log("show-tag-delete-section", createTooltipArgs)
    //   deleteTag(createTooltipArgs) 
    // });

    // Add hover event listeners
    span.addEventListener('mouseenter', (e) => {
      setCurrentVisibleHotspotID(e.target.id);
      console.log("mouseentermouseenter", createTooltipArgs);
      // seIsClickOnSavedTag(true);
    });
    span.addEventListener('mouseleave', () => {
      setCurrentVisibleHotspotID("");
      // seIsClickOnSavedTag(false);
    });
    span.style.width = span.scrollWidth - 20 + 'px';
    span.style.marginLeft = -(span.scrollWidth - hotSpotDiv.offsetWidth) / 2 + 'px';
    span.style.marginTop = -span.scrollHeight - 12 + 'px';
  }
 
  // SHOW HOT-SPOT AFTER ADDING
  function addHotspotParmanent(createTooltipArgs, tagName) {

    // var currenHSiD= Date.now();
    var newHotspot = { 
      id: createTooltipArgs.uniqueTimestamp,
      pitch: createTooltipArgs.pitchValue, 
      yaw: createTooltipArgs.pitchYaw,
      cssClass: getIcon(createTooltipArgs.tagType),
      createTooltipFunc: showHotspot,
      // createTooltipArgs: "New Hotspot",
      createTooltipArgs: {
         pitch: createTooltipArgs.pitchValue, yaw: createTooltipArgs.pitchYaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, mainDBid: createTooltipArgs.id, id: createTooltipArgs.uniqueTimestamp,
      },
      clickHandlerFunc: (e)=>{
        e.stopPropagation()
        setIsEnableNewTag(false);
        setActiveTagID(createTooltipArgs.id)
        
        editTag("e", {pitch: createTooltipArgs.pitchValue, yaw: createTooltipArgs.pitchYaw, tagName: createTooltipArgs.tagName, ...createTooltipArgs, mainDBid: createTooltipArgs.id, id: createTooltipArgs.uniqueTimestamp});
      },
      isAddInfoEnable: false,
      draggable: true,
      "dragHandlerFunc": dragHandlerFunc,
      "dragHandlerArgs": {id: 0}
    };
    
    // RESET ALL LOCAL-STORAGE AFTER ADDING OR UPDATING HOT-SPOT(start)
    if(createTooltipArgs.isAddOnClientSide){
      setPreviousHSiD("");
      localStorage.setItem("isNewAddedTagData", JSON.stringify({
        newAddedID: ""
      }));
    }
    localStorage.setItem("isEditableTagData", JSON.stringify({
      previouseEditableTagID: "",
      isEditableTagOpen: false,
      pitchYaw: null
    }));
    setPreviousHSiD("");
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: ""
    }));
    // RESET ALL LOCAL-STORAGE AFTER ADDING OR UPDATING HOT-SPOT(end)

    // Add hotspot
    viewer.addHotSpot(newHotspot);
  }

  // ADD TAG HOT-SPOT FORM
  function addEditTagHotspot(hotSpotDiv, createTooltipArgs) {

    let copyCreateTooltipArgs= {...createTooltipArgs}
    
    console.log("getScenesArraygetScenesArray 56", createTooltipArgs);
    let isEdite= false;
    if(createTooltipArgs.isEdite){
      isEdite= true;
    } 
     
    let none= ``
   
    let image= `<label class="mb-2">Select asset</label><div class="image-container">
      <div id="image-result-container" class="scroll-style"></div>
    </div>`

    let video= `<label class="mb-2">Select asset</label><div class="video-container">
      <div id="video-result-container" class="scroll-style"></div>
    </div>`
    
    let audio= `<label class="mb-2">Select asset</label><div class="audio-container">
      <div id="audio-result-container" class="scroll-style"></div>
    </div>`
    
    let url= `<label for="url${createTooltipArgs.pitch}">Url</label><input 
      type="text" 
      class="form-control pannelium-tag-input"
      id="url${createTooltipArgs.pitch}" 
      placeholder="Url"
      value="${isEdite && createTooltipArgs.url? createTooltipArgs.url: ""}"
    >`

    const formTemplate = `
        <form>
            <div class="form-group">
                <label for="tagName">Tag Name</label>
                <input type="text" maxlength="30" value="${isEdite? createTooltipArgs.tagName: ""}" class="form-control pannelium-tag-input" id="tagName${createTooltipArgs.pitch}" placeholder="Tag Name">
            </div>

            <div class="rdo-btn form-group">
                <span>
                
                  <input
                    type="radio"
                    value="NONE"
                    name= "assest-name"
                    id="NONE${createTooltipArgs.pitch}"
                    ${getCheckedValue({...createTooltipArgs, whichTag: 0})}
                  />
                  <label for="NONE${createTooltipArgs.pitch}">None</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="IMAGE"
                      name= "assest-name"
                      id="IMAGE${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 3})}
                  />
                  <label for="IMAGE${createTooltipArgs.pitch}">Image</label>
                </span>
                <span>
                    <input
                        type="radio"
                        value="VIDEO"
                        name= "assest-name"
                        id="VIDEO${createTooltipArgs.pitch}"
                        ${getCheckedValue({...createTooltipArgs, whichTag: 2})}
                    />
                    <label for="VIDEO${createTooltipArgs.pitch}">Video</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="AUDIO"
                      name= "assest-name"
                      id="AUDIO${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 1})}
                  />
                  <label for="AUDIO${createTooltipArgs.pitch}">Audio</label>
                </span>
                <span>
                  <input
                      type="radio"
                      value="URL"
                      name= "assest-name"
                      id="URL${createTooltipArgs.pitch}"
                      ${getCheckedValue({...createTooltipArgs, whichTag: 4})}
                  />
                  <label for="URL${createTooltipArgs.pitch}">Url</label>
                </span>
            </div>

            <div class="form-group" id= "action-tag${createTooltipArgs.pitch}">
               ${none}
            </div>

            <button id="add-tag-submit${createTooltipArgs.pitch}" type="submit" class="btn btn-primary pannelium-tag-button">Save</button>
            <button id="add-tag-cancel${createTooltipArgs.pitch}" type="button" class="btn btn-secondary pannelium-tag-button">Cancel</button>
        </form>
    `;

    // <div class="myDiv" id="add-tag-section" style="visibility: visible;">
    const divTemplate = `
        <div class="myDiv" id="${isEdite? 'edit-tag-section': "add-tag-section"}" style="visibility: visible;">
        ${formTemplate}
        </div>
    `;
    
    hotSpotDiv.innerHTML = divTemplate;

    hotSpotDiv.addEventListener('mouseenter', (e) => {
      console.log("mouseentermouseenter 4", createTooltipArgs);
      seIsClickOnSavedTag(true);
    })

    hotSpotDiv.addEventListener('mouseleave', () => {
      seIsClickOnSavedTag(false);
    });

    let assetId= null;
    let urlVal= null;
    let tagName = document.getElementById(`tagName${createTooltipArgs.pitch}`);

    let appedValue ={ 
      tagType: 0,
      assetId: null
    }

    setTimeout(() => {
      if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 0){
        document.getElementById(`NONE${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 1){
        document.getElementById(`AUDIO${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 2){
        document.getElementById(`VIDEO${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 3){
        document.getElementById(`IMAGE${createTooltipArgs.pitch}`).click();
        
      }else if(createTooltipArgs.isEdite && createTooltipArgs.tagType=== 4){
        document.getElementById(`URL${createTooltipArgs.pitch}`).click();
        
      }
    }, 100);
    
    document.getElementById(`NONE${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', none);
        // appedValue ={ tagName, fileInput: null, urlVal: "" }

        appedValue.tagType= 0
        appedValue.assetId= null
    });
    
    document.getElementById(`IMAGE${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', image);
        appedValue.tagType= 3
        let pageNum = 1;
        let isLoading = false;
        const container =  document.getElementById("image-result-container");
        const resultContainer = document.getElementById("image-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)
        
        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
          
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;

            try{ 
              let getImgAssetsForTagging =await getAssetsForTagging(pageNum, 10, 3, tokenContext.accessToken);
              
              console.log("getImgAssetsForTagging.result.payload.pagedList", getImgAssetsForTagging.result.payload.pagedList,typeof pageNum, pageNum===1)
              if(getImgAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              } 
              
              if(getImgAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;
              
                let selectedTag = getImgAssetsForTagging.result.payload.pagedList.filter( (tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getImgAssetsForTagging.result.payload.pagedList.find( (tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }
            
              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="image${item.id}" src="${item.thumbnailUrl}" alt="image" class="assets_image"/>`;
                  let img= `<img src="${item.thumbnailUrl}" alt="image" class="assets_image"/>`;
                
                  const container = document.createElement('span');
                  container.setAttribute('id', `image${item.id}`);

                  getSavedTags.forEach(element => {
                    // console.log("item.nameitem.name", createTooltipArgs.assetID, item);
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  });
                  
                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);
              
                  container.addEventListener('click', (e) => {
                    // console.log("passpasspass 1", e.currentTarget);
                    
                    const parentElement = document.getElementById('image-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');
                    // imageElement.classList.add('class_second');
                    
                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("image", "");
                      createTooltipArgs.assetID= item.id;
                      // appedValue.tagType= 3
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }

        }
          
        container.onscroll = () => {
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop) >=
                    container.scrollHeight) && isLoadMore
            ) {
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`VIDEO${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        }
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', video);
        appedValue.tagType= 2
        let pageNum = 1;
        let isLoading = false;
        const container = document.getElementById("video-result-container");
        const resultContainer = document.getElementById("video-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)

        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
        
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;
            
            try{
              let getVidAssetsForTagging =await getAssetsForTagging(pageNum, 10, 2, tokenContext.accessToken);

              if(getVidAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              }

              if(getVidAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;

              let selectedTag = getVidAssetsForTagging.result.payload.pagedList.filter( (tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getVidAssetsForTagging.result.payload.pagedList.find( (tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }

              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="video${item.id}" src="${item.thumbnailUrl}" alt="video" class="assets_image"/><label class="vid_icon"></label>`;
                  let img= `<img src="${item.thumbnailUrl}" alt="video" class="assets_image"/><label class="vid_icon"></label>`;
                  
                  const container = document.createElement('span');
                  container.setAttribute('id', `video${item.id}`);

                  getSavedTags.forEach(element => {
                    // console.log("item.nameitem.name", createTooltipArgs.assetID, item);
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  });

                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);

                  container.addEventListener('click', (e) => {

                    const parentElement = document.getElementById('video-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');

                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("video", "");
                      createTooltipArgs.assetID= item.id;
                      // appedValue.tagType= 2
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }
            
        }
          
        container.onscroll = () => {
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop) >=
                    container.scrollHeight) && isLoadMore
            ) {
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`AUDIO${createTooltipArgs.pitch}`).addEventListener('click', async(e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        } 
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', audio);
        appedValue.tagType= 1
        let pageNum = 1;
        let isLoading = false;
        const container = document.getElementById("audio-result-container");
        const resultContainer = document.getElementById("audio-result-container");

        let data= {
          id: props.id
        }
        let getScenesArrayPromiseJson= await getVTtag(data , dispatch, tokenContext.accessToken)

        let getSavedTags= getScenesArrayPromiseJson.result.payload;
        let isLoadMore= true;
        
        async function fetchData() {
            if (isLoading) return;
            isLoading = true;

            try{
              let getAudAssetsForTagging =await getAssetsForTagging(pageNum, 10, 1, tokenContext.accessToken);

              if(getAudAssetsForTagging.result.payload.pagedList.length === 0 && pageNum===1){
                let noContentDiv = document.createElement('div');
                noContentDiv.className = 'no-cnt';
                noContentDiv.innerText = 'No Asset Added';
                resultContainer.appendChild(noContentDiv);
                return;
              }

              if(getAudAssetsForTagging.result.payload.pagedList.length === 0)
                isLoadMore= false;

              let selectedTag = getAudAssetsForTagging.result.payload.pagedList.filter((tag)=>createTooltipArgs.assetID !== tag.id );
              let allTag = getAudAssetsForTagging.result.payload.pagedList.find((tag)=>createTooltipArgs.assetID === tag.id );

              let placedSelectedTagAtTop =[];
              if(allTag !== undefined){
                placedSelectedTagAtTop =[allTag, ...selectedTag]
              }else{
                placedSelectedTagAtTop =[...selectedTag]
              }

              placedSelectedTagAtTop.forEach((item) => {
                  // let img= `<img id="audio${item.id}" src="${AudioAsset}" alt="audio" class="assets_image"/>`;
                  // <label>Audo ${item.id}</label>
                  let img= `<img id="audio${item.id}" src="${AudioAsset}" alt="audio" class="assets_image"/><label>${item.name.length >25 ? item.name.substring(0, 25)+"...": item.name}</label>`;
                 
                  const container = document.createElement('span');
                  container.setAttribute('id', `audio${item.id}`);

                  getSavedTags.forEach(element => {
                    if( createTooltipArgs.assetID==item.id ){
                      container.classList.add('class_second');
                      appedValue.assetId= item.id
                    }
                  }); 
                 
                  container.innerHTML = img.trim();
                  resultContainer.appendChild(container);
                  
                  container.addEventListener('click', (e) => {
                    const parentElement = document.getElementById('audio-result-container');
                    const childElements = parentElement.getElementsByClassName('assets_image');
                    for (let i = 0; i < childElements.length; i++) {
                      childElements[i].parentElement.classList.remove('class_second');
                    }
                    const imageElement = e.currentTarget;
                    imageElement.classList.add('class_second');

                    if (imageElement.classList.contains('class_second')) {
                      assetId= imageElement.id.replace("audio", "");
                      createTooltipArgs.assetID= item.id;
                      console.log("item.nameitem.name", createTooltipArgs.assetID);
                      // appedValue.tagType= 1
                      appedValue.assetId= assetId;
                    } else {
                      assetId= null;
                    }
                  })
              });
              isLoading = false;
            }catch(err) {
              isLoading = false;
            }
        }
          
        container.onscroll = () => {
          console.log("isLoadingisLoading",Math.ceil(container.clientHeight 
            + container.scrollTop) ,
            container.scrollHeight)
            if (isLoading) return;
          
            if (
                (Math.ceil(container.clientHeight 
                    + container.scrollTop)+1 >=
                    container.scrollHeight) && isLoadMore
            ) {
              
                pageNum++;
                fetchData();
            }
        };
          
        fetchData();

    });

    document.getElementById(`URL${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        while (document.getElementById('action-tag' + createTooltipArgs.pitch).childNodes.length > 1) {
            document.getElementById('action-tag' + createTooltipArgs.pitch).removeChild(document.getElementById('action-tag' + createTooltipArgs.pitch).lastChild);
        } 
        document.getElementById('action-tag' + createTooltipArgs.pitch).insertAdjacentHTML('beforeend', url);

        urlVal = document.getElementById(`url${createTooltipArgs.pitch}`);

        appedValue.tagType= 4
        appedValue.assetId= null
        
    });
  
    document.getElementById(`add-tag-submit${createTooltipArgs.pitch}`).addEventListener('click', (e) => {
        e.preventDefault();

        let urlVal= ""
        if(document.getElementById(`url${createTooltipArgs.pitch}`)!== undefined &&document.getElementById(`url${createTooltipArgs.pitch}`)!== null ){
            urlVal= document.getElementById(`url${createTooltipArgs.pitch}`).value
        }

        if(checkValidation({isEdit: createTooltipArgs.isEdite ,...appedValue, urlVal, tagName: tagName.value})){

          if( userProfileDetails.isExpired ){
              dispatch(setDoNotShowExpiredModal(false));
              // setOpenUpgrade(true);
              toast("Your subscription has been expired");
              return;
          }

          console.log("passpasspass", appedValue);
          addEditTagHandler(e, {
          ...createTooltipArgs, 
          ...appedValue, 
          urlVal: urlVal, 
          tagName: tagName.value, 
          })
        }

    });

    document.getElementById(`add-tag-cancel${createTooltipArgs.pitch}`).addEventListener('click', (e) => {cancelTagHandler(e, copyCreateTooltipArgs)});
  
  }
 
  const handleMouseDown = (event) => {
    clickStartPosition.x = event.clientX;
    clickStartPosition.y = event.clientY;
  };
  
  const isClickedUnderSubmiteTagSection = (event) => {
    var clickedX = event.clientX; // X coordinate of the clicked area
    var clickedY = event.clientY; // Y coordinate of the clicked area
  
    var addTagSection = document.getElementById('add-tag-section');
    var editTagSection = document.getElementById('edit-tag-section');
    var showTagSection = document.getElementById(currentVisibleHotspotID);
    
    console.log("currentVisibleHotspotIDcurrentVisibleHotspotID", currentVisibleHotspotID, showTagSection);
  
    if(editTagSection !== null){
      var rect = editTagSection.getBoundingClientRect(); // Get the position and size of the element
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;
    } 
  
    if(addTagSection !== null){
      var rect = addTagSection.getBoundingClientRect(); // Get the position and size of the element
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;
    } 

    if(showTagSection){
      var rect = showTagSection.getBoundingClientRect(); // Get the position and size of the element
    
      var elementX = rect.left; // X coordinate of the element
      var elementY = rect.top; // Y coordinate of the element
      var elementWidth = rect.width; // Width of the element
      var elementHeight = rect.height; // Height of the element
    
      // Check if the clicked area falls within the boundaries of the element
      if (clickedX >= elementX && clickedX <= elementX + elementWidth &&
          clickedY >= elementY && clickedY <= elementY + elementHeight) 
        return true;  
    }
    
    return false;
  }
  
  const handleMouseUp = async(event) => {
    console.log("clickHandlerFuncclickHandlerFunc 2", event)

    if(isClickOnSavedTag){
      return;
    }

    clickEndPosition.x = event.clientX;
    clickEndPosition.y = event.clientY;
    if(isClickedUnderSubmiteTagSection(event)){
      return;
    }

    // return;
    const distanceThreshold = 5; // Adjust the threshold as needed
    const deltaX = Math.abs(clickEndPosition.x - clickStartPosition.x);
    const deltaY = Math.abs(clickEndPosition.y - clickStartPosition.y);
    if (deltaX <= distanceThreshold && deltaY <= distanceThreshold) {
      handleMouseClick(event);
    }
  };

  const handleMouseClick = async(event) => {
    console.log("viewer.mouseEventToCoords", viewer)
    // Get the pitch and yaw values at the clicked coordinates

    let coords= "";
    let pitch= "";
    let yaw= "";

    if(event instanceof Object){
        coords = viewer.mouseEventToCoords(event);
        console.log(coords);
        // pitch = coords.pitch;
        pitch = coords[0];//viewer.getPitch();
        yaw =  coords[1];//viewer.getYaw();
    }else{
        pitch = viewer.getPitch();//viewer.getPitch();
        yaw =  viewer.getYaw();//viewer.getYaw();
    }
    var currenHSiD= Date.now();
    var newHotspot = {
        id: currenHSiD,
        pitch: pitch,
        yaw: yaw,
        cssClass: "custom-hotspot",
        createTooltipFunc: addEditTagHotspot,
        // createTooltipArgs: "New Hotspot",
        createTooltipArgs: {
            id: currenHSiD, pitch, yaw, currenHSiD, isAddOnClientSide: true
        },
        isAddInfoEnable: true,
        draggable: true,
        "dragHandlerFunc": dragHandlerFunc,
        "dragHandlerArgs": {id: 0}
    };

    // REMOVE EDIT AND ADD HOT-SPOT(start)

    // click somewhere(new adding): update state, previouse-tag, localStorage with new one(start)
    if(previousHSiD){
      viewer.removeHotSpot(previousHSiD);
    }
    setPreviousHSiD(currenHSiD);
    localStorage.setItem("isNewAddedTagData", JSON.stringify({
      newAddedID: currenHSiD
    }));
    // click somewhere(new adding): update state, previouse-tag, localStorage with new one(end)

    // IF YOU CLICK ON EMPTY PITCH, THEN `isEditableTagData` HOT-SPOT SHOUD BE REMOVED IF EXIXST
    let string= await localStorage.getItem("isEditableTagData");
    const stringToObj = JSON.parse(string);
    if(stringToObj!==null && stringToObj.isEditableTagOpen){
      viewer.removeHotSpot(stringToObj.previouseEditableTagID);
      // Show hotSpot
      addShowHotspot(stringToObj.pitchYaw);
      localStorage.setItem("isEditableTagData", JSON.stringify({
        previouseEditableTagID: "",
        isEditableTagOpen: false,
        pitchYaw: null
      }));
    }

    // REMOVE EDIT AND ADD HOT-SPOT(end)

    
    
    // Add hotspot
    viewer.addHotSpot(newHotspot);

  };

  const getHotSpot = ()=>{
    return (
      fetchedHotSpot.map(hs=>{
        return  <div key={hs.uniqueTimestamp} class={getActiveClass(hs)} onClick={async()=>{
            viewer.lookAt(parseInt(hs.pitchValue), parseInt(hs.pitchYaw));
            // setIsEnableNewTag(false);
            setActiveTagID(hs.mainDBid);
            setTimeout(() => {
              editTag("e", hs);
            }, 700);
            // let string= await localStorage.getItem("isEditableTagData");
            // console.log("stringstring", string)
            // const stringToObj = JSON.parse(string);
            // if(stringToObj===null ){
            //   editTag("e", hs);
            // }else if(!stringToObj.isEditableTagOpen){
            //   editTag("e", hs);
            // }
          }}
          >
          <img src={getRightDrawerIcon(hs.tagType)} class="tagImage" alt="back icon" />
          <p>{hs.tagName}</p>
          <button class="btn btn-link" type="button" onClick={(e)=>{
            e.stopPropagation();

            if( userProfileDetails.isExpired ){
                dispatch(setDoNotShowExpiredModal(false));
                // setOpenUpgrade(true);
                toast("Your subscription has been expired");
                return;
            }

            deleteAssestToggle();
            setHStobeDeleted(hs)
            console.log("updateFetchedHotSpotupdateFetchedHotSpot", hs)
            // deleteTag(hs)
          }}>
            <img src={deleteIcon} alt="delete tag" />
          </button>
        </div>
      })
    )
  }

  const getDeleteConfirmationPopup = ()=>{
    return  <Modal isOpen={deleteModal} centered className="modal-medium" toggle= {deleteAssestToggle} >
              <ModalBody>
                  <div className="text-center mt-3">
                      <img src={CancelIcon} alt="Images" className="img-fluid mb-4" />
                      <p>Are you sure? Do you want to delete this tag ?</p>
                  </div>  
              </ModalBody>
              <ModalFooter className="justify-content-center pt-0">
                  <Button className="me-2 me-md-3" color="outline-secondary" onClick={()=>{
                    setHStobeDeleted({});
                    deleteAssestToggle();
                  }}>Cancel</Button>
                  <Button className="ms-2 ms-md-3" color="primary" onClick={()=> deleteTag(HStobeDeleted)}>Yes</Button>
              </ModalFooter>
      </Modal>
  }
  
  const getActiveClass = (hs)=>{
    if(hs.mainDBid === activeTagID){
      console.log("getActiveClassgetActiveClass 1", hs, activeTagID);
      return "tagitem active"
    }else{
      return "tagitem"
    }
  }

  const updateFetchedHotSpot = (timeStamp)=>{
    setFetchedHotSpot((prev)=>{
      return prev.map(hs=>{
        if(hs.uniqueTimestamp == timeStamp){
          return{
            ...hs, id: hs.uniqueTimestamp
          }
        }else{
          return hs;
        }
      })
    })
  }

  const checkValidation = (tagsData)=>{
    toast.dismiss();
    console.log("vgetElementByIdjjjj", tagsData);

    if(tagsData.tagName === ""){
      toast.error("Please Enter Tag Name", { });
      return false
    }

    if(tagsData.isEdit){
      switch (tagsData.tagType) {
        case 1:
        case 2:
        case 3:
          if(tagsData.assetId === null){
            toast.error("Please Select a asset", { });
            return false
          }
          break;
        case 4:
        // if(tagsData.urlVal === ""){
          if(validator.isEmpty(tagsData.urlVal.trim()) || !validator.isURL(tagsData.urlVal.trim()) ){
            toast.error("Please Enter Valid Url", { });
            return false
          }
          break;
      }
      return true;
    }else{
      switch (tagsData.tagType) {
        case 1:
        case 2:
        case 3:
          if(tagsData.assetId === null){
            toast.error("Please Select a asset", { });
            return false
          }
          break;
        case 4:
          // if(tagsData.urlVal === ""){
          if(validator.isEmpty(tagsData.urlVal.trim()) || !validator.isURL(tagsData.urlVal.trim()) ){
            toast.error("Please Enter Valid Url", { });
            return false
          }
          break;
      }
      return true;
    }
  }

  const getIcon = (tagType)=>{
    switch (tagType) {
      case 0:
        return "hotspot-location";
      case 1:
        return "hotspot-audio";
      case 2:
        return "hotspot-video";
      case 3:
        return "hotspot-image";
      case 4:
        return "hotspot-url";
    }
  }

  const getRightDrawerIcon = (tagType)=>{
    switch (tagType) {
      case 0:
        return Location;
      case 1:
        return Audio;
      case 2:
        return Video;
      case 3:
        return Image;
      case 4:
        return Web;
    }
  }

  const getCheckedValue = (createTooltipArgs)=>{
    
    if(createTooltipArgs.whichTag===0){
      return "checked"
    }else if(!createTooltipArgs.isEdite){
      console.log("setTimeoutsetTimeoutsetTimeout", createTooltipArgs)
      return;
    }else if(createTooltipArgs.whichTag=== 1 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 1 ){
      return "checked"
    }else if(createTooltipArgs.whichTag=== 2 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 2 ){
      return "checked"
    }else if(createTooltipArgs.whichTag=== 3 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 3 ){
      return "checked"
    }else if(createTooltipArgs.whichTag=== 4 && createTooltipArgs.isEdite && createTooltipArgs.tagType=== 4 ){
      return "checked"
    }
  }
  
  return (
    <React.Fragment>
      <ExpiredModal />
      {getDeleteConfirmationPopup()}
      <div className={`virtual-tour-wrap ${isEnableDrawer && 'act'}`}>
    
        {isParonamaIMGloaded && <div className={`virtual-tour-drawer ${isEnableDrawer && 'actDrawer'}`}>
          <div className="collapseBtn">
              <button className="btn btn-link" 
                onClick={()=>{
                  setIsEnableDrawer(!isEnableDrawer);
                }}
              >{!isEnableDrawer ?
                  <img src={Collapse} alt="collapse icon" />
                :
                  <img src={Expand} alt="collapse icon" />
                }
                </button>
          </div>
          <div class="tour-drawer-inner">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="tags">
                  <button class={`accordion-button ${!isEnableDrawerItem && 'collapsed'}`} type="button" 
                  onClick={()=>{
                    setIsEnableDrawerItem(!isEnableDrawerItem);
                  }}
                  >
                    Tags
                  </button>
                </h2>
                <div id="flush-collapseOne" class={`accordion-collapse collapse ${isEnableDrawerItem && 'show'}`}>
                  <div class="accordion-body">
                    {fetchedHotSpot.length === 0 ?
                    <p class="no-cnt">No tags found</p> 
                    : 
                    <div class="taglist">
                      {getHotSpot()}
                    </div>
                    }
                  </div>
                </div>
              </div>
                        
            </div>
          </div>
        </div>}
    
        <div className="panorama-wrap">
          {isParonamaIMGloaded && <div className="backBtn">
              <button className="btn btn-round" onClick={() => navigate(-1)}>
                <img src={Back} alt="back icon" />
              </button>
          </div>}
          
          <div
              className="panorama"
              ref={panoramaRef}
              style={{height: "calc(100vh - 67px)", width: "100%", minHeight: "230px"}}
              onMouseDown={(e)=>isEnableNewTag ? handleMouseDown(e): ()=>{}}
              onMouseUp={(e)=>isEnableNewTag ? handleMouseUp(e): ()=>{}}
              // onMouseDown={handleMouseDown}
              // onMouseUp={handleMouseUp}
          ></div>

         {isParonamaIMGloaded && <div className="addtag">
            {!isEnableNewTag? 
              <button className="btn btn-primary" onClick={()=>{
                  // handleMouseClick("ADING");
                  setIsEnableNewTag(true);
                  setActiveTagID("");
              }}>
                <img src={AddTag} className="add" alt="add icon" /> Add Tag
              </button> :
              <div>
                <span class="tagButtonInfo">Click on an object that you want to tag in the 360 Image.</span>
                <button className="btn btn-primary" onClick={async()=>{
                  setActiveTagID("");
                  setIsEnableNewTag(false);
                  let isNewAddedTagData= await localStorage.getItem("isNewAddedTagData");
                    const stringToObjIsNewAddedTagData = JSON.parse(isNewAddedTagData);
                    if(stringToObjIsNewAddedTagData!== null && stringToObjIsNewAddedTagData.newAddedID){
                      viewer.removeHotSpot(stringToObjIsNewAddedTagData.newAddedID);
                      setPreviousHSiD("");
                      localStorage.setItem("isNewAddedTagData", JSON.stringify({
                        newAddedID: ""
                      }));
                      // addShowHotspot(createTooltipArgs);
                    }
                  let isEditableTagData= await localStorage.getItem("isEditableTagData");
                    const stringToObjIsEditableTagData = JSON.parse(isEditableTagData);
                    if(stringToObjIsEditableTagData!== null && stringToObjIsEditableTagData.isEditableTagOpen){
                      console.log("createTooltipArgscreateTooltipArgs 1", stringToObjIsEditableTagData);
                      // REMOVE TEMPORARY EDIT HOT-SPOT
                      viewer.removeHotSpot(stringToObjIsEditableTagData.previouseEditableTagID);
                      // SHOW HOTSPOT IN REPLACE OF PREVIOUSE TEMPORARY EDIT HOT-SPOT
                      addShowHotspot(stringToObjIsEditableTagData.pitchYaw);
                    }
              }}>
              <img src={AddTag} className="cancel" alt="add icon" /> Cancel
                </button>
              </div>
            }
          </div>}
        </div>
      </div>
    </React.Fragment>
  );

}

export default PenneliumReactOne;