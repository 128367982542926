import React, { useEffect } from "react";
import './index.css';
import { NavLink } from "react-router-dom";
import {useLocation, useNavigate} from "react-router-dom";
import Logo from './../../../ASSETS/Images/Recuerdo_1x.png';
import loginBackground from './../../../ASSETS/Images/Background_image.jpg';
import verifyEmail from './../../../ASSETS/Images/verify_email.png';
import { toast } from 'react-toastify';
import {resendEmailOnConfirmUser} from './../../../UTILITYS/endPoints'
import { useDispatch } from 'react-redux'

function ConfirmUser(props) {

    let { state } = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(()=>{
      window.scrollTo(0, 0);
      if(state === undefined || state === null || state.email === undefined ){
        return navigate('/')
      }else{
        console.log('this is confirmation props', state);
        ( state.isNotVerified != undefined && state.isNotVerified) && applyResendEmailOnConfirmUser()
      }
    }, [])

    const applyResendEmailOnConfirmUser = async(e)=>{
      e!= undefined && e.preventDefault();
      try{
        let data = {
          email: state.email
        }
        await resendEmailOnConfirmUser(data, dispatch);
      }catch (err){
        if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        console.log('this is catch applyResetPassword', err)
      }

    }

    return (
      <React.Fragment>
        <div className="container-fluid login-container">
          <div className="row">
              <div className="col-md-6 login-content d-none d-md-flex" style={{backgroundImage: `url(`+loginBackground+`)` }}>
                  <h4 className="text-light text-center">"Love your parents. We are so busy growing up; we often forget that they are also growing old." - Unknown</h4>
              </div>
              <div className="col-md-6 login-form">
                  
                  <div className="text-center mb-3 mt-3">
                    <img src={Logo} alt="Logo" className="mb-5" />
                    
                    <div className="email-verification pb-5">
                      <img src={verifyEmail} alt="Logo" className="mb-3" /> 
                      <h4 className="mb-3">Verify your email address</h4>
                      <p>Almost there! An email containing verification instructions was sent to <a href="mailto:xerxes@gmail.com">{state!=undefined && state.email!= undefined && state.email}</a></p>
                    
                      <div className="mt-4 pt-2">
                        <p className="mb-0">Didn't receive the email? <a href="#" onClick={applyResendEmailOnConfirmUser}>Resend email</a></p>
                        <hr />
                        <p className="mb-0">Already have an account? <NavLink to="/login"><u>Login</u></NavLink></p>
                      </div>
                    </div>
                  </div>
                   

                  {/* copyright content  */} 
                  <div className="text-center copyright">
                    <small>Copyright 2024 © Recuerdo All Rights Reserved</small>
                  </div>
              </div>
              
          </div>
      </div>
      </React.Fragment>
    );
  }
  
  export default ConfirmUser;