import React, { useEffect, useState, useContext } from "react";
import { 
  useSelector, 
  useDispatch } from 'react-redux'
import { loginDispatch, setSessionData } from "./slice.reducer";
import { useNavigate } from 'react-router-dom'
import './index.css';
import { NavLink, useLocation } from "react-router-dom";

// import Logo from './../../../ASSETS/Images/Recuerdo_1x.png';
import Eye from './../../../ASSETS/Images/eye.png';
import EyeOff from './../../../ASSETS/Images/eye-off.png';
import EyeOpen from './../../../ASSETS/Images/eye.png';
import loginBackground from './../../../ASSETS/Images/Background_image.jpg';
import validator from 'validator'; 
import Dashboard from "./../../Dashboard";

import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Logo from './../../../COMPONENTS/Logo'
import classNames from 'classnames';
import Container from './../index.container'
import ButtonContainer from '../../../COMPONENTS/Button/buttonContainer'
import Button from '../../../COMPONENTS/Button/Button'
import CopyRight from "../../../COMPONENTS/CopyRight";
import { fetchUserDetailsDispatch } from "../../Profile/slice.reducer.tsx";
import { setToggleForExpandAndColleps } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import CreateTokenContextProvider from "../../../STORE/App-wide-state/Token/createTokenContextProvider";
import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer.js";

function Login() {

  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const navigate = useNavigate()
  const [isLogedIn, setIsLogedIn] = useState(false)
  const [isLoginButtonClicked, setisLoginButtonClicked] = useState(false)
  const localSessionDetails = useSelector(state => state.localSessionDetails)
  // console.log('this is our login submit data', localSessionDetails)
  const [isPasswordMaskedOPen, setIsPasswordMaskedOPen] = useState(true)
  const [emailErr, setEmailErr] = useState(false)
  const [passwordErr, setPasswordErr] = useState(false)
  const location = useLocation();
  const {state} = useLocation();
  const tokenContext = useContext(CreateTokenContextProvider);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const onInputChange = (e)=>{
    
    switch(e.target.name){
      case "email":
        setEmail(e.target.value);
        setEmailErr(false)
        break;
      case "password":
        setPassword(e.target.value);
        setPasswordErr(false)
        break;
      default:
        setPassword(e.target.value);
        break;
    }
  }

  const login = (e)=>{
  
    // toast.clearWaitingQueue()
    toast.dismiss()
    e.preventDefault();
    toast.dismiss()
    
    if(!validator.isEmail(email.trim())){
      // setEmailErr(true)
      if(email === "") toast.error("Please Enter Email", { });
      else toast.error("Please Enter valid Email", { });
      setEmailErr(true)
      return
    }
    // if(!validator.isLength(password, {min: 8, max: 14})){
    //   // setPasswordErr(true)
    //   if(password === "") toast.error("Please Enter Password", { });
    //   else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
    //   setPasswordErr(true)
    //   return;
    // }
    
    dispatch(loginDispatch({email: email.trim(), password: password}, navigate, email)); 
    setisLoginButtonClicked(true);

  }

  const navigateToForgetPassword = () =>{
    navigate('/forgetPassword');
  }

  // AFTER RELOAD, if local-storage not emply 
  useEffect(()=>{
    // console.log('this is our login submit data', isLogedIn)
    
    async function setStoreData(){
      
      if(localSessionDetails.payload === ""){
        const data = await JSON.parse(localStorage.getItem('data'))
        // console.log("this is a login useEffect", localSessionDetails, data)
        if(data != undefined && data.payload!= undefined && data.payload!= ""){
          // alert()
          dispatch(setSessionData(data));
          setIsLogedIn(true);
          tokenContext.addTokens(data.payload.accessToken, data.payload.refreshToken);
          setTimeout(() => {
            console.log('this is our login submit data data.payload 1', data);
            dispatch(fetchUserDetailsDispatch()); 
          }, 0);
        }else{
          dispatch(LoaderForIndividualPageDispatch(false));
        } 
      }else if(localSessionDetails.payload !== ""){
        // alert()
        setIsLogedIn(true);
        tokenContext.addTokens(localSessionDetails.payload.accessToken, localSessionDetails.payload.refreshToken);
        console.log('this is our login submit data data.payload 2', localSessionDetails.payload);
      }else if(localSessionDetails.isLoginFaild){
        // alert()
        setIsLogedIn(false);  
      }else{ 
        setIsLogedIn(false);  
      }
 
    }
    setStoreData()
    console.log("this is a app useEffect", localSessionDetails)    

  },[localSessionDetails.payload, localSessionDetails.isLoginFaild, dispatch])

  if(isLogedIn){
    if(isLoginButtonClicked){
      return navigate('/homePage');
    }
    if( state != undefined && state.currentLocation){
      dispatch(setToggleForExpandAndColleps(true))
      return navigate(state.currentLocation, {state: location.state.state});
    }
    return navigate('/homePage');
  } 

  return (
  <React.Fragment>
    <Container>      
      <div className="text-center mb-4 mt-3">
          <Logo logoClass="mb-5" />
          <h4>Login</h4>
      </div>
      <form className="pb-5" onSubmit={login}>
        <div className="mb-3">
            <input type={'text'} className={classNames("form-control", { "form-error": emailErr })} name={'email'} placeholder="Email" value={email}
                onChange={onInputChange}  />
        </div>
        <div className="mb-1 password-field">
            <input type={isPasswordMaskedOPen ?'password': "Text"} name={'password'} placeholder="Password" value={password}
                onChange={onInputChange} className={classNames("form-control", { "form-error": passwordErr })} />
            <span onClick={()=>setIsPasswordMaskedOPen(!isPasswordMaskedOPen)}>
                <img src={isPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                {/* <img src={EyeOff} className="d-none" alt="Eye" /> */}
            </span>
        </div>
        <div className="text-end">
            <a href="#" className="secondary" onClick={navigateToForgetPassword}>Forgot Password?</a>
        </div>
        <ButtonContainer 
          containerClacess = {["mb-4", "mt-4"]}
          render={() => <Button lable ="Login" type="submit" buttonClassess = {["btn", "btn-primary", "w-100"]}/>}
        />
        <div className="text-center">
            Don't have an account? <NavLink to="/registration"><u>Sign Up</u></NavLink>
        </div>
      </form>
      <CopyRight copyRightClassess = {["text-center", "copyright"]} />
    </Container>
  </React.Fragment>
  );
}
  
  export default Login;