import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    virtualTourName: "",
    isVirtourNameEmpty: false 
} 

export const threeSixtyVirtualImageOnUpload = createSlice({
  name: 'threeSixtyVirtualImageOnUpload',
  initialState: { ...initialState },
  reducers: {
    setVirTourName: (state, action) => {
      return {...state, virtualTourName: action.payload}
    },
    resetThreeSixtyVirtualTour: (state, action) => {
        return { ...initialState }
    },
    setIsVirTourNameEmpty: (state, action) => {
      console.log("setIsVirTourNameEmptysetIsVirTourNameEmpty 5", action);
      return {...state, isVirtourNameEmpty: action.payload}
    },
  }
})

export const { setVirTourName,
  resetThreeSixtyVirtualTour,
  setIsVirTourNameEmpty } = threeSixtyVirtualImageOnUpload.actions;

export const setVirTourNameDispatch = (data) => {
  return async(dispatch, getState) => {
    dispatch(setVirTourName(data.virtualTourName));
  }
}

export const resetThreeSixtyVirtualTourDispatch = (data) => {
  return async(dispatch) => { 
    dispatch(resetThreeSixtyVirtualTour(data));
  }
}

export const setIsVirTourNameEmptyDispatch = (data) => {
  console.log("threeSixtyVirtualTourthreeSixtyVirtualTour 6", data)
  return async(dispatch) => {
    dispatch(setIsVirTourNameEmpty(data));
  }
}


export default threeSixtyVirtualImageOnUpload.reducer