import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import AnimatedProgressBar from "../AnimatedProgressBar";
import { easeQuadInOut } from "d3-ease";

export default  (props)=>{ 

    
    return (
        <React.Fragment>
            <div className="Counter_item w-60">
                <div style={{ width: 65, height: 65 }}>

                    <AnimatedProgressBar 
                        valueStart={0}
                        valueEnd={props.totalUsedStorage}
                        duration={1.4}
                        easingFunction={easeQuadInOut}
                    />

                </div>
            </div>
        </React.Fragment>
    )
}