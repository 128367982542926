import React, { useEffect, useState } from "react";
import Profile from ".";
import Arrow from './../../ASSETS/Images/icons/arrow_1.png';
import Upload from './../../ASSETS/Images/icons/upload.png';
import Delete from './../../ASSETS/Images/icons/deleteWhite.png'; 
import { NavLink } from "react-router-dom";
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import Edit from './../../ASSETS/Images/edit.png';
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux'  
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { useAppSelector, useAppDispatch } from './../../UTILITYS/hook/typeVersionOfredux.tsx'
import userProfileModal from './../../UTILITYS/Modals/userProfile.modal.tsx'

type allProps = {};  

type componentType= JSX.Element;

export default (props: allProps): componentType=>{
    const [modal, setModal] = useState<boolean>(false);
    
    // const userProfileDetails = useSelector(state: RootState => state.userProfileDetails)
    const userProfileDetails = useAppSelector(state => state.userProfileDetails)
    const [userDetails, setUserDetails] = useState<userProfileModal>();
    console.log("this is a usesrprofile useEffect", userProfileDetails, userDetails)
    
    const toggle: ()=> void = () => setModal(!modal);
    // const dispatch = useDispatch();
    const dispatch = useAppDispatch()

    useEffect(()=>{
        dispatch(setModuleActiveDispatch("profile"))
        dispatch(setParentModuleActiveDispatch("parentProfile"))
    }, []); 

    useEffect(()=>{
        setUserDetails(userProfileDetails) 
    }, [userProfileDetails]);

    return (
        <React.Fragment>
            <Profile>
                {/* content header - Breadcrumb */}
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    <div className="pageTitle">
                        <Row>
                            <Col xs="6">
                                <h5>Profile</h5>
                            </Col>
                            <div className="col-6 text-end">
                                <NavLink to="/editProfile" className="btn btn-primary me-0 me-md-3 mt-0 mt-md-1">Edit Profile</NavLink>
                            </div>
                        </Row>
                    </div>

                    {/* Profile content  */}
                    <div className="ps-3 pe-3 pt-3 profile-cont">
                        <div className="row mb-0 mb-md-2 text-center text-md-start">
                            <div className="d-md-flex col-md-12 col-lg-12">
                                <div className="profile-large ms-auto ms-md-0 me-auto me-md-0">
                                    <img src={userDetails?.profilePicPath!=""?userDetails?.profilePicPath: ProfileImage} alt="profile" className="img-thumbnail" />
                                    {/* <img src={Edit} alt="profile" className="edit_profile" onClick={toggle} /> */}
                                </div>
                                <div className="ps-0 ps-md-4 ms-0 ms-md-2 pt-4 pt-md-0">
                                    <h4 className="userName">{userDetails?.firstName} {userDetails?.lastName}</h4>
                                    <p>{userDetails?.email}</p>
                                </div>
                            </div>
                        </div> 
                        <div className="row pt-2 pt-md-4 userInfo">
                            <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                <small>First Name</small>
                                <h5>{userDetails?.firstName}</h5>
                            </div>
                            <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                <small>Last Name</small>
                                <h5>{userDetails?.lastName}</h5>
                            </div>
                            <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                <small>Mobile</small>
                                <h5>{userDetails?.mobile}</h5>
                            </div>
                            <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                <small>City</small>
                                <h5>{userDetails?.city}</h5>
                            </div>
                            <div className="col-md-6 col-lg-5 pt-3 mb-0 mb-md-3">
                                <small>Country</small>
                                <h5>{userDetails?.countryCode}</h5>
                            </div>
                        </div>
                    </div>
                </div>
                {/* upload profile modal start  */}
                <Modal isOpen={modal} centered className="modal-md" toggle={toggle} {...props}>
                    <ModalHeader className="text-center" toggle={toggle}>Update profile picture</ModalHeader>
                    <ModalBody className="mb-2">
                        <div className="profile-large ms-auto me-auto">
                            <img src={ProfileImage} alt="profile" className="img-thumbnail" />
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button className="me-2 me-md-3" color="primary" onClick={toggle}>
                            <img src={Delete} alt="Remove" /> Remove
                        </Button>
                        <Button className="ms-2 ms-md-3" color="primary" onClick={toggle}>
                            <img src={Upload} alt="Upload" /> Upload Image
                        </Button>
                    </ModalFooter>
                </Modal>
                {/* upload profile modal end */}

            </Profile>
        </React.Fragment> as JSX.Element
    )
}