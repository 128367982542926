import React, { useState, useEffect } from "react";
import Home from "..";
import { useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchVirtualTourDispatch, updateVirtualTourDispatch } from "../Slices/VirtualTour.slice";
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ImagesIcon from './../../../ASSETS/Images/defaultProfilePic.jpg';
import AddVirtualTourButton from "../../../COMPONENTS/360VirtualTour/AddVirtualTourButton"; 
import Edit from './../../../ASSETS/Images/icons/edit.png';
import { toast } from 'react-toastify';
import validator from 'validator';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import classNames from 'classnames';
import { Button, Modal, ModalBody, FormGroup, Input, ModalFooter } from 'reactstrap';
import defaultThreeSixty from './../../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';
import { setDoNotShowExpiredModal } from "../../Profile/slice.reducer";
import { ExpiredModal } from "../../../COMPONENTS/Modal/Expired";

let visitPageFirstTime= true;

export default ()=>{

    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const virtualTours = useSelector(state => state.virtualTours);
    const [loadVirtualTour, setLoadVirtualTour] = useState(1);
    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const [modal, setModal] = useState(false);
    const [updatedVirtualTourName, setupdatedVirtualTourName] = useState('');
    const [updatedVirtualTourNameErr, setupdatedvirtualTourNameErr] = useState(false);
    const [updatedVirtualTourNameDATA, setupdatedVirtualTourNameDATA] = useState({})

    const closeUpdatedModal = () => {
        setupdatedVirtualTourNameDATA({})
        setModal(!modal);
    }

    useEffect(()=>{
        visitPageFirstTime= true;
    }, [])

    useEffect(()=>{
        if(userProfileDetails.isUserFetch && !visitPageFirstTime ){
            dispatch(fetchVirtualTourDispatch(loadVirtualTour, 25))
        }
        visitPageFirstTime= false;
    },[loadVirtualTour, userProfileDetails.isUserFetch])  

    const fetchMoreData = () => {
        setLoadVirtualTour(loadVirtualTour+1) 
    };

    const onNavigate = (tour) => {
        // navigate('/vr-rooms-list', {state: tour});
        
        navigate('/virtual-tour-images', {state: tour})
    } 

    const getVirtualList = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={virtualTours.tour}
                    next={fetchMoreData}
                    hasMore={virtualTours.setHasMoreVirtualTour}
                    loader={virtualTours.tour.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    } 
                >
                {getVirtualTours()}
            </InfiniteScroll>
       
     }

    const getVirtualTours = ()=>{ 
        return virtualTours.tour !=undefined && virtualTours.tour.length >0 && virtualTours.tour.map((tour, index)=>{     
            return getVirtualTour(tour)
        })
            
    }
    const getVirtualTour = (tour)=>{ 
        return <Col sm="6" md="4" lg="4" onClick={()=>onNavigate(tour)}>
                <Card className="my-2 cursor-pointer" >
                    <CardBody className="p-0 text-center">
                        <img src={tour.thumbnailUrl? tour.thumbnailUrl: defaultThreeSixty} alt="image" className="assets_image" />
                    </CardBody>
                    <CardFooter> 

                    <Row > 
                        <Col col={11}>
                            <p className="mb-0 lead">{tour.name}</p>
                        </Col>
                        <Col col={1} className="align-end" onClick={(e)=>{
                            e.stopPropagation()

                            if(userProfileDetails.isExpired ){
                                toast.dismiss()
                                dispatch(setDoNotShowExpiredModal(false));
                                // setOpenUpgrade(true);
                                toast("Your subscription has been expired");
                                return;
                            }
                    
                            getUpdateTour(tour)
                        }}>
                            <img src={Edit} alt="Images"  />
                        </Col>
                    </Row> 

                    </CardFooter>
                </Card>
            </Col>

    }

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "virtual-tour":
                setupdatedVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setupdatedvirtualTourNameErr(false)
            break;
        }
    }
     
    const getUpdateTour = (tour)=>{ 
        setupdatedVirtualTourNameDATA(tour);
        setupdatedVirtualTourName(tour.name);
        setModal(!modal);
    }

    const updateTour= async()=>{
        
        toast.dismiss();

        if(validator.isEmpty(updatedVirtualTourName.trim())){
            toast.error("Please Enter Tour Name", { });
            setupdatedvirtualTourNameErr(true)
            return;
        }else if(!validator.isLength(updatedVirtualTourName.trim(), {min: 1, max: 25})){
            toast.error("Tour Name length should be minimum 3 char and maximum 25 char", { });
            setupdatedvirtualTourNameErr(true)
            return;
        }

        let mappingData = {
            name: updatedVirtualTourName,
            id: updatedVirtualTourNameDATA.id 
        }

        try{
            console.log('object', mappingData)
            dispatch(updateVirtualTourDispatch(mappingData));
            closeUpdatedModal()
          }catch (err){ 
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          }

    }

    return (
        <React.Fragment>
            <Home>
            <ExpiredModal />
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>Virtual Tour</h5> 
                            </Col>
                        </Row>
                    </div>
                    <div className="userCard virtual-tour-and-img">
                       {getVirtualList()} 
                    </div>
                </div>

                {/* ADD VIRTUAL-TOUR BUTTON */}
                <AddVirtualTourButton /> 

                {/* UPDATED VIRTUAL-TOUR MODAL */}
                <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
                    closeUpdatedModal()
                    }} >
                    <Button className="modalClose" onClick={()=>{
                        closeUpdatedModal()
                    }}>
                        <img src={Close} alt="Images" className="img-fluid" />
                    </Button>
                    <ModalBody>
                        <div className="share_people p-2">
                            <div class="row">
                                <div className="col-12">
                                    <div>Rename</div>
                                    <FormGroup>
                                        <input value={updatedVirtualTourName} type="text" placeholder="Enter your virtual tour name"
                                        className={classNames("form-control", { "form-error": updatedVirtualTourNameErr })} name={'virtual-tour'} onChange={onInputChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 text-center mt-4">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={updateTour}
                                    >Save</button>
                                </div>
                            </div>
                        </div> 
                    </ModalBody>
                </Modal>

            </Home>
        </React.Fragment>
    )
}