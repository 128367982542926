import React, {useEffect, useState} from "react";
import HeaderSideBar from ".";
import { hamburgurDispatch } from "../../STORE/CommonSlices/hameBurgerOnOff";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import CustomeCard from "../../COMPONENTS/CustomeCard";
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import {fetchVrUsersDispatch, resetVRusers} from './../VR-experience/Slices/VRexperienceUser.slice';
import PinContents from "./PinContents";
import NoContentWithOutUploadPage from "../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import { NO_VR_USER_DESCRIPTION_TEXT, NO_VR_USER_PRIMARY_TEXT } from "../../UTILITYS/Constant";
import Asset from './../../ASSETS/Images/icons/VRAsset.png';
import Breadcrum from "../../COMPONENTS/Breadcrum";
import { Row, Col } from 'reactstrap';

export default (props)=>{
   
    const dispatch = useDispatch();
    const vrPins = useSelector(state => state.vrUsers);
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isPinListLenghtEmply, setIsPinListLenghtEmply] = useState(true);

    useEffect(()=>{
        if(vrPins.users.length === 0 ){ 
            setIsPinListLenghtEmply(true)
        }else{
            setIsPinListLenghtEmply(false)
        }
    }, [vrPins.users.length])

    console.log("LoaderForIndividualPageDispatchLoaderForIndividualPageDispatch 5")

    useEffect(()=>{
        dispatch(resetVRusers());
        dispatch(setModuleActiveDispatch("pinManagement"))
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))

        return ()=>{
            console.log("LoaderForIndividualPageDispatchLoaderForIndividualPageDispatch 3")
            dispatch(resetVRusers()); 
        }
    }, []); 

    useEffect(()=>{
        dispatch(fetchVrUsersDispatch(loadEventIndex, 25, "pinManagment"))
    },[loadEventIndex]); 
    
    const fetchMoreData = () => {
        seLoadEventIndex(loadEventIndex+1) 
    };

    const getVRpinsList = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={vrPins.users}
                    next={fetchMoreData}
                    hasMore={vrPins.setHasMoreVRUser}
                    loader={vrPins.users.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    }
                >
                {getPins()}
            </InfiniteScroll>
       
     }

     const getPins = ()=>{ 

        return vrPins.users !=undefined && vrPins.users.length >0 && vrPins.users.map((pin, index)=>{    
            return <CustomeCard>
            {getContent(pin)}
        </CustomeCard>
         
        }) 
    }
 
    const getContent =(pin)=>  {
        console.log('this is getContentgetContent', pin)
        return <PinContents pin={pin} />
    }

    if(isPinListLenghtEmply){
        return <HeaderSideBar><NoContentWithOutUploadPage 
                    primaryText= {NO_VR_USER_PRIMARY_TEXT}
                    description= {NO_VR_USER_DESCRIPTION_TEXT}
                    breadCrumbItems= {["PIN Management"]}
                    noImgIcon= {Asset}
                 /></HeaderSideBar>
     }

    return ( 
        <React.Fragment>
            <HeaderSideBar>
                {/* <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                            <Breadcrum 
                                breadCrumbItems= {["PIN Management"]}
                            />
                    </div>
                </div> */}
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>Pin Management</h5>
                            </Col>
                        </Row>
                    </div>
                    <div className="pin-management">
                        <div className="row">
                            {getVRpinsList()}
                        </div>
                    </div>
                </div>
            </HeaderSideBar>
        </React.Fragment>
    )
}