import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import HeaderSideBar from "./../../index";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'reactstrap';
import NoAssests from "../../../../COMPONENTS/NoAssests/index.tsx";
import AudioCardList from "../../../../COMPONENTS/AssestsAndList/Audios/AudioCardList";
import VideosCardList from "../../../../COMPONENTS/AssestsAndList/Videos/VideosCardList";
import { getImgURL } from "../../../../UTILITYS/index.tsx";
import ImagesCardList from "../../../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import { VRfetchAudioDispatch, VRfetchImagesDispatch, VRfetchVideoDispatch, VRresetAssests } from "../../Slices/VRassestList.slice";
import NoContentWithAddassestButton from "../../../../COMPONENTS/NoContentPage/NoContentWithAddassestButton";
import { EMPTY_AR_ASSEST_DES, EMPTY_ASSEST_PRIMARY_TEXT } from "../../../../UTILITYS/Constant";
import Asset from './../../../../ASSETS/Images/icons/Asset_icon.png';
import VRaddAssestModal from "../../../../COMPONENTS/VRaddAssestModal";
import BG from './../../../../ASSETS/Images/BG.jpg';
import MobileBG from './../../../../ASSETS/Images/Mobile-BG.jpg';
import Loader from "../../../../COMPONENTS/Loader";
import { LoaderForIndividualPageDispatch } from "../../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { setParentModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeModule.slice";
import SendEmail from './../../InviteContributor/SendEmail'
import Close from './../../../../ASSETS/Images/icons/cancel.png';
import { Modal, ModalBody, Button } from 'reactstrap';

export default (props)=>{

    let { state } = useLocation();
    const dispatch = useDispatch();
    const [isAnyAssestShare, setIsAnyAssestShare] = useState(false);
    const [room, setRoom] = useState(state.room);
    const [vrUser, setVrUser] = useState(state.vrUser);
    const VRassestList = useSelector(state => state.VRassestList);
    const [modal, setModal] = useState(false);

    console.log('`this is deata commmie  f af 1`', room, vrUser);

    const toggle = () => {
        setModal(!modal);
    }

    // TO GET YOUR ASSEST THAT YOU UPLOADED
    const fetchAssests = ()=>{
        
        
        dispatch(LoaderForIndividualPageDispatch(true))
        dispatch(VRresetAssests())
        dispatch(VRfetchImagesDispatch(1, 4,  
            room.relationshipID,
            room.vrRoomMappingID
        ))
        dispatch(VRfetchAudioDispatch(1, 4, 
            room.relationshipID,
            room.vrRoomMappingID
        ))
        dispatch(VRfetchVideoDispatch(1, 4, 
            room.relationshipID,
            room.vrRoomMappingID 
        ))
    }
   
    useEffect(()=>{
        fetchAssests();
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("vr_Experience"))
    }, [dispatch]);

    useEffect(()=>{
        if(VRassestList.isImgResGet && VRassestList.isAudioResGet && VRassestList.isVideoResGet ){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [VRassestList.isImgResGet, VRassestList.isAudioResGet, VRassestList.isVideoResGet]);

    useEffect(()=>{
        if(VRassestList.images.length === 0 && VRassestList.videos.length === 0 && VRassestList.audios.length === 0 && VRassestList.isImgResGet && VRassestList.isAudioResGet && VRassestList.isVideoResGet ){
            setIsAnyAssestShare(true)
        }else{
            setIsAnyAssestShare(false)
        }
    }, [VRassestList.images.length, VRassestList.videos.length, VRassestList.audios.length, VRassestList.isImgResGet, VRassestList.isAudioResGet, VRassestList.isVideoResGet])
    

    const getCheckBoxClasses = (id)=>{
        return "my-1";
    }
    const toggleSelectBox = (id)=>{
        return; 
    } 

    const inviteContributorModal = ()=>{
        {/* MODAL TO INVITE CONTRIBUTOR TO SPECIFIC TOUR */}
        return <Modal isOpen={modal} centered className="drop-modal modal-lg medium" toggle={()=>{
            toggle()
            }} {...props}>
                <Button className="modalClose" onClick={()=>{
                    toggle()
                }}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    <SendEmail
                        toggle={toggle}
                        room= {room}
                        vrUser= {vrUser}
                    />
                </ModalBody>
            </Modal>
    }

    if(isAnyAssestShare){
        return <HeaderSideBar><NoContentWithAddassestButton     
                primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                description= {EMPTY_AR_ASSEST_DES} 
                breadCrumbItems= {["Asset Library", "Audios" ]}
                noImgIcon= {Asset}
                vrUser = {vrUser} 
                room = {room}
                fetchAssests = {fetchAssests}
                toggleInviteContributors= {toggle}
         />{inviteContributorModal()}</HeaderSideBar>
    }

    return ( 
        <React.Fragment>
            <HeaderSideBar>
            <div className="pageTitle mt-3">
                <div className="imageHeader">
                    <img src={BG} alt="banner" className="d-none d-md-block w-100" />
                    <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                    <h3>{vrUser.name} ({room.name})</h3>
                </div>
            </div>
            
            <div className="sharedFF ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                {/* Your Library  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Images</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/vr-images-list" 
                            state={{ isShareByMe: true, room, vrUser}}
                        >See All</NavLink>
                        </span>
                    </div>
                    {( VRassestList.images.length === 0) ? <NoAssests />: 
                        <Row className="ps-2 pe-2">{VRassestList.images.map((img, index)=>{
                            return (
                                <ImagesCardList img={img} index ={index} key ={index} isFF={true} assests= {VRassestList.images}
                                getCheckBoxClasses ={()=>getCheckBoxClasses(img.id)}
                                toggleSelectBox ={()=>toggleSelectBox(img.id)}
                                isHideCheckBox= {true}
                                />
                            )
                    })} </Row>}  
                </div>

                {/* Share with others  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Videos</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/vr-videos-list"
                            state={{ isShareByMe: true, room, vrUser}}
                        >See All</NavLink>
                        </span>
                    </div> 
                    {(VRassestList.videos.length === 0 ) ? <NoAssests />: 
                    <Row className="ps-2 pe-2">{VRassestList.videos.map((video, index)=>{
                        return (
                            <VideosCardList 
                                video={video} 
                                key={index} 
                                isFF={true}
                                getCheckBoxClasses ={()=>getCheckBoxClasses(video.id)}
                                toggleSelectBox ={()=>toggleSelectBox(video.id)}
                            />
                        )
                    })}</Row>}     
                </div>

                {/* Share with me  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Audios</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/vr-audios-list"
                            state={{ isShareByMe: true, room, vrUser}}
                        >See All</NavLink>
                        </span> 
                    </div>
                    {(VRassestList.audios.length === 0 ) ? <NoAssests />: 
                        <Row className="ps-2 pe-2">{VRassestList.audios.map((audio, index)=>{
                            return (
                                <AudioCardList 
                                    audio={audio} 
                                    key={index} 
                                    isFF={true}
                                    getCheckBoxClasses ={()=>getCheckBoxClasses(audio.id)}
                                    toggleSelectBox ={()=>toggleSelectBox(audio.id)}
                                />
                            )
                    })}</Row>}
                </div> 
            </div>
         
           {
           (VRassestList.images.length !== 0 || VRassestList.videos.length !== 0 || VRassestList.audios.length !== 0) && VRassestList.isImgResGet && VRassestList.isAudioResGet && VRassestList.isVideoResGet &&
            <VRaddAssestModal 
                fetchAssests ={fetchAssests}
                vrUser = {vrUser} 
                room = {room}
                noMeSe = {false}
                toggleInviteContributors= {toggle}
            />
            }

            {inviteContributorModal()}
        </HeaderSideBar>
        </React.Fragment>
    )
}