import React, {useState, useEffect } from "react";
import HeaderSideBar from "..";
import { Row } from 'reactstrap';
// import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyAssest from "../../Home/EmptyAssest";
import VideosCardList from "../../../COMPONENTS/AssestsAndList/Videos/VideosCardList";
import CustomeBreadcrumb from "../../../COMPONENTS/CustomeBreadcrumb";
import { resetSharedAssestWithMeOrBy, fetchVideoSharedBymeDispatch,fetchVideoSharedByThisUserDispatch } from "../slice.reducer";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../../UTILITYS/Constant";
import NoContents from "../../../COMPONENTS/NoContentPage/NoContents";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import Breadcrum from "../../../COMPONENTS/Breadcrum";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import {sharedAssestWithMeOrByInterface, sharedByThisUserInterface, sharedBymeInterFace} from "./../../../UTILITYS/Modals/slicese/sharedAssestWithMeOrBy.modal.ts";

export default ()=>{

    const dispatch = useAppDispatch();   
    let {state}  = useLocation();
    const [isVidioEmpty, setIsVideoEmpty] = useState<boolean>(true);
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const sharedAssestWithMeOrBy: sharedAssestWithMeOrByInterface = useAppSelector(state => state.sharedAssestWithMeOrBy) as sharedAssestWithMeOrByInterface;
    const [userData, setUserData] = useState(state)

    let assestsInfo: sharedByThisUserInterface| sharedBymeInterFace = null;

    if(userData.isShareByMe){
        assestsInfo= sharedAssestWithMeOrBy.sharedByme;
    }else{
        assestsInfo= sharedAssestWithMeOrBy.sharedByThisUser;
    }
    
    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        dispatch(resetSharedAssestWithMeOrBy({}))
        if(!userData.isShareByMe){
            dispatch(setModuleActiveDispatch("shared_with_me"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }else{
            dispatch(setModuleActiveDispatch("shared_with_others"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }
    }, [dispatch])
 
    useEffect(()=>{
        if(userData.isShareByMe){
            // alert()
            dispatch(fetchVideoSharedBymeDispatch(loadEventIndex, 25, userData.userID)) 
        }else{
            dispatch(fetchVideoSharedByThisUserDispatch(loadEventIndex, 25, userData.userID)) 
        }
    },[loadEventIndex])

    useEffect(()=>{
        if( assestsInfo?.videos?.length === 0){
            setIsVideoEmpty(true)
        }else{
            setIsVideoEmpty(false)
        }
    }, [assestsInfo?.videos])

    const fetchMoreData: () => void = () => {
        seLoadEventIndex(loadEventIndex+1)
    };

    const getCheckBoxClasses: () => string = ()=>{
        return "my-1";
    }
    const toggleSelectBox: () => void = ()=>{
        return;
    }

    const getVideosAssestsList:() => JSX.Element = ()=>{
    
       return  <InfiniteScroll
                    className="row"
                    dataLength={assestsInfo?.videos?.length as number}
                    next={fetchMoreData}
                    hasMore={assestsInfo?.hasMoreVediosAssests as boolean}
                    loader={assestsInfo?.videos && assestsInfo?.videos?.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
               >
               {getVideossAssest()}
           </InfiniteScroll> as JSX.Element
    
      
    }

    const getVideossAssest: () => JSX.Element[] = ()=>{
        return assestsInfo?.videos?.map((video, index)=>{
            return <VideosCardList 
                video={video} 
                key ={index} 
                isFF={true}
                getCheckBoxClasses ={()=>getCheckBoxClasses()}
                toggleSelectBox ={()=>toggleSelectBox()}
            />
        }) as JSX.Element[]
    } 

    if(isVidioEmpty){

        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Videos"]: ["Asset Library", "Shared with me", "Videos"]}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar> as JSX.Element

    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                        <Breadcrum 
                            breadCrumbItems= {userData.isShareByMe ?["Asset Library", "Shared with others", "Videos"]: ["Asset Library", "Shared with me", "Videos"]}
                        />
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4">
                    
                    <div className="assets_item ps-2 pe-2">
                        <Row>
                            {getVideosAssestsList()}
                        </Row>
                    </div>
                </div>

            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}