import React from 'react';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

export default ({assests, index, hasLeftButton, hasRightButton}) => {

  const images = assests.map((assest) => { 
    return{src: assest.fIleUrl}
  });

  return (
    <Carousel 
      images={images} 
      style={{ height: 500, width: 800 }} 
      index ={index}
      hasLeftButton ={hasLeftButton}
      hasRightButton ={hasRightButton}
    />
  );
};