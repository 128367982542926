import React, {useEffect} from "react";
import Dashboard from "../Dashboard";

export default (props)=>{

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
            <Dashboard>
                {props.children}
            </Dashboard>
        </React.Fragment>
    )
} 