import React, { useState, useEffect } from "react";
import HeaderSideBar from "..";
import { NavLink, useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
// import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import { fetchShareWithMeUserInfoDispatch, resetShareWithMeAndOthers } from "../../Home/slices/ShareWithMe";
import UserInfoCard from "../../../COMPONENTS/HOME/CustomeCard/UserInfoCard";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { hamburgurDispatch } from "../../../STORE/CommonSlices/hameBurgerOnOff";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import EmptyAssest from "../../Home/EmptyAssest";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT, EMPTY_ASSET_WITH_ME_USER_PRIMARY_TEXT, EMPTY_ASSET_WITH_ME_DESCRIPTION_TEXT } from "../../../UTILITYS/Constant";
import { random } from "../../../UTILITYS/index.tsx";
import NoContents from "../../../COMPONENTS/NoContentPage/NoContents";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import Breadcrum from "../../../COMPONENTS/Breadcrum";
import {shareWithMeAndOthersUsersInfoInterface, userInfo} from "./../../../UTILITYS/Modals/slicese/shareWithMeAndOthersUsersInfo.modal.ts";
 

export default ()=>{
 
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const shareWithMeAndOthers: shareWithMeAndOthersUsersInfoInterface = useAppSelector(state => state.shareWithMeAndOthers) as shareWithMeAndOthersUsersInfoInterface;
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isUserEmpty, setIsUserEmpty] = useState<boolean>(true);

    console.log("NoContentWithOutUploadPageNoContentWithOutUploadPage", shareWithMeAndOthers);

    useEffect(()=>{
        dispatch(resetShareWithMeAndOthers({}))
        // SET SIDE MENU HIGHLIGHTING
        dispatch(setModuleActiveDispatch("shared_with_me"))
        dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
    }, [dispatch]) 

    useEffect(()=>{
        dispatch(fetchShareWithMeUserInfoDispatch(loadEventIndex, 25))
    },[loadEventIndex])  

    const fetchMoreData: () => void = () => {
        seLoadEventIndex(loadEventIndex+1) 
    };

    useEffect(()=>{
        if( shareWithMeAndOthers?.sharedWithMeUserInfo?.length === 0){
            setIsUserEmpty(true)
        }else{
            setIsUserEmpty(false)
        }
    }, [shareWithMeAndOthers?.sharedWithMeUserInfo?.length])

    const onNavigate: (navigateTo: string, assest: userInfo) => void = (navigateTo: string, assest: userInfo) => { 
        console.log("onNavigateHandler >", navigateTo, assest)
        switch(navigateTo){
            case "sharedByUser":
                dispatch(setModuleActiveDispatch("shared_with_me"))
                navigate('/SharedByUser', {state: assest})
                break;
        }
    }

    const getImagesAssestsList: () => JSX.Element = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={shareWithMeAndOthers?.sharedWithMeUserInfo?.length as number}
                    next={fetchMoreData}
                    hasMore={shareWithMeAndOthers.hasMoreSharedWithMeUserInfo as boolean}
                    loader={shareWithMeAndOthers?.sharedWithMeUserInfo &&shareWithMeAndOthers?.sharedWithMeUserInfo?.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    }
                >
                {getImagesAssest()}
            </InfiniteScroll> as JSX.Element
       
     }

     const getImagesAssest = ()=>{ 

        return shareWithMeAndOthers.sharedWithMeUserInfo !=undefined && shareWithMeAndOthers.sharedWithMeUserInfo.length >0 && shareWithMeAndOthers.sharedWithMeUserInfo.map((assest, index)=>{
            return <UserInfoCard  
                        // tolltipID ={random(14)}
                        tolltipID ={"SWM"+index}
                        key ={index}
                        {...assest} 
                        sharedWithMeUserInfo= {true}
                        onNavigate = {()=>onNavigate("sharedByUser", assest)}
                        isFF={true}
                    />
        }) as JSX.Element[]
 
    }

    if(isUserEmpty){
        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        breadCrumbItems= {["Asset Library", "Shared with me"]}
                        primaryText= {EMPTY_ASSET_WITH_ME_USER_PRIMARY_TEXT}
                        description= {EMPTY_ASSET_WITH_ME_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar> as JSX.Element
    }

    return (
        <React.Fragment>
            <HeaderSideBar>

                <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                        <Breadcrum 
                            breadCrumbItems= {["Asset Library", "Shared with me"]}
                        />
                    </div>
                </div>
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="shared_item dashboard_card  pt-3">
                       {getImagesAssestsList()}
                    </div>
                </div>
            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}