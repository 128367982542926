import React from "react";
import {Card, CardBody, Col} from 'reactstrap';

export default (props)=>{
    console.log('props: ',)
    return (
        <React.Fragment>
            <Col xs={12} className={`${props.customClassName ? props.customClassName : 'col-sm-6 col-md-6 col-lg-6 col-xl-3'}`}>
                <Card className="pm-card my-2">
                    <CardBody className="">
                        {props.children}
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    )
}