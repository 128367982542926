import { createSlice } from '@reduxjs/toolkit'
import { getData } from './../../../UTILITYS/index.tsx';
import * as API from './../../../APIs'

const initialState = {
  romms: [],
  setHasMoreVRrooms: true,
  isVRroomResGet: false,
} 

export const vrRooms = createSlice({
  name: 'vrRooms',
  initialState: { ...initialState }, 
  reducers: {
  
    // REDUCERS FOR SHARED BY USERS
    setVRrooms: (state, action) => {
      // console.log('this is my vr userss', action)
      return {
        ...state,
        romms: [...state.romms ,...action.payload.pagedList]
      }
    },
    resetVRrooms: ()=>{
        return {...initialState}
    },
    setHasMoreVRrooms: (state, action)=>{
        return {
            ...state,
            setHasMoreVRrooms: action.payload
        }
    },
    setResponseForVRroom: (state, action) => {
      console.log('this is res of i sssf ff', action)
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    }
  }
})  

// Action creators are generated for each case reducer function
export const { setVRrooms,
    resetVRrooms,
    setHasMoreVRrooms,
    setResponseForVRroom
  } = vrRooms.actions

// for images (SHARED BY USER)
const fetchVRrooms =async (token, dispatch, pageIndex, pageSize, vrUser) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VR_USERS}?pageindex=${pageIndex}&pagesize=${pageSize}&relationshipid=${vrUser}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const resVRrooms =await getData({url, headerConfig}, dispatch)

      console.log("this is a resVRrooms try", resVRrooms)
    
    return resVRrooms;
    
  }catch (err){

    console.log("this is a resVRrooms catch")
    throw (err)
  }

}
export const fetchVrRoomsDispatch = (paegIndex, pageSize, vrUser) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVRrooms =await fetchVRrooms(token, dispatch, paegIndex, pageSize, vrUser)
        
        if(resVRrooms.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVRrooms(false))
        }
        
        dispatch(setVRrooms(resVRrooms.result.payload))

        dispatch(setResponseForVRroom({ key: "isVRroomResGet", value: true}))
        // dispatch(setVRrooms(dummy))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default vrRooms.reducer 