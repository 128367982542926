import React, { useEffect, useState } from "react";
import Dropzone, {defaultClassNames} from './dragFile/react-dropzone-uploader/dist/Dropzone.tsx';
import 'react-dropzone-uploader/dist/styles.css' 
import * as API from '../../APIs'; 
import { 
  useSelector, 
  useDispatch } from 'react-redux'
import { isFileUploadingDispatch } from "../../SCREENS/Home/slice.reducer";
import { setCurrentUploadedAssestsCountDispatch } from "./dragFileFolder.slice";
import convert from 'convert';
import { toast } from 'react-toastify';
import { generateVideoThumbnails, importFileandPreview } from "@rajesh896/video-thumbnails-generator";
import { getMedia } from "../../UTILITYS/index.tsx";
import {Modal, ModalBody, ModalFooter, Button} from 'reactstrap';
import hourglass from "./../../ASSETS/Images/icons/hourglass.png";
import uploadProcessing from "./../../ASSETS/Images/uploadProcessing.gif";
import { expireSASurlToUploadVideosFiles, getSASurlToUploadVideosFiles, makeSomeDelay } from "../../UTILITYS/endPoints.js";
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import UpgradModal from "../Subscription/UpgradModal.js";
import { ExpiredModal } from "../Modal/Expired.js";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer.tsx";

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {

    return (
      <div>
        <div {...dropzoneProps}>
          {files.length < maxFiles && input}
        </div>
       
       {/* your priview component */}
        <div >
          {previews}
        </div>
    </div> 
    )
} 
  
const DragAndDropFiles = (props) => {

  const [isUpdatePreview, setIsUpdatePreview] = useState(false);
  const tokenContext = useContext(CreateTokenContextProvider);
  const dispatch = useDispatch();
  const userProfileDetails = useSelector(state => state.userProfileDetails) 
  const [isVideoSelected, setIsVideoSelected] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedErrFiles, setSelectedErrFiles] = useState([]);
  const [modal, setModal] = useState(false);
  const [azurSAS, setAzurSAS] = useState({containerUrl: "",saasUrl: ""});
  const [disableDZ, setDisableDZ] = useState(false);
  const [isAnyFileSelected, setIsAnyFileSelected] = useState(false);
  const [selectedFilesCount, setSelectedFilesCount] = useState(0);
  const [openUpgrade, setOpenUpgrade] = useState(false);
 
  const toggle = (shouldNotCalllApi) => {

    if(!shouldNotCalllApi){
      props.closeVideoInfoModalAndDZ();
      setIsVideoSelected(false);
      setSelectedFiles([]);
      setModal(false);
      // reset SAS url
      setAzurSAS({containerUrl: "",saasUrl: ""});
      expireSASurlToUploadVideosFiles(tokenContext.accessToken, dispatch)
    }
    setSelectedFilesCount(0);
    setDisableDZ(false);
    setModal(!modal);
  }

  const setCurrentUploadedAssestsCount= (file)=>{
    if(file.type.split("/")[0] === ""){
      let media = getMedia(file.name.split(".")[1])
      dispatch(setCurrentUploadedAssestsCountDispatch(media));
    }else{
      dispatch(setCurrentUploadedAssestsCountDispatch(file.type.split("/")[0]));
    }
  }

  const ModalIfVideoUploaded= ()=>{

    return <Modal isOpen={modal} centered className="modal-pinGeneration" toggle={toggle.bind(null, false)}>
        <ModalBody>
            <div className="p-2">
                <div className="row">
                    <div className="col-12 text-center pt-3 pb-3">
                        <img src={hourglass} alt="Images" className="img-fluid mb-4" />
                        <div className="video-optimized-info-text">
                          <div>Your video is being optimized for VR viewing and 
                            may take a few minutes to upload. We will notify 
                            you via email once the video is available.
                          </div>
                          <div className="video-optimized-info-text-footer">Thank You for your patience!</div>
                        </div>
                    </div>
                </div>
            </div> 
        </ModalBody>

        <ModalFooter className="justify-content-center pt-0">
            <Button className="ms-2 ms-md-3" color="primary" onClick={toggle.bind(null, false)}>OK</Button>
        </ModalFooter>
    </Modal>
  }

  const getUploadParams =async ({ file, meta }) =>{
    // let delay= await makeSomeDelay();
    
    console.log("fileSizeInKBfileSizeInKB 1");
    setCurrentUploadedAssestsCount(file); 
    
    let fileSizeInKB = 0;
    fileSizeInKB =convert(file.size, 'bytes').to('kB');
    
    if(204800<fileSizeInKB  ){
      toast.error(`${file.name} file is more than 200MB`, { });
      setIsVideoSelected(false);
      setIsAnyFileSelected(false);
      setSelectedFiles([]);
      setSelectedFilesCount(0);
      props.preventToUpload();
      return 
    }
    
    const formData = new FormData(); 
    formData.append( "files", file );
    
    // if(file.type.split("/")[0] === "video"){
    //   let getThumnail =await addThumbnailForVideo(file);
    //   alert(JSON.stringify(6))
    //   // formData.append( "thumbnailbase64", getThumnail[0].split(",")[1]);
    // }
    // alert("1"+ JSON.stringify(file.type.split("/")[0]));

    let headerConfig = {}

    if(file.type.split("/")[0] === "video" || file.name.split(".")[1] === "hevc"){

      let containerUrl = azurSAS.containerUrl;
      let blobSASURL = azurSAS.saasUrl;

      headerConfig = {
        "Content-Type": "application/octet-stream",
        "x-ms-blob-type": "BlockBlob"
      }

      return { 
        url: `${containerUrl}/${userProfileDetails.userID}_@$$_${Date.now()}_$@$_${file.name}${blobSASURL}`,
        method: "PUT",
        body: new Blob([file]),
        headers: headerConfig
      } 
    }else{

       headerConfig = {
        "Authorization": "Bearer " + tokenContext.accessToken
      }
      return { 
        url: API.BASE_URL+API.UPLOAD_ASSESTS, 
        method: "POST",
        body: formData,
        headers: headerConfig
      } 
    }
    
  } 

  const getSASoneTime = async() => {
    return await getSASurlToUploadVideosFiles(tokenContext.accessToken, dispatch);
  }
  
  const addThumbnailForVideo = async(file) => {
    return await generateVideoThumbnails(file, 15).then((thumbs) => {
      return thumbs
    }).catch((err)=> alert(5));
  }

  const handleChangeStatus = async(status) => {
    
    if(status.meta.status === "rejected_file_type"){
      toast.error(`${status.meta.type.split("/")[1]} format is not supported`);
      return;
    }
    dispatch(isFileUploadingDispatch(true))
    setIsUpdatePreview(!isUpdatePreview);

    if(isVideoSelected || isAnyFileSelected)
      await removeSelectedFileIfDone(status.meta)
  }

  const removeSelectedFileIfDone = async(fileStatus) => { 
    //   let updateSelectedFiles =[];
    //   updateSelectedFiles=await selectedFiles.filter(SF =>{
    //   if(fileStatus.name.replace(/[^a-zA-Z0-9]/g, "") === SF.name && fileStatus.status === "done" ){
    //     return false
    //   }else{
    //     return true
    //   }
    // })

    // if( fileStatus.status === "done"){
    //   setSelectedFiles(()=>{
    //     return [...updateSelectedFiles]
    //   });
    // }

    // if( fileStatus.status === "error_upload"){
    //   setSelectedErrFiles((previouse)=>{
    //    return [...previouse,
    //       {name: fileStatus.name.replace(/[^a-zA-Z0-9]/g, "")},
    //     ]
    //   })
    // }

    console.log('fileStatus.statusfileStatus.status status', fileStatus);

    if( fileStatus.status === "done" || fileStatus.status === "removed"){
      setSelectedFilesCount((previouse)=>{
        return previouse-1
      });
    }

    // if( fileStatus.status === "error_upload"){
    //   setSelectedFilesCount((previouse)=>{
    //     return previouse+1
    //   });
    // }
  }
  
  const setSelectedFilesHandler = async(selectedFiles) => {
    
    setSelectedFiles(selectedFiles.map((file) => {
      return {
        name: file.name.replace(/[^a-zA-Z0-9]/g, ""),
      };
    }));

    let isVideoSelected = false;
    selectedFiles.forEach(file => {
      if(file.type.split("/")[0] === "video" || file.name.split(".")[1] === "hevc"){
        isVideoSelected= true;
        setIsVideoSelected(true);
      }
    });
    setIsAnyFileSelected(true);
    setSelectedFilesCount(selectedFiles.length);
    if(isVideoSelected){
       if(azurSAS.containerUrl === "" || azurSAS.saasUrl === ""){
        let res = await getSASoneTime();
        let getActualData = res.result.payload;
        await setAzurSAS({ containerUrl: getActualData.containerUrl, saasUrl: getActualData.saasUrl });
      }
    }
  }

  // useEffect(()=>{
  //   if(selectedFiles.length === 0 && isVideoSelected){
  //     // show info
  //     toggle(true);
  //     setIsVideoSelected(false);
  //   }else if(selectedFiles.length === 0 && isAnyFileSelected){
  //     toast("Files uploaded successfully");
  //     props.closeVideoInfoModalAndDZ();
  //     setIsAnyFileSelected(false)
  //     setSelectedFiles([]);
  //   }

  // }, [selectedFiles.length, selectedErrFiles.length, isAnyFileSelected])

  useEffect(()=>{
    if(selectedFilesCount === 0 && isVideoSelected){
      toggle(true);
      setIsVideoSelected(false);
      setSelectedFilesCount(0);
    }else if(selectedFilesCount === 0 && isAnyFileSelected){
      toast("Files uploaded successfully");
      props.closeVideoInfoModalAndDZ();
      setIsAnyFileSelected(false)
      setSelectedFiles([]);
      setSelectedFilesCount(0);
    }

  }, [selectedFilesCount, isAnyFileSelected])
  
  const inputContent = () => {
    if(disableDZ) return <div><img className="files-upload-processing" src={uploadProcessing} /></div>
    return <div>Drop your file here or <span>upload</span></div>
  }

  const isStorageLimiteExccede = (files) => { 
    
    let initialValue = 0;
    let totalUploadFileSizeInBytes = 0;
    let totalUploadFileSizeInKB = 0;

    totalUploadFileSizeInBytes =files.reduce((accumulator, file) => accumulator + 
    file.size, initialValue );
 
    totalUploadFileSizeInKB =convert(totalUploadFileSizeInBytes, 'bytes').to('kB');
    
    if( userProfileDetails.isExpired ){
        toast.dismiss()
        dispatch(setDoNotShowExpiredModal(false));
        // setOpenUpgrade(true);
        toast("Your subscription has been expired");
        return true;
    }
    // else if(
    //     // !userProfileDetails.isPremium
    //     userProfileDetails.isExpired 
    //     && userProfileDetails.totalLeftStorageLimit < totalUploadFileSizeInKB
    //   ){
      
    //   toast("Your subscription has been expired");
    //   setOpenUpgrade(true);
    //   setIsVideoSelected(false);
    //   setIsAnyFileSelected(false);
    //   setSelectedFiles([]);
    //   setSelectedFilesCount(0);
    //   setModal(false);
    //   return true;
    // }
    else if(userProfileDetails.totalLeftStorageLimit< totalUploadFileSizeInKB){

      setIsVideoSelected(false);
      setIsAnyFileSelected(false);
      setSelectedFiles([]);
      setSelectedFilesCount(0);
      setModal(false);
      toast.error("File storage size exceed ", { });
      this.props.preventToUpload();
      return true;
  
    }
    else{
      return false;
    }
    
  }

  const isMoreThanOneVideosSelected = (files) => { 
    let videos = 0; 
    files.forEach(file => {
      if(file.type.split("/")[0] === "video" || file.name.split(".")[1] === "hevc"){
        videos = videos+1;
      }
    });
    toast.dismiss();
    
    if(videos >1) toast.error("Uploading multiple video files is not allowed, upload one video file at a time", { });
    console.log("videosvideos", videos);
    return videos >1? true: false;
  }

  const disabledDZ = () => { 
    setDisableDZ(true);
  }
  
  // const isPremium = () => {
  //   if(userProfileDetails.premiumUser === 0 && virtualTourImages.images.length >0){
  //     setOpenUpgrade(true);
  //     return false
  //   }
  //   return true;
  // } 

  // const upgradModal = () => { 
  //   {/* Modal to be upgraded  */}
  //     return <UpgradModal
  //       isOpen= {openUpgrade} 
  //       setOpenUpgrade ={setOpenUpgrade}
  //     />
  // }


  return ( 
    <React.Fragment>
      <ExpiredModal />
      {ModalIfVideoUploaded()}
      {/* {upgradModal()}  */}
      <Dropzone  
        disabled ={disableDZ}
        isStorageLimiteExccede= {isStorageLimiteExccede}
        setSelectedFilesHandler= {setSelectedFilesHandler}
        preventToUpload= {props.preventToUpload}
        isMoreThanOneVideosSelected= {isMoreThanOneVideosSelected}
        disabledDZ= {disabledDZ}
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        onChangeStatus={handleChangeStatus}
        inputContent={inputContent}
        inputWithFilesContent={inputContent}
        styles={{
          dropzone: { width: 400, height: 180, padding: 0, overflow: "hidden" }
        }}
        accept= ".png, .jpeg, .jpg, .heic, .mp4, .3gp, .mov, .m4v, .mp3, .aac, .wav, .m4a, .Hevc, .Heif"
      
      />
    </React.Fragment>
  )
}
  
export default DragAndDropFiles