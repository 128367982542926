import React from "react";
import Logout from './../../ASSETS/Images/icons/logout (1).svg';
import {
    NavItem,
    NavLink,
  } from 'reactstrap';

export default (props)=>{
    // console.log('')
    return (
        <NavItem >
            <NavLink href="#" onClick={()=>props.onClick()}>
                <img src={Logout} alt="Family" />
                <span>Logout</span>
            </NavLink>
        </NavItem>
    )
}