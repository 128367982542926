import React, { useEffect, useState } from "react";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice"; 
import { fetchUserDetailsDispatch } from "../Profile/slice.reducer.tsx";
import EmptyAssest from "./EmptyAssest"; 
import { fetchSelFAUDIOassestDispatch, fetchSelFVEDIOassestDispatch, fetchSelFIMGassestDispatch } from "./slice.reducer";
import WithAssest from "./WithAssest";
import { toast } from 'react-toastify';
import { fetchShareWithMeUserInfoDispatch, fetchShareWithOthersUserInfoDispatch, resetShareWithMeAndOthers } from "./slices/ShareWithMe";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../UTILITYS/Constant";

export default (args)=>{
    
    const dispatch = useDispatch();    
    const [isSelfUploadedAssestsEmpty, setIsSelfUploadedAssestsEmpty] = useState(true);
    const assestsData = useSelector(state => state.assestsData);
    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const shareWithMeAndOthers = useSelector(state => state.shareWithMeAndOthers);

    useEffect(()=>{
        dispatch(setParentModuleActiveDispatch("parentDashboard"))
        dispatch(resetShareWithMeAndOthers())
    }, [])  
    
    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        if(userProfileDetails.isUserFetch){
            dispatch(fetchSelFAUDIOassestDispatch(1, 1)) 
            dispatch(fetchSelFVEDIOassestDispatch(1, 1))
            dispatch(fetchSelFIMGassestDispatch(1, 1))
    
            // FETCH SHARE WITH ME AND OTHER USER INFO
            dispatch(fetchShareWithMeUserInfoDispatch(1, 4));
            dispatch(fetchShareWithOthersUserInfoDispatch(1, 4));
        }
    }, [userProfileDetails.isUserFetch])

    useEffect(()=>{
        dispatch(fetchUserDetailsDispatch()); 
    },[])
    
    useEffect(()=>{
        if(assestsData.selfAUDIOAssestsOnHome.length === 0 && assestsData.selfVEDIOAssestsOnHome.length === 0 && assestsData.selfIMGAssestsOnHome.length === 0 && shareWithMeAndOthers.sharedWithMeUserInfo.length===0 && shareWithMeAndOthers.shareWithOtherUserInfo.length ===0){
            setIsSelfUploadedAssestsEmpty(true)
        }else{
            setIsSelfUploadedAssestsEmpty(false)
        }
    }, [assestsData.selfAUDIOAssestsOnHome.length, assestsData.selfVEDIOAssestsOnHome.length, assestsData.selfIMGAssestsOnHome.length,shareWithMeAndOthers.sharedWithMeUserInfo.length, shareWithMeAndOthers.shareWithOtherUserInfo.length])

   // THIS IS FIRST PAGE AFTER LOGING, SO ITS GOOD TO GET USER DETAILS
    useEffect(()=>{
        if(!userProfileDetails.isUserFetch){
            dispatch(fetchUserDetailsDispatch());
        }
    }, [userProfileDetails.isUserFetch]);
   
    useEffect(()=>{
        console.log("this is a usesrprofile useEffect userProfileDetails.isUserFetchFaild", userProfileDetails.isUserFetchFaild)
        if(userProfileDetails.isUserFetchFaild!== null){
            toast.error(userProfileDetails.isUserFetchFaild, { });
        }
    }, [userProfileDetails.isUserFetchFaild, userProfileDetails.totalUsedStorage]);

    if(isSelfUploadedAssestsEmpty){
       return <EmptyAssest 
            primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
            description= {EMPTY_ASSEST_DESCRIPTION_TEXT} 
        />
    }
 
    return (
        <WithAssest /> 
    )
}