import React from "react";
import VideosCard from './VideosCard'
import { useSelector } from 'react-redux';

export default (props)=>{
    const assestsData = useSelector(state => state.assestsData);
    
    return <VideosCard video={props.video} {...props}/>
    // return assestsData.selfVEDIOAssestsOnHome.map((video, index)=>{
    // return ( <VideosCard video={video} key ={index}/>)
    // })
}