import React, {useState} from "react";
import { Col, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleSelectForVideoDispatch } from "./../../../SCREENS/Home/slice.reducer";
import Video from './../../../ASSETS/Images/icons/play.svg';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap';
import ReactPlayer from 'react-player'
import CustomVideoPlayer from "../../Galaries/CustomVideoPlayer";
import { setToggleSelectForVideoSharedByThisUser } from "../../../SCREENS/FamilyAndFriends/slice.reducer";

export default (props)=>{

    const dispatch = useDispatch();
    const assestsData = useSelector(state => state.assestsData);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const [isOpenVidioPlayer, setIsOpenVidioPlayer] = useState(false); 

    
    const closeVideoPlayr = ()=>{
        setIsOpenVidioPlayer(false)
    }

    const getCheckBox = ()=>{
        if(!props.isFF && props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.video.id)}
            ></span>
        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.video.id)}
            ></span>
        }else if(!props.isFF && (props.isMobileView=== undefined || !props.isMobileView)){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.video.id)}
            ></span>
        }
    }
    

    return (
        <React.Fragment>
            <Col sm="6" md="4" lg="3" className="ps-1 pe-1 videoCard">
                <Card className={props.getCheckBoxClasses(props.video.id)}>
                {getCheckBox()}
                    <CardBody className="p-0" {...(props.bind !== undefined ? props.bind(props.video.id) : {})} onClick={()=>{
                        if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
                            props.toggleSelectBox(props.video.id)
                        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
                            props.toggleSelectBox(props.video.id);
                        }else{
                            setIsOpenVidioPlayer(true)
                        }
                    }}>
                        <ReactPlayer  
                            url={props.video.fIleUrl}
                            config={{
                                file: {
                                    attributes: {
                                        preload: "none",
                                        poster: props.video.thumbnailUrl,
                                        onContextMenu: e => e.preventDefault(),
                                    }
                                }
                            }}
                        />
                        <img src={Video} onContextMenu={(event) => {
                            event.preventDefault(); // Prevent the context menu from appearing
                        }} alt="image" className="play_image prevent-context-view" />
                    </CardBody>
                </Card>
            </Col>

            {/* CAROUSAL MODAL */}
            <CustomVideoPlayer 
                isOpenVidioPlayer= {isOpenVidioPlayer}
                closeVideoPlayr ={closeVideoPlayr}
                index ={props.index}
                {...props}
            />
        </React.Fragment>
    )
}