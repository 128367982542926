import React, { useState, useEffect } from "react";
import Home from "..";
import { useNavigate, useLocation } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import UserInfoCard from "../../../COMPONENTS/HOME/CustomeCard/UserInfoCard";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { random } from "../../../UTILITYS/index.tsx";
import { fetchVrRoomsDispatch, resetVRrooms } from "../Slices/Room.slice";
import { Row, Col, Card, CardBody, CardFooter, Modal, ModalHeader, ModalFooter } from 'reactstrap';
import ImagesIcon from './../../../ASSETS/Images/icons/virtual-tour.png';
import RankIcon from './../../../ASSETS/Images/icons/rank.svg';
import Padlock from './../../../ASSETS/Images/icons/padlock.png';
import Premium from './../../../ASSETS/Images/icons/Icon1x.png';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";

export default (props)=>{

    let { state } = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [vrUser, setVrUser] = useState(state);
    const vrRoom = useSelector(state => state.vrRoom);
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal)
    };

    useEffect(()=>{
        dispatch(fetchVrRoomsDispatch(loadEventIndex, 25, vrUser.relationshipID)) 
    },[loadEventIndex])  
 
    useEffect(()=>{
        dispatch(resetVRrooms())
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("vr_Experience"))
    },[]);   
 
    const fetchMoreData = () => {
        seLoadEventIndex(loadEventIndex+1)  
    };

    // const onNavigate = (room) => { 
    //     if(room.roomType === 1){
    //         if(room.accessable === 1) 
    //         navigate('/shared-room-assests', {state: {room, vrUser}})
    //         else{
    //             toggle();
    //         }
    //     }else{
    //         if(room.accessable === 1) 
    //         navigate('/shared-room-assests', {state: {room, vrUser}})
    //         else{
    //             toggle();
    //         }
    //     }
    // } 
   
    const onNavigate = (room) => { 
        // if(userProfileDetails.premiumUser){
            navigate('/shared-room-assests', {state: {room, vrUser}})
        // }else{
            // toggle();
        // }
    } 

    const getVRroomsList = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={vrRoom.romms.length}
                    next={fetchMoreData}
                    hasMore={vrRoom.setHasMoreVRUser}
                    loader={vrRoom.romms.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    }
                > 
                {getVRrooms()}
            </InfiniteScroll>
       
     }

     const getVRrooms = ()=>{ 
         
         return vrRoom.romms !=undefined && vrRoom.romms.length >0 && vrRoom.romms.map((romm, index)=>{
             console.log('jieinnd hhh', romm)
             return getVRroomsTils(romm)
            })
            
        }
    const showLockIcon = (romm)=>{
        if(romm.accessable === 0 && romm.roomType ===1) return false;
        return true;

    } 

    // VR rooms tils (4rth VRpic-Sprint-6)
    const getVRroomsTils = (romm)=>
            // <Row> 
                <Col sm="6" md="6" lg="4" xl="3">
                    <Card className="my-2" onClick={()=>onNavigate(romm)}>
                        <CardBody className="text-center">
                            {(romm.roomType === 1) && <img src={RankIcon} alt="Images" className="vr-icon img-fluid" />}
                            <img src={ImagesIcon} alt="Images" className="img-fluid" />
                            <div className= { classNames("padlock", { "d-none": showLockIcon(romm) } )}>
                                <img src={Padlock} alt="Images" className="img-fluid" />
                            </div>
                        </CardBody>
                        <CardFooter>
                        <span>{romm.name}</span>
                        </CardFooter>
                    </Card>
                </Col>
            // </Row>

    return (
        <React.Fragment>
            <Home>
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>Virtual Tour for {vrUser.name}</h5>
                            </Col>
                        </Row>
                    </div>
                    <div className="VR_rooms">
                       {getVRroomsList()}
                    </div>
                </div>
            </Home>

            {/* Modal for Upgrade the premium  */}
            <Modal isOpen={modal} centered className="modal-md drop-modal" toggle={toggle} {...props}>
                <button className="modalClose" onClick={()=>{
                    toggle()
                }}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </button>
                <ModalHeader className="text-center bg-primary">
                    <img src={Premium} />
                </ModalHeader>
                <modalBody className="text-center pt-4 mt-2 p-3">
                    Upgrade to a Premium Plan to personalize a VR Room.<br />
                    Contact us now by clicking the button below.
                </modalBody>
                <ModalFooter className="justify-content-center">
                    <a href="http://www.vreminisce.com/" class="btn btn-primary" target={"_blank"}>I want to upgrade</a>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}