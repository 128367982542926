import React, { useEffect, useState } from "react";
import HeaderSideBar from ".";
import HTMLtextEditor from './../../COMPONENTS/HTMLtextEditor';
import { useDispatch,useSelector } from 'react-redux'
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { hamburgurDispatch } from "../../STORE/CommonSlices/hameBurgerOnOff";
import validator from 'validator';
import { toast } from 'react-toastify';
import { inviteContributor } from "../../UTILITYS/endPoints";
import classNames from 'classnames';
import { Row, Col } from 'reactstrap';
import InputAndEmail from "../../COMPONENTS/AddSameUnlimitedComponent/InputAndEmail";
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";

let defaulTemleDes="<p>Dear &lt;Invitee Name&gt;,&nbsp;</p><p><br /></p><p>I'd like to invite you to share Image/Video/Audio assets with me through the Recuerdo Platform.&nbsp;</p><p><br /></p><p>I intend to create a Virtual Reality Room and it would be awesome if you can&nbsp;contribute to the VR Experience.&nbsp;</p><p><br /></p><p>Thank You in advance.&nbsp;</p><p><br /></p><p>Regards,&nbsp;</p>" 

export default ()=>{;

    const dispatch = useDispatch();
    const userProfileDetails = useSelector(state => state.userProfileDetails);
    const [editorValue, setEditorValue] = useState(`${defaulTemleDes}<p>${userProfileDetails.firstName} ${userProfileDetails.lastName} &nbsp;</p><p>${userProfileDetails.email}</p>`);
    const [myRef, setmyRef] = useState(null);
    const [email, setEmail] = useState('');
    const tokenContext = useContext(CreateTokenContextProvider);
    const [emails, setEmails] = useState([]);
    const [emailsErr, setEmailsErr] = useState(false);
    const [resetAllInputAndEmails, setResetAllInputAndEmails] = useState(false);

    useEffect(()=>{
        dispatch(setParentModuleActiveDispatch("parentICexperience"));
        dispatch(setModuleActiveDispatch(""));
        dispatch(hamburgurDispatch());
    }, [editorValue]);

    useEffect(()=>{
        setEditorValue(`${defaulTemleDes}<p>${userProfileDetails.firstName} ${userProfileDetails.lastName} &nbsp;</p><p>${userProfileDetails.email}</p>`);
    }, [userProfileDetails]);
 
    const sendEmail = async()=>{

        toast.dismiss();

        let isAllMailValidate= false;
        let isUsingSameMail= false;

        if(emails.length === 0){
            toast.error("Please Add at least one Contributor", { });
            return; 
        }

        emails.forEach((mail) =>{
            let myMail = {...mail.email}
            console.log("emailsemails 1", )
            
            // if(!validator.isEmail(myMail.value, {blacklisted_chars : "+-"})){ 
            if(myMail.value.toLowerCase() === userProfileDetails.email.toLowerCase()){ 

                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.email.id === myMail.id
                        ? { 
                            ...inputBox,
                            email: {
                                ...inputBox.email,
                                Err: true, 
                            }
                        }
                        : inputBox
                    })
                });
                
                isAllMailValidate= true;
                isUsingSameMail= true;
            }
        });
 
        emails.forEach((mail) =>{
            let myMail = {...mail.email}
            console.log("emailsemails 1", )
            
            if(!validator.isEmail(myMail.value, {blacklisted_chars : "+-"})){ 
                console.log("emailsemails 2", )

                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.email.id === myMail.id
                        ? { 
                            ...inputBox,
                            email: {
                                ...inputBox.email,
                                Err: true, 
                            }
                        }
                        : inputBox
                    })
                });
                
                isAllMailValidate= true 
            }
        });

        emails.forEach((mail) =>{
            let myFirstName = {...mail.fistName}

            console.log("emailsemails 3", )
            
            if(validator.isEmpty(myFirstName.value.trim())){ 
                console.log("emailsemails 4", )
                
                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.fistName.id === myFirstName.id
                        ? { 
                            ...inputBox,
                            fistName: {
                                ...inputBox.fistName,
                                Err: true,
                            }
                        }
                        : inputBox
                    })
                });
                isAllMailValidate= true 
            }else if(!validator.isLength(myFirstName.value.trim(), {min: 1, max: 25})){
                console.log("emailsemails 5", )
                setEmails((prevEmails) =>{
                    return prevEmails.map((inputBox) =>{
                        return  inputBox.fistName.id === myFirstName.id
                        ? { 
                            ...inputBox,
                            fistName: {
                                ...inputBox.fistName,
                                Err: true,
                            }
                        }
                        : inputBox
                    })
                });
                isAllMailValidate= true;
            }
        });

        // return;
        if(isUsingSameMail){
            toast.error("You can't invite your own as a contributor", { });
        }
        
        if(isAllMailValidate){
            setEmailsErr(true)
            return;
        }
        
        if(userProfileDetails.email === email){
            toast.error("You can't invite yourself as a contributor", { });
            return
        } 
 
        let sendMailData = {
            InviteContributor: emails.map(email=> ({ 
                ContributorName: email.fistName.value,
                ContributorEmail: email.email.value,
                CustodianName: userProfileDetails.firstName+" "+userProfileDetails.lastName,
                CustodianEmail: userProfileDetails.email
            })),
            // description: editorValue
        }

        try{
          console.log('this is try sendMailData', "try")
          await inviteContributor(sendMailData, dispatch, tokenContext.accessToken);
          setResetAllInputAndEmails(true);
        }catch (err){
          if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          console.log('this is catch sendMailData', err)
        }

    }

    const onGetEmailsHandler = (mails)=>{
        setEmails(mails);
    }

    return (
        <HeaderSideBar>
            <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                <div className="pageTitle">
                    <Row>
                        <Col xs="12">
                            <h5>Invite Contributor</h5>
                        </Col>
                    </Row>
                </div>

                <div className="inviteCont mt-2 mt-md-4">
                    <div className="invite-screen">
                        <h6 className="mb-3 mb-md-4">Invite Contributors to share photos, videos, or audio assets to enhance the VR experience of your loved ones via email. Please feel free to edit the template below.</h6>
                        
                        <div className="mb-3 mb-md-4">
                            <p className="mb-1">Contributor email</p>
                            <InputAndEmail 
                                getEmails= {onGetEmailsHandler} 
                                emails= {emails}
                                emailsErr= {emailsErr}
                                setEmailsErr= {setEmailsErr}
                                resetAllInputAndEmails= {resetAllInputAndEmails}
                                setResetAllInputAndEmails= {setResetAllInputAndEmails}
                            />
                        </div>

                        <div  className="mb-3 mb-md-4">
                            <label>Email body</label>
                            <HTMLtextEditor 
                                className="form-control"
                                editorValue = {editorValue}
                                getMyQuilRef= {(ref)=>{
                                    setmyRef(ref)
                                }}
                                readOnly= {true}
                            />
                        </div>
                            <button className="btn btn-primary" onClick={sendEmail}>Invite Contributor</button>
                    </div>
                </div>
            </div>
        </HeaderSideBar>
    )
}