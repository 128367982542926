import React from "react";
import { Row, Col } from 'reactstrap';
import NoContentPage from "./NoContents";
import Breadcrum from "./../../Breadcrum";

export default (props)=>{
 
    return (
        <React.Fragment>
            <div className="ps-0 pe-0 ps-md-3 pe-md-3">
                {/* Dashboard no content  */}
                <NoContentPage 
                    primaryText ={props.primaryText}
                    description ={props.description}
                    icon ={props.noImgIcon}
                />
            </div>
        </React.Fragment>
    )
}
