//export const BASE_URL = "https://recuerdodevapi.azurewebsites.net";
// export const BASE_URL = "https://recuerdoqaapi.azurewebsites.net";
// export const BASE_URL = "https://recuerdoprodapi.azurewebsites.net";
export const BASE_URL = "https://api.recuerdoapp.com";
// export const BASE_URL = "https://8ffc-2405-201-300b-61a8-e94a-98a1-e6ce-d19e.in.ngrok.io";

// THIS END POINTS RELATED TO AUTHENTICATIONS
export const LOGIN = "/api/account/login";
export const REGISTRATION = "/api/account/register";
export const FORGOT_PASSWORD = "/api/account/forgotpassword";
export const RESET_PASSWORD = "/api/account/resetpassword";
export const THANK_YOU = "/api/NoThankYou/savenothankyou";
export const VERIFY_EMAIL = "/api/account/VerifyEmail";
export const UPDATE_PASSWORD = "/api/account/changepassword";
export const REFRESH_TOKEN = "/api/account/refreshtoken";
export const LOG_OUT = "/api/account/logout";
export const RESEND_EMAIN_VERIFY_USER = "/api/account/resendemailverificationlink";
export const REMOVE_PROFILE_PIC = "/api/UserProfile/removeprofilepic";

// THIS END POINTS RELATED TO PROFILE MANAGMENT
export const GET_PROFILE_DETAILS = "/api/UserProfile/getuserdetailbyuserid";
export const UPDATE_USER_PROFILE_DATA = "/api/UserProfile/saveuserdetail";

// COMMON API
export const GET_COUNTRY = "/api/Country/getcountries";
export const GET_IMG_UL = "/api/UserProfile/uploaduserprofilepic";

// UPLOADING 
export const UPLOAD_ASSESTS = "/api/asset/saveuserassets";
export const GET_SELF_UPLOAD_ASSESTS = "/api/asset/getuserassets";
export const UPLOAD_360_IMAGES = "/api/VirtualTour/savevirtualtourtimage";

// SHARING
export const SHARE_WITH_ME_AND_OTHERS_USER_INFO = "/api/asset/getassetsharinguserlist";
export const SHARE_ASSESTS = "/api/asset/shareassets";
export const DELETE_ASSESTS = "/api/Asset/deleteassets";
export const GET_SHARED_WITH_OTHERS = "/api/Asset/GetSharedWithOtherAssets";
export const GET_SHARED_WITH_ME = "/api/Asset/GetSharedWithMeAssets";

// VR-experience
export const GET_VR_USERS = "/api/vrexperience/getmapedvrroomorrelationshiplist";
export const GET_VR_ROOM_RELATIONSHIP = "/api/vrexperience/getvrroomandrelationshiplist";
export const MAP_VR_WITH_RELATIONSHIP = "/api/vrexperience/mapvrroomwithrelationship";
export const GET_VR_ASSEST_LIST = "/api/vrexperience/getvrassetslist";
export const SHARE_ASSEST_IN_VR_ROOM = "/api/vrexperience/shareassetsinvrexperience";
export const GENERATE_PIN = "/api/vrexperience/generatepinforvrroom";
export const DELETE_SHARED_ASSESTS = "/api/vrexperience/deleteassetsinvrexperience";

// VIRTUAL-TOUR
export const SAVE_VIRTUAL_TOUR = "/api/VirtualTour/savevirtualtourt";
export const GET_VIRTUAL_TOUR = "/api/VirtualTour/getvirtualtourt";
export const DELETE_VIRTUAL_TOUR_IMAGE = "/api/VirtualTour/deletevirtualtourimage";
export const GET_VIRTUAL_TOUR_IMAGES = "/api/VirtualTour/Getvirtualtourimages";
export const GET_DEFAULT_VIRTUAL_TOUR_IMAGES = "/api/VirtualTour/GetDefaultvirtualtour";

// TAGGING
export const GET_VT_TAGGING = "/api/VirtualTour/GetVirtualTourImageTagging?VirtualTourImageID=";
export const GET_USER_ASSETS_FOR_TAGGING = "/api/asset/GetUserAssetsForTagging?";
export const DELETE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/deletevirtualtourimagetagging";
export const SAVE_VERTUAL_TOUR_TAGGING = "/api/VirtualTour/savevirtualtourtimagetagging";

// contributor
export const INVITE_CONTRIBUTOR = "/api/InviteContributor/saveinvitecontributor";

// blob storage 
export const AZUR_SAS = "/api/Asset/getsaasurltouploadvideo";
export const EXPIRE_AZUR_SAS = "/api/Asset/expiresaasurltouploadvideo";

// Subscription 
// export const GET_SUBSCRIPTION_DETAILS = "/api/Subscription/GetSubscriptionDetails";
// export const GET_SETUP_INTENT = "/api/Subscription/GetClientSecret?";
// export const POST_PAYMENT_METHOD = "/api/Subscription/CreateSubscription";
// export const PAUSH_SUBSCRIPTION = "/api/Subscription/CancelSubscription";
// export const RESUME_SUBSCRIPTION = "/api/Subscription/ResumeSubscription";
// export const GET_PAYMENT_METHODS = "/api/Subscription/GetCustomerCard";
// export const SET_PAYMENT_METHOD_DEFAULT = "/api/Subscription/SetDefaultCard";

// SUBSCRIPTION
export const GET_SUBSCRIPTION_DETAILS = "/api/Subscription/get-subscription-details";
export const RE_START_SUBSCRIPTION = "/api/Subscription/restart";
export const CANCEL_SUBSCRIPTION = "/api/Subscription/cancel-at-periodend";
export const CREATE_SETUP_INTENT = "/api/Subscription/create-setup-intent";
// export const CREATE_SETUP_INTENT_NEW = "/api/Subscription/create-setup-intent-new";
export const UPDATE_PAYMENT_METHOD = "/api/Subscription/update-subscription-withpayment";
export const CREATE_NEW_SUBSCRIPTION = "/api/Subscription/create-subscription";
export const GET_PORTAL_LINK = "/api/Subscription/get-portal-link";