import React, {useState, useEffect} from "react";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../UTILITYS/Constant";
import { useDispatch, useSelector } from 'react-redux'
import { fetchVrUsersDispatch, resetVRusers } from "./Slices/VRexperienceUser.slice";
import NoVRexperience from "./VR-landing-page/NoVRexperience";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import VRexperienceUsers from "./VR-landing-page/VRexperienceUsers";
import Loader from "../../COMPONENTS/Loader";
import HeaderFooter from ".";
import { LoaderForIndividualPageDispatch } from "../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { resetVirtualTour } from "../360virtualTour/Slices/VirtualTour.slice";

export default (props)=>{ 

    const dispatch = useDispatch(); 
    const vrUsers = useSelector(state => state.vrUsers);
    // const [isVRusersEmpty, setIsVRusersEmpty] = useState(true);

    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        console.log("LoaderForIndividualPageDispatchLoaderForIndividualPageDispatch 1");
        dispatch(resetVirtualTour());
        dispatch(LoaderForIndividualPageDispatch(true));
        dispatch(resetVRusers()); 
        dispatch(fetchVrUsersDispatch(1, 25));
        dispatch(setParentModuleActiveDispatch("parentVRexperience"));
        dispatch(setModuleActiveDispatch("vr_Experience"));
        
        return ()=>{
            console.log("LoaderForIndividualPageDispatchLoaderForIndividualPageDispatch 4")
            dispatch(resetVRusers());
        }

    }, []);

    useEffect(()=>{
        if(vrUsers.isVRusersResGet)
            dispatch(LoaderForIndividualPageDispatch(false))
    }, [vrUsers.isVRusersResGet]);
    
    
    if(!vrUsers.isVRusersResGet){ 
        return <HeaderFooter></HeaderFooter> 
    }
        
    if(vrUsers.users.length === 0 && vrUsers.isVRusersResGet){
        return <NoVRexperience   
            primaryText= {EMPTY_ASSEST_PRIMARY_TEXT} 
            description= {EMPTY_ASSEST_DESCRIPTION_TEXT} 
        />
    }
 
    return (
        <VRexperienceUsers />  
    )
}