import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default (props) =>{

    const settings = {
      // className: "center",
      // centerMode: true,
      infinite: false,
      centerPadding: "60px",
      // slidesToShow: 2,
      speed: 500,
      // rows: 2,
      // slidesPerRow: 2
      slidesToShow: 2.5,
      slidesToScroll: 2.5,
      // responsive: [
      //   {
      //     breakpoint: 370,
      //     settings: {
      //       arrows: false,
      //       centerMode: true,
      //       centerPadding: '10px',
      //       slidesToShow: 3,
      //       infinite: false,
      //     }
      //   },
      //   {
      //     breakpoint: 480,
      //     settings: {
      //       arrows: false,
      //       centerMode: true,
      //       centerPadding: '10px',
      //       slidesToShow: 1,
      //       infinite: false,
      //     }
      //   }
      // ]
      };

   return <Slider {...settings}>
            {props.items}
        </Slider>
}