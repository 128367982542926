import HeaderSideBar from "./../index"; 
import ThreeSixtyViewer from "../../../COMPONENTS/ThreeSixtyViewer/Viewr";
import { useLocation } from "react-router-dom";

export default (props)=>{

    let {state}  = useLocation();
    
    return (
        <HeaderSideBar> 
          <ThreeSixtyViewer {...state}/>
        </HeaderSideBar> 
    )
}