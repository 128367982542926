import React, { useState } from "react";
import classNames from 'classnames';
import { useEffect } from "react";
import { Col, Card, CardBody, CardFooter, Row, Label } from 'reactstrap';
import cross from './../../ASSETS/Images/icons/Cross.png';
import plus from './../../ASSETS/Images/icons/plus.png';

export default (props)=>{

    let initialFields= [
        {
            email: {
                value: "",
                Err: false,
                id: Date.now() ,
                name: `email`,
                placeholder: `Enter Email`,
            },
            fistName: {
                value: "",
                Err: false,
                id: Date.now() ,
                name: `fistName`,
                placeholder: `Enter Name`,
            },
            mainId: Date.now() 
        }
    ]

    const [inputBoxes, setInputBoxes] = useState(initialFields);

    useEffect(()=>{
        props.getEmails([...inputBoxes]);
    }, [])
    
    const onInputChange = (e, input)=>{
        
        let updatedEmails= inputBoxes.map(inputBoxe=>{

            if(input.name === "email"){
                
                if(inputBoxe.email.id === input.id){
                    return {
                        ...inputBoxe,
                        email: {
                            ...inputBoxe.email,
                            value: e.target.value,
                            Err: false, 
                        }
                    }
                }else{
                    return inputBoxe;
                }
            }else{

                if(inputBoxe.fistName.id === input.id){
                    return {
                        ...inputBoxe,
                        fistName: {
                            ...inputBoxe.fistName,
                            value: e.target.value.replace(/[^a-zA-Z\s]/g, ''),
                            Err: false, 

                        }
                    }
                }else{
                    return inputBoxe;
                }
            }
        })
        setInputBoxes(updatedEmails);
        props.getEmails([...updatedEmails]);
    }

    const handleAddInputBox = () => {
        
        let field= {
            email: {
                value: "",
                Err: false,
                id: Date.now(),
                name: `email`,
                placeholder: `Enter Email`,
            },
            fistName: {
                value: "",
                Err: false,
                id: Date.now(),
                name: `fistName`,
                placeholder: `Enter Name`,
            },
            mainId: Date.now()
        }
        setInputBoxes([...inputBoxes, field]);
        props.getEmails([...inputBoxes, field]);
    };

    const removeInput = (inputBox) => {
        props.getEmails(inputBoxes.filter(inputBoxe=> inputBoxe.mainId !== inputBox.mainId));
        return setInputBoxes(inputBoxes.filter(inputBoxe=> inputBoxe.mainId !== inputBox.mainId));
    }
    
    useEffect(()=>{
        if(props.emailsErr){
            setInputBoxes(props.emails);
            props.setEmailsErr(false);
        }

        if(props.resetAllInputAndEmails !=undefined && props.resetAllInputAndEmails){
            setInputBoxes(initialFields); 
            props.setResetAllInputAndEmails(false);
            props.getEmails([...initialFields]);
        }

    },[props.emailsErr, props.resetAllInputAndEmails])

    return (
        <div className="add-input-boxes-container-upper-div">
            {/* {inputBoxes.length >0 && <><Label htmlFor="email">Email Address</Label>
            <Label htmlFor="Name" className="second-label-input-and-email">Name</Label></>} */}

            {inputBoxes.length >0 && <Row className="add-input-boxes-container_lable">
                <div class="col-sm-4 col-md-6 add-input-boxes-container_lable_1">Email Address</div>
                <div class="col-sm-4 col-md-6 add-input-boxes-container_lable_1">Name</div>
                <div className="col-md-2 cancel-input-boxes-container"></div>
            </Row>}

            {inputBoxes.map((inputBox) => { 

               return  <Row key={inputBox.mainId} className="add-input-boxes-container">

                        
                        <Col className="col-md-6">
                            <input 
                                value= {inputBox.email.value} 
                                onChange= {(e)=>onInputChange(e, inputBox.email)}  
                                type= "text" 
                                className= {classNames('form-control', { "form-error": inputBox.email.Err })}
                                name= {inputBox.email.name} 
                                placeholder= {inputBox.email.placeholder}
                            />
                        </Col>
                        
                        
                        <Col className="col-md-4"> 
                            <input 
                                onChange={(e)=>onInputChange(e, inputBox.fistName)} 
                                value={inputBox.fistName.value}
                                placeholder={inputBox.fistName.placeholder}
                                name={inputBox.fistName.name} 
                                type="text" 
                                className= {classNames('form-control', { "form-error": inputBox.fistName.Err })}
                            />
                        </Col>
                        
                        <Col className="col-md-2 cancel-input-boxes-container">
                            {/* <button onClick={()=>{removeInput(inputBox)}}>remove</button> */}
                            <img src={cross} alt="profile" onClick={()=>{removeInput(inputBox)}} />
                        </Col>
                    
                    </Row>
            })}
            
            <button onClick={handleAddInputBox} className="add-new-contributor-button"><img src={plus} alt="Images" className="img-fluid" /> Add new</button>
        
        </div>
        
    )
}