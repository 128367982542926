import React from "react";
// import Loader from './../../ASSETS/Images/Loader.gif';
export default (props) => {

    // COMMON CLASS WILL GO HERE
    let loaderClass = "";

    if( props.loaderClass!= undefined){
        loaderClass = loaderClass+ ' ' + props.loaderClass;
    }

    return  <React.Fragment>
            <div className="loader">
                {/* <img src={Loader} alt="Loader" className={loaderClass} /> */}
                <div class="lds-spinner">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </React.Fragment>
}