import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, ModalBody, FormGroup, Input, Row, Col,

    TabContent, TabPane, Nav, NavItem, NavLink, Card, CardTitle, CardText
 } from 'reactstrap';
 import classnames from 'classnames';
import {  } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux'
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css'; 
import classNames from 'classnames';
import { addVirtualTour, fetchDefaultVTimages } from "../../UTILITYS/endPoints";
import { toast } from 'react-toastify';
import validator from 'validator';
import { fetchVirtualTourDispatch, resetVirtualTour } from "../../SCREENS/360virtualTour/Slices/VirtualTour.slice";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";
import { default as ReactSelect, components } from "react-select";
import RadioButton from "../RadioButton";
import CarouselModal from "../Galaries/CarouselModal";
import ImagesCardList from "./../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import UpgradModal from "../Subscription/UpgradModal";
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";
export default (props)=>{

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [virtualTourName, setVirtualTourName] = useState('');
    const [virtualTourNameErr, setvirtualTourNameErr] = useState(false) 
    const [defaulImgOptions, setDefaulImgOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState();
    const [isVTcreatedByOwn, setIsVTcreatedByOwn] = useState(true);
    const [isOpenCarousel, setIsOpenCarousel] = useState(false); 
    const tokenContext = useContext(CreateTokenContextProvider);
    const [activeTab, setactiveTab] = useState(1); 
    const [openUpgrade, setOpenUpgrade] = useState(false); 
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const virtualTours = useSelector(state => state.virtualTours);
    const [isDefaulOptionFetched, setIsDefaulOptionFetched] = useState(false);
   const togglerr=(tab)=> {
        if (activeTab !== tab) {
        //   this.setState({
        //     activeTab: tab
        //   });
        setactiveTab(tab)
        }
    }

    const handleOptionChange = (id) => {
        setSelectedOption(defaulImgOptions.filter(img => parseInt(img.id) === parseInt(id)).map(img=> ({...img, fIleUrl: img.imagePath}))[0]);
    };

    const cratedVTbyOwn = () => {setIsVTcreatedByOwn(true)}
    const cratedVTbyDefault = () => {setIsVTcreatedByOwn(false)}
    const closeCarousel = ()=>{ setIsOpenCarousel(false) }

    const toggle = async() => {

        if( userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        if(!modal && !isDefaulOptionFetched){
            let fetchDefaultVTimg=await fetchDefaultVTimages(tokenContext.accessToken, dispatch);
            setIsDefaulOptionFetched(true);
            if(fetchDefaultVTimg.result.payload.length >0){
                setDefaulImgOptions(fetchDefaultVTimg.result.payload);
                setSelectedOption({...fetchDefaultVTimg.result.payload[0], fIleUrl: fetchDefaultVTimg.result.payload[0].imagePath });
            }
        }

        resetState()
        setModal(!modal);
    }

    const resetState= ()=>{
        setVirtualTourName("")
    }

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "virtual-tour":
                setVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setvirtualTourNameErr(false)
            break;
        }
    }

    const createdRoom= async()=>{
        
        toast.dismiss();
    
        let payload= {}

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        // else if(!userProfileDetails.isPremium && virtualTours.tour.length >0){
        //     setOpenUpgrade(true);
        //     toast.error("Free users are allowed only 1 Virtual Tour.", { });
        //     return;
        // }

        if(isVTcreatedByOwn){
            if(validator.isEmpty(virtualTourName.trim())){
                toast.error("Please Enter Virtual Tour Name", { });
                setvirtualTourNameErr(true)
                return;
            }else if(!validator.isLength(virtualTourName.trim(), {min: 1, max: 25})){
                toast.error("Virtual Tour Name length should be minimum 3 char and maximum 25 char", { });
                setvirtualTourNameErr(true) 
                return;
            }
            payload.name= virtualTourName;
        }else{
            if(validator.isEmpty((selectedOption.id.toString()))){
                toast.error("Please Select at least one Tour", { });
                return;
            }
            let splitImagePath= selectedOption.imagePath.split('/');
            payload.name = selectedOption.virtualTourName;
            payload.imageTitle = selectedOption.imageName;
            payload.Default360ImagNames = splitImagePath[splitImagePath.length-1];
        }

        let mappingData = payload;

        try{
            await addVirtualTour(mappingData, dispatch, tokenContext.accessToken); 
            toast("Virtual Tour Created Successfully.");
            dispatch(resetVirtualTour());
            dispatch(fetchVirtualTourDispatch(1, 25));
            toggle()
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }

    }

    return (
        <React.Fragment>  
            <ExpiredModal /> 
            {/* ADD VIRTUAL-TOUR */}
            <div className="addAssets">
                <button className="btn btn-primary" onClick={toggle}>Add Virtual Tour</button>
            </div>

             {/* ADD VIRTUAL-TOUR MODAL */}
            <Modal isOpen={modal} centered className="drop-modal modal-md virtualMdl" toggle={()=>{
                toggle()
                }} {...props}>
                <Button className="modalClose" onClick={()=>{
                    toggle()
                }}> 
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    <Nav tabs className="create-virtul-tour-nav">
                        <NavItem className="create-virtul-tour-nav-itme">
                            <NavLink
                            className={classnames({ active: activeTab === 1 })}
                            onClick={() => {
                                togglerr(1);
                                cratedVTbyOwn();
                            }}
                            >
                            Create your Virtual Tour
                            </NavLink>
                        </NavItem>
                        <NavItem className="create-virtul-tour-nav-itme">
                            <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                                togglerr(2);
                                cratedVTbyDefault();
                            }}
                            >
                            Start with a default Virtual Tour
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} style={{
                        backgroundColor: "#fff"
                    }}>
                        <TabPane tabId={1}>
                            <div>
                                {isVTcreatedByOwn && <div class="row m-0">
                                    <div className="col-12 p-0">
                                        <label className="mb-1">Create Your Virtual Tour</label>
                                        <FormGroup>
                                            <input value={virtualTourName} type="text" placeholder="Enter your virtual tour name"
                                            className={classNames("form-control", { "form-error": virtualTourNameErr })} name={'virtual-tour'} onChange={onInputChange}
                                            />
                                        </FormGroup> 
                                    </div>
                                </div>}
                            </div>
                        </TabPane>
                        <TabPane tabId={2}>
                            <div>
                                {!isVTcreatedByOwn && <div className="x-hidden"><Row className="row">
                                    <Col className="col-12">
                                        <label className="mb-1">Select a default Virtual Tour</label> 
                                    </Col>
                                </Row>
                                <Row>{defaulImgOptions.map(defaulImges=>{
                                    return (

                                        
                                        <Col className="col-sm-6 col-12 mb-2 d-flex align-items-center">
                                            <RadioButton  
                                                value= {defaulImges.id}
                                                checked= {parseInt(selectedOption.id) === parseInt((defaulImges.id))}
                                                onChange= {(e)=>{
                                                    setSelectedOption(defaulImgOptions.filter(img => parseInt(img.id) === parseInt(e.target.value)).map(img=> ({...img, fIleUrl: img.imagePath}))[0])
                                                }}
                                                radioClassess= "virtual-tour-radio-button"
                                                // label= {defaulImges.imageName}
                                                >
                                            </RadioButton>
                                            
                                            <div className="col-10 p-0 ps-2">
                                                <img src={defaulImges.imageThumbnailPath} alt="image" className="tour_image"               
                                                onClick={()=>{
                                                    setIsOpenCarousel(true)
                                                    handleOptionChange(parseInt(defaulImges.id));    
                                                }}/>
                                                <p style={{"font-size": "14px"}}>{`${defaulImges.virtualTourName}`}</p>
                                            </div>
                                        </Col>
                                    )
                                })}</Row></div>}

                            </div>
                        </TabPane>
                    </TabContent >
                        <div style={{textAlign: "center"}}>
                            <button 
                                className="btn btn-primary"
                                onClick={createdRoom}
                            >Add Virtual Tour</button>
                        </div>
                </ModalBody>
            </Modal>


            {/* Modal to be upgraded  */}
            {/* <UpgradModal
                isOpen= {openUpgrade} 
                setOpenUpgrade ={setOpenUpgrade}
            /> */}


            {selectedOption!==undefined && <CarouselModal 
                assests= {[selectedOption]}
                isOpenCarousel= {isOpenCarousel}
                closeCarousel ={closeCarousel}
                index ={0}
                hasLeftButton ={true}
                hasRightButton ={true}
            /> }
            
        </React.Fragment>
    )
}
