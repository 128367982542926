import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
  currentUploadedAssestsCount: {
    image: 0,
    audio: 0,
    video: 0
  }
}

export const currentUploadedAssestsCount = createSlice({
  name: 'currentUploadedAssestsCount',
  initialState: {
   ...initialState
  },
  reducers: {
    setCurrentUploadedAssestsCountDispatch: (state, action)=>{
        console.log('dfsdfsdyyyyuyy 2', action)
      return {
        ...state, 
        currentUploadedAssestsCount: {
            ...state.currentUploadedAssestsCount,
            [action.payload]: state.currentUploadedAssestsCount[action.payload]+1
        }
      }
    },
    resetCurrentUploadedAssestsCountDispatch: (state, action)=>{
        return {...initialState}
    },
  }
});

// Action creators are generated for each case reducer function
export const { setCurrentUploadedAssestsCountDispatch, resetCurrentUploadedAssestsCountDispatch } = currentUploadedAssestsCount.actions


export default currentUploadedAssestsCount.reducer