import React, {useState, useEffect, useMemo, useCallback } from "react";
import HeaderSideBar from "./../../index";
import { useDispatch, useSelector } from 'react-redux'; 
import InfiniteScroll from "react-infinite-scroll-component";
import ImagesCardList from "./../../../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "./../../../../UTILITYS/Constant";
import Asset from './../../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "./../../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import { VRfetchImagesDispatch, VRresetAssests, resetToggleSelectForIMGVRDispatch, setToggleSelectForIMGVRDispatch } from "./../../Slices/VRassestList.slice";
import Breadcrum from "../../../../COMPONENTS/Breadcrum";
import BG from './../../../../ASSETS/Images/BG.jpg';
import MobileBG from './../../../../ASSETS/Images/Mobile-BG.jpg';
import { setParentModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { toast } from 'react-toastify';
import BreadCrumWithShareAndDelete from "../../../AssestsManagment/BreadCrumWithShareAndDelete";
import { deleteShareAssest } from "../../../../UTILITYS/endPoints";
import DeleteModal from "../../../../COMPONENTS/DeleteModal";
import Delete from './../../../../ASSETS/Images/icons/delete.svg';
import { Tooltip } from 'reactstrap';
import useLoadNextPage from "./../../../../UTILITYS/hook/loadNextPage";
import { useMediaQuery } from 'react-responsive';
import { useLongPress } from 'use-long-press';

function ImageAssest(props){
    
    const dispatch = useDispatch();
    let {state}  = useLocation();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isIMGEmply, setIsIMGEmply] = useState(true);
    const VRassestList = useSelector(state => state.VRassestList);
    const [room, seTroom] = useState(state.room)
    const [deleteModal, setDeleteModal] = useState(false); 
    const [page, loadNextPage, resetValue] = useLoadNextPage(1, VRfetchImagesDispatch, 25, room.relationshipID,
        room.vrRoomMappingID);
    const isMobileView = useMediaQuery({ maxWidth: 767 });
    const [isLongPressEnable, setIsLongPressEnable] = useState(false); 

    const toggleTolltip=() =>{
        setTooltipOpen(!tooltipOpen)
    }

    useEffect(()=>{
        dispatch(VRresetAssests())
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("vr_Experience"))
    }, [])

    const fetchMoreData = () => {
        loadNextPage();
    };

    useEffect(()=>{
        if( VRassestList.images.length === 0){
            setIsIMGEmply(true)
        }else{
            setIsIMGEmply(false)
        }
    }, [VRassestList.images])  

    const getCheckBoxClasses = (id)=>{ 
        let currentImg= null;
        if(VRassestList.images.length>0){
            currentImg =VRassestList.images.find(img=> img.id === id && img.isImgSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    } 

    const toggleSelectBox = (id)=>{
        if(VRassestList.images.find(img=> (img.id === id && img.isImgSelected))){
            dispatch(setToggleSelectForIMGVRDispatch(id))
        }else if(VRassestList.images.filter(img=> img.isImgSelected).length ===10){
            toast.dismiss();
            toast.error("You can only select maximum 10 image", { });
            return
        }else{
            dispatch(setToggleSelectForIMGVRDispatch(id))
        }
    }

    const getImagesAssestsList = ()=>{
        if(VRassestList.images.length ===0) return null;
       return  <InfiniteScroll
                    className="row"
                    dataLength={VRassestList.images}
                    next={fetchMoreData}
                    hasMore={VRassestList.hasMoreImageAssests}
                    loader={VRassestList.images.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                    }
               >
               {getImagesAssest()}
           </InfiniteScroll>
      
    }

    const bind = useLongPress((e, params) => {
        // if(!isLongPressEnable){ 
        if(!isLongPressEnable && isMobileView !== undefined && isMobileView){ 
            setIsLongPressEnable(true);
            toggleSelectBox(params.context);
        }
    });

    useEffect(()=>{
        if(VRassestList.images.filter(img=> img.isImgSelected).length === 0){
            setIsLongPressEnable(false);
        }
    }, [VRassestList.images.filter(img=> img.isImgSelected).length])


    const getImagesAssest = ()=>{
        return VRassestList.images.map((img, index)=>{
            return <ImagesCardList 
                img={img} 
                index ={index} 
                key ={index} 
                // isFF={true} 
                assests= {VRassestList.images}
                getCheckBoxClasses ={()=>getCheckBoxClasses(img.id)}
                toggleSelectBox ={()=>toggleSelectBox(img.id)}
                isMobileView= {isMobileView}
                bind= {bind}
                isLongPressEnable= {isLongPressEnable}
                // isHideCheckBox= {true}
            /> 
        })
    }

    const getArrayOfOBJ =()=>{
      
        let imagesIDs= "";
        imagesIDs=  VRassestList.images.filter(img=> img.isImgSelected ).map(img=> {
            return img.id
        }).toString();

        return {
            AssetsIDs: imagesIDs,
            VRExperienceMappintID: room.vrRoomMappingID
        }
    }

    const deletingAssestHandler = async(deleteAssestDATA, dispatch, token)=>{
        let deletRes=  await deleteShareAssest( deleteAssestDATA, dispatch, token);
        dispatch(resetToggleSelectForIMGVRDispatch())
        dispatch(VRresetAssests());
        if(page !== 1){
            resetValue(1);
        }else{
            dispatch(VRfetchImagesDispatch(1, 25, room.relationshipID, room.vrRoomMappingID));
        }
        return deletRes;
    }

    let isAnyOneIsSelected = null;
    let assestsCounts = null;
    isAnyOneIsSelected =VRassestList.images.find(img=> img.isImgSelected );
    assestsCounts =VRassestList.images.filter(img=> img.isImgSelected);

    const memoizedValue = useMemo(() =>["VR Experience", "Images" ], []);
    const memoizedValueIsAnyOneIsSelected = useMemo(() =>isAnyOneIsSelected, [assestsCounts.length]);
    const memoizedValueAssestsCounts = useMemo(() =>assestsCounts, [assestsCounts.length]);
    const memoizedFunOnUnselectAssest = useCallback(() =>{ dispatch(resetToggleSelectForIMGVRDispatch()) }, [assestsCounts.length]);

    if(isIMGEmply){

        return <HeaderSideBar>
            <NoContentWithOutUploadPage
                // breadCrumbItems= {["VR Experience", "Images" ]}
                breadCrumbItems= {memoizedValue}
                primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                noImgIcon= {Asset}
            />
        </HeaderSideBar>
    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                {/* Mom (Living Room) (7th VRpic-Sprint-6) */}

                <div className={isAnyOneIsSelected != null && isAnyOneIsSelected != undefined ? 'innerHeader withBorder ps-3 pe-3 ps-md-4 pe-md-4' : 'innerHeader ps-3 pe-3 ps-md-4 pe-md-4'}>
                    <div className="row align-items-center">
                            {/* <Breadcrum 
                                breadCrumbItems= {["VR Experience", "Images"]}
                            /> */}
                            <BreadCrumWithShareAndDelete  
                                // isAnyOneIsSelected= {isAnyOneIsSelected}
                                // assestsCounts ={assestsCounts}
                                // breadCrumbItems = {["VR Experience", "Images"]}
                                // onUnselectAssest={()=>dispatch(resetToggleSelectForIMGVRDispatch())}
                                isAnyOneIsSelected= {memoizedValueIsAnyOneIsSelected}
                                assestsCounts ={memoizedValueAssestsCounts}
                                breadCrumbItems = {memoizedValue}
                                onUnselectAssest={memoizedFunOnUnselectAssest}
                            />

                        {isAnyOneIsSelected != null && isAnyOneIsSelected != undefined &&
                            <div className="col-sm-6 text-end">
                            <button id={"deleteAssestButton"} className="btn btn-primary btn-sm" onClick={setDeleteModal}>
                                <img src={Delete} alt="Images" className="img-fluid" />
                            </button>
                            <Tooltip placement="bottom" isOpen={tooltipOpen} target={"deleteAssestButton"} toggle={toggleTolltip}>Delete
                            </Tooltip>
                        </div>}
                    </div>
                </div>
 
                <div className="pageTitle">
                    <div className="imageHeader">
                        <img src={BG} alt="banner" className="d-none d-md-block w-100" />
                        <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                        <h3>{state.vrUser.name} (Living Room)</h3>
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4 inner_lists">            
                    <div className="assets_item ps-2 pe-2">
                        {getImagesAssestsList()}
                    </div>
                </div>

                 {/* Delete assests */}
                 <DeleteModal 
                    isOpen= {deleteModal} 
                    setDeleteModal ={setDeleteModal}
                    getArrayOfOBJ= {getArrayOfOBJ}
                    onDeletingAssest = {deletingAssestHandler}
                />
            </HeaderSideBar>
        </React.Fragment>
    )
}

export default (ImageAssest)