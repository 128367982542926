import React, {useState} from "react";
import { Col, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleSelectForIMGDispatch } from "./../../../SCREENS/Home/slice.reducer";
import Close from './../../../ASSETS/Images/icons/cancel.png' 
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap';
import Gallery from './../../../COMPONENTS/Galaries/Gallerary'
import CarouselModal from "../../Galaries/CarouselModal";
import { setToggleSelectForIMGSharedByThisUser } from "../../../SCREENS/FamilyAndFriends/slice.reducer";
// import { useLongPress } from 'use-long-press';

export default (props)=>{

    const dispatch = useDispatch(); 
    const assestsData = useSelector(state => state.assestsData);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const [isOpenCarousel, setIsOpenCarousel] = useState(false); 
    // const [isLongPressEnable, setIsLongPressEnable] = useState(false); 

    const closeCarousel = ()=>{
        setIsOpenCarousel(false)
    }

    const getCheckBox = ()=>{
        if(!props.isFF && props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.img.id)}
            ></span>
        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.img.id)}
            ></span>
        }else if(!props.isFF && (props.isMobileView=== undefined || !props.isMobileView)){
            return <span class="customCheck" 
                onClick={()=>props.toggleSelectBox(props.img.id)}
            ></span>
        }
    }
    
    return (
        <React.Fragment >
            <Col sm="6" md="4" lg="3" className="ps-1 pe-1" key={props.img.id}>
                <Card className={props.getCheckBoxClasses(props.img.id)} >
                   {getCheckBox()}
                    <CardBody className="p-0" {...(props.bind !== undefined ? props.bind(props.img.id) : {})} onClick={()=>{           
                        if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable){
                            props.toggleSelectBox(props.img.id);
                        }else if(props.isMobileView !== undefined && props.isMobileView && props.isLongPressEnable === undefined){
                            props.toggleSelectBox(props.img.id);
                        }else{
                            setIsOpenCarousel(true);
                        }
                    }}>
                        <img src={props.img.thumbnailUrl} 
                            onContextMenu={(event) => {
                                event.preventDefault(); // Prevent the context menu from appearing
                            }} 
                            alt="image" 
                            className="assets_image prevent-context-view" 
                        />
                    </CardBody>
                </Card>
            </Col>

            {/* CAROUSAL MODAL */}
            <CarouselModal 
                assests= {props.assests}
                isOpenCarousel= {isOpenCarousel}
                closeCarousel ={closeCarousel}
                index ={props.index}
            />
        </React.Fragment>
    )
}