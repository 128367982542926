import React, {useState} from "react";
import UploadButton from './Button'
import { Modal, ModalBody,Button} from 'reactstrap';
import DragAndDropFiles from "../DRAGE_FILE_FOLDER";
import Close from './../../ASSETS/Images/icons/cancel.png'
import { ExpiredModal } from "../Modal/Expired";
import { setDoNotShowExpiredModal } from "../../SCREENS/Profile/slice.reducer";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux'

const UploadButtonCompo= (props)=>{

    console.log('this is UploadButtonCompo component 1',);

    const [uploadModal, setUploadModal] = useState(false);
    const assestsData = useSelector(state => state.assestsData);
    const dispatch = useDispatch();
    const userProfileDetails = useSelector(state => state.userProfileDetails)

    const toggleUploadModal = () => {

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        if(uploadModal && assestsData.isFileUploadingState){
            props.onToggleUpload();
        }
        setUploadModal(!uploadModal);
    } 

    return (
        <React.Fragment>
            <ExpiredModal /> 
            <UploadButton toggleUploadModal={toggleUploadModal}/> 
            
            {/* Upload Model */}
            <Modal isOpen={uploadModal} centered className="drop-modal" >
                <Button className="modalClose" onClick={toggleUploadModal}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    <div className="Drop_Area">
                        <DragAndDropFiles 
                            preventToUpload = {()=>setUploadModal(!uploadModal)}
                            closeVideoInfoModalAndDZ = {toggleUploadModal}
                        />
                    </div>
                </ModalBody>
            </Modal>
       
        </React.Fragment>
    )
}

export default React.memo(UploadButtonCompo);
// export default (UploadButtonCompo);