export const getSubscriptionStatusBC =(status)=> {
    if( status === "1" || status === "3" ) return {"background-color": "#00D924", "color": "#000", "border": "1px solid black"}
  else if( status === "2" || status === "4" ) return {"background-color":"#EB9A00", "color": "#000", "border": "1px solid black"}
    else return {"background-color":"#DB0000", "color": "#000", "border": "1px solid black"}
  }
  
  export const getColor =(color, property)=> {
    return {[property]: color}
  }
  
  export const getStatusClass =(userDetails)=>{    
  
    if(userDetails.isPremium && !userDetails.isExpired){
      return "user-premium"
    }else if(userDetails.isExpired){
      return "user-expired"
    }else if(!userDetails.isPremium){
      return "user-free"
    }
  
  }

  // 8423793782 akansh