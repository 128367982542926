import React, { useState, useEffect, useReducer } from "react";
import Profile from ".";
import { NavLink, useNavigate } from "react-router-dom";
import Arrow from './../../ASSETS/Images/icons/arrow_1.png';
import Upload from './../../ASSETS/Images/icons/upload.png';
import Delete from './../../ASSETS/Images/icons/deleteWhite.png';
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import Edit from './../../ASSETS/Images/edit.png';
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter,Breadcrumb, BreadcrumbItem, Form, FormGroup, Label, Input } from 'reactstrap';
import Eye from './../../ASSETS/Images/eye.png';
import EyeOff from './../../ASSETS/Images/eye-off.png';
import { updatePassword } from "../../UTILITYS/endPoints";
import { useSelector, useDispatch } from 'react-redux'
import validator from 'validator';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import classNames from 'classnames';
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { useContext } from "react";
import CreateTokenContextProvider from "../../STORE/App-wide-state/Token/createTokenContextProvider";

const defaultReducerValue = {
    currentPassword: {value: "", isNotValid: false, name: "currentPassword"},
    newPassword: {value: "", isNotValid: false, name: "newPassword"},
    confirmPassword: {value: "", isNotValid: false, name: "confirmPassword"},
};

const CPreducer = (state, action)=>{
    
    switch(action.type){
        case "NEW_PASSWORD":
            return {
                ...state,
                newPassword: {
                    ...state.newPassword,
                    value: action.payload,
                    isNotValid: false
                }
            }
        case "CURRENT_PASSWORD":
            return {
                ...state,
                currentPassword: {
                    ...state.currentPassword,
                    value: action.payload,
                    isNotValid: false
                }
            }
        case "CONFIRM_PASSWORD":
            return {
                ...state,
                confirmPassword: {
                    ...state.confirmPassword,
                    value: action.payload,
                    isNotValid: false
                }
            }
        
        case "ERR":
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    isNotValid: true
                }
            }

        default: 
           return state
    }
}

export default ()=>{
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const tokenContext = useContext(CreateTokenContextProvider);
    const userProfileDetails = useSelector(state => { return state.userProfileDetails })
    const [isCurrentPasswordMaskedOPen,  setisCurrentPasswordMaskedOPen] = useState(true)
    const [isNewPasswordMaskedOPen, setisNewPasswordMaskedOPen] = useState(true)
    const [isConfirmPasswordMaskedOPen, setConIsPasswordMaskedOPen] = useState(true)
    const [changePasswordInputes, dispatchchangePasswordInputes] = useReducer( CPreducer, defaultReducerValue );

    useEffect(()=>{
        dispatch(setModuleActiveDispatch("changePassword"))
        dispatch(setParentModuleActiveDispatch("parentProfile")) 
        },
    []);

    const onInputChange = (e)=>{
    
        switch(e.target.name){
          case "currentPassword":
            dispatchchangePasswordInputes({type: "CURRENT_PASSWORD", payload: e.target.value});
            break;
          case "newPassword":
            dispatchchangePasswordInputes({type: "NEW_PASSWORD", payload: e.target.value});
            break;
          case "confirmPassword":
            dispatchchangePasswordInputes({type: "CONFIRM_PASSWORD", payload: e.target.value});
            break;
        }
    }

    async function applyUpdatePassword(e){
    
        e.preventDefault();
        toast.dismiss()

        if(!validator.isLength(changePasswordInputes.currentPassword.value, {min: 8, max: 14})){
            if(changePasswordInputes.currentPassword.value === "") toast.error("Please Enter Current Password", { });
            else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "currentPassword"});
            return;
        }

        if(!validator.isLength(changePasswordInputes.newPassword.value, {min: 8, max: 14})){
            if(changePasswordInputes.newPassword.value === "") toast.error("Please Enter New Password", { });
            else toast.error("Password length should be minimum 8 char and maximum 14 char", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "newPassword"});
            return;
        }

        if(validator.isEmpty(changePasswordInputes.confirmPassword.value)){
            toast.error("Please Enter Confirm Password", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "confirmPassword"});
            return;
          }else if(changePasswordInputes.newPassword.value !== changePasswordInputes.confirmPassword.value){
            toast.error("New Password and Confirm Password must be the same", { });
            dispatchchangePasswordInputes({type: "ERR", payload: "confirmPassword"});
            return;
          } 

          let updatePasswordData = {
            currentPassword: changePasswordInputes.currentPassword.value,
            newPassword: changePasswordInputes.newPassword.value,
            confirmPassword: changePasswordInputes.confirmPassword.value,
            email: userProfileDetails.email
        }

        try{
            await updatePassword(updatePasswordData, dispatch, tokenContext.accessToken);
            toast("Password Updated Successfully!");
            navigate('/manage-account');
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }

    }

    return ( 
        <React.Fragment>
            <Profile >
                
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>Change password</h5>
                            </Col>
                        </Row>
                    </div>

                    {/* Profile content  */}
                    <div className="ps-3 pe-3 pt-4 profile-cont">
                        <div className="row mb-0 mb-md-2 text-center text-md-start">
                            <div className="d-md-flex col-md-12 col-lg-12">
                                <div className="profile-large ms-auto ms-md-0 me-auto me-md-0">
                                    <img src={userProfileDetails.profilePicPath!=""?userProfileDetails.profilePicPath: ProfileImage} alt="profile" className="img-thumbnail" />
                                </div>
                                <div className="ps-0 ps-md-4 ms-0 ms-md-2 pt-4 pt-md-0">
                                    <h4 className="userName">{userProfileDetails.firstName} {userProfileDetails.lastName}</h4>
                                    <p>{userProfileDetails.email}</p>
                                </div>
                            </div>
                        </div>

                        {/* edit profile Form  */}
                        <Form className="change_password" onSubmit={applyUpdatePassword}>
                            <div className="row pt-2 pt-md-4">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <FormGroup>
                                        <Label htmlFor={changePasswordInputes.currentPassword.name}>Current Password</Label>
                                        <div className="password-field">
                                            <Input value={changePasswordInputes.currentPassword.value} onChange={onInputChange} id={changePasswordInputes.currentPassword.name} placeholder="Current Password" name={changePasswordInputes.currentPassword.name} type={isCurrentPasswordMaskedOPen ?'password': "Text"}
                                            className={classNames({ "form-error": changePasswordInputes.currentPassword.isNotValid })}
                                            />
                                            <span onClick={()=>setisCurrentPasswordMaskedOPen(!isCurrentPasswordMaskedOPen)}>
                                                <img src={isCurrentPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                            </span>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <FormGroup>
                                        <Label htmlFor={changePasswordInputes.newPassword.name}>New Password</Label>
                                        <div className="password-field">
                                            <Input value={changePasswordInputes.newPassword.value} onChange={onInputChange} id={changePasswordInputes.newPassword.name} placeholder="New Password" name={changePasswordInputes.newPassword.name} type={isNewPasswordMaskedOPen ?'password': "Text"}
                                            className={classNames({ "form-error": changePasswordInputes.newPassword.isNotValid })}
                                            />
                                            <span onClick={()=>setisNewPasswordMaskedOPen(!isNewPasswordMaskedOPen)}>
                                                <img src={isNewPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                            </span>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <FormGroup >
                                        <Label htmlFor={changePasswordInputes.confirmPassword.name}>Confirm Password</Label>
                                        
                                        <div className="password-field">
                                            <Input value={changePasswordInputes.confirmPassword.value} onChange={onInputChange} id={changePasswordInputes.confirmPassword.name} placeholder="Confirm Password" name={changePasswordInputes.confirmPassword.name} type={isConfirmPasswordMaskedOPen ?'password': "Text"} 
                                            className={classNames({ "form-error": changePasswordInputes.confirmPassword.isNotValid })}
                                            />
                                            <span onClick={()=>setConIsPasswordMaskedOPen(!isConfirmPasswordMaskedOPen)}>
                                                <img src={isConfirmPasswordMaskedOPen ?Eye: EyeOff} alt="Eye" />
                                            </span>
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-lg-12 pt-3 mb-3">
                                    <Button className="me-2 me-md-3 mb-2" color="primary">
                                        Save
                                    </Button>
                                    <NavLink to="/manage-account" className="ms-2 ms-md-3 mb-2 btn btn-outline-dark">Cancel</NavLink>
                        
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </Profile>
        </React.Fragment>
    )
}