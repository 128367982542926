import { Route, Routes, Navigate } from "react-router-dom";
import Login from './../SCREENS/Authentications/Login'

// REGISTRATION RELETED ROUTE
import Registration from './../SCREENS/Authentications/Registration'
import ConfirmUser from './../SCREENS/Authentications/ConfirmUser'
import Congratulation from './../SCREENS/Congratulation/Congratulation'

// FORGET AND RESET RELETED ROUTE
import ChangePasswordSuccessView from './../SCREENS/Authentications/ForgetResetPassword/ChangePasswordSuccessView'
import ForgetPassword from './../SCREENS/Authentications/ForgetResetPassword/ForgetPassword'
import ResetPassword from './../SCREENS/Authentications/ForgetResetPassword/ResetPassword'
import SentEmailView from './../SCREENS/Authentications/ForgetResetPassword/SentEmailView'

// THANK YOU
import ThankYou from '../SCREENS/ThankYou/landing'

// PROFILE RELETED ROUTE
import ViewProfile from "./../SCREENS/Profile/View.profile.tsx";
import EditProfile from "./../SCREENS/Profile/Edit.profile.tsx";
import ChangePasswordProfile from "./../SCREENS/Profile/ChangePassword.profile";
import Home from "../SCREENS/Home/Home";

// ASSESTS MANAGMENT RELATED ROUTE
import Audios from "./../SCREENS/AssestsManagment/Assest/Audios";
import Videos from "./../SCREENS/AssestsManagment/Assest/Videos";
import Images from "../SCREENS/AssestsManagment/Assest/Images";

// FAMILY AND FRIENDS
import SharedWithMeUserInfo from "../SCREENS/FamilyAndFriends/SharedUserInfo/SharedWithMeUsersInfo";
import SharedWithOthersUsersInfo from "../SCREENS/FamilyAndFriends/SharedUserInfo/SharedWithOthersUsersInfo";
import SharedByMe from "../SCREENS/FamilyAndFriends/SharedAssests/SharedByMe";
import SharedByUser from "../SCREENS/FamilyAndFriends/SharedAssests/SharedByUser";
import SharedIMGlist from "../SCREENS/FamilyAndFriends/ListOfassests/SharedIMGlist";
import SharedAudiolist from "../SCREENS/FamilyAndFriends/ListOfassests/SharedAudiolist";
import SharedVideolist from "../SCREENS/FamilyAndFriends/ListOfassests/SharedVideolist";

// VR-EXPERIENCE
import VRhome from "../SCREENS/VR-experience/VRhome";
import RoomsList from "../SCREENS/VR-experience/VR-Room/RoomsList";
import SharedAssestsInRoom from "../SCREENS/VR-experience/Assests/SharedAssests";
import VRImagesList from "../SCREENS/VR-experience/Assests/List/Images";
import VRAudiosList from "../SCREENS/VR-experience/Assests/List/Audios";
import VRVideosList from "../SCREENS/VR-experience/Assests/List/Videos";

// SUBSCRIPTION
import Subscription from "../SCREENS/Subscription/index.js";
import Checkout from "../SCREENS/Subscription/checkout.js";
// import Subscription from "../SCREENS/Subscription/Landing.js";
// import Checkout from "../SCREENS/Subscription/Screen/Checkout";

// PIN-MANAGMENT
import PinLandingPage from "../SCREENS/PinManagement/PinLandingPage";

// INVITE-CONTRIBUTOR-MANAGMENT
import InviteContributor from "../SCREENS/InviteContributor/SendEmail";

// VIRTUAL-TOUR
import VirtualHome from "../SCREENS/360virtualTour/VirtualHome.js";
import VirtualTourImages from "../SCREENS/360virtualTour/Virtual-tour-images-landing-page";
import ThreeSixtyWorld from "../SCREENS/360virtualTour/ThreeSixtyWorld";
 
const AllRoutes = () => ( 
    <Routes>
        {/* SCREENS RELATED TO authentication(outer part of application) */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/confirmation" element={<ConfirmUser />} />
        <Route path="/congratulation" element={<Congratulation />} />
        <Route path="/ChangePasswordSuccessView" element={<ChangePasswordSuccessView />} />
        <Route path="/CreatedPasswordSuccessView" element={<ChangePasswordSuccessView />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="/thankYou" element={<ThankYou />} />
        <Route path="/createpassword" element={<ResetPassword />} />
        <Route path="/sentEmailView" element={<SentEmailView />} />

        {/* SCREENS RELATED TO INNER PART */}
        {/* <Route path="/sideBar" element={<SideBar />} /> */}
        {/* <Route path="/profile" element={<Profile />} /> */}

        {/* SCREENS RELATED TO PROFILE ROUTE(inner part of application) */}
        <Route path="/manage-account" element={<ViewProfile />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/change-password" element={<ChangePasswordProfile />} />

        {/* SCREEN RELETED TO HOME PAGE */}
        <Route path="/homePage" element={<Home />} />

        {/* SCREEN RELETED TO HOME PAGE */}
        <Route path="/assets-images" element={<Images />} />
        <Route path="/assets-videos" element={<Videos />} />
        <Route path="/assets-audios" element={<Audios />} />

        {/* FAMILY AND FRIENDS */}
        <Route path="/share-with-me" element={<SharedWithMeUserInfo />} />
        <Route path="/share-with-others" element={<SharedWithOthersUsersInfo />} />
        <Route path="/SharedByMe" element={<SharedByMe />} />
        <Route path="/SharedByUser" element={<SharedByUser />} />
        <Route path="/Shared-img-list" element={<SharedIMGlist />} />
        <Route path="/Shared-audio-list" element={<SharedAudiolist />} />
        <Route path="/Shared-video-list" element={<SharedVideolist />} />
        
        {/* VR-EXPERIENCE */}
        <Route path="/viewer" element={<VRhome />} />
        <Route path="/vr-rooms-list" element={<RoomsList />} />
        <Route path="/shared-room-assests" element={<SharedAssestsInRoom />} />
        <Route path="/vr-images-list" element={<VRImagesList />} />
        <Route path="/vr-audios-list" element={<VRAudiosList />} />
        <Route path="/vr-videos-list" element={<VRVideosList />} />

        {/* PIN-MANAGMENT */}
        <Route path="/pin-management" element={<PinLandingPage />} />
    
        {/* INVITE-CONTRIBUTOR-MANAGMENT */}
        <Route path="/invite-contributor" element={<InviteContributor />} />
        
        {/* VIRTUAL-TOUR */}
        <Route path="/virtual-tour" element={<VirtualHome />} />
        <Route path="/virtual-tour-images" element={<VirtualTourImages />} />
        <Route path="/virtual-tour-world" element={<ThreeSixtyWorld />} />
        
        {/* SUBSCRIPTION */}
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/checkout" element={<Checkout />} />

        {/* WILD CARD URL(if url not founded) */}
        <Route path="*" element={<Navigate to="/"/>} />
    </Routes>
  );

  export default AllRoutes;