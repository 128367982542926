import React, { useEffect, useState } from "react";
import './index.css';
import { NavLink, useSearchParams } from "react-router-dom";

import Logo from './../../ASSETS/Images/Recuerdo_1x.png';
import loginBackground from './../../ASSETS/Images/Background_image.jpg';
import verifiedEmail from './../../ASSETS/Images/email_verified.png';
import { verifyEmail } from "../../UTILITYS/endPoints";
import { 
  useDispatch } from 'react-redux'

function Congratulation() {

  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams();

  const [token, setToken] = React.useState(
      searchParams.get("token")
  );
  const [email, setEmail] = React.useState(
      searchParams.get("email")
  );

  useEffect(()=>{
    window.scrollTo(0, 0);
    async function verifyEmailHandler(){
      try{
        console.log('this is try verifyEmailHandler', searchParams.get("email"), searchParams.get("token"))
        await verifyEmail({token, email}, dispatch);
      }catch (err){

      }
    }
    verifyEmailHandler()

  }, [])

    return (
      <React.Fragment>
        <div className="container-fluid login-container">
          <div className="row">
              <div className="col-md-6 login-content d-none d-md-flex" style={{backgroundImage: `url(`+loginBackground+`)` }}>
                  <h4 className="text-light text-center">"Love your parents. We are so busy growing up; we often forget that they are also growing old." - Unknown</h4>
              </div>
              <div className="col-md-6 login-form">
                 
                <div className="text-center mb-3 mt-3">
                  <img src={Logo} alt="Logo" className="mb-5" />
                  
                  <div className="email-verification pb-5">
                    <img src={verifiedEmail} alt="Logo" className="mb-3 pb-1" /> 
                      <h5>Congratulations, your account has been verified.</h5>
                      <div className="mt-4 pt-2">
                        <NavLink className="btn btn-primary w-100" to="/login">Login</NavLink>
                      </div>
                    </div>
                  </div>

                  {/* copyright content  */}
                  <div className="text-center copyright">
                    <small>Copyright 2024 © Recuerdo All Rights Reserved</small>
                  </div>
              </div>
              
          </div>
      </div>
      </React.Fragment>
    );
  }
  
  export default Congratulation;