import { createSlice } from '@reduxjs/toolkit'
import { getData } from './../../UTILITYS/index.tsx';
import { current } from '@reduxjs/toolkit'

import * as API from './../../APIs'

const initialState = {
  sharedByThisUser: {
    images: [],
    selectedImgIds: [],
    audios: [],
    selectedAudIds: [],
    videos: [],
    selectedVidIds: [],
    isImgResGet: false,
    isAudioResGet: false,
    isVideoResGet: false,
    hasMoreImageAssests: true,
    hasMoreAudioAssests: true,
    hasMoreVediosAssests: true,
  }, 
  sharedByme: {
    images: [],
    audios: [],
    videos: [],
    hasMoreImageAssests: true,
    hasMoreAudioAssests: true,
    hasMoreVediosAssests: true,
  }, 
}

export const sharedAssestWithMeOrBy = createSlice({
  name: 'sharedAssestWithMeOrBy',
  initialState: { ...initialState }, 
  reducers: {
  
    // REDUCERS FOR SHARED BY USERS
    setImagesSharedByThisUser: (state, action) => {
      return {
        ...state, 
        sharedByThisUser: {
          ...state.sharedByThisUser,
          images: [...state.sharedByThisUser.images ,...action.payload.pagedList.map(img=>{

            let isImgSelected= false;
            if(state.sharedByThisUser.selectedImgIds.length >0){
              state.sharedByThisUser.selectedImgIds.forEach(sizeId=>{
                if(img.id === sizeId.id){
                  isImgSelected= true
                }
              })
            }

            return {
              ...img,
              isImgSelected: isImgSelected
            }
          })]
        }
      }
    },
    setToggleSelectForIMGSharedByThisUser: (state, action) => {
      
      let addImgId= false;
      let addImgSize= 0;
      [...state.sharedByThisUser.images].map(img=>{
        if(action.payload === img.id){
          addImgSize= img.fileSizeInKB;
          if(!img.isImgSelected)
          addImgId= true;
        }
      })

      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          images: [...state.sharedByThisUser.images].map(img=>{
            if(action.payload === img.id){
                return {
                  ...img,
                  isImgSelected: !img.isImgSelected
                }
              }
            return {
              ...img,
            }
          }),
          // selectedImgIds: addImgId ? [...state.sharedByThisUser.selectedImgIds, action.payload]: state.sharedByThisUser.selectedImgIds.filter(id => id!== action.payload)
          selectedImgIds: addImgId ? [...state.sharedByThisUser.selectedImgIds, {id: action.payload, size: addImgSize}]: state.sharedByThisUser.selectedImgIds.filter(sizeId => sizeId.id!== action.payload)
        }
      }
    },
    resetToggleSelectForIMGSharedByThisUser: (state, action) => {
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          images: [...state.sharedByThisUser.images].map(img=>{
            return {
              ...img,
              isImgSelected: false
            }
          })
        }
      }
    },
    setAudiosSharedByThisUser: (state, action) => {
      // console.log('this is our sharedAssestWithMeOrBy submit data >>', action)
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          audios: [...state.sharedByThisUser.audios ,...action.payload.pagedList.map(aud=>{

            let isAudSelected= false;
            if(state.sharedByThisUser.selectedAudIds.length >0){
              state.sharedByThisUser.selectedAudIds.forEach(sizeId=>{
                if(aud.id === sizeId.id){
                  isAudSelected= true
                }
              })
            }

            return {
              ...aud,
              isAudioSelected: isAudSelected
            }
          })]
        }
      }
    },
    setToggleSelectForAudioSharedByThisUser: (state, action) => {

      let addAudId= false;
      let addAudSize= 0;
      [...state.sharedByThisUser.audios].map(aud=>{
        if(action.payload === aud.id){
          addAudSize =aud.fileSizeInKB;
          if(!aud.isAudioSelected)
          addAudId= true;
        }
      })

      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          audios: [...state.sharedByThisUser.audios].map(aud=>{
            if(action.payload === aud.id){
                return {
                  ...aud,
                  isAudioSelected: !aud.isAudioSelected
                }
              }
            return {
              ...aud
            }
          }),
          selectedAudIds: addAudId ? [...state.sharedByThisUser.selectedAudIds, {id: action.payload, size: addAudSize}]: state.sharedByThisUser.selectedAudIds.filter(sizeId => sizeId.id!== action.payload)
        }
      }
    },
    resetToggleSelectForAudioSharedByThisUser: (state, action) => {
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          audios: [...state.sharedByThisUser.audios].map(aud=>{
            return {
              ...aud,
              isAudioSelected: false
            }
          })
        }
      }
    },
    setVideosSharedByThisUser: (state, action) => {
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          videos: [...state.sharedByThisUser.videos ,...action.payload.pagedList.map(vid=>{

            let isVidSelected= false;
            if(state.sharedByThisUser.selectedVidIds.length >0){
              state.sharedByThisUser.selectedVidIds.forEach(sizeId=>{
                if(vid.id === sizeId.id){
                  isVidSelected= true
                }
              })
            }

            return {
              ...vid,
              isVideoSelected: isVidSelected
            }
          })]
        }
      }
    },
    setToggleSelectForVideoSharedByThisUser: (state, action) => { 

      let addVidId= false;
      let addVidSize= 0;
      [...state.sharedByThisUser.videos].map(vid=>{
        if(action.payload === vid.id){
          addVidSize= vid.fileSizeInKB;
          if(!vid.isVideoSelected)
          addVidId= true;
        }
      })

      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          videos: [...state.sharedByThisUser.videos].map(vid=>{
            if(action.payload === vid.id){
                return {
                  ...vid,
                  isVideoSelected: !vid.isVideoSelected
                }
              }
            return {
              ...vid
            }
          }),
          selectedVidIds: addVidId ? [...state.sharedByThisUser.selectedVidIds, {id: action.payload, size: addVidSize}]: state.sharedByThisUser.selectedVidIds.filter(sizeId => sizeId.id!== action.payload)
        }
      }
    },
    resetToggleSelectForVideoSharedByThisUser: (state, action) => {
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          videos: [...state.sharedByThisUser.videos].map(aud=>{
            return {
              ...aud,
              isVideoSelected: false
            }
          })
        }
      }
    },
    setResponseForSharedByThisUser: (state, action) => {
      return {
        ...state,   
        sharedByThisUser: {
          ...state.sharedByThisUser,
          [action.payload.key]: action.payload.value
        } 
      }
    },
    setHasMoreImageAssestsForSharedByThisUser: (state, action)=>{
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          hasMoreImageAssests: action.payload
        }
      }
    },
    setHasMoreAudioAssestsForSharedByThisUser: (state, action)=>{
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          hasMoreAudioAssests: action.payload
        }
      }
    },
    setHasMoreVideoAssestsForSharedByThisUser: (state, action)=>{
      return {
        ...state,
        sharedByThisUser: {
          ...state.sharedByThisUser,
          hasMoreVediosAssests: action.payload
        }
      }
    },
    
    // REDUCERS FOR SHARED BY ME
    setImagesSharedByme: (state, action) => {
      // console.log('this is our sharedAssestWithMeOrBy submit data >>', action)
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          images: [...state.sharedByme.images ,...action.payload.pagedList]
        }
      }
    },
    setAudiosSharedByme: (state, action) => {
      // console.log('this is our sharedAssestWithMeOrBy submit data >>', action)
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          audios: [...state.sharedByme.audios ,...action.payload.pagedList]
        }
      }
    },
    setVideosSharedByme: (state, action) => {
      // console.log('this is our sharedAssestWithMeOrBy submit data >>', action)
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          videos: [...state.sharedByme.videos ,...action.payload.pagedList]
        }
      }
    },
    setHasMoreImageAssestsForSharedByme: (state, action)=>{
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          hasMoreImageAssests: action.payload
        }
      }
    },
    setHasMoreAudioAssestsForSharedByme: (state, action)=>{
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          hasMoreAudioAssests: action.payload
        }
      }
    },
    setHasMoreVideoAssestsForSharedByme: (state, action)=>{
      return {
        ...state,
        sharedByme: {
          ...state.sharedByme,
          hasMoreVediosAssests: action.payload
        }
      }
    },

    isFileUploading: (state, action) => {
      // console.log('this is our sharedAssestWithMeOrBy submit data >>', action)
      return {
        ...state,
        isFileUploadingState: action.payload
      }
    },
    resetSharedAssestWithMeOrBy: (state, action)=>{
      return {...initialState}
    },

    resetSharedAssestWithMeOrByButPreservState: (state, action)=>{
      return {
        ...initialState,
        sharedByThisUser: {
          ...initialState.sharedByThisUser,
          selectedImgIds: state.sharedByThisUser.selectedImgIds,
          selectedAudIds: state.sharedByThisUser.selectedAudIds,
          selectedVidIds: state.sharedByThisUser.selectedVidIds,
        }
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setImagesSharedByThisUser,
  setAudiosSharedByThisUser,
  setVideosSharedByThisUser,
  setHasMoreImageAssestsForSharedByThisUser,
  setHasMoreAudioAssestsForSharedByThisUser,
  setHasMoreVideoAssestsForSharedByThisUser,
  setImagesSharedByme,
  setAudiosSharedByme,
  setVideosSharedByme,
  setHasMoreImageAssestsForSharedByme,
  setHasMoreAudioAssestsForSharedByme,
  setHasMoreVideoAssestsForSharedByme,
  isFileUploading,
  resetSharedAssestWithMeOrBy,
  resetSharedAssestWithMeOrByButPreservState,
  setToggleSelectForIMGSharedByThisUser,
  setToggleSelectForAudioSharedByThisUser,
  setToggleSelectForVideoSharedByThisUser,
  resetToggleSelectForIMGSharedByThisUser,
  resetToggleSelectForAudioSharedByThisUser,
  resetToggleSelectForVideoSharedByThisUser,
  setResponseForSharedByThisUser
} = sharedAssestWithMeOrBy.actions

// for images (SHARED BY USER)
const imagesSharedByThisUserFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a ImagesSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_ME}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=3&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchImagesSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchImagesSharedByThisUser try", fetchImagesSharedByThisUser)
    
    return fetchImagesSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchImagesSharedByThisUser catch")
    throw (err)
  }

}
export const fetchImagesSharedByThisUserDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resImagesSharedByThisUser =await imagesSharedByThisUserFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resImagesSharedByThisUser.result.payload.pagedList.length === 0){
          dispatch(setHasMoreImageAssestsForSharedByThisUser(false))
        }
        
        dispatch(setImagesSharedByThisUser(resImagesSharedByThisUser.result.payload))

        dispatch(setResponseForSharedByThisUser({ key: "isImgResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}
// for audios (SHARED BY USER)
const AudioSharedByThisUserFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a AudioSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_ME}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=1&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchAudioSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchAudioSharedByThisUser try", fetchAudioSharedByThisUser)
    
    return fetchAudioSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchAudioSharedByThisUser catch")
    throw (err)
  }

}
export const fetchAudioSharedByThisUserDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resAudioSharedByThisUser =await AudioSharedByThisUserFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resAudioSharedByThisUser.result.payload.pagedList.length === 0){
          dispatch(setHasMoreAudioAssestsForSharedByThisUser(false))
        }
        
        dispatch(setAudiosSharedByThisUser(resAudioSharedByThisUser.result.payload))

        dispatch(setResponseForSharedByThisUser({ key: "isAudioResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}
// for videos (SHARED BY USER)
const vidioSharedByThisUserFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a AudioSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_ME}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=2&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchAudioSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchAudioSharedByThisUser try", fetchAudioSharedByThisUser)
    
    return fetchAudioSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchAudioSharedByThisUser catch")
    throw (err)
  }

}
export const fetchVideoSharedByThisUserDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVideoSharedByThisUser =await vidioSharedByThisUserFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resVideoSharedByThisUser.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVideoAssestsForSharedByThisUser(false))
        }
        
        dispatch(setVideosSharedByThisUser(resVideoSharedByThisUser.result.payload))

        dispatch(setResponseForSharedByThisUser({ key: "isVideoResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}


// for images (SHARED BY ME)
const imagesSharedBymeFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a ImagesSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_OTHERS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=3&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchImagesSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchImagesSharedByThisUser try", fetchImagesSharedByThisUser)
    
    return fetchImagesSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchImagesSharedByThisUser catch")
    throw (err)
  }

}
export const fetchImagesSharedBymeDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resImagesSharedByme =await imagesSharedBymeFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resImagesSharedByme.result.payload.pagedList.length === 0){
          dispatch(setHasMoreImageAssestsForSharedByme(false))
        }
        
        dispatch(setImagesSharedByme(resImagesSharedByme.result.payload))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}
// for audios (SHARED BY ME)
const AudioSharedBymeFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a AudioSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_OTHERS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=1&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchAudioSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchAudioSharedByThisUser try", fetchAudioSharedByThisUser)
    
    return fetchAudioSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchAudioSharedByThisUser catch")
    throw (err)
  }

}
export const fetchAudioSharedBymeDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resAudioSharedByme =await AudioSharedBymeFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resAudioSharedByme.result.payload.pagedList.length === 0){
          dispatch(setHasMoreAudioAssestsForSharedByme(false))
        }
        
        dispatch(setAudiosSharedByme(resAudioSharedByme.result.payload))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        } 
    }
  }
}
// for videos (SHARED BY ME)
const vidioSharedBymeFetch =async (token, dispatch, pageIndex, pageSize, userID, LOAD_MORE) =>{
    
  console.log("this is a AudioSharedByThisUser try", token)

    try{

      let url= `${API.BASE_URL+API.GET_SHARED_WITH_OTHERS}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=2&SharedUserID=${userID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchAudioSharedByThisUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchAudioSharedByThisUser try", fetchAudioSharedByThisUser)
    
    return fetchAudioSharedByThisUser;
    
  }catch (err){

    console.log("this is a fetchAudioSharedByThisUser catch")
    throw (err)
  }

}
export const fetchVideoSharedBymeDispatch = (paegIndex, pageSize, userID, LOAD_MORE) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVideoSharedByThisUser =await vidioSharedBymeFetch(token, dispatch, paegIndex, pageSize, userID, LOAD_MORE)
        
        if(resVideoSharedByThisUser.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVideoAssestsForSharedByme(false))
        }
        
        dispatch(setVideosSharedByme(resVideoSharedByThisUser.result.payload))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default sharedAssestWithMeOrBy.reducer 