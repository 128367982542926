import React from "react";


export default (props) => {

    // COMMONE CLASS WILL GO HERE
    let containerClassess = " ";

    if(props.containerClacess!= undefined){
        containerClassess = containerClassess+ props.containerClacess.join(" ")
    }

    return <React.Fragment>
        <div className={containerClassess} >
            {props.render(props)}
        </div>
    </React.Fragment>
}