import React from "react";

type allProps = {
    src: string;
    count: number;
    whichAssest: string;
}; 

export default (props: allProps): JSX.Element =>{
    
    return (
        <React.Fragment>
            <div className="Counter_item">
                <img src={props.src} alt="Images" className="img-fluid me-1" />
                <div className="ms-3 ms-lg-4">
                    <h4 className="mb-0">{props.count}</h4>
                    <p className="mb-0">{props.whichAssest}</p>
                </div>
            </div>
        </React.Fragment>
    )
}             
             