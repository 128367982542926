import React from "react";
import AudioCard from './AudioCard'
import { useSelector } from 'react-redux';

export default (props)=>{
    const assestsData = useSelector(state => state.assestsData);
    
    // return assestsData.selfAUDIOAssestsOnHome.map((audio, index)=>{
    // return ( <AudioCard audio={audio} key ={index}/>)
    // })
    return <AudioCard audio={props.audio} {...props}/>
}