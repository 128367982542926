import React, {useState, useEffect, useMemo, useCallback } from "react";
import HeaderSideBar from "..";
import Delete from './../../../ASSETS/Images/icons/delete.svg';
import MaterialShare from './../../../ASSETS/Images/icons/Icon material-share.svg';
// import { useDispatch, useSelector } from 'react-redux'; 
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx'
import {  fetchSelFIMGassestDispatch, resetSelfAssestsOnHome, isFileUploadingDispatch, resetToggleSelectForIMGDispatch, setToggleSelectForIMGDispatch } from "../../Home/slice.reducer";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyAssest from "../../Home/EmptyAssest";
import UploadButton from "../../../COMPONENTS/UploadButton";
import ShareModal from "../../../COMPONENTS/ShareModal";
import DeleteModal from "../../../COMPONENTS/DeleteModal";
import ImagesCardList from "../../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import CustomeBreadcrumb from "../../../COMPONENTS/CustomeBreadcrumb";
import { deleteAssest, sharingAssest } from "../../../UTILITYS/endPoints";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "../../../UTILITYS/Constant";
import Asset from './../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithUploadIcon from "../../../COMPONENTS/NoContentPage/NoContentWithUploadIcon";
import BreadCrumWithShareAndDelete from "../BreadCrumWithShareAndDelete";
import { toast } from 'react-toastify';
import { fetchUserDetailsDispatch } from "../../Profile/slice.reducer.tsx";
import { useNavigate } from 'react-router-dom'
import { redirectAfterUpload } from "../../../UTILITYS/index.tsx";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { resetCurrentUploadedAssestsCountDispatch } from "../../../COMPONENTS/DRAGE_FILE_FOLDER/dragFileFolder.slice";
import { Tooltip } from 'reactstrap';
import useLoadNextPage from "./../../../UTILITYS/hook/loadNextPage";
import { ImageInterface } from "./../../../UTILITYS/Modals/Assests";
import { useMediaQuery } from 'react-responsive';
import { useLongPress } from 'use-long-press';

const ImageAssest: () => JSX.Element =(): JSX.Element =>{
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);
    // const dispatch = useDispatch();  
    const dispatch = useAppDispatch();  
    const navigate = useNavigate();
    const [shareAssetsModal, setShareAssetsModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false); 
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isSelfUploadedAssestsEmpty, setIsSelfUploadedAssestsEmpty] = useState<boolean>(true);
    const assestsData = useAppSelector(state => state.assestsData);
    const userProfileDetails = useAppSelector(state => state.userProfileDetails);
    const currentUploadedAssestsCount = useAppSelector(state => state.currentUploadedAssestsCount);
    const [page, loadNextPage, resetValue] = useLoadNextPage(1, fetchSelFIMGassestDispatch, 25, "LOAD_MORE"); 
    const [isLongPressEnable, setIsLongPressEnable] = useState(false);
    const isMobileView = useMediaQuery({ maxWidth: 767 });

    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        dispatch(setModuleActiveDispatch("image"))
        dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        dispatch(LoaderForIndividualPageDispatch(true));
        dispatch(resetSelfAssestsOnHome({}))
    }, []);

    const toggleTolltip: () => void=() =>{
        setTooltipOpen(!tooltipOpen)
    }

    // useEffect(()=>{
    //     if(loadEventIndex === 1){
    //         dispatch(fetchSelFIMGassestDispatch(loadEventIndex, 25, "LOAD_MORE")) 
    //     }else{
    //         dispatch(fetchSelFIMGassestDispatch(loadEventIndex, 25, "LOAD_MORE")) 
    //     }
    // },[loadEventIndex]);

    const fetchMoreData: () => void = () => {
        console.log(" ksi sss heele ee 2>>", page)
        loadNextPage();
        // seLoadEventIndex(loadEventIndex+1)
    };

    useEffect(()=>{
        if( assestsData.selfIMGAssestsOnHome.length === 0){
            setIsSelfUploadedAssestsEmpty(true) 
        }else{
            setIsSelfUploadedAssestsEmpty(false)
        }
    }, [assestsData.selfIMGAssestsOnHome])

    const getImagesAssestsList: () => JSX.Element | null = ()=>{
        if(assestsData.selfIMGAssestsOnHome.length ===0) return null;
        return  <InfiniteScroll
                        className="row"
                        dataLength={assestsData.selfIMGAssestsOnHome.length as number}
                        next={fetchMoreData}
                        hasMore={assestsData.hasMoreImageAssests}
                        loader={assestsData.selfIMGAssestsOnHome.length > 25 ?<h4>Loading...</h4>: ""}
                        endMessage={    
                            <p style={{ textAlign: "center" }}>
                                {/* <b>Yay! You have seen it all</b> */}
                            </p>
                        }
                        scrollableTarget= "getSelfImagesListScroll"
                >
                {getImagesAssest()}   
            </InfiniteScroll> as JSX.Element
      
    }

    const bind = useLongPress((e, params: any) => {
        // if(!isLongPressEnable){ 
        if(!isLongPressEnable && isMobileView !== undefined && isMobileView){ 
            console.log("isLongPressEnable 1")
            setIsLongPressEnable(true);
            toggleSelectBox(params.context);
        }
    });

    useEffect(()=>{
        if(assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected).length === 0){
            setIsLongPressEnable(false);
        }
    }, [assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected).length])

    // need to implement interface
    const getImagesAssest: () => JSX.Element[] = (): JSX.Element[]=>{
        return assestsData.selfIMGAssestsOnHome.map((img: ImageInterface, index)=>{
            return <ImagesCardList 
                img={img} 
                index ={index} 
                key ={index} 
                assests= {assestsData.selfIMGAssestsOnHome}
                getCheckBoxClasses ={()=>getCheckBoxClasses(img.id)}
                toggleSelectBox ={()=>toggleSelectBox(img.id)}

                isMobileView= {isMobileView}
                bind= {bind}
                isLongPressEnable= {isLongPressEnable}
            />
        }) as JSX.Element[]
    }
 
    const getCheckBoxClasses: (id: number) => "my-1 selected" | "my-1" = (id: number)=>{
        let currentImg= null;
        if(assestsData.selfIMGAssestsOnHome.length>0){
            currentImg =assestsData.selfIMGAssestsOnHome.find((img: ImageInterface)=> img.id === id && img.isImgSelected )
        }
        if(currentImg != null && currentImg != undefined)
        return "my-1 selected";
        return "my-1";
    }

    const toggleSelectBox: (id: number) => void = (id: number)=>{
        if(assestsData.selfIMGAssestsOnHome.find((img: ImageInterface)=> (img.id === id && img.isImgSelected))){
            dispatch(setToggleSelectForIMGDispatch(id))
        }else if(assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected).length ===10){
            toast.dismiss();
            toast.error("You can only select maximum 10 image", { });
            return
        }else{
            dispatch(setToggleSelectForIMGDispatch(id))
        }
    }
    
    const onFileUpload: () => void = ()=>{
        dispatch(resetSelfAssestsOnHome({}));
        if(page !== 1){
            setTimeout(() => {
                // seLoadEventIndex(1);
                resetValue(1);
            }, 300);
            // seLoadEventIndex(1)
        }else{
            dispatch(fetchSelFIMGassestDispatch(1, 25, "LOAD_MORE")) 
        }
        setIsUploaded(true)
        navigateToAssestManagmentBasedOnCurrentUploadedCount(); 
        dispatch(LoaderForIndividualPageDispatch(true))
        // dispatch(fetchUserDetailsDispatch()); 
        dispatch(isFileUploadingDispatch(false))
    }

    const navigateToAssestManagmentBasedOnCurrentUploadedCount: () => void = ()=>{
        let routToNavigate = "assets-images";
        routToNavigate= redirectAfterUpload(currentUploadedAssestsCount.currentUploadedAssestsCount.image, currentUploadedAssestsCount.currentUploadedAssestsCount.audio ,currentUploadedAssestsCount.currentUploadedAssestsCount.video); 
        dispatch(resetCurrentUploadedAssestsCountDispatch({}))
        
        if(routToNavigate !== "assets-videos")
            navigate(`/${routToNavigate}`);
    }

    // useEffect(()=>{  
    //     if(isUploaded){
    //         let routToNavigate = "assets-images";
    //         routToNavigate= redirectAfterUpload(userProfileDetails.imageFileCount, userProfileDetails.audioFileCount ,userProfileDetails.videoFileCount);
    //         setIsUploaded(false)
    //         navigate(`/${routToNavigate}`);
    //     }
    // }, [userProfileDetails.imageFileCount, userProfileDetails.audioFileCount, userProfileDetails.videoFileCount]);

    useEffect(()=>{
        if( assestsData.isImgResGet ){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [assestsData.isImgResGet]);

    const getSharingIDs: () => string =()=>{
        
        return assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected ).map((img: ImageInterface)=> img.id)
        .join();
    }

    const getArrayOfOBJ =()=>{   
        return assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected ).map((img: ImageInterface)=> {
            return {
                id: img.id,
                fIleUrl: img.fIleUrl
            }
        })
    }
    
    const onSharingHnadler = async(sharingData: any, dispatch: any, token: any)=>{
        try{
            await sharingAssest( sharingData, dispatch, token);
        }catch (err){
            console.log('ererer', err)
        }
        dispatch(resetToggleSelectForIMGDispatch())
    }    
    
    const deletingAssestHandler = async(deleteAssestDATA: any, dispatch: any, token: any)=>{
        let deletRes=  await deleteAssest( deleteAssestDATA, dispatch, token);
        dispatch(resetToggleSelectForIMGDispatch())
        dispatch(resetSelfAssestsOnHome({}))
        if(page !== 1){
            // seLoadEventIndex(1);
            resetValue(1);
        }else{
            dispatch(fetchSelFIMGassestDispatch(1, 25, "LOAD_MORE")) 
        }
        return deletRes;
        // dispatch(fetchSelFIMGassestDispatch(1, 25, "LOAD_MORE"));
        // // resetValue();
    }
    
    let isAnyOneIsSelected: any = null;
    let assestsCounts: any = null;
    isAnyOneIsSelected =assestsData.selfIMGAssestsOnHome.find((img: ImageInterface)=> img.isImgSelected );
    assestsCounts =assestsData.selfIMGAssestsOnHome.filter((img: ImageInterface)=> img.isImgSelected);

    const memoizedValue = useMemo(() =>["Asset Library", "Images" ], []);
    const memoizedValueIsAnyOneIsSelected = useMemo(() =>isAnyOneIsSelected, [assestsCounts.length]);
    const memoizedValueAssestsCounts = useMemo(() =>assestsCounts, [assestsCounts.length]);
    const memoizedFunOnUnselectAssest = useCallback(() =>{ dispatch(resetToggleSelectForIMGDispatch()) }, [assestsCounts.length]);
    const memoizedFunOnFileUpload = useCallback(() =>{ onFileUpload() }, [currentUploadedAssestsCount.currentUploadedAssestsCount.image, currentUploadedAssestsCount.currentUploadedAssestsCount.audio ,currentUploadedAssestsCount.currentUploadedAssestsCount.video]);

    if(isSelfUploadedAssestsEmpty){  
        return <HeaderSideBar> 
                    <NoContentWithUploadIcon  
                        onFileUpload={onFileUpload} 
                        breadCrumbItems= {memoizedValue}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    /> 
                </HeaderSideBar> as JSX.Element
    }
  
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                
                {/* content header - Breadcrumb */}
                <div className={isAnyOneIsSelected != null && isAnyOneIsSelected != undefined ? 'innerHeader withBorder ps-3 pe-3 ps-md-4 pe-md-4' : 'innerHeader ps-3 pe-3 ps-md-4 pe-md-4'}>
                    <div className="row align-items-center">
                        <BreadCrumWithShareAndDelete    
                            // isAnyOneIsSelected= {isAnyOneIsSelected}
                            // assestsCounts ={assestsCounts}
                            // onUnselectAssest={()=>dispatch(resetToggleSelectForIMGDispatch())}
                            // breadCrumbItems= {["Asset Library", "Images" ]}
                            isAnyOneIsSelected= {memoizedValueIsAnyOneIsSelected}
                            assestsCounts ={memoizedValueAssestsCounts}
                            breadCrumbItems = {memoizedValue}
                            onUnselectAssest={memoizedFunOnUnselectAssest}
                        />
                        {isAnyOneIsSelected != null && isAnyOneIsSelected != undefined &&
                        <div className="col-sm-6 text-end">
                            <button className="btn btn-primary btn-sm me-2" onClick={()=>setShareAssetsModal(true)}>
                                <img src={MaterialShare} alt="Images" className="img-fluid me-2" />
                            Share</button>
                            <button id={"deleteAssestButton"} className="btn btn-primary btn-sm" onClick={()=>setDeleteModal(true)}>
                                <img src={Delete} alt="Images" className="img-fluid" />
                            </button>
                            <Tooltip placement="bottom" isOpen={tooltipOpen} target={"deleteAssestButton"} toggle={toggleTolltip}>Delete
                            </Tooltip>
                        </div>
                        }   
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4 inner_lists" id= {"getSelfImagesListScroll"}>            
                    {/* Images  */}
                    <div className="assets_item ps-2 pe-2">
                        {getImagesAssestsList()}
                    </div>
                </div>
                
                {/* UPLOAD ASSESTS BUTTON */}
                <UploadButton onToggleUpload ={memoizedFunOnFileUpload}/> 
                
                {/* Share modal  */}
                <ShareModal 
                    isOpen={shareAssetsModal} 
                    setShareAssetsModal ={setShareAssetsModal}
                    getSharingIDs ={getSharingIDs}
                    onSharing ={onSharingHnadler}
                    accessType ={3}
                /> 

                {/* Delete assests */}
                <DeleteModal 
                    isOpen= {deleteModal} 
                    setDeleteModal ={setDeleteModal}
                    getArrayOfOBJ= {getArrayOfOBJ}
                    onDeletingAssest = {deletingAssestHandler}
                />

            </HeaderSideBar>
        </React.Fragment> as JSX.Element
    )
}

export default (ImageAssest)