import React, {useState, useEffect} from "react";
import { EMPTY_360_TOUR_PRIMARY_TEXT, EMPTY_360_TOUR_DESCRIPTION_TEXT } from "../../UTILITYS/Constant";
import { useDispatch, useSelector } from 'react-redux'
import { fetchVirtualTourDispatch, resetVirtualTour } from "./Slices/VirtualTour.slice";
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeModule.slice";
import NoVRexperience from "./Virtual-tour-landing-page/NoVirtualTour";
import VirtualTours from "./Virtual-tour-landing-page/VirtualTours";
import HeaderFooter from ".";
import { LoaderForIndividualPageDispatch } from "../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer"; 

export default ()=>{ 

    const dispatch = useDispatch(); 
    const virtualTours = useSelector(state => state.virtualTours);
    const [isVirtualTourEmpty, setIsVirtualTourEmpty] = useState(true);
    const userProfileDetails = useSelector(state => state.userProfileDetails);

    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{

        if(userProfileDetails.isUserFetch){
            dispatch(resetVirtualTour());
            dispatch(LoaderForIndividualPageDispatch(true));
            dispatch(fetchVirtualTourDispatch(1, 25));
            dispatch(setParentModuleActiveDispatch("parentVRexperience"));
            dispatch(setModuleActiveDispatch("virtualTour"));
        }

        return ()=>{
            dispatch(resetVirtualTour());
        }
    }, [userProfileDetails.isUserFetch])

    useEffect(()=>{
        if(virtualTours.isVirtualTourResGet)
            dispatch(LoaderForIndividualPageDispatch(false));
    }, [virtualTours.isVirtualTourResGet]); 

    if(!virtualTours.isVirtualTourResGet){ 
        return <HeaderFooter></HeaderFooter> 
    }

    // if(isVirtualTourEmpty){
    //    return <NoVRexperience 
    //         primaryText= {EMPTY_360_TOUR_PRIMARY_TEXT}
    //         description= {EMPTY_360_TOUR_DESCRIPTION_TEXT} 
    //     />
    // }

    if(virtualTours.tour.length === 0 && virtualTours.isVirtualTourResGet){
        return <NoVRexperience   
            primaryText= {EMPTY_360_TOUR_PRIMARY_TEXT}
            description= {EMPTY_360_TOUR_DESCRIPTION_TEXT} 
        />
    }
 
    return (
        <VirtualTours /> 
    )
}