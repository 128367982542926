import React, { useState } from "react";
import Dropzone, {defaultClassNames} from '../dragFile/react-dropzone-uploader/dist/Dropzone.tsx';
import 'react-dropzone-uploader/dist/styles.css' 
import * as API from '../../../APIs'; 
import { useSelector, useDispatch } from 'react-redux'
import { isFileUploadingDispatch } from "../../../SCREENS/Home/slice.reducer";
// import { setCurrentUploadedAssestsCountDispatch } from "./dragFileFolder.slice";
import convert from 'convert';
import { toast } from 'react-toastify';
import { generateVideoThumbnails, importFileandPreview } from "@rajesh896/video-thumbnails-generator";
import { FormGroup } from 'reactstrap';
import classNames from 'classnames';
import validator from 'validator';
import { setVirTourNameDispatch, setIsVirTourNameEmptyDispatch, resetThreeSixtyVirtualTourDispatch } from "../../../SCREENS/360virtualTour/Slices/threeSixtyVirtualImageOnUpload.slice.js"; 
import { useEffect } from "react";
import { is360Image, is360Image__ } from "../../../UTILITYS/endPoints.js"; 
import uploadProcessing from "./../../../ASSETS/Images/uploadProcessing.gif";
import { useContext } from "react";
import CreateTokenContextProvider from "../../../STORE/App-wide-state/Token/createTokenContextProvider.js";
import UpgradModal from "../../Subscription/UpgradModal.js";
import { setDoNotShowExpiredModal } from "../../../SCREENS/Profile/slice.reducer.tsx";
import { ExpiredModal } from "../../Modal/Expired.js";

const Layout = ({ input, previews, dropzoneProps, files, extra: { maxFiles } }) => {

  const [virtualTourName, setVirtualTourName] = useState('');
  // const [virtualTourNameErr, setvirtualTourNameErr] = useState(false);
  const threeSixtyVirtualImageOnUpload = useSelector(state => state.threeSixtyVirtualImageOnUpload);
  const dispatch = useDispatch();

  console.log("threeSixtyVirtualTourthreeSixtyVirtualTour", threeSixtyVirtualImageOnUpload)

  const onInputChange = (e)=>{
    switch(e.target.name){
      case "virtual-tour":
          setVirtualTourName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
          // setvirtualTourNameErr(false);
          dispatch(setVirTourNameDispatch({virtualTourName: e.target.value.replace(/[^a-zA-Z0-9\s]/g, '')}));
          dispatch(setIsVirTourNameEmptyDispatch(false));
      break;
    }
  }

  return (
    <div>
      <div className="col-12">
        <div>Name</div>
        <FormGroup>
            <input value={virtualTourName} type="text" placeholder="Enter name"
            className={classNames("form-control", { "form-error": threeSixtyVirtualImageOnUpload.isVirtourNameEmpty })} name={'virtual-tour'} onChange={onInputChange}
            />
        </FormGroup>
      </div>

      <div {...dropzoneProps}>
        {files.length < maxFiles && input}
      </div>
      
      {/* your priview component */}
      <div>
        {previews}
      </div>
  </div> 
  )

} 
  
const DragAndDropFiles = (props) => {

  const [isUpdatePreview, setIsUpdatePreview] = useState(false);
  const tokenContext = useContext(CreateTokenContextProvider);
  const dispatch = useDispatch();
  const userProfileDetails = useSelector(state => state.userProfileDetails)
  const threeSixtyVirtualImageOnUpload = useSelector(state => state.threeSixtyVirtualImageOnUpload); 
  const virtualTourImages = useSelector(state => state.virtualTourImages);
  const [disableDZ, setDisableDZ] = useState(false);
  const [openUpgrade, setOpenUpgrade] = useState(false);
  // const setCurrentUploadedAssestsCount= (file)=>{
  //   if(file.type.split("/")[0] === ""){
  //     let media = getMedia(file.name.split(".")[1])
  //     dispatch(setCurrentUploadedAssestsCountDispatch(media));
  //   }else{
  //     dispatch(setCurrentUploadedAssestsCountDispatch(file.type.split("/")[0]));
  //   }
  // }

  const getUploadParams =async ({ file, meta }) =>{
    
    // setCurrentUploadedAssestsCount(file);

    let fileSizeInKB = 0;
    fileSizeInKB =convert(file.size, 'bytes').to('kB');

    if(204800<fileSizeInKB  ){
      toast.error(`${file.name} file is more than 200MB`, { });
      return  
    }
    
    const formData = new FormData(); 
    formData.append( "Image", file );
    formData.append( "VirtualTourID", props.tour.id );
    formData.append( "name", threeSixtyVirtualImageOnUpload.virtualTourName );
    // dispatch(resetThreeSixtyVirtualTourDispatch());
    
    props.setIsSaving !== undefined && props.setIsSaving(true)
    
    // if(file.type.split("/")[0] === "video"){
    //   let getThumnail =await addThumbnailForVideo(file);
    //   console.log('DragAndDropFilesDragAndDropFiles 2',  getThumnail[0].split(",")[1]);
    //   formData.append( "thumbnailbase64", getThumnail[0].split(",")[1]);
    // }

    let headerConfig = {
      "Authorization": "Bearer " + tokenContext.accessToken
    }

    return { 
      url: API.BASE_URL+API.UPLOAD_360_IMAGES,  
      method: "POST",
      body: formData,
      headers: headerConfig
    } 
     
  } 

  const handleChangeStatus = (status) => { 
    
    if(status.meta.status === "rejected_file_type"){
      toast.error(`${status.meta.type.split("/")[1]} format is not supported`);
      return;
    }

    if(status.meta.status === "done"|| status.meta.status === "removed"){
      toast(`Image uploaded successfully`);
      props.toggle();
      setDisableDZ(false);
      dispatch(resetThreeSixtyVirtualTourDispatch());
      return;
    }else if(status.meta.status === "error_upload"){
      setTimeout(() => {
        console.log("DragAndDropFilesDragAndDropFiles status 1", JSON.parse(status.xhr.response));
        let parseErr= JSON.parse(status.xhr.response);
        if(parseErr.isError){
          toast(parseErr.responseException.exceptionMessage);
        }
      }, 500);

    }

    dispatch(isFileUploadingDispatch(true))
    setIsUpdatePreview(!isUpdatePreview)
  }

  const inputContent = () => {
    if(disableDZ) return <div><img className="files-upload-processing" src={uploadProcessing} /></div>
    return <div>Drop your 360° image here or <span>upload</span></div>
  }

  const isStorageLimiteExccede = (files) => { 
    
    let totalUploadFileSizeInBytes= 0;
    let totalUploadFileSizeInKB= 0;
    let initialValue = 0;
    totalUploadFileSizeInBytes =files.reduce((accumulator, file) => accumulator + file.size, initialValue);
    totalUploadFileSizeInKB =convert(totalUploadFileSizeInBytes, 'bytes').to('kB');
   
    if(virtualTourImages.images.length >0){
      let usedStorageInKB= 0;
      usedStorageInKB =virtualTourImages.images[0].fileSizeInKB;
      console.log("isUploadedLimitEccedisUploadedLimitEcced 1", files, convert(1, 'GB').to('kB'), totalUploadFileSizeInKB, usedStorageInKB)
      if(usedStorageInKB+totalUploadFileSizeInKB >= convert(1, 'GB').to('kB')){
        toast.error("File storage size exceed ", { });
        return true;
      }else{
        return false;
      }
    }
    console.log("isUploadedLimitEccedisUploadedLimitEcced", files, convert(1, 'GB').to('kB'), totalUploadFileSizeInKB)
    return false;
  }

  const isNameEmpty = () => { 

    if(validator.isEmpty(threeSixtyVirtualImageOnUpload.virtualTourName.trim())){
      toast.error("Please Enter Virtual Tour Name First", { });
      dispatch(setIsVirTourNameEmptyDispatch(true));
      return true;
    }else if(!validator.isLength(threeSixtyVirtualImageOnUpload.virtualTourName.trim(), {min: 1, max: 25})){
        toast.error("Virtual Tour Name length should be minimum 3 char and maximum 25 char", { });
        dispatch(setIsVirTourNameEmptyDispatch(true));
        return true;
    }
    return false;
  }
  
  const isIMageNot360 = async (files) => {

    // console.log("imageUrlimageUrl", files);
    // return true

    const file = files[0];
    if (file) {
      await is360Image__(file)
      .then(is360 => {
        console.log('This is a 360-degree image.', is360);
        if (is360) {
          return false;
        } else {
          console.log('This is not a 360-degree image.', is360);
          return true;
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
  } 

  const onDublicateImageHandler =  () => {
    toast("Duplicate name not allowed.", {});
  }

  const disabledDZ = () => { 
    setDisableDZ(true);
  }

  const isMoreThanOneImagesSelected = (files) => { 
    let images = 0; 
    files.forEach(file => {
      if(file.type.split("/")[0] === "image" || file.name.split(".")[1] === "heic"){
        images = images+1;
      }
    });
    toast.dismiss();
    console.log("isMoreThanOneImagesSelectedisMoreThanOneImagesSelected", files, images)
    
    if(images >1) toast.error("Uploading multiple images files is not allowed, upload one images file at a time", { });
    console.log("videosvideos", images);
    return images >1? true: false;
  }

  const isPremium = () => {
    if( userProfileDetails.isExpired ){
      toast.dismiss()
      dispatch(setDoNotShowExpiredModal(false));
      // setOpenUpgrade(true);
      toast("Your subscription has been expired");
      return false;
    }
    // else if(!userProfileDetails.isPremium && virtualTourImages.images.length >0){
    //   setOpenUpgrade(true);
    //   toast.error("Free users are allowed only 1 No. of 360° Rooms/Photos", { });
    //   return false
    // }
    return true;
  } 

  // const upgradModal = () => { 
  //   {/* Modal to be upgraded  */}
  //     return <UpgradModal
  //       isOpen= {openUpgrade} 
  //       setOpenUpgrade ={setOpenUpgrade}
  //     />
  // }

  return ( 
    <React.Fragment>
      {/* {upgradModal()} */}
      <ExpiredModal />
      <Dropzone 
        disabled ={disableDZ}
        isMoreThanOneImagesSelected= {isMoreThanOneImagesSelected}
        isPremium= {isPremium}
        isStorageLimiteExccede= {isStorageLimiteExccede}
        isThreeSixtyImage= {props.isThreeSixtyImage}
        onDublicateImage= {onDublicateImageHandler}  
        virtualTourName= {threeSixtyVirtualImageOnUpload.virtualTourName}  
        virtualTourImages= {virtualTourImages.images}  
        isNameEmpty= {isNameEmpty}  
        isIMageNot360= {isIMageNot360}
        disabledDZ= {disabledDZ}  
        preventToUpload= {props.preventToUpload}
        getUploadParams={getUploadParams}
        LayoutComponent={Layout}
        classNames={{ inputLabelWithFiles: defaultClassNames.inputLabel }}
        onChangeStatus={handleChangeStatus}
        inputContent={inputContent}
        inputWithFilesContent={inputContent}
        styles={{
          dropzone: { width: 400, height: 180, padding: 0, overflow: "hidden" }
        }}
        accept= ".png, .jpeg, .jpg, .heic"
        multiple= {false}
      
      />
    </React.Fragment>
  )
}
  
export default DragAndDropFiles