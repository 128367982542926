import React, { useState, useEffect } from "react";
import Dashboard from './../../ASSETS/Images/icons/Dashboard.svg';
import User from './../../ASSETS/Images/icons/Profile.svg';
import Picture from './../../ASSETS/Images/icons/Asset Management.svg';
import Subscription from './../../ASSETS/Images/icons/subs.png';

import Contrbutors from './../../ASSETS/Images/icons/Invite.svg';
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import VRIcon from './../../ASSETS/Images/icons/VR2.svg';
import Parent from './index'
import {
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import NavBarTab from "../NavBarTab";
import LogoutCOM from '../Logout'
import {useLocation, useNavigate} from "react-router-dom";
import {SIDE_MENU_ITEMS as LIST_SUBMENU, PARENT_MODULE_ITEMS} from './moduleList'
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux'
import {hamburgurDispatch} from './../../STORE/CommonSlices/hameBurgerOnOff';
import { setModuleActiveDispatch } from "./activeModule.slice";
import { setParentModuleActiveDispatch, setToggleForExpandAndColleps } from "./activeParentModule.slice";


const SidebarComponent = (props) =>{ 
    
    const navigate = useNavigate();
    const hamburgur = useSelector(state => state.hamburgur)
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const [userDetails, setUserDetails] = useState({});
    const dispatch = useDispatch();
    const activeModuleSlice = useSelector(state => state.activeModule);
    const activeParentModuleSlice = useSelector(state => state.activeParentModule);

    useEffect(()=>{
        setUserDetails(userProfileDetails)
    }, [userProfileDetails]);   
    const SIDE_MENU_ITEMS ={
        fistMenuItems: [
            {
                onClick: (subModule)=>{
                    // activeThisModuleFun(subModule)
                    dispatch(setModuleActiveDispatch(subModule))
                    dispatch(setParentModuleActiveDispatch("parentProfile"))
                    dispatch(hamburgurDispatch())
                    navigate('/manage-account');
                }, 
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[0].subModule === activeModuleSlice.subModule }),
                subHeading: "Profile",
                subModule: "profile"
            },
            {
                onClick: (subModule)=>{
                    // activeThisModuleFun(subModule)
                    dispatch(setModuleActiveDispatch(subModule))
                    dispatch(setParentModuleActiveDispatch("parentProfile"))
                    dispatch(hamburgurDispatch())
                    navigate('/change-password')
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[1].subModule === activeModuleSlice.subModule }),
                subHeading: "Change Password",
                subModule: "changePassword"
            },
            {
                onClick: (subModule)=>{
                    // activeThisModuleFun(subModule)
                    dispatch(setModuleActiveDispatch(subModule))
                    dispatch(setParentModuleActiveDispatch("parentProfile"))
                    dispatch(hamburgurDispatch())
                    navigate('/subscription')
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[2].subModule === activeModuleSlice.subModule }),
                subHeading: "Subscription",
                subModule: "subscription"
            },
            // {
            //     onClick: (subModule)=>{
            //         // dispatch(hamburgurDispatch())
            //         // dispatch(setModuleActiveDispatch(subModule))
            //         dispatch(setParentModuleActiveDispatch("parentProfile"))
            //         activeThisModuleFun(subModule)
            //     },
            //     dropdownItemClasses: classNames({ "active": LIST_SUBMENU.fistMenuItems[2].subModule === activeModuleSlice.subModule }),
            //     subHeading: "Pin Management",
            //     subModule: "pinManagement"
            // }
        ], 
     
        secondMenuItems: [
            {
                onClick: (subModule)=>{
                    // dispatch(setModuleActiveDispatch(subModule))
                    dispatch(hamburgurDispatch())
                    // dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                    navigate('/assets-images');
                },
                subHeading: "Images",
                subModule: "image",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.secondMenuItems[0].subModule === activeModuleSlice.subModule }), 
            },
            {
                onClick: (subModule)=>{
                    // dispatch(setModuleActiveDispatch(subModule))
                    dispatch(hamburgurDispatch())
                    // dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                    navigate('/assets-videos');
                },
                subHeading: "Videos",
                subModule: "video",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.secondMenuItems[2].subModule === activeModuleSlice.subModule }),
            },
            { 
                onClick: (subModule)=>{
                    // dispatch(setModuleActiveDispatch(subModule))
                    dispatch(hamburgurDispatch())
                    // dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
                    navigate('/assets-audios');
                },
                subHeading: "Audios",
                subModule: "audio",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.secondMenuItems[1].subModule === activeModuleSlice.subModule }),
            },
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch())
                    navigate('/share-with-others')
                },
                subHeading: "Shared with others",
                subModule: "shared_with_others",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.secondMenuItems[3].subModule === activeModuleSlice.subModule }), 
            }, 
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch())
                    navigate('/share-with-me')
                },
                subHeading: "Shared with me",
                subModule: "shared_with_me",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.secondMenuItems[4].subModule === activeModuleSlice.subModule }),
            },
        ], 
    
        theirdMenuItems: [
            // {
            //     onClick: (subModule)=>{
            //         navigate('/share-with-others')
            //     },
            //     subHeading: "Shared with others",
            //     subModule: "shared_with_others",
            //     dropdownItemClasses: classNames({ "active": LIST_SUBMENU.theirdMenuItems[0].subModule === activeModuleSlice.subModule }), 
            // },
            // {
            //     onClick: (subModule)=>{
            //         navigate('/share-with-me')
            //     },
            //     subHeading: "Shared with me",
            //     subModule: "shared_with_me",
            //     dropdownItemClasses: classNames({ "active": LIST_SUBMENU.theirdMenuItems[1].subModule === activeModuleSlice.subModule }),
            // },
        ],

        forthMenuItems: [
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch());
                    navigate('/virtual-tour');
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.forthMenuItems[0].subModule === activeModuleSlice.subModule }),
                subHeading: "Virtual Tour",
                subModule: "virtualTour"
            },
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch());
                    navigate('/viewer');
                },
                subHeading: "Viewer",
                subModule: "vr_Experience",
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.forthMenuItems[1].subModule === activeModuleSlice.subModule }), 
            },
            {
                onClick: (subModule)=>{
                    dispatch(hamburgurDispatch());
                    navigate('/pin-management');
                },
                dropdownItemClasses: classNames({ "active": LIST_SUBMENU.forthMenuItems[2].subModule === activeModuleSlice.subModule }),
                subHeading: "PIN Management",
                subModule: "pinManagement"
            }
        ]
    } 

    const removeDrawer =() =>{
        dispatch(hamburgurDispatch())
    }

    const expandCollepsed = (bool)=>{
        if(bool && activeParentModuleSlice.toggleModul){  
            return true;
        }else{
            return false;
        }
    }

    return ( 
            <React.Fragment>
                <div 
                className={classNames( "sideDrawer" ,{"active": hamburgur.isHamburgerOn })}
                >
                    <div className="drawer-wrap">
                        <Nav vertical>
                            <div className="d-inline-block d-md-none mobile-profile ps-2">
                                <NavLink href="#" onClick={()=> navigate('/manage-account')}>
                                    <img src={userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage} className="profile-medium img-thumbnail mb-3  mt-3" alt="User" />
                                    <h4 className="mb-1 userName">{
                                userDetails.firstName !=undefined && userDetails.firstName.length >8 ? userDetails.firstName.substring(0, 8)+"...": userDetails.firstName
                                    } {
                                        userDetails.lastName !=undefined && userDetails.lastName.length >8 ? userDetails.lastName.substring(0, 8)+"...": userDetails.lastName
                                    }</h4>
                                    <p>{userDetails.email}</p>
                                </NavLink>
                            </div>
                            <NavItem 
                            className={classNames({ "active": PARENT_MODULE_ITEMS.fistMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(setParentModuleActiveDispatch("parentDashboard"))
                                    dispatch(setModuleActiveDispatch(""))
                                    dispatch(hamburgurDispatch())
                                    navigate('/homePage')
                                }}>
                                    <img src={Dashboard} alt="Dashboard" />
                                    <span>Dashboard</span>
                                </NavLink>
                            </NavItem>
 
                            <NavBarTab 
                                headingAvatar = {User}
                                tabHeading = {"Manage Account"}
                                dropdownClasses= {classNames({ 
                                    "active show": PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module,
                                    "menu__active": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module) 
                                })}
                                dropdownMenuClasses ={classNames(
                                     {
                                        "show": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module),
                                        "position-static": expandCollepsed(PARENT_MODULE_ITEMS.secondMenuItems[0].module === activeParentModuleSlice.module)
                                    })}
                                currentModule= "parentProfile"
                                setThisModuleActive ={(currentModule)=>{
                                    dispatch(setToggleForExpandAndColleps(!activeParentModuleSlice.toggleModul))
                                    if(activeParentModuleSlice.module === "parentProfile"){
                                        return;
                                    } 
                                    navigate('/manage-account');
                                    dispatch(setParentModuleActiveDispatch(currentModule))
                                    dispatch(setToggleForExpandAndColleps(true))
                                }}
                                subMen = {SIDE_MENU_ITEMS.fistMenuItems} 
                            />

                            <NavBarTab 
                                headingAvatar = {Picture}
                                tabHeading = {"Asset Library"}
                                dropdownClasses= {classNames({ 
                                    "active show": PARENT_MODULE_ITEMS.thiredMenuItems[0].module === activeParentModuleSlice.module,
                                    "menu__active": expandCollepsed(PARENT_MODULE_ITEMS.thiredMenuItems[0].module === activeParentModuleSlice.module)
                                })}
                                dropdownMenuClasses ={classNames(
                                    {
                                        "show": expandCollepsed(PARENT_MODULE_ITEMS.thiredMenuItems[0].module === activeParentModuleSlice.module),
                                        "position-static": expandCollepsed(PARENT_MODULE_ITEMS.thiredMenuItems[0].module === activeParentModuleSlice.module)
                                    })}
                                currentModule= "parentAssestManagment"
                                setThisModuleActive ={(currentModule)=>{
                                    dispatch(setToggleForExpandAndColleps(!activeParentModuleSlice.toggleModul))
                                    if(activeParentModuleSlice.module === "parentAssestManagment"){
                                        return;
                                    } 
                                    dispatch(setParentModuleActiveDispatch(currentModule))
                                    dispatch(setModuleActiveDispatch("image"))
                                    navigate('/assets-images');
                                    dispatch(setToggleForExpandAndColleps(true))
                                }} 
                                subMen = {SIDE_MENU_ITEMS.secondMenuItems}
                            />

                            {/* <NavBarTab  
                                headingAvatar = {Family}
                                tabHeading = {"Family & Friends"}
                                dropdownClasses= {classNames({ "active show": PARENT_MODULE_ITEMS.forthMenuItems[0].module === activeParentModuleSlice.module })}
                                dropdownMenuClasses ={classNames("position-static", { "show": PARENT_MODULE_ITEMS.forthMenuItems[0].module === activeParentModuleSlice.module })}
                                currentModule= "parentFamilyFreind"
                                setThisModuleActive ={(currentModule)=>{
                                    dispatch(setParentModuleActiveDispatch(currentModule))
                                }}
                                subMen = {SIDE_MENU_ITEMS.theirdMenuItems}
                            /> */}

  
                            <NavBarTab 
                                headingAvatar = {VRIcon}
                                tabHeading = {"VR Experience"}
                                dropdownClasses= {classNames({ 
                                    "active show": PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module,
                                    "menu__active": expandCollepsed(PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module)
                                    
                                })}
                                dropdownMenuClasses ={classNames(
                                    {
                                        "show": expandCollepsed(PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module),
                                        "position-static": expandCollepsed(PARENT_MODULE_ITEMS.fifthMenuItems[0].module === activeParentModuleSlice.module)
                                    })}
                                currentModule= "parentVRexperience"
                                setThisModuleActive ={(currentModule)=>{
                                    dispatch(setToggleForExpandAndColleps(!activeParentModuleSlice.toggleModul))
                                    if(activeParentModuleSlice.module === "parentVRexperience"){
                                        return;
                                    } 
                                    navigate('/virtual-tour');
                                    dispatch(setToggleForExpandAndColleps(true))
                                }}
                                subMen = {SIDE_MENU_ITEMS.forthMenuItems}
                            />
 
                            <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.sixthMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    dispatch(setParentModuleActiveDispatch("parentICexperience"))
                                    dispatch(setModuleActiveDispatch(""))
                                    navigate('/invite-contributor')
                                }}>
                                    <img src={Contrbutors} alt="invitecontributor" />
                                    <span>Invite Contributor</span>
                                </NavLink>
                            </NavItem>
                           
                            {/* <NavItem 
                                className={classNames({ "active": PARENT_MODULE_ITEMS.sevenMenuItems[0].module === activeParentModuleSlice.module })}
                            >
                                <NavLink href="#" onClick={()=>{
                                    navigate('/subscription')
                                    dispatch(hamburgurDispatch())
                                }}>
                                    <img src={Subscription} alt="parentSubscription" />
                                    <span>Subscription</span>
                                </NavLink>
                            </NavItem> */}
                            
                            <hr />
                            <LogoutCOM onClick = {props.onLogout}/>
                        </Nav> 
                    </div>
                </div>
                <div className="drawerBackdrop" onClick={removeDrawer}></div>
            </React.Fragment>
    )
}

export default (props)=>{ 
    return (
        <Parent>
            <React.Fragment>
                <SidebarComponent {...props} />
            </React.Fragment>
        </Parent>
    )
}