import React, { useState } from "react";
import Home from "../NoAssests/index.tsx";
import { Row, Col, Button, Modal, ModalBody } from 'reactstrap';
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';
import Close from './../../ASSETS/Images/icons/cancel.png'
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux'
import DragAndDropFiles from "../DRAGE_FILE_FOLDER";
import NoContents from "./NoContents";
import Breadcrum from "../Breadcrum"; 

export default (props)=>{

    const [modal, setModal] = useState(false); 
    const assestsData = useSelector(state => state.assestsData);
  
    const toggle = () => {
        if(modal && assestsData.isFileUploadingState){
            props.onFileUpload(true);
            setModal(!modal);
            return;
        }
        setModal(!modal);
    }

    return (
        <React.Fragment>
            <div className="ps-0 pe-0 ps-md-3 pe-md-3">
                <div className="pageTitle">
                    <Row>
                        <Col xs="12">
                            <Breadcrum 
                                breadCrumbItems ={props.breadCrumbItems}
                            />
                        </Col>  
                    </Row>
                </div> 

                {/* Dashboard no content  */}
                <NoContents 
                    primaryText ={props.primaryText}
                    description ={props.description}
                    icon ={props.noImgIcon}
                />
                
                {/* Add Asset button  */}
                <div className="addAssets">
                    <button className="btn btn-link" onClick={toggle}><img src={AddAssets} alt="profile" className="img-fluid" /></button>
                </div>

                <Modal isOpen={modal} centered className="drop-modal" >
                    <Button className="modalClose" onClick={toggle}>
                        <img src={Close} alt="Images" className="img-fluid" />
                    </Button>
                    <ModalBody>
                        <div className="Drop_Area">
                            <DragAndDropFiles 
                                preventToUpload = {()=>setModal(!modal)}
                                closeVideoInfoModalAndDZ = {toggle}
                            />
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>
    )
}
