import React, {useEffect, useState} from "react";
import Logo from './../../ASSETS/Images/Recuerdo_2x.png';
import RankIcon from './../../ASSETS/Images/icons/rank2x.png';

import { 
  useSelector, 
  useDispatch } from 'react-redux' 
import {
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
  } from 'reactstrap';
import Parent from './index'
import ProfileImage from './../../ASSETS/Images/defaultProfilePic.jpg';
import {hamburgurDispatch} from './../../STORE/CommonSlices/hameBurgerOnOff';
import { useNavigate } from 'react-router-dom'
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";

const HeaderComponent = () =>{
 
  const userProfileDetails = useSelector(state => state.userProfileDetails);
  const [userDetails, setUserDetails] = useState({});
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(()=>{
      setUserDetails(userProfileDetails)
  }, [userProfileDetails]);
    

  const getStatus =()=>{
    // if(userDetails.isPremium && !userDetails.isExpired){
    //   return "Premium"
    // }else if(userDetails.isExpired){
    //   return "Expired"
    // }else if(!userDetails.isPremium){
    //   return "Trial"
    // }

    if(userDetails.subscriptionStatus && userDetails.subscriptionStatus === "Trialing"){
      return "Trial";
    }else if(userDetails.subscriptionStatus && userDetails.subscriptionStatus === "Canceled"){
      return "Expired";
    }else if(userDetails.subscriptionStatus && userDetails.subscriptionStatus === "Active"){
      return "Premium";
    }
  }
  
  return  <React.Fragment>
      <header className="header">
        <Navbar className="fixed-top navbar-expand-lg">

          {/* mobile drawer button  */}
          <NavbarToggler className="d-block d-md-none" onClick={()=>dispatch(hamburgurDispatch())}/>

          <NavbarBrand className="ms-0 ms-md-3" href="#" onClick={()=>{
            dispatch(setParentModuleActiveDispatch("parentDashboard"))
            navigate("/homePage")
          }}>
              <img src={Logo} alt="Logo" />
          </NavbarBrand>
          <Nav className="me-0 me-md-3 ml-auto d-none d-md-block" navbar onClick={()=>navigate("/manage-account")}>
              <NavItem className="header-profile">
                  <NavLink href="#" className={userDetails.premiumUser === 1 ? 'user-premium' : 'user-free'}>
                    <span className="userProfile">
                      <img src={userDetails.profilePicPath!=""?userDetails.profilePicPath: ProfileImage} alt="profile" className="profile-sm" />
                      <img src={RankIcon} alt="profile" className="profile-rank" />
                    </span>
                      <label>
                        <span className="userName">{userDetails.firstName} {userDetails.lastName}</span>
                        <span className="userPlan">{getStatus()}</span>
                        {/* <span className="userPlan">{userDetails.premiumUser === 1 ?"Premium": "Free" }</span> */}
                      </label>
                  </NavLink>
              </NavItem>
          </Nav>
        </Navbar>
      </header>
    </React.Fragment>
}

export default (props)=>{
    return (
        <Parent>
          <React.Fragment>
              {HeaderComponent()}
          </React.Fragment>
        </Parent>
    )
}