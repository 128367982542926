import React from "react";
import Info from "./info";
import Dashboard from "./../Dashboard";

export default (props) =>{

    const subscription= props.subscription;

    console.log("getStripCustomerresFreeTrialgetClientSecret premium 2", );

    return <Dashboard><Info subscription ={subscription} /></Dashboard> 

}