import React from "react";
import AddAssets from './../../ASSETS/Images/icons/Add_Assets.png';

export default (props)=>{

    return (
        <React.Fragment>
            {/* Add Asset button  */}
            <div className="addAssets">
                <button className="btn btn-link" onClick={props.toggleUploadModal}><img src={AddAssets} alt="profile" className="img-fluid" /></button>
            </div>
        </React.Fragment>
    )
}