import React, { useEffect } from "react";
import Parent from '.'
import {postSavenothankyou} from '../../UTILITYS/endPoints'
import { useNavigate, useSearchParams} from 'react-router-dom'
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {useDispatch} from 'react-redux'
import { Card, CardBody, CardFooter, Col} from 'reactstrap'
import thankYou from './../../ASSETS/Images/thankYou.jpg'

export default (props) =>{
    
    const dispatch = useDispatch()
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();

    let token = React.useState(
        searchParams.get("token") 
    );
    
    let id = React.useState(
        searchParams.get("id")
    );
     
    let cid = React.useState(
        searchParams.get("cid")
    );

    console.log("tokentoken", id[0], token[0], cid[0]);

    useEffect(()=>{
        if(token[0] === null || id[0] === null){
            navigate("/");
        }else{
            applySaveThankYou();
        } 
    }, []);

    const applySaveThankYou =async () =>{
        let saveThankYouDATA = {
            id: id[0],
            token: token[0],
            CustodianID: cid[0]
        }
        try{
            await postSavenothankyou(saveThankYouDATA, dispatch); 
            }catch (err){
        }
    }

    return (
        // <Parent>
            <Col sm="6" md="4" lg="3" className="modal-wrapper-thankyou">
                <Card className="my-2 cursor-pointer">
                    <CardBody className="p-0 text-center">
                    <img src={thankYou} alt="Images" className="img-fluid" />
                    </CardBody>
                    <CardFooter style={{background: "white"}}>
                    <Col >
                        <h5>Thank You!!</h5>
                    </Col>
                    <Col>
                        <h6>Your response has been received</h6>
                    </Col>
                    </CardFooter>
                </Card>
            </Col>
        // </Parent>
    )
}