import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
  open: false
}

export const loader = createSlice({
  name: 'loader',
  initialState: {
   ...initialState
  },
  reducers: {
    setLoaderState: (state, action)=>{
        // alert()
      state.open = action.payload;
    }
  }
})

// Action creators are generated for each case reducer function
export const { setLoaderState } = loader.actions


export const loaderDispatch = bool => {
    
    return async(dispatch, getState) => {
        dispatch(setLoaderState(bool))
    }
}


export default loader.reducer