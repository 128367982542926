import { useState, useEffect } from "react"
import { useDispatch } from 'react-redux';

export default function useLoadNextPage(defaultPage, fetchFunction, pageSize, ...rest) {
    
    const dispatch = useDispatch();  
    const [page, setPage] = useState(defaultPage);

    useEffect(()=>{
        console.log(" ksi sss heele ee 3>>", page)
        if(page === 1){
            dispatch(fetchFunction(page, pageSize, ...rest)); 
        }else{
            dispatch(fetchFunction(page, pageSize, ...rest)); 
        }
    },[page]);

    function loadNextPage() {
        setPage(page+1);
    }

    function resetValue() {
        setPage(1);
    }

    return [page, loadNextPage, resetValue]
}
