import React, {useState, useEffect, useMemo, useCallback } from "react";
import HeaderSideBar from "./../../index";
import { Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import VideosCardList from "./../../../../COMPONENTS/AssestsAndList/Videos/VideosCardList";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "./../../../../UTILITYS/Constant";
import Asset from './../../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "./../../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import { VRresetAssests, VRfetchVideoDispatch, setToggleSelectForVideoVRDispatch, resetToggleSelectForVidioVRDispatch } from "../../Slices/VRassestList.slice";
import Breadcrum from "../../../../COMPONENTS/Breadcrum";
import BG from './../../../../ASSETS/Images/BG.jpg';
import MobileBG from './../../../../ASSETS/Images/Mobile-BG.jpg';
import { setParentModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { toast } from 'react-toastify';
import BreadCrumWithShareAndDelete from "../../../AssestsManagment/BreadCrumWithShareAndDelete";
import Delete from './../../../../ASSETS/Images/icons/delete.svg';
import { Tooltip } from 'reactstrap';
import DeleteModal from "../../../../COMPONENTS/DeleteModal";
import { deleteShareAssest } from "../../../../UTILITYS/endPoints";
import useLoadNextPage from "./../../../../UTILITYS/hook/loadNextPage";
import { useMediaQuery } from 'react-responsive';
import { useLongPress } from 'use-long-press';

export default (props)=>{

    const dispatch = useDispatch();
    let {state}  = useLocation();
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isVideoEmpty, setIsVideoEmpty] = useState(true);
    const VRassestList = useSelector(state => state.VRassestList);
    const [room, seTroom] = useState(state.room)
    const [deleteModal, setDeleteModal] = useState(false); 
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [page, loadNextPage, resetValue] = useLoadNextPage(1, VRfetchVideoDispatch, 25, room.relationshipID,
        room.vrRoomMappingID);
    const isMobileView = useMediaQuery({ maxWidth: 767 });
    const [isLongPressEnable, setIsLongPressEnable] = useState(false); 
    
    // TO GET YOUR ASSEST THAT YOU UPLOADED
    useEffect(()=>{
        dispatch(VRresetAssests())
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("vr_Experience"))
    }, [dispatch]);

    const toggleTolltip=() =>{
        setTooltipOpen(!tooltipOpen)
    }
 
    // useEffect(()=>{
    //     dispatch(VRfetchVideoDispatch(loadEventIndex, 25, 
    //         room.relationshipID,
    //         room.vrRoomMappingID
    //         ))
    //     // dispatch(VRfetchVideoDispatch(loadEventIndex, 25, userData.userID))
    // },[loadEventIndex])
    
    // const fetchMoreData = () => {
    //     seLoadEventIndex(loadEventIndex+1)
    // };

    const fetchMoreData = () => {
        loadNextPage();
    };

    useEffect(()=>{
        if( VRassestList.videos.length === 0){
            setIsVideoEmpty(true)
        }else{
            setIsVideoEmpty(false)
        }
    }, [VRassestList.videos])


    const getCheckBoxClasses = (id)=>{
        let currentVideo= null;
        if(VRassestList.videos.length>0){
            currentVideo =VRassestList.videos.find(video=> video.id === id && video.isVideoSelected )
        }
        if(currentVideo != null && currentVideo != undefined)
        return "my-1 selected";
        return "my-1";
    }
    const toggleSelectBox = (id)=>{
        if(VRassestList.videos.find(video=> (video.id === id && video.isVideoSelected))){
            dispatch(setToggleSelectForVideoVRDispatch(id))
        }else if(VRassestList.videos.filter(video=> video.isVideoSelected).length ===10){
            toast.dismiss();
            toast.error("You can only select maximum 10 Videos", { });
            return
        }else{
            dispatch(setToggleSelectForVideoVRDispatch(id))
        }
    }

    const getVideosAssestsList = ()=>{
        if(VRassestList.videos.length ===0) return null;
       return  <InfiniteScroll
                    className="row"
                    dataLength={VRassestList.videos}
                    next={fetchMoreData}
                    hasMore={VRassestList.hasMoreVediosAssests}
                    loader={VRassestList.videos.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
               >
               {getVideossAssest()}
           </InfiniteScroll>
    
      
    }

    const bind = useLongPress((e, params) => {
        // if(!isLongPressEnable){ 
        if(!isLongPressEnable && isMobileView !== undefined && isMobileView){ 
            setIsLongPressEnable(true);
            toggleSelectBox(params.context);
        }
    });

    useEffect(()=>{
        if(VRassestList.videos.filter(video=> video.isVideoSelected).length === 0){
            setIsLongPressEnable(false);
        }
    }, [VRassestList.videos.filter(video=> video.isVideoSelected).length])

    const getVideossAssest = ()=>{
        return VRassestList.videos.map((video, index)=>{
            return <VideosCardList 
                        video={video} 
                        key ={index} 
                        // isFF={true}
                        getCheckBoxClasses ={()=>getCheckBoxClasses(video.id)}
                        toggleSelectBox ={()=>toggleSelectBox(video.id)}
                      
                        isMobileView= {isMobileView}
                        bind= {bind}
                        isLongPressEnable= {isLongPressEnable}
                        />
        })
    } 

    const getArrayOfOBJ =()=>{
      
        let videosIDs= "";
        videosIDs=  VRassestList.videos.filter(video=> video.isVideoSelected ).map(video=> {
            return video.id
        }).toString();

        return {
            AssetsIDs: videosIDs,
            VRExperienceMappintID: room.vrRoomMappingID
        }
    }

    const deletingAssestHandler = async(deleteAssestDATA, dispatch, token)=>{
        let deletRes= await deleteShareAssest( deleteAssestDATA, dispatch, token);
        dispatch(resetToggleSelectForVidioVRDispatch())
        dispatch(VRresetAssests())
        if(page !== 1){
            resetValue(1);
        }else{
            dispatch(VRfetchVideoDispatch(1, 25, room.relationshipID,
                room.vrRoomMappingID))   
        }
        return deletRes;
    } 

    let isAnyOneIsSelected = null;
    let assestsCounts = null;
    isAnyOneIsSelected =VRassestList.videos.find(video=> video.isVideoSelected );
    assestsCounts =VRassestList.videos.filter(video=> video.isVideoSelected );

    const memoizedValue = useMemo(() =>["VR Experience", "Videos" ], []);
    const memoizedValueIsAnyOneIsSelected = useMemo(() =>isAnyOneIsSelected, [assestsCounts.length]);
    const memoizedValueAssestsCounts = useMemo(() =>assestsCounts, [assestsCounts.length]);
    const memoizedFunOnUnselectAssest = useCallback(() =>{ dispatch(resetToggleSelectForVidioVRDispatch()) }, [assestsCounts.length]);

    if(isVideoEmpty){

        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        // breadCrumbItems= {["VR Experience", "Videos" ]}
                        breadCrumbItems= {memoizedValue}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar>

    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                {/* Mom (Living Room) (7th VRpic-Sprint-6) */}

                <div className={isAnyOneIsSelected != null && isAnyOneIsSelected != undefined ? 'innerHeader withBorder ps-3 pe-3 ps-md-4 pe-md-4' : 'innerHeader ps-3 pe-3 ps-md-4 pe-md-4'}>
                    <div className="row align-items-center">
                            {/* <Breadcrum 
                                breadCrumbItems= {["VR Experience", "Videos"]}
                            /> */}
                            <BreadCrumWithShareAndDelete 
                                // isAnyOneIsSelected= {isAnyOneIsSelected}
                                // assestsCounts ={assestsCounts}
                                // breadCrumbItems = {["VR Experience", "Videos"]}
                                // onUnselectAssest={()=>dispatch(resetToggleSelectForVidioVRDispatch())}

                                isAnyOneIsSelected= {memoizedValueIsAnyOneIsSelected}
                                assestsCounts ={memoizedValueAssestsCounts}
                                breadCrumbItems = {memoizedValue}
                                onUnselectAssest={memoizedFunOnUnselectAssest}
                            />

                    {isAnyOneIsSelected != null && isAnyOneIsSelected != undefined &&
                        <div className="col-sm-6 text-end">
                            <button id={"deleteAssestButton"} className="btn btn-primary btn-sm" onClick={setDeleteModal}>
                                <img src={Delete} alt="Images" className="img-fluid" />
                            </button>
                            <Tooltip placement="bottom" isOpen={tooltipOpen} target={"deleteAssestButton"} toggle={toggleTolltip}>Delete
                            </Tooltip>
                        </div>
                        }   


                    </div>
                </div>

                <div className="pageTitle">
                    <div className="imageHeader">
                        <img src={BG} alt="banner" className="d-none d-md-block w-100" />
                        <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                        <h3>{state.vrUser.name} (Living Room)</h3>
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4 inner_lists">  
                    <div className="assets_item ps-2 pe-2">
                        {getVideosAssestsList()}
                        
                    </div>
                </div>

                {/* Delete assests */}
                <DeleteModal 
                    isOpen= {deleteModal} 
                    setDeleteModal ={setDeleteModal}
                    getArrayOfOBJ= {getArrayOfOBJ}
                    onDeletingAssest = {deletingAssestHandler}
                />

            </HeaderSideBar>
        </React.Fragment>
    )
}