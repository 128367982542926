import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LoaderForIndividualPageDispatch } from "./../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { useLocation } from 'react-router-dom'
import { setParentModuleActiveDispatch } from "./../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "./../../COMPONENTS/SideBarMenu/activeModule.slice";
import { getSubscriptionDispatch, resetSubscriptionDetails, setIsDetailsGetToFalse } from "./sbscription.slice";
import Free from './free'
import Premium from './premium'
import Dashboard from "./../Dashboard";


export default ()=> {

    const dispatch = useDispatch();
    const subscription = useSelector(state => state.subscription);
    let {state}  = useLocation();
    
    // TO GET THE INFORMATION OF THE EXISTING USER
    useEffect(()=>{

        dispatch(setParentModuleActiveDispatch("parentProfile"))
        dispatch(setModuleActiveDispatch("subscription"))

        if(!state){
            dispatch(resetSubscriptionDetails())
            dispatch(LoaderForIndividualPageDispatch(true));
            dispatch(getSubscriptionDispatch())
            dispatch(LoaderForIndividualPageDispatch(false));
        }
        
        return ()=>{
            dispatch(setIsDetailsGetToFalse())
        }

    }, []);

    useEffect(()=>{
    }, [])

    if(!subscription.isDetailsGet){ 
        console.log('setSubscriptionDetails updateSubscriptionDetails >>', 2, subscription)
        return <Dashboard></Dashboard> 
    }

    // need to add one conditon for `due` status
    console.log("getStripCustomerresFreeTrialgetClientSecret free upper", subscription);
    if(subscription.stripeSubscriptionDetails && subscription.stripeSubscriptionDetails.status === "trialing"){
        console.log("getStripCustomerresFreeTrialgetClientSecret free 1", subscription);
        return <Free subscription= {subscription}/>
    }

    console.log("getStripCustomerresFreeTrialgetClientSecret premium 1", );
 
    return (
        <Premium subscription= {subscription}/>
    )
    

};