import React from "react";
import { Row, Col, Card, CardBody, CardFooter, Button, Modal, ModalBody, Progress } from 'reactstrap';
import { BASE_URL } from "../../../APIs";
import FolderIcon from './../../../ASSETS/Images/icons/Folder.png';

export default (props)=>{
    const getAuViImText = ()=>{
        if(props.selfLibraryOnHome){
            if(props.fileType === 1){
                return "Audios";
            }else if(props.fileType === 2){
                return "Videos";
            }else if(props.fileType === 3){
                return "Images"; 
            }
        }else{
            return props.name.length >9 ? props.name.substring(0, 7)+"...": props.name
        }
    }

    return (
        <CardFooter>
            {/* <img src={getImage()} alt="Folder" className="me-2" /> {getAuViImText()} */}
            {!props.isFF &&<img src={FolderIcon} alt="Folder" className="me-2" />} {getAuViImText()}
        </CardFooter> 
    )
}