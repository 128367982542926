import React, { useEffect, useContext, useState } from "react";
import Parent from "./../Dashboard";
import {useStripe, useElements, PaymentElement, Elements, CardElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CustomeCard from "./../../COMPONENTS/CustomeCard";
import {Button} from 'reactstrap'; 
import { PostPaymentMethod, creatSetupIntent, getSetupIntent, resumeSubscription, setDefautPaymentMethod } from "./../../UTILITYS/endPoints";
import { useSelector, useDispatch } from "react-redux";
import { LoaderForIndividualPageDispatch } from "./../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col } from 'reactstrap';
import Rank from './../../ASSETS/Images/icons/premium-plan.png'
import { setParentModuleActiveDispatch } from "./../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "./../../COMPONENTS/SideBarMenu/activeModule.slice";
import { startNewSubscription, updateSubscriptionWithPayment } from "./../../UTILITYS/endPoints";
import CreateTokenContextProvider from "./../../STORE/App-wide-state/Token/createTokenContextProvider";
import { updateSubscriptionDetailsDispatch } from "./sbscription.slice";
import { setSubscriptionStatus } from "./../Profile/slice.reducer";
const stripePromise = loadStripe("pk_test_51P6wMLI6PTYc8o1OnECPlEpQLiy6b98SnVI0l1wqGLez4AhozyCAv7uOiWFqgkhRUFriVmmrodKrdW1MrH6XEN6d00YC9xNjK4");

const CheckOutForm = (props)=>{
    
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let {state}  = useLocation();
    const subscription = useSelector(state => state.subscription);
    const tokenContext = useContext(CreateTokenContextProvider);

    const getStatusCode =(subscription)=>{

        // TRIAL
            // 1) with card `S_002` `S_004`
            if( (subscription.stripeSubscriptionDetails.status === "trialing" && !subscription.hasPaymentMethod) || (state !== null && state.isUpdatePaymentMethod))
                return "6"
            
        // COMMAN
            if( subscription.stripeSubscriptionDetails.status === "canceled" )
                return "5"
    }
    
    const showErrToast = async (result) => {
            toast.dismiss();
            if(result && result.error && result.error.code){
                if(result && result.error && result.error.message)
                toast(result.error.message, { });
        }
    }

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        const status = getStatusCode(subscription);
        // console.log("onChangeonChange 4", elements);
        
        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        
        toast.dismiss();
        dispatch(LoaderForIndividualPageDispatch(true));
        
        const {error: submitError} = await elements.submit();
        if (submitError) {
            dispatch(LoaderForIndividualPageDispatch(false));
            if(submitError.message)
                toast(submitError.message, { });
            return;
        }

        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
           
            },
            redirect: "if_required"
        });

        console.log("resultresultresult", result);
    
        if (result.error) {
            dispatch(LoaderForIndividualPageDispatch(false));
            showErrToast(result);
            return;
        } else {

            try{
                if(status === "5"){

                    console.log("getStripCustomerresFreeTrialgetClientSecret premium 3", );
                    
                    let dataWithNewCard ={
                        customerid: subscription.stripeSubscriptionDetails.customerId,
                        paymentmethodid: result.setupIntent.payment_method
                    }
    
                    let res = await startNewSubscription(dataWithNewCard, tokenContext.accessToken);
                    dispatch(updateSubscriptionDetailsDispatch(res.result.payload));
                    dispatch(setSubscriptionStatus({
                        isPremium: true,
                        isExpired: false
                    }))
    
                    console.log("getStripCustomerresFreeTrialgetClientSecret startNewSubscription", res);
                
                }else{

                    let data ={
                        subscriptionId: subscription.stripeSubscriptionDetails.subscriptionId,
                        // paymentmethodid: result.setupIntent.payment_method,
                        paymentMethodId: result.setupIntent.payment_method,
                        customerId: subscription.stripeSubscriptionDetails.customerId,
                        // "setupIntentId": result.setupIntent.id
                    }

                    let res = await updateSubscriptionWithPayment(data, tokenContext.accessToken);

                    console.log("getStripCustomerresFreeTrialgetClientSecret startNewSubscription", data );
                    dispatch(updateSubscriptionDetailsDispatch(res.result.payload));
                    dispatch(setSubscriptionStatus({
                        isPremium: true,
                        isExpired: false
                    }))
                }

                dispatch(LoaderForIndividualPageDispatch(false));
                navigate('/subscription', {state: {isupdate: true}})
            }catch (err){
                dispatch(LoaderForIndividualPageDispatch(false));
                if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
                console.log("resultresult 3", err);
            }
        
        }
    };
    
    return (
        <Parent>
            <React.Fragment>
                <div className="mx-3">
                    <Row className="">
                    <Col md={12} lg={7} xl={8} xxl={9}>
                       <div className="p-3">
                        <Row  className="justify-content-center align-items-center mh-100-67"> 
                            <CustomeCard customClassName="col-xs-12 col-sm-8 col-md-11 col-xl-7 col-xxl-5 pay">
                                <form onSubmit={handleSubmit}>
                                    <PaymentElement  />
                                    {/* <CardElement /> */}
                                    <div className="row">
                                        <div className="col-12 mt-3">
                                            <Button className=" w-100" color="primary" type="submit" >
                                                {state !== null && state.isUpdatePaymentMethod? "Update Payment Method": "Pay now"}
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </CustomeCard>
                        </Row>
                    </div>
                    </Col>
                    <Col md={12} lg={5} xl={4} xxl={3}>
                        <div className="order-detail">
                            <div>
                                <h6>Order Details</h6>
                                <div className="text-center  my-3 my-lg-4 py-lg-2">
                                    <img src={Rank} className="img-thumbnail" /><br/>
                                    <strong className="text-primary">Premium Plan</strong>
                                </div>
                                <table className="w-100">
                                    <tbody>
                                        <tr>
                                            <td className="small">Monthly subscription</td>
                                            <td className="text-end small">$9.99</td>
                                        </tr>
                                        <tr>
                                            <td className="small">VAT/GST/Sales taxes</td>
                                            <td className="text-end small">$0.00</td>
                                        </tr>
                                        
                                        <tr>
                                            <td className=""><strong><hr />Total</strong></td>
                                            <td className="text-end "><strong><hr />$9.99</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                            <div className="mt-3">
                                <small className="x-small">
                                Your subscription will renew automatically every month as one payment of 1416.00 INR. Cancel it anytime from your subscription settings. By clicking "Confirm and pay" you agree to the <a href="#">Terms and Condition.</a>
                                </small>
                            </div>
                        </div>
                    </Col>
                    </Row>
                </div>
            </React.Fragment>
        </Parent>
    )
}

export default ()=> {

    const [setupIntent, setSetupIntent] = useState(null);
    const tokenContext = useContext(CreateTokenContextProvider);
    const dispatch = useDispatch();

    const subscription = useSelector(state => state.subscription);

    const userProfileDetails = useSelector(state => state.userProfileDetails);
    
    useEffect(()=>{
        dispatch(setParentModuleActiveDispatch("parentProfile"))
        dispatch(setModuleActiveDispatch("subscription"))
        dispatch(LoaderForIndividualPageDispatch(true));
        getSetupIntentClientSecret();
        
    }, [userProfileDetails.isUserFetch]);
    
    const getSetupIntentClientSecret = async (event) => {
        toast.dismiss();
        try{
            let getSetup = await creatSetupIntent(subscription.stripeSubscriptionDetails, tokenContext.accessToken, dispatch );
            console.log("getSetupgetSetup", getSetup)
            setSetupIntent(getSetup);
        }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) 
            // toast.error(err.responseException.exceptionMessage, { });
            console.log('this is catch registration', err)
        }
        dispatch(LoaderForIndividualPageDispatch(false));
    }

    if(!setupIntent){
        return <Parent> </Parent>
    }else{
        const options = {
            clientSecret: setupIntent.result.payload.client_secret
        }
        return (
            <Elements 
                stripe={stripePromise} 
                options={{...options}}
            >
                <CheckOutForm 
                    clientSecret= {setupIntent.result.payload.client_secret}
                    customerID= {subscription.stripeSubscriptionDetails.customerId}
                    token= {tokenContext.accessToken}
                    email= {userProfileDetails.email}
                />
            </Elements> 
        );
    }

};


// I am doing recurring payment in india, and india's card required 3d secure authentication.
// I used done 3d secure authentication on client side successfully.
// But it gave me this Err `payment was initiated which requires an additional user action` and status is `incomplete` in stripe console
// I used react js and .net 


// Server side code(.net)::
//     ## Create Subscription with Trial

//     public async Task<Subscription> CreateSubscriptionWithTrialAsync(string customerId, string planId, int trialInMinutes)
//     {
//         try
//         {
//             // Assuming trialPeriodInMinutes is the variable representing the desired trial period in minutes
//             DateTimeOffset now = DateTimeOffset.UtcNow; // Get the current UTC time
//             DateTimeOffset trialEnd = now.AddMinutes(trialInMinutes); // Calculate trial end time (15 minutes from now)

//             Subscription? subscription = new Subscription();
//             //Console.WriteLine(trialEnd.ToString()); // Output trial end time
//             var subscriptionService = new SubscriptionService();

//             //Check if Subscription exist for this Customer
//             var subscriptions = await subscriptionService.ListAsync(
//                 new SubscriptionListOptions
//                 {
//                     Customer = customerId,
//                     Status = "all"
//                 });
//             subscription = subscriptions.FirstOrDefault();

//             //If there is no Subscription 
//             if (subscription == null)
//             {
//                 var subscriptionOptions = new SubscriptionCreateOptions
//                 {
//                     Customer = customerId,
//                     Items = new List<SubscriptionItemOptions>
//                     { 
//                         new SubscriptionItemOptions
//                         {
//                             Plan = planId,
//                             Quantity = 1,
//                         },
//                     },
//                     PaymentBehavior = "default_incomplete",
//                     //TrialPeriodDays = trialPeriodInDays,
//                     TrialEnd = DateTimeOffset.FromUnixTimeSeconds(trialEnd.ToUnixTimeSeconds()).UtcDateTime,
//                     // Set the collection method to charge automatically
//                     CollectionMethod = "charge_automatically",
//                 };
//                 subscription = await subscriptionService.CreateAsync(subscriptionOptions);
//             }
//             return subscription;
//         }
//         catch (Exception ex)
//         {
//             // Log the exception or handle it as needed
//             return null;
//         }
//     }


//     # Setup Intent With Mandate Option

//     public async Task<SetupIntent> CreateSetupIntentAsync_New(string customerId)
//     {
//         var intentService = new SetupIntentService();
//         var intentOptions = new SetupIntentCreateOptions
//         {
//             Customer = customerId,                
//             PaymentMethodTypes = new List<string> { "card" },
//             Usage = "off_session", // Set the usage to "off_session" if the payment will occur later
//             PaymentMethodOptions = new SetupIntentPaymentMethodOptionsOptions
//             {
//                 Card = new SetupIntentPaymentMethodOptionsCardOptions
//                 {
//                     MandateOptions = new SetupIntentPaymentMethodOptionsCardMandateOptionsOptions
//                     {
//                         Reference = "For Customer " + customerId,
//                         Description = "SetupIntent For New Subscription",
//                         Amount = 500,
//                         Currency = "inr",
//                         StartDate = DateTime.UtcNow,
//                         AmountType = "maximum",
//                         Interval = "day",
//                         IntervalCount = 1,
//                         SupportedTypes = new List<string> { "india" },
//                     },
//                 },
//             },
//         };
//         var setupIntent = await intentService.CreateAsync(intentOptions);
//         return setupIntent;
//     }


//     ## Attach PaymentID with Subscription after Adding Card

//     public async Task<Subscription> UpdateSubscriptionWithPaymentMethodAsync(string customerId, string subscriptionId, string paymentMethodId, string setupIntentId)
//     {
//         try
//         {
//             var subscriptionService = new SubscriptionService();
//             var customerService = new CustomerService();

//             var currentSubscription = await subscriptionService.GetAsync(subscriptionId);

//             // Confirm the SetupIntent with the new payment method
//             var setupIntent = await ConfirmSetupIntentAsync(setupIntentId, paymentMethodId);

//             // Set the newly attached payment method as the default for the customer
//             var updatedCustomer = await customerService.UpdateAsync(
//                 customerId,
//                 new CustomerUpdateOptions
//                 {
//                     InvoiceSettings = new CustomerInvoiceSettingsOptions
//                     {
//                         DefaultPaymentMethod = paymentMethodId,
//                     }
//                 }
//             );

//             // Update the subscription with the new payment method
//             var subscriptionUpdateOptions = new SubscriptionUpdateOptions
//             {
//                 DefaultPaymentMethod = paymentMethodId,
//                 // Set the trial end to 'now' to start the subscription immediately if in trial               
//                 //TrialEnd = currentSubscription.Status == "trialing" && (DateTime.UtcNow < currentSubscription.TrialEnd.Value) ? DateTime.UtcNow : currentSubscription.TrialEnd.Value,
//                 // Additional subscription update options if needed
//                 // Set the collection method to charge automatically
//                 CollectionMethod = "charge_automatically"
//             };

//             var updatedSubscription = await subscriptionService.UpdateAsync(subscriptionId, subscriptionUpdateOptions);

//             return updatedSubscription;
//         }
//         catch (Exception ex)
//         {
//             _logger.LogInformation(ex.Message);
//             return null;
//         }
//     }

//     public async Task<SetupIntent> ConfirmSetupIntentAsync(string setupIntentId, string paymentMethodId)
//     {
//         var intentService = new SetupIntentService();

//         // Create the SetupIntent confirmation options
//         var confirmOptions = new SetupIntentConfirmOptions
//         {
//             PaymentMethod = paymentMethodId, // The PaymentMethod to attach to the SetupIntent
//         };

//         // Confirm the SetupIntent
//         var setupIntent = await intentService.ConfirmAsync(setupIntentId, confirmOptions);

//         return setupIntent;
//     }


//  Client side code(react js):: 

//         const stripePromise = loadStripe(STRIP_PUBLISH_KEY);

//         const options = {
//             clientSecret: setupIntent.result.payload.client_secret, appearance: stripAppearance
//         }
//         return (
//             <Elements 
//                 stripe={stripePromise} 
//                 options={{...options}}
//             >
//                 <form onSubmit={handleSubmit}>
//                         <PaymentElement />
//                         <div className="row">
//                             <div className="col-12 mt-3">
//                                 <Button className=" w-100" color="primary" type="submit" >
//                                     {state !== null && state.isUpdatePaymentMethod? "Update Payment Method": "Pay now"}
//                                 </Button>
//                                 {/* <Button className="ms-2 ms-md-3 w-100" color="primary" >Cancel</Button> */}
//                             </div>
//                         </div>
//                 </form>
//             </Elements>
//         )


//     const handleSubmit = async (event) => {
//         // We don't want to let default form submission happen here,
//         // which would refresh the page.
//         event.preventDefault();
//         const status = getStatusCode(subscription);
//         // console.log("onChangeonChange 4", elements);
        
//         if (!stripe || !elements) {
//             // Stripe.js hasn't yet loaded.
//             // Make sure to disable form submission until Stripe.js has loaded.
//             return;
//         }
        
//         toast.dismiss();
//         dispatch(LoaderForIndividualPageDispatch(true));
        
//         const {error: submitError} = await elements.submit();
//         if (submitError) {
//             dispatch(LoaderForIndividualPageDispatch(false));
//             if(submitError.message)
//                 toast(submitError.message, { });
//             return;
//         }

//         const result = await stripe.confirmSetup({
//             elements,
//             confirmParams: {
           
//             },
//             redirect: "if_required"
//         })
    
//         if (result.error) {
//             dispatch(LoaderForIndividualPageDispatch(false));
//             showErrToast(result);
//             return;
//         } else {

//             try{
//                 let data ={
//                     subscriptionId: subscription.stripeSubscriptionDetails.subscriptionId,
//                     paymentmethodid: result.setupIntent.payment_method,
//                     customerId: subscription.stripeSubscriptionDetails.customerId,
//                     "setupIntentId": result.setupIntent.id
//                 }


//                 let res = await updateSubscriptionWithPayment(data, tokenContext.accessToken);

//                 dispatch(updateSubscriptionDetailsDispatch(res.result.payload));
//                 dispatch(setSubscriptionStatus({
//                     isPremium: true,
//                     isExpired: false
//                 }))

//                 dispatch(LoaderForIndividualPageDispatch(false));
//                 navigate('/subscription', {state: {isupdate: true}})
//             }catch (err){
//                 dispatch(LoaderForIndividualPageDispatch(false));
//                 if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
//                 console.log("resultresult 3", err);
//             }
        
//         }
//     };


// card `Supported`:: 3dsauthentication@yopmail.com 4000000000003055 <-- working
// card `Required`:: paymentmust1@yopmail.com 4000000000003220   <-- Not working