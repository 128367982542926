import React, {useState} from "react";
import CustomeCardBody from "./CardBody";
import CustomeCardFooter from "./CardFooter";
import {  Col, Card } from 'reactstrap';
import { Tooltip } from 'reactstrap';

export default (props)=>{
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const toggle=() =>{
        setTooltipOpen(!tooltipOpen)
    }

    return (
        <React.Fragment>
            <Col sm="6" md="4" lg="3" id={props.tolltipID}>
                <Card className="my-2" onClick={props.onNavigate}>
                    <CustomeCardBody {...props}/> 
                    <CustomeCardFooter {...props}/>
                </Card>
                {!props.hideTooltip && <Tooltip placement="bottom" isOpen={tooltipOpen} target={props.tolltipID} toggle={toggle}>
                    {props.name}
                </Tooltip>}
            </Col>
        </React.Fragment>
    ) 
} 