import React, { useState, useEffect } from "react";
import Home from "..";
import { useNavigate } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from "react-infinite-scroll-component";
import UserInfoCard from "../../../COMPONENTS/HOME/CustomeCard/UserInfoCard";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { random } from "../../../UTILITYS/index.tsx";
import { fetchVrUsersDispatch } from "../Slices/VRexperienceUser.slice";
import AddVRexperieceModal from "../../../COMPONENTS/AddVRexperieceModal";
import { Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import ImagesIcon from './../../../ASSETS/Images/defaultProfilePic.jpg';
import AddVRaddAssestModal from "../../../COMPONENTS/VRaddAssestModal";
import Edit from './../../../ASSETS/Images/icons/edit.png';
import { setDoNotShowExpiredModal } from "../../Profile/slice.reducer.tsx";
import { toast } from 'react-toastify';
import { ExpiredModal } from "../../../COMPONENTS/Modal/Expired.js";

let visitPageFirstTime= true;

export default ()=>{ 

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const vrUsers = useSelector(state => state.vrUsers);
    const [loadEventIndex, seLoadEventIndex] = useState(2);
    const [updatedVrUser, setUpdatedVrUser] = useState({});
    const [openUpdatedVrUserModal, setOpenUpdatedVrUserModal] = useState(false);
    const userProfileDetails = useSelector(state => state.userProfileDetails);

    useEffect(()=>{
        visitPageFirstTime= true;
    }, [])

    useEffect(()=>{
        if(!visitPageFirstTime ){
            dispatch(fetchVrUsersDispatch(loadEventIndex, 25))
        }
        visitPageFirstTime= false;
    },[loadEventIndex])  

    const fetchMoreData = () => {
        seLoadEventIndex(loadEventIndex+1) 
    };

    const onNavigate = (user) => { 
        // console.log('this is deata commmie  f af', user)
        navigate('/vr-rooms-list', {state: user})
    } 

    const getVRusersList = ()=>{
        
        return <InfiniteScroll
                    className="row"
                    dataLength={vrUsers.users}
                    next={fetchMoreData}
                    hasMore={vrUsers.setHasMoreVRUser}
                    loader={vrUsers.users.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                    </p>
                    }
                >
                {getVRusers()}
            </InfiniteScroll>
       
    }

    const getVRusers = ()=>{ 
         
         return vrUsers.users !=undefined && vrUsers.users.length >0 && vrUsers.users.map((user, index)=>{
             return getUser(user)
            })
    }

    const onUpdate = (user)=>{ 

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }
        setOpenUpdatedVrUserModal(!openUpdatedVrUserModal)
        setUpdatedVrUser(user)
    }
        
    // VR users tiles (3rd VRpic-Sprint-6)
    const getUser = (user)=>{ 
        return <Col sm="6" md="4" lg="3" onClick={()=>onNavigate(user)}>
                <Card className="my-2 cursor-pointer" >
                    <CardBody className="p-0 text-center">
                        <img src={ImagesIcon} alt="Images" className="img-fluid" />
                    </CardBody>
                    <CardFooter>
                        {/* <p className="mb-0 lead">{user.name}</p>
                        <span className="truncateEmail">{user.email}</span>  */}
                        <Row> 
                            <Col col={11}>
                            <p className="mb-0 lead">{user.name}</p>
                            <span className="truncateEmail">{user.email.substring(0, 19)+"..."}</span>
                            </Col> 
                            
                            <Col col={1} className="align-end" >
                                <img src={Edit} alt="Images" onClick={(e)=>{
                                    e.stopPropagation();
                                    onUpdate(user)
                                }} />
                            </Col>
                        </Row> 
                    </CardFooter>
                </Card>
            </Col>

    }

    return ( 
        <React.Fragment>
            <ExpiredModal />
            <Home>
                <div className="ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                    {/* SHARED USERS INFORMATIONS  */}
                    <div className="pageTitle">
                        <Row>
                            <Col xs="12">
                                <h5>VR Viewer for</h5>
                            </Col>
                        </Row>
                    </div>
                    <div className="userCard">
                       {getVRusersList()} 
                    </div>
                </div>

                {/* { `Add VR Experience` will goes here (1st VRpic-Sprint-6 )  */}
                <AddVRexperieceModal 
                    updatedVrUser= {updatedVrUser}
                    openUpdatedVrUserModal= {openUpdatedVrUserModal}
                    setOpenUpdatedVrUserModal= {()=>{
                        setUpdatedVrUser({});
                        setOpenUpdatedVrUserModal(false);
                    }}
                /> 
                {/* <AddVRaddAssestModal /> */}
            </Home> 
        </React.Fragment>
    )
}