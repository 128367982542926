import { createSlice } from '@reduxjs/toolkit'
import { getData } from './../../../UTILITYS/index.tsx';
 
import * as API from './../../../APIs'

const initialState = {
    images: [],
    audios: [],
    videos: [],
    isImgResGet: false,
    isAudioResGet: false,
    isVideoResGet: false,
    hasMoreImageAssests: true,
    hasMoreAudioAssests: true,
    hasMoreVediosAssests: true,
}

export const VRassestList = createSlice({
  name: 'VRassestList',
  initialState: { ...initialState }, 
  reducers: {
  
    // REDUCERS FOR SHARED BY USERS
    setImages: (state, action) => {
      return {
        ...state,
          images: [...state.images ,...action.payload.pagedList]
        }
    },
    setAudios: (state, action) => {
      return {
        ...state,     
        audios: [...state.audios ,...action.payload.pagedList]
      }
    },
    setVideos: (state, action) => {
      return {
        ...state,    
        videos: [...state.videos ,...action.payload.pagedList]
      }
    },
    setResponseForVrAssestList: (state, action) => {
      console.log('this is res of i sssf ff', action)
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    },
    setHasMoreImage: (state, action)=>{
      return {
        ...state,
          hasMoreImageAssests: action.payload
      }
    },
    setHasMoreAudio: (state, action)=>{
      return {
        ...state,
          hasMoreAudioAssests: action.payload
      }
    },
    setHasMoreVideo: (state, action)=>{
      return {
        ...state,
          hasMoreVediosAssests: action.payload
      }
    },
    isFileUploading: (state, action) => {
      return {
        ...state,
        isFileUploadingState: action.payload
      }
    },
    VRresetAssests: (state, action)=>{
      return {...initialState}
    },
    setToggleSelectForAudioVR: (state, action) => {
      return {
        ...state,
        audios: [...state.audios].map(audio=>{
          if(action.payload === audio.id){
            console.log('this is our assestsData submit data >>', action.payload, audio.id, audio)
            return {
              ...audio,
              isAudioSelected: !audio.isAudioSelected
            }
          }
          return {
            ...audio
          }
        })
      }
    },
    setToggleSelectForVideoVR: (state, action) => {
      return {
        ...state,
        videos: [...state.videos].map(video=>{
          if(action.payload === video.id){
            console.log('this is our assestsData submit data >>', action.payload, video.id, video)
            return {
              ...video,
              isVideoSelected: !video.isVideoSelected
            }
          }
          return {
            ...video
          }
        })
      }
    },
    setToggleSelectForIMGVR: (state, action) => {
      return {
        ...state,
        images: [...state.images].map(img=>{
          if(action.payload === img.id){
              console.log('this is our assestsData submit data >>', action.payload, img.id, img)
              return {
                ...img,
                isImgSelected: !img.isImgSelected
              }
            }
          return {
            ...img
          }
        })
      }
    },

    resetToggleSelectForAudioVR: (state, action) => {
      return {
        ...state,
        audios: [...state.audios].map(audio=>{
          return {
            ...audio,
            isAudioSelected: false
          }
        })
      }
    },
    resetToggleSelectForVideoVR: (state, action) => {
      return {
        ...state,
        videos: [...state.videos].map(img=>{
          return {
            ...img,
            isVideoSelected: false
          }
        })
      }
    },
    resetToggleSelectForIMGVR: (state, action) => {
      return {
        ...state,
        images: [...state.images].map(img=>{
          return {
            ...img,
            isImgSelected: false
          }
        })
      }
    },

  }
}) 

// Action creators are generated for each case reducer function
export const { 
  setImages,
  setAudios,
  setVideos,
  setHasMoreImage,
  setHasMoreAudio, 
  setHasMoreVideo,
  setResponseForVrAssestList,
  isFileUploading,
  VRresetAssests,
  setToggleSelectForAudioVR,
  setToggleSelectForVideoVR,
  setToggleSelectForIMGVR,
  resetToggleSelectForAudioVR,
  resetToggleSelectForVideoVR, 
  resetToggleSelectForIMGVR
 } = VRassestList.actions

const fetchIMG =async (token, dispatch, pageIndex, pageSize, relationshipID, vrRoomID) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VR_ASSEST_LIST}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=3&relationshipid=${relationshipID}&vrroomid=${vrRoomID}` 
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchIMGs =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchIMGs try", fetchIMGs)
    
    return fetchIMGs;
    
  }catch (err){

    console.log("this is a fetchIMGs catch")
    throw (err)
  }

}
export const VRfetchImagesDispatch = (paegIndex, pageSize, relationshipID, vrRoomID) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

      try{ 

        let token = getState().localSessionDetails.payload.accessToken;

        const resIMGs =await fetchIMG(token, dispatch, paegIndex, pageSize, relationshipID, vrRoomID)
        
        if(resIMGs.result.payload.pagedList.length === 0){
          dispatch(setHasMoreImage(false))
        }
        
        dispatch(setImages(resIMGs.result.payload))

        dispatch(setResponseForVrAssestList({ key: "isImgResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }

}

const fetchAudio =async (token, dispatch, pageIndex, pageSize, relationshipID, vrRoomID) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VR_ASSEST_LIST}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=1&relationshipid=${relationshipID}&vrroomid=${vrRoomID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const resAudios =await getData({url, headerConfig}, dispatch)

      console.log("this is a resAudios try", resAudios)
    
    return resAudios;
    
  }catch (err){

    console.log("this is a resAudios catch")
    throw (err)
  }

}
export const VRfetchAudioDispatch = (paegIndex, pageSize, relationshipID, vrRoomID) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resAudio =await fetchAudio(token, dispatch, paegIndex, pageSize, relationshipID, vrRoomID)
        
        if(resAudio.result.payload.pagedList.length === 0){
          dispatch(setHasMoreAudio(false))
        }
        
        dispatch(setAudios(resAudio.result.payload))

        dispatch(setResponseForVrAssestList({ key: "isAudioResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

const fetchVideo =async (token, dispatch, pageIndex, pageSize, relationshipID, vrRoomID) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VR_ASSEST_LIST}?pageindex=${pageIndex}&pagesize=${pageSize}&fileType=2&relationshipid=${relationshipID}&vrroomid=${vrRoomID}`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const resVideos =await getData({url, headerConfig}, dispatch)

      console.log("this is a resVideos try", resVideos)
    
    return resVideos;
    
  }catch (err){

    console.log("this is a resVideos catch")
    throw (err)
  }

}
export const VRfetchVideoDispatch = (paegIndex, pageSize, relationshipID, vrRoomID) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVideos =await fetchVideo(token, dispatch, paegIndex, pageSize, relationshipID, vrRoomID)
        
        if(resVideos.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVideo(false))
        }
        
        dispatch(setVideos(resVideos.result.payload))

        dispatch(setResponseForVrAssestList({ key: "isVideoResGet", value: true}))
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  } 
}


export const setToggleSelectForAudioVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForAudioVR(id))
  }
}
export const setToggleSelectForVideoVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForVideoVR(id))
  }
}
export const setToggleSelectForIMGVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(setToggleSelectForIMGVR(id))
  }
}
export const resetToggleSelectForAudioVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForAudioVR()) 
  }
}
export const resetToggleSelectForVidioVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForVideoVR())
  }
}
export const resetToggleSelectForIMGVRDispatch = (id) => {
  // console.log('this is our assestsData submit data >>',  id)
  return async(dispatch, getState) => {
    dispatch(resetToggleSelectForIMGVR())
  }
}


export default VRassestList.reducer 