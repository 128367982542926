import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import 'react-circular-progressbar/dist/styles.css';
// import { useDispatch, useSelector } from 'react-redux'
import { useAppSelector, useAppDispatch } from '../../../UTILITYS/hook/typeVersionOfredux.tsx';
import { Row, Col } from 'reactstrap';
import NoAssests from "../../../COMPONENTS/NoAssests/index.tsx";
import Dashboard from "../../Dashboard";
import { useNavigate, useLocation } from 'react-router-dom'
import { fetchImagesSharedByThisUserDispatch, fetchAudioSharedByThisUserDispatch, fetchVideoSharedByThisUserDispatch, resetSharedAssestWithMeOrBy } from "../slice.reducer";
import UserInfoCard from "../../../COMPONENTS/HOME/CustomeCard/UserInfoCard"; 
import AudioCardList from "../../../COMPONENTS/AssestsAndList/Audios/AudioCardList";
import VideosCardList from "../../../COMPONENTS/AssestsAndList/Videos/VideosCardList";
import ImagesCardList from "../../../COMPONENTS/AssestsAndList/Images/ImagesCardList";
import { setModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { setParentModuleActiveDispatch } from "../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { getImgURL } from "../../../UTILITYS/index.tsx";
import CarouselModal from "../../../COMPONENTS/Galaries/CarouselModal";
import {sharedAssestWithMeOrByInterface, sharedByThisUserInterface, sharedBymeInterFace} from "./../../../UTILITYS/Modals/slicese/sharedAssestWithMeOrBy.modal.ts";

export default ()=>{ 

    const dispatch = useAppDispatch();
    let { state } = useLocation();
    const [userData, setUserData] = useState(state)
    // const userProfileDetails = useSelector(state => state.userProfileDetails)
    const sharedAssestWithMeOrBy: sharedAssestWithMeOrByInterface = useAppSelector(state => state.sharedAssestWithMeOrBy) as sharedAssestWithMeOrByInterface;

        // TO GET YOUR ASSEST THAT YOU UPLOADED
        useEffect(()=>{
            dispatch(resetSharedAssestWithMeOrBy({}))
            dispatch(fetchImagesSharedByThisUserDispatch(1, 4, userData.userID))
            dispatch(fetchAudioSharedByThisUserDispatch(1, 4, userData.userID))
            dispatch(fetchVideoSharedByThisUserDispatch(1, 4, userData.userID))

            // SET SIDE MENU HIGHLIGHTING
            dispatch(setModuleActiveDispatch("shared_with_me"))
            dispatch(setParentModuleActiveDispatch("parentAssestManagment"))
        }, [dispatch]);

        const getCheckBoxClasses: () => string = ()=>{
            return "my-1";
        }
        const toggleSelectBox: () => void = ()=>{
            return;
        }

    return (
        <React.Fragment>
            <Dashboard>
            <div className="pageTitle orangeHeader">
                <Row>
                    <Col xs="12">
                        <h4><span>Shared by {userData.name}</span> <img src={getImgURL(userData.profilePic)} alt="Images" className="img-fluid profile-sm-square ms-3" /></h4>
                        
                    </Col>
                </Row>
            </div>
            
            <div className="sharedFF ps-0 pe-0 ps-md-3 pe-md-3 mb-3">
                {/* Your Library  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Images</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/Shared-img-list"
                            state={{...userData, isShareByMe: false}}
                        >See All</NavLink>
                        </span>
                    </div>
                    {( sharedAssestWithMeOrBy?.sharedByThisUser?.images?.length === 0) ? <NoAssests />: 
                        <Row>{sharedAssestWithMeOrBy?.sharedByThisUser?.images?.map((img, index)=>{
                            return (
                                <ImagesCardList img={img} index ={index} key ={index} isFF={true} assests= {sharedAssestWithMeOrBy?.sharedByThisUser?.images}
                                getCheckBoxClasses ={()=>getCheckBoxClasses()}
                                toggleSelectBox ={()=>toggleSelectBox()}
                                isHideCheckBox= {true}
                                />
                            )
                    })} </Row>} 
                </div>

                {/* Share with others  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Videos</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/Shared-video-list"
                            state={{...userData, isShareByMe: false}}
                        >See All</NavLink>
                        </span>
                    </div> 
                    {(sharedAssestWithMeOrBy?.sharedByThisUser?.videos?.length === 0 ) ? <NoAssests />: 
                    <Row>{sharedAssestWithMeOrBy?.sharedByThisUser?.videos?.map((video, index)=>{
                        return (
                            <VideosCardList 
                                video={video} 
                                key={index} 
                                isFF={true}
                                getCheckBoxClasses ={()=>getCheckBoxClasses()}
                                toggleSelectBox ={()=>toggleSelectBox()}
                            />
                        )
                    })}</Row>}     
                </div>

                {/* Share with me  */}
                <div className="assets_item ps-3 pe-3 mb-3 mt-4">
                    <div className="mb-1">
                        <span className="lead">Audios</span>
                        <span className="float-end">
                        <NavLink 
                            to= "/Shared-audio-list"
                            state={{...userData, isShareByMe: false}}
                        >See All</NavLink>
                        </span>
                    </div>
                    {(sharedAssestWithMeOrBy?.sharedByThisUser?.audios?.length === 0 ) ? <NoAssests />: 
                        <Row>{sharedAssestWithMeOrBy?.sharedByThisUser?.audios?.map((audio, index)=>{
                            return (
                                <AudioCardList 
                                    audio={audio} 
                                    key={index} 
                                    isFF={true}
                                    getCheckBoxClasses ={()=>getCheckBoxClasses()}
                                    toggleSelectBox ={()=>toggleSelectBox()}
                                />
                            )
                    })}</Row>}
                </div> 

            </div>

        </Dashboard>
        </React.Fragment> as JSX.Element
    )
}