import React, {useState, useEffect } from "react";
import HeaderSideBar from "./../index";
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import ImagesCardList from "./../../../COMPONENTS/360VirtualTour/Images/ImagesCardList";
import { useLocation, useNavigate} from "react-router-dom";
import { EMPTY_360_TOUR_DESCRIPTION_TEXT, EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT, EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT, EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "./../../../UTILITYS/Constant";
import Asset from './../../../ASSETS/Images/icons/VRAsset.png';
import NoContentWithOutUploadPage from "./../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
// import { VRfetchImagesDispatch, VRresetAssests } from "./../Slices/virtualTourImages.slice";
import Breadcrum from "./../../../COMPONENTS/Breadcrum";
import BG from './../../../ASSETS/Images/threeSixtyBanner.jpg';
import MobileBG from './../../../ASSETS/Images/Mobile-BG.jpg';
import { setParentModuleActiveDispatch } from "./../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "./../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { deleteVirtualTourImageDispatch, fetchVirtualTourImagesDispatch, resetvirtualTourImages, updateVirtualTourImageDispatch } from "../Slices/VirtualTourImages.slice";
import NoContentWithAddVirtualImagesButton from "../../../COMPONENTS/NoContentPage/NoContentWithAddVirtualImagesButton";
import { isFileUploadingDispatch } from "../../Home/slice.reducer";
import { LoaderForIndividualPageDispatch } from "../../../COMPONENTS/Loader/LoaderForIndividualPage.slice.reducer";
import ThreeSixtyImageUploader from "../../../COMPONENTS/DRAGE_FILE_FOLDER/ThreeSixtyImageUploader";
import ThreeSixtyImageUploderButton from "../../../COMPONENTS/UploadButton/ThreeSixtyImageUploderButton";
import { Button, Modal, ModalBody, FormGroup, Input, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import validator from 'validator';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import classNames from 'classnames';
import CancelIcon from './../../../ASSETS/Images/icons/close.svg' 
import { ExpiredModal } from "../../../COMPONENTS/Modal/Expired";
import { setDoNotShowExpiredModal } from "../../Profile/slice.reducer";
function ImageAssest(props){
    
    const dispatch = useDispatch(); 
    const LoaderForIndividualPage = useSelector(state => state.LoaderForIndividualPage)
    let {state}  = useLocation();
    const [loadVirtualImages, setLoadVirtualImages] = useState(1);
    const [isSaving, setIsSaving] = useState(false);
    const [isVirtualImageEmpty, setIsVirtualImageEmpty] = useState(true);
    const virtualTourImages = useSelector(state => state.virtualTourImages);
    const [tour, seTour] = useState(state)
    const [modal, setModal] = useState(false);
    const [deleteImgmodal, setdeleteImgmodal] = useState(false);
    const [updateName, setUpdateName] = useState('');
    const [updateNameErr, setUpdateNameErr] = useState(false)
    const [updatedImgOrDeletedImgDATA, setupdatedImgOrDeletedImgDATA] = useState({})
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const navigate = useNavigate();

    useEffect(()=>{
        dispatch(resetvirtualTourImages())
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("virtualTour"))
        dispatch(LoaderForIndividualPageDispatch(true))
    }, [])

    const closeUpdatedModal = () => {
        setupdatedImgOrDeletedImgDATA({})
        setModal(!modal);
    }

    const closeDeletedModal = () => {
        setupdatedImgOrDeletedImgDATA({})
        setdeleteImgmodal(!deleteImgmodal);
    }

    useEffect(()=>{
        if( virtualTourImages.isImgResGet ){
            dispatch(LoaderForIndividualPageDispatch(false))
        }
    }, [virtualTourImages.isImgResGet]);

    useEffect(()=>{
        console.log("resetvirtualTourImagesresetvirtualTourImages 4", loadVirtualImages);
        dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, tour.id)); 
    },[loadVirtualImages]) 

    const fetchMoreData = () => { 
        console.log("resetvirtualTourImagesresetvirtualTourImages 5", loadVirtualImages);
        // LoaderForIndividualPage.open
        if(!isSaving){
            console.log("resetvirtualTourImagesresetvirtualTourImages 6", loadVirtualImages);
            setLoadVirtualImages(loadVirtualImages+1)
        }
    };

    useEffect(()=>{
        if( virtualTourImages.images.length === 0){
            setIsVirtualImageEmpty(true)
        }else{
            setIsVirtualImageEmpty(false)
        }
    }, [virtualTourImages.images])   
    
    
    const onUpdateHanler = (img)=>{

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        setupdatedImgOrDeletedImgDATA(img);
        setUpdateName(img.name);
        setModal(!modal);
        // dispatch(updateVirtualTourImageDispatch(false));
    }

    const onDeleteHanler = (img)=>{

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        setupdatedImgOrDeletedImgDATA(img);
        setdeleteImgmodal(!deleteImgmodal);
        // dispatch(deleteVirtualTourImageDispatch(false));
    }

    const getVirtualImagesList = ()=>{
       return  <InfiniteScroll
                    className="row"
                    dataLength={virtualTourImages.images}
                    next={fetchMoreData}
                    hasMore={virtualTourImages.hasMoreVirtualTourImages}
                    loader={virtualTourImages.images.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                    }
                    // scrollThreshold= {0.9}
               >
               {getVirtualImag()}
           </InfiniteScroll>
      
    } 

    const goToTSworldHandler =(img) =>{
        navigate('/virtual-tour-world', {state: img});
    }

    const getVirtualImag = ()=>{
        return virtualTourImages.images.map((img, index)=>{
            console.log("getVirtualImaggetVirtualImag", img)
            return <ImagesCardList   
                img={img} 
                index ={index} 
                key ={index} 
                isFF={true} 
                assests= {virtualTourImages.images}
                getCheckBoxClasses ={()=>{}}
                onUpdate ={onUpdateHanler}
                onDelete= {onDeleteHanler}
                onGoToTSworld= {()=>goToTSworldHandler(img)}
            /> 
        })
    }

    const onFileUpload = ()=>{
        dispatch(resetvirtualTourImages());
        console.log("resetvirtualTourImagesresetvirtualTourImages 1", loadVirtualImages);
        if(loadVirtualImages !== 1){
            console.log("resetvirtualTourImagesresetvirtualTourImages 2", loadVirtualImages);
            setLoadVirtualImages(1);
        }else{
            console.log("resetvirtualTourImagesresetvirtualTourImages 3", loadVirtualImages);
            dispatch(fetchVirtualTourImagesDispatch(loadVirtualImages, 25, tour.id))
        }
        dispatch(LoaderForIndividualPageDispatch(true))
        dispatch(isFileUploadingDispatch(false))
        setIsSaving(false);
    } 

    const onInputChange = (e)=>{
        switch(e.target.name){
            case "update-name":
                setUpdateName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''));
                setUpdateNameErr(false)
            break;
        }
    }

    const updateImage= async()=>{
        
        toast.dismiss();

        if(validator.isEmpty(updateName.trim())){
            toast.error("Please Enter Image Name", { });
            setUpdateNameErr(true)
            return;
        }else if(!validator.isLength(updateName.trim(), {min: 1, max: 25})){
            toast.error("Image Name length should be minimum 3 char and maximum 25 char", { });
            setUpdateNameErr(true)
            return;
        }

        let mappingData = {
            name: updateName,
            id: updatedImgOrDeletedImgDATA.id 
        }

        try{
            console.log('object', mappingData)
            dispatch(updateVirtualTourImageDispatch(mappingData));
            // toast("Image updatad Successfully.");
            // dispatch(resetVirtualTour());
            // dispatch(fetchVirtualTourDispatch(1, 25));
            closeUpdatedModal()
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
          }

    }

    const deleteImage =async ()=>{
        let deleteAssestDATA = updatedImgOrDeletedImgDATA;
        try{
            console.log("deleteAssestDATAdeleteAssestDATA", deleteAssestDATA)
            dispatch(deleteVirtualTourImageDispatch({id: deleteAssestDATA.id}));
            closeDeletedModal()
          }catch (err){
            if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
        }
    } 

    if(isVirtualImageEmpty){ 
        return <HeaderSideBar> 
            <NoContentWithAddVirtualImagesButton 
                breadCrumbItems= {["VR Experience", "Images" ]}
                primaryText= {EMPTY_360_TOUR_IMAGES_PRIMARY_TEXT}
                description= {EMPTY_360_TOUR_IMAGES_DESCRIPTION_TEXT}
                noImgIcon= {Asset}
                onFileUpload ={onFileUpload}
                tour ={tour}
            />
        </HeaderSideBar>
    }
 
    return ( 
        <React.Fragment>
            <HeaderSideBar>
                <ExpiredModal />
                {/* <div className="innerHeader ps-3 pe-3 ps-md-4 pe-md-4">
                    <div className="row align-items-center">
                            <Breadcrum 
                                breadCrumbItems= {["VR Experience", "Images"]}
                            />
                    </div>
                </div> */}

                <div className="pageTitle">
                    <div className="imageHeader">
                        <img src={BG} alt="banner" className="d-none d-md-block w-100" />
                        <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                        <h3>{tour.name}</h3>
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4">            
                    <div className="assets_item ps-2 pe-2 virtual-tour-and-img">
                        {getVirtualImagesList()}
                    </div>
                </div>


                {/* UPLOAD 360 IMAGES */}
                <ThreeSixtyImageUploderButton   
                    onFileUpload ={onFileUpload}
                    tour ={tour}
                    setIsSaving ={setIsSaving}
                />

                {/* UPDATE IMAGE */}
                <Modal isOpen={modal} centered className="drop-modal" toggle={()=>{
                    closeUpdatedModal()
                    }} {...props}>
                    <Button className="modalClose" onClick={()=>{
                        closeUpdatedModal()
                    }}>
                        <img src={Close} alt="Images" className="img-fluid" />
                    </Button>
                    <ModalBody>
                        <div className="share_people p-2">
                            <div class="row">
                                <div className="col-12">
                                    <div>Rename</div>
                                    <FormGroup>
                                        <input value={updateName} type="text" placeholder="Enter name"
                                        className={classNames("form-control", { "form-error": updateNameErr })} name={'update-name'} onChange={onInputChange}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="col-12 text-center mt-4">
                                    <button 
                                        className="btn btn-primary"
                                        onClick={updateImage}
                                    >Save</button>
                                </div>
                            </div>
                        </div> 
                    </ModalBody>
                </Modal>

                {/* DELETE IMAGE */}
                <Modal isOpen={deleteImgmodal} centered className="modal-medium" toggle= {closeDeletedModal} {...props}>
                        <ModalBody>
                            <div className="text-center mt-3">
                                <img src={CancelIcon} alt="Images" className="img-fluid mb-4" />
                                <p>Are you sure?</p>
                            </div>  
                        </ModalBody>
                        <ModalFooter className="justify-content-center pt-0">
                            <Button className="me-2 me-md-3" color="outline-secondary" onClick={closeDeletedModal}>Cancel</Button>
                            <Button className="ms-2 ms-md-3" color="primary" onClick={deleteImage}>Yes</Button>
                        </ModalFooter>
                </Modal> 

            </HeaderSideBar>
        </React.Fragment>
    )
}

export default (ImageAssest)