import React from "react";
import { Row, Col, Card, CardBody, CardFooter, Button, Modal, ModalBody, Progress } from 'reactstrap';

import Image2 from './../../../ASSETS/Images/1.jpg';
import Video2 from './../../../ASSETS/Images/2.jpg';
import Audio2 from './../.././../ASSETS/Images/3.jpg';

import ProfileImage from './../../../ASSETS/Images/defaultProfilePic.jpg';
import AudiosIcon from './../../../ASSETS/Images/icons/Mus.png';
import VideosIcon from './../../../ASSETS/Images/icons/Vid.png';
import ImagesIcon from './../../../ASSETS/Images/icons/Img.png';

export default (props)=>{
    
    const getImagIcon = ()=>{ 
        if(props.fileType === 1){
            return AudiosIcon;
        }else if(props.fileType === 2){
            return VideosIcon;
        }else if(props.fileType === 3){
            return ImagesIcon;
        }
    }

    const getThumbNail = ()=>{
        if(props.selfLibraryOnHome){
            return props.imgSrc;
        }else if(props.shareWithOtherUserInfo || props.sharedWithMeUserInfo){
            if(props.profilePic !== null && props.profilePic !== "")
            return `${props.profilePic}`;
            return ProfileImage;
        }else{
            return props.thumbnailUrl;
        }
    }

    return (
        <CardBody className="p-0" onClick={props.onClick}>
            <img src={getThumbNail()} alt="Images" className="assets_image" />
            {props.selfLibraryOnHome &&<img src={getImagIcon()} alt="Images" className="card-icon img-fluid" />}
        </CardBody> 
    )
}