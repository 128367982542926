import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import './ASSETS/Css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import store from './STORE/store.tsx'
import { Provider } from 'react-redux'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // https://stackoverflow.com/questions/72369908/why-is-useeffect-running-twice
  // <React.StrictMode>
     <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// 4:38am UTC 2022-Oct-31