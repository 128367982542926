import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subModule: ""
}

export const activeModule = createSlice({
  name: 'activeModule',
  initialState: { ...initialState },
  reducers: {
    setModuleActive: (state, action) => {
      console.log('this is our setModuleActiveDispatch', action)
      return {subModule: action.payload}
    },
    resetModuleActive: (state, action) => {
    //   console.log('this is our activeModule submit data >>', action)
        return { ...initialState }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setModuleActive, resetModuleActive } = activeModule.actions

export const setModuleActiveDispatch = (data) => {

  return async(dispatch, getState) => {
    dispatch(setModuleActive(data));
  }
}

export const resetModuleActiveDispatch = () => {
  return async(dispatch) => {
    dispatch(resetModuleActive());
  }
}


export default activeModule.reducer