import React, {useEffect, useState} from "react";
import AudioPlayer from 'react-h5-audio-player';
import Close from './../../../ASSETS/Images/icons/cancel.png'
import { Modal, ModalBody, Button } from 'reactstrap';

export default (props)=>{

    const [isOpenAudio, setIsOpenAudio] = useState(props.isOpenAudio); 

    useEffect(()=>{
        setIsOpenAudio(props.isOpenAudio)
    }, [props.isOpenAudio])
    return (
        <Modal isOpen={isOpenAudio} centered className="drop-modal audio-modal" toggle={setIsOpenAudio} {...props}>
            <Button className="modalClose" onClick={()=>{
                    setIsOpenAudio(false)
                    props.closeAudioPlayer()
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <AudioPlayer
                    src={props.audio.fIleUrl}
                    volume={0.5}
                    autoPlay= {true}
                />
            </ModalBody>
        </Modal>
    )
}