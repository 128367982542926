import React from "react";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap'
 
const BC= (props)=>{
  
    console.log('this is breadcrumb component 1',)
  
    const BredCrumItems =props.breadCrumbItems.map((item, index) =>{
        if(index === 0)
            return  <BreadcrumbItem active>
                        {item} 
                    </BreadcrumbItem>
        return  <BreadcrumbItem >
                    {item} 
                </BreadcrumbItem>
        })

    return <div className='col-12'>   
            <Breadcrumb className="mb-0">
                {BredCrumItems}
            </Breadcrumb>
        </div>
}

export default React.memo(BC);