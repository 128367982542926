import { createSlice } from '@reduxjs/toolkit'
import { getData, postData } from './../../../UTILITYS/index.tsx';
import { toast } from 'react-toastify';
import * as API from './../../../APIs'

const initialState = { 
  users: [],
  setHasMoreVRUser: true,
  isVRusersResGet: false,
} 

export const vrUsers = createSlice({
  name: 'vrUsers',
  initialState: { ...initialState }, 
  reducers: {
  
    // REDUCERS FOR SHARED BY USERS
    setVRusers: (state, action) => {
      // console.log('this is my vr userss', action)
      return {
        ...state,
        users: [...state.users, ...action.payload.pagedList]
      }
    },
    resetVRusers: ()=>{
        return {...initialState}
    },
    setHasMoreVRUser: (state, action)=>{
        return {
            ...state,
            setHasMoreVRUser: action.payload
        }
    },
    updatePIN: (state, action) => {
      return {
        ...state,
        users: [...state.users.map(user =>{
          if(user.id === action.payload.id)
            return {
              ...user,
              pin: action.payload.pin
            }
            return user;
        })]
      }
    },
    setResponseForVRusers: (state, action) => {
      console.log('this is res of i sssf ff', action)
      return {
        ...state,    
        [action.payload.key]: action.payload.value
        }
    },

    setUpdateVrUser: (state, action) => {

      return {
        ...state,
        users: [...state.users.map(user =>{
          if(user.id === action.payload.id)
            return {
              ...user,
              ...action.payload
            }
            return user;
        })]
      }
    }
  }
}) 

// Action creators are generated for each case reducer function
export const { setVRusers,
    resetVRusers,
    setHasMoreVRUser,
    setResponseForVRusers,
    updatePIN,
    setUpdateVrUser } = vrUsers.actions

// for images (SHARED BY USER)
const fetchVrUsers =async (token, dispatch, pageIndex, pageSize) =>{

    try{

      let url= `${API.BASE_URL+API.GET_VR_USERS}?pageindex=${pageIndex}&pagesize=${pageSize}&relationshipid=0`
     
      let headerConfig = {
        "Content-Type": "application/json",
        "Authorization": "Bearer " +token
      }

      const fetchVRUser =await getData({url, headerConfig}, dispatch)

      console.log("this is a fetchVRUser try", fetchVRUser)
    
    return fetchVRUser;
    
  }catch (err){

    console.log("this is a fetchVRUser catch")
    throw (err)
  } 

}

export const fetchVrUsersDispatch = (paegIndex, pageSize, pinManagment) => {

  return async(dispatch, getState) => {

    console.log("fkjfkdfjkdj", getState().localSessionDetails.payload)
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resVRUser =await fetchVrUsers(token, dispatch, paegIndex, pageSize)
        
        if(resVRUser.result.payload.pagedList.length === 0){
          dispatch(setHasMoreVRUser(false))
        }
        
        dispatch(setVRusers(resVRUser.result.payload));

        console.log("setVRuserssetVRusers", resVRUser.result.payload);

        pinManagment === undefined && dispatch(setResponseForVRusers({ key: "isVRusersResGet", value: true}));
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

const updatePin =async (token, dispatch, data) =>{

  try{

    let url= `${API.BASE_URL+API.GENERATE_PIN}`
   
    let headerConfig = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " +token
    }

    let dataObje= {
      ...data
    }

    const fetchUpdatedPIN =await postData({url, headerConfig, dataObje}, dispatch)

    console.log("this is a fetchUpdatedPIN try", fetchUpdatedPIN)
  
  return fetchUpdatedPIN;
  
}catch (err){

  if(err!=undefined && err.isError!= undefined && err.isError) toast.error(err.responseException.exceptionMessage, { });
}

}

export const updateVrUsersDispatch = (data) => {
  return async(dispatch, getState) => {
    dispatch(setUpdateVrUser(data))
  }
} 


export const updatePinDispatch = (data) => {

  return async(dispatch, getState) => {
    
    if(getState().localSessionDetails.payload === "") return;

    try{
        let token = getState().localSessionDetails.payload.accessToken;

        const resPIN =await updatePin(token, dispatch, data)
        
        if(resPIN.result != undefined && resPIN.result.code === 200){
          dispatch(updatePIN(resPIN.result.payload))
        }
       
      }catch (err){

        if(err!= undefined && err.isError){
           
        }
    }
  }
}

export default vrUsers.reducer 