import React, {useEffect, useState} from "react";
import Gallerary from './Gallerary'
import Close from './../../ASSETS/Images/icons/cancel.png'
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap';
 
export default (props)=>{

    const [isOpenGallery, setIsOpenGallery] = useState(props.isOpenCarousel); 

    useEffect(()=>{
        console.log("isOpenGalleryisOpenGallery", props.assests,
        props.index);
        setIsOpenGallery(props.isOpenCarousel)
    }, [props.isOpenCarousel])

    return (
        <Modal isOpen={isOpenGallery} centered className="drop-modal imageModal" toggle={setIsOpenGallery} {...props}>
            <Button className="modalClose" onClick={()=>{
                    setIsOpenGallery(false)
                    props.closeCarousel()
            }}>
                <img src={Close} alt="Images" className="img-fluid" />
            </Button>
            <ModalBody>
                <Gallerary 
                    assests = {props.assests}
                    index = {props.index}
                    hasLeftButton ={props.hasLeftButton}
                    hasRightButton ={props.hasRightButton}
                />
            </ModalBody>
        </Modal>
    )
}