import React from "react";
import HeaderFooter from "..";
import Asset from '../../../ASSETS/Images/icons/VRAsset.png';
import NoContentWithAddVirtualTourButton from "../../../COMPONENTS/NoContentPage/NoContentWithAddVirtualTourButton";

export default (props)=>{

    return ( 
        <React.Fragment>
            <HeaderFooter>
                <NoContentWithAddVirtualTourButton 
                    primaryText= {props.primaryText}
                    description= {props.description}
                    noImgIcon= {Asset}
                />
            </HeaderFooter>
        </React.Fragment>
    )
}
