import React, {useState} from "react";
import { Button, Modal, ModalBody } from 'reactstrap'; 
import AddAssets from '../../../ASSETS/Images/icons/Add_Assets.png';
import Close from '../../../ASSETS/Images/icons/cancel.png' 
import 'react-circular-progressbar/dist/styles.css';
import ThreeSixtyImageUploader from "../../DRAGE_FILE_FOLDER/ThreeSixtyImageUploader";
import { useSelector, useDispatch } from 'react-redux';
import { resetThreeSixtyVirtualTourDispatch } from "./../../../SCREENS/360virtualTour/Slices/threeSixtyVirtualImageOnUpload.slice";
import { toast } from 'react-toastify';
import { setDoNotShowExpiredModal } from "../../../SCREENS/Profile/slice.reducer";
import { ExpiredModal } from "../../Modal/Expired";

export default (props)=>{

    const [modal, setModal] = useState(false); 
    const assestsData = useSelector(state => state.assestsData); 
    const userProfileDetails = useSelector(state => state.userProfileDetails)
    const dispatch = useDispatch();
  
    const toggle = () => {

        if(userProfileDetails.isExpired ){
            toast.dismiss()
            dispatch(setDoNotShowExpiredModal(false));
            // setOpenUpgrade(true);
            toast("Your subscription has been expired");
            return;
        }

        dispatch(resetThreeSixtyVirtualTourDispatch());
        if(modal && assestsData.isFileUploadingState){
            props.onFileUpload(true);
            setModal(!modal);
            return;
        }
        setModal(!modal);
    }

    return (
        <React.Fragment>
            <ExpiredModal />
            {/* Add Asset button  */}
            <div className="addAssets">
                <button 
                    className="btn btn-primary"
                    onClick={toggle}
                >Add 360° Images</button>
            </div>
 
            <Modal isOpen={modal} centered className="drop-modal">
                <Button className="modalClose" onClick={toggle}>
                    <img src={Close} alt="Images" className="img-fluid" />
                </Button>
                <ModalBody>
                    <div className="Drop_Area"> 
                        <ThreeSixtyImageUploader  
                            preventToUpload = {()=>setModal(!modal)}
                            toggle = {toggle}
                            isThreeSixtyImage= {true}
                            tour ={props.tour}
                            {...props}
                        />
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    )
} 
