import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isHamburgerOn: false
}

export const hamburgur = createSlice({
  name: 'hamburgur',
  initialState: { ...initialState },
  reducers: {
    setHamburger: (state, action) => {
    //   console.log('this is our hamburgur submit data >>', action)
      return {isHamburgerOn: action.payload}
    },
    resetHamburg: (state, action) => {
    //   console.log('this is our hamburgur submit data >>', action)
        return { ...initialState }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setHamburger, resetHamburg } = hamburgur.actions

export const hamburgurDispatch = (data) => {

  return async(dispatch, getState) => {
    console.log('this is our getData', getState().hamburgur.isHamburgerOn)
    dispatch(setHamburger(!getState().hamburgur.isHamburgerOn));
  }
}

export const resetHamburgDispatch = (data) => {
  return async(dispatch) => {
    dispatch(resetHamburg());
  }
}


export default hamburgur.reducer