import React, {useState, useEffect, useMemo, useCallback } from "react";
import HeaderSideBar from "./../../index";
import { Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from "react-infinite-scroll-component";
import AudioCardList from "./../../../../COMPONENTS/AssestsAndList/Audios/AudioCardList";
import {useLocation} from "react-router-dom";
import { EMPTY_ASSEST_DESCRIPTION_TEXT, EMPTY_ASSEST_PRIMARY_TEXT } from "./../../../../UTILITYS/Constant";
import Asset from './../../../../ASSETS/Images/icons/Asset_icon.png';
import NoContentWithOutUploadPage from "./../../../../COMPONENTS/NoContentPage/NoContentWithOutUploadPage";
import { VRfetchAudioDispatch, VRresetAssests, resetToggleSelectForAudioVRDispatch, setToggleSelectForAudioVRDispatch } from "./../../Slices/VRassestList.slice";
import Breadcrum from "../../../../COMPONENTS/Breadcrum";
import BG from './../../../../ASSETS/Images/BG.jpg';
import MobileBG from './../../../../ASSETS/Images/Mobile-BG.jpg';
import { setParentModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeParentModule.slice";
import { setModuleActiveDispatch } from "../../../../COMPONENTS/SideBarMenu/activeModule.slice";
import { toast } from 'react-toastify';
import BreadCrumWithShareAndDelete from "../../../AssestsManagment/BreadCrumWithShareAndDelete";
import { deleteShareAssest } from "../../../../UTILITYS/endPoints";
import DeleteModal from "../../../../COMPONENTS/DeleteModal";
import Delete from './../../../../ASSETS/Images/icons/delete.svg';
import { Tooltip } from 'reactstrap';
import useLoadNextPage from "./../../../../UTILITYS/hook/loadNextPage";
import { useMediaQuery } from 'react-responsive';
import { useLongPress } from 'use-long-press';

export default (props)=>{

    const dispatch = useDispatch();
    let {state}  = useLocation();
    const [loadEventIndex, seLoadEventIndex] = useState(1);
    const [isAudioEmply, setIsAudioEmply] = useState(true);
    const VRassestList = useSelector(state => state.VRassestList);
    const [room, seTroom] = useState(state.room)
    const [deleteModal, setDeleteModal] = useState(false); 
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [page, loadNextPage, resetValue] = useLoadNextPage(1, VRfetchAudioDispatch, 25, room.relationshipID,
        room.vrRoomMappingID);
    const isMobileView = useMediaQuery({ maxWidth: 767 });
    const [isLongPressEnable, setIsLongPressEnable] = useState(false); 

    const toggleTolltip=() =>{
        setTooltipOpen(!tooltipOpen)
    }

    useEffect(()=>{ 
        dispatch(VRresetAssests())
        dispatch(setParentModuleActiveDispatch("parentVRexperience"))
        dispatch(setModuleActiveDispatch("vr_Experience"))
    }, [])

    // useEffect(()=>{
    //     dispatch(VRfetchAudioDispatch(loadEventIndex, 25, 
    //         room.relationshipID,
    //         room.vrRoomMappingID
    //     ))
    //     // dispatch(VRfetchAudioDispatch(loadEventIndex, 25, userData.userID)) 
    // },[loadEventIndex])

    // const fetchMoreData = () => {
    //     seLoadEventIndex(loadEventIndex+1)
    // };

    const fetchMoreData = () => {
        loadNextPage();
    };

    useEffect(()=>{
        if( VRassestList.audios.length === 0){
            setIsAudioEmply(true)
        }else{
            setIsAudioEmply(false)
        }
    }, [VRassestList.audios])

  

    const getCheckBoxClasses = (id)=>{
        let currentAudio= null;
        if(VRassestList.audios.length>0){
            currentAudio =VRassestList.audios.find(audio=> audio.id === id && audio.isAudioSelected )
        }
        if(currentAudio != null && currentAudio != undefined)
        return "my-1 selected";
        return "my-1";
    }
    const toggleSelectBox = (id)=>{
        if(VRassestList.audios.find(audio=> (audio.id === id && audio.isAudioSelected))){
            dispatch(setToggleSelectForAudioVRDispatch(id))
        }else if(VRassestList.audios.filter(audio=> audio.isAudioSelected).length ===10){
            toast.dismiss();
            toast.error("You can only select maximum 10 Audios", { });
            return
        }else{
            dispatch(setToggleSelectForAudioVRDispatch(id))
        }
    }

    const getAudiosAssestsList = ()=>{
        if(VRassestList.audios.length ===0) return null;
       return  <InfiniteScroll
                    className="row"
                    dataLength={VRassestList.audios}
                    next={fetchMoreData}
                    hasMore={VRassestList.hasMoreAudioAssests}
                    loader={VRassestList.audios.length > 25 ?<h4>Loading...</h4>: ""}
                    endMessage={
                    <p style={{ textAlign: "center" }}>
                        {/* <b>Yay! You have seen it all</b> */}
                    </p>
                }
               >
               {getAudiosAssest()}
           </InfiniteScroll>
    
    }

    const bind = useLongPress((e, params) => {
        // if(!isLongPressEnable){ 
        if(!isLongPressEnable && isMobileView !== undefined && isMobileView){ 
            setIsLongPressEnable(true);
            toggleSelectBox(params.context);
        }
    });

    useEffect(()=>{
        if(VRassestList.audios.filter(audio=> audio.isAudioSelected ).length === 0){
            setIsLongPressEnable(false);
        }
    }, [VRassestList.audios.filter(audio=> audio.isAudioSelected ).length])

    const getAudiosAssest = ()=>{
        return VRassestList.audios.map((audio, index)=>{
            return <AudioCardList 
                        audio={audio} 
                        key={index} 
                        // isFF={true}
                        getCheckBoxClasses ={()=>getCheckBoxClasses(audio.id)}
                        toggleSelectBox ={()=>toggleSelectBox(audio.id)}
                        
                        isMobileView= {isMobileView}
                        bind= {bind}
                        isLongPressEnable= {isLongPressEnable}
                    /> 
        })
        
    }

    const getArrayOfOBJ =()=>{
      
        let audiosIDs= "";
        audiosIDs=  VRassestList.audios.filter(audio=> audio.isAudioSelected ).map(audio=> {
            return audio.id
        }).toString();

        return {
            AssetsIDs: audiosIDs,
            VRExperienceMappintID: room.vrRoomMappingID
        }
    }

    const deletingAssestHandler = async(deleteAssestDATA, dispatch, token)=>{
        let deletRes=  await deleteShareAssest( deleteAssestDATA, dispatch, token);
        dispatch(resetToggleSelectForAudioVRDispatch())
        dispatch(VRresetAssests())
        if(page !== 1){
            resetValue(1);
        }else{
            dispatch(VRfetchAudioDispatch(1, 25, room.relationshipID, room.vrRoomMappingID));
        }
        return deletRes;
    }

    let isAnyOneIsSelected = null; 
    let assestsCounts = null;
    isAnyOneIsSelected =VRassestList.audios.find(audio=> audio.isAudioSelected );
    assestsCounts =VRassestList.audios.filter(audio=> audio.isAudioSelected );

    const memoizedValue = useMemo(() =>["VR Experience", "Audios" ], []);
    const memoizedValueIsAnyOneIsSelected = useMemo(() =>isAnyOneIsSelected, [assestsCounts.length]);
    const memoizedValueAssestsCounts = useMemo(() =>assestsCounts, [assestsCounts.length]);
    const memoizedFunOnUnselectAssest = useCallback(() =>{ dispatch(resetToggleSelectForAudioVRDispatch()) }, [assestsCounts.length]);

    if(isAudioEmply){

        return <HeaderSideBar>
                    <NoContentWithOutUploadPage
                        // breadCrumbItems= {["VR Experience", "Audios" ]}
                        breadCrumbItems= {memoizedValue}
                        primaryText= {EMPTY_ASSEST_PRIMARY_TEXT}
                        description= {EMPTY_ASSEST_DESCRIPTION_TEXT}
                        noImgIcon= {Asset}
                    />
                </HeaderSideBar>

    }
  
    return (  
        <React.Fragment> 
            <HeaderSideBar> 
                {/* Mom (Living Room) (7th VRpic-Sprint-6) */}

                <div className={isAnyOneIsSelected != null && isAnyOneIsSelected != undefined ? 'innerHeader withBorder ps-3 pe-3 ps-md-4 pe-md-4' : 'innerHeader ps-3 pe-3 ps-md-4 pe-md-4'}>
                    <div className="row align-items-center">
                        {/* <Breadcrum
                            breadCrumbItems= {["VR Experience", "Audios"]}
                        /> */}
                            <BreadCrumWithShareAndDelete 
                                // isAnyOneIsSelected= {isAnyOneIsSelected}
                                // assestsCounts ={assestsCounts}
                                // breadCrumbItems = {["VR Experience", "Audios"]}
                                // onUnselectAssest={()=>dispatch(resetToggleSelectForAudioVRDispatch())}
                                isAnyOneIsSelected= {memoizedValueIsAnyOneIsSelected}
                                assestsCounts ={memoizedValueAssestsCounts}
                                breadCrumbItems = {memoizedValue}
                                onUnselectAssest={memoizedFunOnUnselectAssest}
                            />

                        {isAnyOneIsSelected != null && isAnyOneIsSelected != undefined &&
                        <div className="col-sm-6 text-end">
                            <button id={"deleteAssestButton"} className="btn btn-primary btn-sm" onClick={setDeleteModal}>
                                <img src={Delete} alt="Images" className="img-fluid" />
                            </button>
                            <Tooltip placement="bottom" isOpen={tooltipOpen} target={"deleteAssestButton"} toggle={toggleTolltip}>Delete
                            </Tooltip>
                        </div>
                       
                       }   
                    </div>
                </div>

                <div className="pageTitle">
                    <div className="imageHeader">
                        <img src={BG} alt="banner" className="d-none d-md-block w-100" />
                        <img src={MobileBG} alt="banner" className="d-block d-md-none w-100" />
                        <h3>{state.vrUser.name} (Living Room)</h3>
                    </div>
                </div>
                
                <div className="mt-3 mb-3 ps-3 pe-3 ps-md-4 pe-md-4 inner_lists">
                    <div className="assets_item ps-2 pe-2">
                        {getAudiosAssestsList()}
                        
                    </div>
                </div>

                {/* Delete assests */}
                <DeleteModal  
                    isOpen= {deleteModal} 
                    setDeleteModal ={setDeleteModal}
                    getArrayOfOBJ= {getArrayOfOBJ}
                    onDeletingAssest = {deletingAssestHandler}
                />

            </HeaderSideBar>
        </React.Fragment>
    )
}