import React, {useState} from "react";
import { Col, Card, CardBody, CardFooter, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleSelectForIMGDispatch } from "./../../../SCREENS/Home/slice.reducer";
import Close from './../../../ASSETS/Images/icons/cancel.png' 
import { toast } from 'react-toastify';
import { Modal, ModalBody, Button, ModalFooter} from 'reactstrap';
import Gallery from './../../../COMPONENTS/Galaries/Gallerary'
import CarouselModal from "../../Galaries/CarouselModal";
import { setToggleSelectForIMGSharedByThisUser } from "../../../SCREENS/FamilyAndFriends/slice.reducer";
import Delete from './../../../ASSETS/Images/icons/delete.png';
import Edit from './../../../ASSETS/Images/icons/edit.png';
import defaultThreeSixty from './../../../ASSETS/Images/360Tours/defaultThreeSixty.jpg';

export default (props)=>{
 
    const dispatch = useDispatch(); 
    const assestsData = useSelector(state => state.assestsData);
    const sharedAssestWithMeOrBy = useSelector(state => state.sharedAssestWithMeOrBy);
    const [isOpenCarousel, setIsOpenCarousel] = useState(false); 


    const closeCarousel = ()=>{
        setIsOpenCarousel(false)
    }

    return (
        <React.Fragment >

            <Col sm="6" md="4" lg="4" className="ps-1 pe-1" key={props.img.id}>
                <Card className="my-2 cursor-pointer" >
                    <CardBody className="p-0 text-center" onClick={()=>props.onGoToTSworld()}>
                        <img src={props.img.thumbnailUrl? props.img.thumbnailUrl: defaultThreeSixty} alt="image" className="assets_image" />
                    </CardBody>
                    <CardFooter>
                        <Row> 
                            <Col col={11}>
                                <p className="mb-0 lead">{props.img.name}</p>
                            </Col>
                            <Col col={1} className="align-end" > 
                                <img src={Edit} alt="Images" className="margin" onClick={()=>{props.onUpdate(props.img)}} />
                                <img src={Delete} alt="Images" className="margin" onClick={()=>{props.onDelete(props.img)}} />
                            </Col>
                        </Row> 
                    </CardFooter>
                </Card>
            </Col> 

            {/* CAROUSAL MODAL */}
                {/* <CarouselModal 
                    assests= {props.assests}
                    isOpenCarousel= {isOpenCarousel}
                    closeCarousel ={closeCarousel}
                    index ={props.index}
                /> */}
        </React.Fragment>
    )
}