import React from "react";
import HeaderFooter from "..";
import Asset from '../../../ASSETS/Images/icons/VRAsset.png';
import { useDispatch } from 'react-redux'
import NoContentWithAddVRexpeButton from "../../../COMPONENTS/NoContentPage/NoContentWithAddVRexpeButton";
import { NO_VR_USER_DESCRIPTION_TEXT, NO_VR_USER_PRIMARY_TEXT } from "../../../UTILITYS/Constant";

export default (props)=>{

    const dispatch = useDispatch();
    
    const onVRuserCreated = ()=>{
       
    }

    return (
        <React.Fragment>
            <HeaderFooter>
                <NoContentWithAddVRexpeButton  
                    onApply={onVRuserCreated} 
                    primaryText= {NO_VR_USER_PRIMARY_TEXT}
                    description= {NO_VR_USER_DESCRIPTION_TEXT}
                    noImgIcon= {Asset}
                />
            </HeaderFooter>
        </React.Fragment>
    )
}
