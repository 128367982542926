import React from "react";
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';

export default (props) =>{

    let isVrMenu =props.tabHeading === "VR Experience"? true: false
    
    const subMenu = ()=>{
        return props.subMen.map((item, index)=>{
            // console.log('JFKDF KJIFE EE', item)
            return (
                <DropdownItem 
                    key={index}
                    onClick={(e)=>{
                        e.stopPropagation();
                        item.onClick(item.subModule)
                    }} 
                    className={item.dropdownItemClasses ? `${item.dropdownItemClasses}`: "" }>
                        {item.subHeading}
                </DropdownItem>
            )
        })
    }

    return (<UncontrolledDropdown nav inNavbar className={props.dropdownClasses ? `${props.dropdownClasses}`: ""} 
    onClick= {()=>props.setThisModuleActive(props.currentModule)} 
    // disabled = {(props.currentModule== "parentAssestManagment" || props.currentModule == "parentFamilyFreind")? true: false}
    >
                <DropdownToggle nav caret className={isVrMenu ? "vr-exp": ""}>
                    <img src={props.headingAvatar} alt="User" />
                    <span>{props.tabHeading}</span>
                </DropdownToggle>
                <DropdownMenu end className={props.dropdownMenuClasses ? `${props.dropdownMenuClasses}`: ""}>
                   {subMenu()}
                </DropdownMenu>
            </UncontrolledDropdown>)
}
