import React, { useState, useEffect } from "react";
import Dashboard from "../Dashboard";
import { useDispatch, useSelector } from 'react-redux'
import { fetchSelFAUDIOassestDispatch, fetchSelFVEDIOassestDispatch, fetchSelFIMGassestDispatch } from "./slice.reducer";
import { fetchUserDetailsDispatch } from "../Profile/slice.reducer.tsx";
import { toast } from 'react-toastify';
import { setParentModuleActiveDispatch } from "../../COMPONENTS/SideBarMenu/activeParentModule.slice";

export default (props)=>{

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, []);

    // const dispatch = useDispatch();
    // const userProfileDetails = useSelector(state => state.userProfileDetails)   
    
    // useEffect(()=>{
    //     dispatch(setParentModuleActiveDispatch("parentDashboard"))
    // }, []) 

    // // TO GET YOUR ASSEST THAT YOU UPLOADED
    // useEffect(()=>{
    //     dispatch(fetchSelFAUDIOassestDispatch(1, 1))
    //     dispatch(fetchSelFVEDIOassestDispatch(1, 1))
    //     dispatch(fetchSelFIMGassestDispatch(1, 1))
    // }, [])

    // // THIS IS FIRST PAGE AFTER LOGING, SO GOOD TO GET USER DETAILS
    // useEffect(()=>{
    //     console.log('oj mey profile iof ths 4') 
    //     dispatch(fetchUserDetailsDispatch());
    // }, []);
   
    // useEffect(()=>{
    //     console.log("this is a usesrprofile useEffect userProfileDetails.isUserFetchFaild", userProfileDetails.isUserFetchFaild)
    //     if(userProfileDetails.isUserFetchFaild!== null){
    //         toast.error(userProfileDetails.isUserFetchFaild, { });
    //     }
    // }, [userProfileDetails.isUserFetchFaild]);

    return (
        <React.Fragment>
            <Dashboard>
                {props.children}
            </Dashboard>
        </React.Fragment>
    )
}